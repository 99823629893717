import useApi from "@/hooks/useApi";
import React from "react";
import CreateTeamModal from "./CreateTeamModal";
import EditTeamModal from "./EditTeamModal";
import useTeams from "@/hooks/useTeams";
import { Team } from "./types";

export default function Teams() {
  const [showCreateModal, setShowCreateModal] = React.useState(false);
  const [editTeamId, setEditTeamId] = React.useState<number>();
  const api = useApi();

  const { teams, setTeams } = useTeams();

  async function deleteTeam(id: number) {
    if (teams.length === 1) {
      alert("Unable to delete team. You must always have at least one team.");
      return;
    }
    if (
      confirm(
        "Are you sure you want to delete this team? Deleting this team could cause users to no longer be apart of a team."
      )
    ) {
      await api.messaging.delete(`Teams/${id}`);
      setTeams(teams.filter((x) => x.id !== id));
    }
  }

  return (
    <>
      <div className="ml-auto">
        <button
          className="btn-green m-2"
          onClick={() => setShowCreateModal(true)}
        >
          New Team
        </button>
      </div>
      <table className="w-full bg-white">
        <thead>
          <tr>
            <th className={thClassName}>Name</th>
            <th className={thClassName}>Id</th>
            <th className={thClassName}>Auto Routing Enabled</th>
            <th className={thClassName}>Auto Routing Type</th>
            {/* <th className={thClassName}>Default Team</th> */}
            <th className={thClassName}>Auto Unassignment Enabled</th>
            <th className={thClassName}>Auto Unassignment Warning</th>
            <th className={thClassName}>Auto Unassignment Timeout</th>
            <th className={thClassName}>Auto Resolve Enabled</th>
            <th className={thClassName}>Auto Resolve Timeout</th>
            <th className={thClassName} style={{ width: 1 }}></th>
          </tr>
        </thead>
        <tbody>
          {teams.map((team) => (
            <tr key={team.id}>
              <td className={tdClassName}>{team.name}</td>
              <td className={tdClassName}>{team.id}</td>
              <td className={tdClassName}>{String(team.autoRoute)}</td>
              <td className={tdClassName}>{team.routingType}</td>
              <td className={tdClassName}>{String(team.autoUnassign)}</td>
              <td className={tdClassName}>{team.unassignWarningTime}</td>
              <td className={tdClassName}>{team.unassignTime}</td>
              <td className={tdClassName}>{String(team.autoResolve)}</td>
              <td className={tdClassName}>{team.autoResolveTime}</td>
              {/* <td className={tdClassName}>{String(team.isDefault)}</td> */}
              <td className={`${tdClassName} text-sm whitespace-nowrap`}>
                <button
                  className="underline"
                  onClick={() => setEditTeamId(team.id)}
                >
                  Edit
                </button>
                <span className="mx-2">&bull;</span>
                <button
                  className="underline"
                  onClick={() => deleteTeam(team.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showCreateModal && (
        <CreateTeamModal
          show={showCreateModal}
          onClose={() => setShowCreateModal(false)}
          onTeamCreated={(team: Team) => {
            setShowCreateModal(false);
            setTeams([...teams, team]);
          }}
        />
      )}
      {editTeamId && (
        <EditTeamModal
          teamId={editTeamId}
          onClose={() => setEditTeamId(undefined)}
          onTeamUpdated={(team: Team) => {
            setEditTeamId(undefined);
            setTeams(teams.map((x) => (x.id === team.id ? team : x)));
          }}
        />
      )}
    </>
  );
}

const thClassName = "border p-2 text-left";
const tdClassName = "border p-2";
