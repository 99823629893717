import React from "react";

export default function IllustrationMissedChatsUnavailable() {
  return (
    <svg viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M130.97 48.19L377.95 56.22C419.14 57.56 453.49 89.33 456.7 127.24L472.72 316.56C475.93 354.47 447.41 391.56 407.03 399.81L164.91 449.24C106.35 461.2 52.42 431.34 48.22 381.77L27.27 134.29C23.08 84.73 71.23 46.23 130.96 48.17L130.97 48.19Z"
        fill="#EEF4FD"
      />
      <path
        d="M315.103 50.5866L307.31 52.3971L317.637 96.8432L325.429 95.0327L315.103 50.5866Z"
        fill="black"
      />
      <path
        d="M452.254 128.124L403.694 133.065L404.504 141.024L453.064 136.083L452.254 128.124Z"
        fill="black"
      />
      <path
        d="M400.499 49.6858L382.647 85.7269L389.816 89.2777L407.668 53.2366L400.499 49.6858Z"
        fill="black"
      />
      <path
        d="M387.41 321.44L364.79 136.89C363.14 123.44 350.9 113.87 337.45 115.52L121.85 141.71C108.4 143.36 98.8301 155.6 100.48 169.05L107.62 355.73C109.27 369.18 121.51 378.75 134.96 377.1L156.2 374.5L164.32 448.53L251.38 362.83L366.03 348.78C379.48 347.13 389.05 334.89 387.4 321.44H387.41Z"
        fill="black"
      />
      <path
        d="M402.88 314.9L380.26 130.35C378.61 116.9 366.37 107.33 352.92 108.98L137.32 135.17C123.87 136.82 114.3 149.06 115.95 162.51L123.09 349.19C124.74 362.64 136.98 372.21 150.43 370.56L171.67 367.96L178.34 428.08L266.85 356.29L381.5 342.24C394.95 340.59 404.52 328.35 402.87 314.9H402.88Z"
        fill="white"
      />
      <path
        d="M175.18 435.79L168.15 372.41L150.93 374.52C135.31 376.44 121.04 365.28 119.13 349.66L119.1 349.33L111.97 162.81C111.09 155.3 113.17 147.89 117.83 141.93C122.52 135.92 129.28 132.11 136.84 131.18L352.44 104.99C368.05 103.08 382.32 114.23 384.24 129.85L406.86 314.4C408.77 330.02 397.62 344.29 382 346.2L268.5 360.11L175.19 435.78L175.18 435.79ZM175.21 363.49L181.52 420.35L265.24 352.45L381.03 338.26C392.27 336.88 400.3 326.61 398.92 315.37L376.3 130.82C374.92 119.58 364.66 111.55 353.41 112.93L137.81 139.12C132.37 139.79 127.51 142.54 124.13 146.86C120.75 151.18 119.26 156.56 119.93 162.01L119.96 162.34L127.09 348.85C128.54 360.01 138.77 367.95 149.96 366.58L175.21 363.48V363.49Z"
        fill="black"
      />
      <path
        d="M270.27 270.47C280.2 268.2 292.92 268.6 304.32 277.28C305.34 278.05 306.25 278.97 307.17 279.86C308.65 281.28 308.92 282.61 306.94 283.9C305.87 284.6 304.93 285.49 303.93 286.3C298.66 290.58 298.7 290.52 293.04 286.87C288.79 284.13 283.9 283.08 279.01 283.5C267.68 284.47 257.36 288.05 249.9 297.24C247.99 299.59 246.5 299.96 243.79 298.56C235.29 294.16 235.05 293.59 241.48 286.49C248.39 278.85 256.96 273.91 270.28 270.48L270.27 270.47Z"
        fill="black"
      />
      <path
        d="M206.98 221.8C206.42 220.53 205.23 220.19 204.29 219.58C195.91 214.24 196.02 214.34 202.12 206.49C204.3 203.68 206.01 203.18 208.42 205.94C209.08 206.7 210.04 207.2 210.85 207.83C218.14 213.48 215.91 213.62 223 207.09C223.28 206.83 223.55 206.55 223.82 206.28C229.86 200.42 229.79 200.48 235.4 206.93C236.98 208.75 236.77 209.91 235.18 211.44C232.77 213.75 230.57 216.27 228.15 218.56C226.72 219.91 226.89 220.75 228.38 221.87C230.85 223.71 233.14 225.79 235.62 227.62C237.64 229.11 238.1 230.38 236.43 232.63C232.41 238.04 232.53 238.12 227.01 234.42C221.217 230.533 215.893 231.03 211.04 235.91C210.22 236.73 209.36 237.5 208.59 238.36C207.11 240.01 205.69 240.14 204.01 238.58C195.73 230.97 196.14 233.11 203.27 225.95C204.58 224.63 206.53 223.83 207 221.81L206.98 221.8Z"
        fill="black"
      />
      <path
        d="M286.23 208.47C285.67 207.2 284.48 206.86 283.54 206.25C275.16 200.91 275.27 201.01 281.37 193.16C283.55 190.35 285.26 189.85 287.67 192.61C288.33 193.37 289.29 193.87 290.1 194.5C297.39 200.15 295.16 200.29 302.25 193.76C302.53 193.5 302.8 193.22 303.07 192.95C309.11 187.09 309.04 187.15 314.65 193.6C316.23 195.42 316.02 196.58 314.43 198.11C312.02 200.42 309.82 202.94 307.4 205.23C305.97 206.58 306.14 207.42 307.63 208.54C310.1 210.38 312.39 212.46 314.87 214.29C316.89 215.78 317.35 217.05 315.68 219.3C311.66 224.71 311.78 224.79 306.26 221.09C300.467 217.203 295.143 217.7 290.29 222.58C289.47 223.4 288.61 224.17 287.84 225.03C286.36 226.68 284.94 226.81 283.26 225.25C274.98 217.64 275.39 219.78 282.52 212.62C283.83 211.3 285.78 210.5 286.25 208.48L286.23 208.47Z"
        fill="black"
      />
      <path
        d="M155.59 83.72L47.34 112.28L61.76 176.26L127.6 168.15L162.39 190.59L162.09 152.58L175.66 149.96L155.59 83.72Z"
        fill="black"
      />
      <path
        d="M166.45 197.96L126.65 172.29L58.65 180.67L42.59 109.4L158.29 78.87L180.77 153.05L166.11 155.88L166.44 197.97L166.45 197.96ZM128.55 164L158.33 183.21L158.06 149.28L170.54 146.87L152.87 88.56L52.09 115.15L64.86 171.83L128.54 163.98L128.55 164Z"
        fill="black"
      />
      <path
        d="M159.59 78.6L51.34 107.16L65.76 171.14L130.43 158.68L160.87 182.78L162.09 152.58L175.66 149.96L159.59 78.6Z"
        fill="#AFCFF8"
      />
      <path
        d="M164.55 190.79L129.38 162.96L62.71 175.81L46.6 104.29L162.58 73.69L180.48 153.13L165.97 155.93L164.56 190.81L164.55 190.79ZM131.47 154.41L157.19 174.77L158.22 149.26L170.85 146.83L156.59 83.54L56.09 110.06L68.8 166.49L131.46 154.42L131.47 154.41Z"
        fill="black"
      />
      <path
        d="M86.5118 140.96C89.9761 140.179 91.9025 135.633 90.8146 130.806C89.7267 125.978 86.0364 122.698 82.5721 123.478C79.1078 124.259 77.1814 128.805 78.2693 133.633C79.3572 138.46 83.0475 141.741 86.5118 140.96Z"
        fill="black"
      />
      <path
        d="M113.515 134.883C116.979 134.102 118.905 129.556 117.817 124.728C116.73 119.901 113.039 116.621 109.575 117.401C106.111 118.182 104.184 122.728 105.272 127.556C106.36 132.383 110.05 135.664 113.515 134.883Z"
        fill="black"
      />
      <path
        d="M141.009 128.681C144.473 127.9 146.4 123.354 145.312 118.527C144.224 113.699 140.534 110.419 137.069 111.199C133.605 111.98 131.679 116.526 132.766 121.354C133.854 126.181 137.545 129.462 141.009 128.681Z"
        fill="black"
      />
    </svg>
  );
}
