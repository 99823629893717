import React from "react";
import { IconProps } from "./types";

function IconExpand(props: IconProps) {
  return (
    <svg
      id="expand-sidebar"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_10463"
            data-name="Rectangle 10463"
            width="24"
            height="24"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="Group_9649" data-name="Group 9649" clipPath="url(#clip-path)">
        <path
          id="Path_2608"
          data-name="Path 2608"
          d="M10.8,12.75h5.669L14.28,14.937A.75.75,0,0,0,15.34,16l3.467-3.467a.751.751,0,0,0,0-1.06L15.34,8a.75.75,0,0,0-1.06,1.061l2.187,2.187H10.8a.75.75,0,0,0,0,1.5"
          fill="#fff"
        />
        <path
          id="Path_2609"
          data-name="Path 2609"
          d="M16.725,23.253H11.67a.879.879,0,0,1,0-1.758h5.055a3.52,3.52,0,0,0,3.516-3.517V6.022a3.488,3.488,0,0,0-1.406-2.813A.879.879,0,0,1,19.891,1.8,5.235,5.235,0,0,1,22,6.022V17.978a5.281,5.281,0,0,1-5.275,5.275"
          fill="#fff"
        />
        <path
          id="Path_2610"
          data-name="Path 2610"
          d="M8.154.748H7.275A5.281,5.281,0,0,0,2,6.022V17.978a5.28,5.28,0,0,0,5.275,5.274h.879a.878.878,0,0,0,.879-.879V1.627A.878.878,0,0,0,8.154.748M7.275,21.494a3.519,3.519,0,0,1-3.516-3.516V6.022A3.52,3.52,0,0,1,7.275,2.506Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default IconExpand;
