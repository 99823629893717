import useAIConfigs from "@/hooks/useAIConfigs";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export interface UseAIValue {
  aiConfig: string;
}

interface Props {
  updateValue: (value: UseAIValue) => void;
  value: UseAIValue;
}

export default function AIRuleform({ value, updateValue }: Props) {
  const { aiConfigurations } = useAIConfigs();
  const keys = Object.keys(aiConfigurations || {});

  useEffect(() => {
    if (!value.aiConfig && keys.length > 0) {
      updateValue({ aiConfig: keys[0] });
    }
  }, [keys, updateValue, value]);

  if (!aiConfigurations || !keys.length) {
    return (
      <>
        <span>{"You don't have any ai configurations yet."}</span>
        <Link to="/bots">
          <button type="button" className="btn-link">
            Create one now.
          </button>
        </Link>
      </>
    );
  }

  return (
    <>
      <div>Use AI as chatbots</div>
      <div>
        Set the conditions for when an AI configuration should be used to handle
        a chat.
      </div>
      <div className="mb-4">
        <div className="p-4">
          <div className="flex flex-col mr-auto">
            <select
              className="flex flex-row input overflow-ellipsis whitespace-nowrap w-64 ml-2"
              value={value["aiConfig"]}
              onChange={(e) => updateValue({ aiConfig: e.currentTarget.value })}
            >
              {aiConfigurations.map((c, i) => (
                <option key={i} value={c.id}>
                  {c.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
}
