import React from "react";

export default function IllustrationFeedbackSurveyPostChat() {
  return (
    <svg viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M130.97 48.19L377.95 56.22C419.14 57.56 453.49 89.33 456.7 127.24L472.72 316.56C475.93 354.47 447.41 391.56 407.03 399.81L164.91 449.24C106.35 461.2 52.42 431.34 48.22 381.77L27.27 134.29C23.08 84.73 71.23 46.23 130.96 48.17L130.97 48.19Z"
        fill="#EEF4FD"
      />
      <path
        d="M433.89 257.95L232.42 285.03L243.95 409.53L375.2 384.09L412.26 409.53L414.18 372.75L457 366.45L433.89 257.95Z"
        fill="black"
      />
      <path
        d="M172.23 254.39L402.05 221.99L378.07 91.62L43.07 125.63L43 286.22L89.14 279.69L93.31 333.74L172.23 254.39Z"
        fill="black"
      />
      <path
        d="M172.23 254.39L420.46 220.84L396.48 90.47L61.48 124.48L61.42 269.37L106.16 263.33L111.45 315.5L172.23 254.39Z"
        fill="#629FF0"
      />
      <path
        d="M108.33 324.31L102.6 267.84L57.42 273.95L57.49 120.87L399.76 86.12L425.17 224.24L174.12 258.17L108.34 324.31H108.33ZM109.72 258.81L114.58 306.69L170.35 250.62L415.77 217.45L393.22 94.84L65.48 128.11L65.42 264.81L109.72 258.82V258.81Z"
        fill="black"
      />
      <path
        d="M146.74 172.5L175 169.63L156.4 190.63L167.07 218.74L140.26 208.18L117.92 226.38L119.41 196.38L95.47 181.99L123.88 176.06L130.74 146.92L146.74 172.5Z"
        fill="white"
      />
      <path
        d="M252.45 159.44L280.71 156.57L262.11 177.56L272.78 205.68L245.97 195.12L223.63 213.31L225.12 183.31L201.17 168.93L229.59 162.99L236.45 133.86L252.45 159.44Z"
        fill="white"
      />
      <path
        d="M358.16 146.37L386.41 143.5L367.82 164.5L378.49 192.61L351.68 182.05L329.34 200.25L330.83 170.25L306.88 155.86L335.3 149.93L342.16 120.79L358.16 146.37Z"
        fill="white"
      />
      <path
        d="M433.89 257.95L243.95 283.48L260.03 395.09L367.54 378.83L412.26 409.53L414.18 372.75L457 366.45L433.89 257.95Z"
        fill="white"
      />
      <path
        d="M415.88 416.87L366.57 383.02L256.65 399.64L239.41 280.05L437.03 253.49L461.8 369.78L418 376.23L415.88 416.87ZM368.5 374.64L408.64 402.19L410.36 369.27L452.21 363.11L430.76 262.41L248.49 286.91L263.43 390.53L368.51 374.64H368.5Z"
        fill="black"
      />
      <path
        d="M401.419 292.371L328.573 303.948L329.828 311.848L402.674 300.272L401.419 292.371Z"
        fill="black"
      />
      <path
        d="M405.964 331.991L284.597 347.151L285.588 355.09L406.955 339.93L405.964 331.991Z"
        fill="black"
      />
    </svg>
  );
}
