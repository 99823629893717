export default function initPreview(el) {
  window.__messenger_preview = window.__messenger_preview || {
    el,
    zIndex: "1"
  };

  (function (n, t, c) {
    function i(n) {
      return e._h ? e._h.apply(null, n) : e._q.push(n);
    }
    var e = {
      _q: [],
      _h: null,
      _v: "1.0",
      on: function () {
        i(["on", c.call(arguments)]);
      },
      once: function () {
        i(["once", c.call(arguments)]);
      },
      off: function () {
        i(["off", c.call(arguments)]);
      },
      get: function () {
        if (!e._h)
          throw new Error(
            "[MessengerPreview] You can't use getters before load."
          );
        return i(["get", c.call(arguments)]);
      },
      call: function () {
        i(["call", c.call(arguments)]);
      },
      init: function () {
        var n = t.createElement("script");
        n.id = "messenger-preview-script";
        n.async = !0;
        n.type = "module";
        n.src = import.meta.env.VITE_MESSENGER_PREVIEW_URL + "?v=" + Date.now();
        t.head.appendChild(n);
      }
    };
    !n.__messenger_preview.asyncInit && e.init(),
      (n.MessengerPreview = n.MessengerPreview || e);
  })(window, document, [].slice);
}
