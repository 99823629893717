import React from "react";
import { IconProps } from "./types";

function IconChevronDown(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.77583 5.17574C3.01015 4.94142 3.39005 4.94142 3.62436 5.17574L8.0001 9.55147L12.3758 5.17574C12.6101 4.94142 12.99 4.94142 13.2244 5.17574C13.4587 5.41005 13.4587 5.78995 13.2244 6.02426L8.42436 10.8243C8.19005 11.0586 7.81015 11.0586 7.57583 10.8243L2.77583 6.02426C2.54152 5.78995 2.54152 5.41005 2.77583 5.17574Z"
        fill="#64748B"
      />
    </svg>
  );
}

export default IconChevronDown;
