import * as React from "react";

interface Props {
  onChange: (value: number) => void;
  value: number;
}

export default function MinuteSelect(props: Props) {
  function onChange(event: any) {
    props.onChange(Number(event.currentTarget.value));
  }

  function getOptions() {
    const options = [];
    for (let i = 0; i < 60; i += 5) {
      let name = i.toString();
      if (i < 10) {
        name = "0" + i;
      }
      options.push({
        name: name,
        value: i
      });
    }
    return options;
  }

  return (
    <select
      value={props.value}
      onChange={onChange}
      className="underline appearance-none"
    >
      {getOptions().map((minute, i) => (
        <option key={i} value={minute.value}>
          {minute.name}
        </option>
      ))}
    </select>
  );
}
