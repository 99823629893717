import * as React from "react";
import SchedulesList from "./SchedulesList";
import { useState, useEffect, useCallback } from "react";
import ScheduleEditor from "./ScheduleEditor";
import daysOfWeek from "./helpers/daysOfWeek";
import Modal from "@velaro/velaro-shared/src/components/Modal";
import { SchedulingModel, WorkSchedule } from "./types";
import useApi from "@/hooks/useApi";
import { set } from "lodash";

export default function ScheduleManager() {
  const [selectedScheduleIndex, setSelectedScheduleIndex] = useState<number>();
  const [schedulingModel, setSchedulingModel] = useState<SchedulingModel>({
    schedules: []
  } as any);
  const [loading, setLoading] = useState<boolean>(true);
  const [pendingChanges, setPendingChanges] = useState<boolean>(false);
  const [newScheduleModal, setNewScheduleModal] = useState<boolean>(false);

  const api = useApi();

  function onAddSchedule() {
    const newSchedule: any = {
      name: "New schedule",
      enabled: true,
      shifts: [
        {
          startDayOfWeek: daysOfWeek.Monday,
          startHour: 9,
          startMinute: 0,
          endDayOfWeek: daysOfWeek.Monday,
          endHour: 17,
          endMinute: 0
        }
      ],
      teams: []
    };
    setNewScheduleModal(true);
    schedulingModel.schedules.push(newSchedule);
    setSelectedScheduleIndex(schedulingModel.schedules.indexOf(newSchedule));
    onSchedulingModelUpdate();
  }

  function onSelectSchedule(selectedIndex: number) {
    setSelectedScheduleIndex(selectedIndex);
  }

  function onDeleteSchedule(schedule: WorkSchedule) {
    setSelectedScheduleIndex(undefined);

    if (confirm("Are you sure you want to delete this schedule?")) {
      const index = schedulingModel.schedules.indexOf(schedule);
      schedulingModel.schedules.splice(index, 1);
      setNewScheduleModal(false);
      onSchedulingModelUpdate();
    }
  }

  function onToggleScheduleEnabled(selectedIndex: number) {
    const schedule = schedulingModel.schedules[selectedIndex];
    schedule.enabled = !schedule.enabled;
    onSchedulingModelUpdate();
  }

  function onConfirmScheduleChanges(newSchedule: WorkSchedule) {
    // todo: this looks pretty wrong.
    setSelectedScheduleIndex(undefined);
    setNewScheduleModal(false);
    schedulingModel.schedules[selectedScheduleIndex!] = newSchedule;
    onSchedulingModelUpdate();
  }

  function onToggleEnableScheduling() {
    schedulingModel.enableScheduling = !schedulingModel.enableScheduling;
    onSchedulingModelUpdate();
  }

  function onToggleUnscheduledTeamsAlwaysUnavailable() {
    schedulingModel.unscheduledTeamsAlwaysUnavailable =
      !schedulingModel.unscheduledTeamsAlwaysUnavailable;
    onSchedulingModelUpdate();
  }

  function onSchedulingModelUpdate() {
    setSchedulingModel({ ...schedulingModel });
    setPendingChanges(true);
  }

  const fetchSchedules = useCallback(async () => {
    const resp = await api.messaging.get("WorkSchedules/List");
    const model = await resp.json();
    setSchedulingModel(model);
    setLoading(false);
  }, [api.messaging]);

  async function onSave() {
    setPendingChanges(false);
    const resp = await api.messaging.post("WorkSchedules", schedulingModel);
  }

  useEffect(() => {
    fetchSchedules();
  }, [fetchSchedules]);

  function renderSaveButton() {
    if (!pendingChanges) {
      return;
    }

    return (
      <button className="btn-green" onClick={onSave}>
        <i className="fa fa-save" />
        Save all
      </button>
    );
  }

  function renderEditorModal() {
    if (selectedScheduleIndex === undefined) {
      return;
    }

    return (
      <Modal show maxWidth="max-w-4xl">
        <ScheduleEditor
          schedule={schedulingModel.schedules[selectedScheduleIndex]}
          onConfirm={onConfirmScheduleChanges}
          onDeleteSchedule={onDeleteSchedule}
          onReturn={() => {
            setSelectedScheduleIndex(undefined);
          }}
          isNewSchedule={newScheduleModal}
        />
      </Modal>
    );
  }
  function renderScheduleList() {
    if (!schedulingModel.schedules.length) {
      return <div>Please add new schedule</div>;
    }

    return (
      <SchedulesList
        schedules={schedulingModel.schedules}
        onSelectSchedule={onSelectSchedule}
        onToggleScheduleEnabled={onToggleScheduleEnabled}
      />
    );
  }

  function renderContent() {
    if (loading) {
      return <div>Loading</div>;
    }

    return (
      <React.Fragment>
        <div className="mb-4">{renderScheduleList()}</div>
        <div>{renderSaveButton()}</div>
        {renderEditorModal()}
      </React.Fragment>
    );
  }

  return (
    <>
      <div className="ml-auto">
        <button className="btn-green m-2" onClick={onAddSchedule}>
          Add schedule
        </button>
      </div>
      <div className="m-4 pb-12">
        <div className="work-schedules">{renderContent()}</div>
      </div>
    </>
  );
}
