import React from "react";
import { IconProps } from "./types";

function IconTicket({ className = "stroke-slate-500", ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      className={className}
      {...props}
    >
      <path
        d="M13.5834 10.6668V9.3335M13.5834 16.6668V15.3335M13.5834 22.6668V21.3335M7.18341 5.3335H25.3167C26.8102 5.3335 27.557 5.3335 28.1274 5.62415C28.6292 5.87981 29.0371 6.28776 29.2928 6.78952C29.5834 7.35995 29.5834 8.10669 29.5834 9.60016V11.3335C27.0061 11.3335 24.9167 13.4228 24.9167 16.0002C24.9167 18.5775 27.0061 20.6668 29.5834 20.6668V22.4002C29.5834 23.8936 29.5834 24.6404 29.2928 25.2108C29.0371 25.7126 28.6292 26.1205 28.1274 26.3762C27.557 26.6668 26.8102 26.6668 25.3167 26.6668H7.18342C5.68994 26.6668 4.9432 26.6668 4.37277 26.3762C3.87101 26.1205 3.46306 25.7126 3.2074 25.2108C2.91675 24.6404 2.91675 23.8936 2.91675 22.4002V20.6668C5.49408 20.6668 7.58341 18.5775 7.58341 16.0002C7.58341 13.4228 5.49408 11.3335 2.91675 11.3335V9.60016C2.91675 8.10669 2.91675 7.35995 3.2074 6.78952C3.46306 6.28776 3.87101 5.87981 4.37277 5.62415C4.9432 5.3335 5.68994 5.3335 7.18341 5.3335Z"
        stroke="inherit"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconTicket;
