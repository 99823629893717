import { createContext } from "react";
import { StyleConfigModel } from "../../types";

interface Props {
  styleConfigs: StyleConfigModel[];
  setStyleConfigs(configs: StyleConfigModel[]): void;
  loading: boolean;
}

const StyleConfigsContext = createContext<Props>({} as Props);

export default StyleConfigsContext;
