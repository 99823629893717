import React, { useState } from "react";

interface Props {
  label: string;
  selected: boolean;
  onSelect: () => void;
}

export default function Tab({ label, selected, onSelect }: Props) {
  return (
    <button
      className={`min-w-[150px] h-16 flex flex-col flex-1 items-center justify-center border ${
        selected && "bg-white border-none"
      }`}
      onClick={onSelect}
    >
      <div className="text-sm">{label}</div>
    </button>
  );
}
