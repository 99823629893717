import useTeams from "@/hooks/useTeams";
import React from "react";
import Select from "../Select";

export default function TeamSelectDeprecated(
  props: React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  >
) {
  const { teams } = useTeams();

  return (
    <>
      <select {...props}>
        <option key={""} value={""}>
          -- Select --
        </option>
        {teams.map((team) => (
          <option key={team.id} value={team.id}>
            {team.name}
          </option>
        ))}
      </select>
    </>
  );
}

export function TeamSelect({
  className,
  value,
  onChange
}: {
  className?: string;
  value: number | undefined;
  onChange: (value: number) => void;
}) {
  const { teamOptions } = useTeams();

  return (
    <Select<number>
      className={className}
      value={value}
      options={teamOptions}
      onChange={onChange}
    />
  );
}
