import React from "react";
import ProfileContext, { defaultProfile, Profile } from "@velaro/velaro-shared/src/context/ProfileContext";
import useApi from "../hooks/useApi";

export default function ProfileProvider(props: { children: React.ReactNode }) {
  const [loading, setLoading] = React.useState(true);
  const [profile, setProfile] = React.useState<Profile>(defaultProfile);
  const api = useApi();

  React.useEffect(() => {
    async function load() {
      const response = await api.messaging.get("Profile/Me");
      setProfile(await response.json());
      setLoading(false);
    }

    load();
  }, [api]);

  const groupMap = React.useMemo(() => {
    const map: { [groupId: number]: boolean } = {};

    if (!profile.teamIds) {
      return map;
    }

    profile.teamIds.forEach((groupId) => {
      map[groupId] = true;
    });

    return map;
  }, [profile.teamIds]);

  const belongsToGroup = React.useCallback(
    (groupId: number) => {
      // null/undefined group id means all users can see.

      if (!groupId) {
        return true;
      }

      return groupMap[groupId] === true;
    },
    [groupMap]
  );

  return (
    <ProfileContext.Provider
      value={{ profile, loading, setProfile, belongsToGroup }}
    >
      {props.children}
    </ProfileContext.Provider>
  );
}
