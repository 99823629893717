import React from "react";
import { IconProps } from "./types";

function IconEditPencil(props: IconProps) {
  return (
    <svg
      id="edit-pencil"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <rect
        id="Rectangle_596"
        data-name="Rectangle 596"
        width="24"
        height="24"
        fill="#3383ec"
        opacity="0"
      />
      <g id="Group_356" data-name="Group 356" transform="translate(0.45 0.5)">
        <path
          id="Path_515"
          data-name="Path 515"
          d="M20.685,1.418a3.315,3.315,0,0,0-4.58,0L4.155,13.367a1.552,1.552,0,0,0,0,2.191l2.388,2.388a1.55,1.55,0,0,0,2.191,0L18.08,8.6l0-.007.007,0L20.685,6a3.238,3.238,0,0,0,0-4.58m-1.21,3.37L16.881,7.382l0,.007-.007,0-9.23,9.23-2.161-2.16L17.315,2.628a1.563,1.563,0,0,1,2.16,0,1.526,1.526,0,0,1,0,2.16"
          transform="translate(-0.094 0)"
        />
        <path
          id="Path_516"
          data-name="Path 516"
          d="M4.784,19.987a2.075,2.075,0,0,1-1.468-3.544l1.128-1.128a.642.642,0,1,1,.908.907L4.222,17.351a.793.793,0,0,0,1.122,1.122l1.129-1.129a.642.642,0,0,1,.907.908L6.252,19.38a2.067,2.067,0,0,1-1.468.607"
          transform="translate(-0.05 -0.636)"
        />
        <path
          id="Path_517"
          data-name="Path 517"
          d="M13.56,23.28H3.635A2.085,2.085,0,0,1,2.16,19.721l1.1-1.1a.642.642,0,0,1,.907.908l-1.1,1.1A.8.8,0,0,0,3.635,22H13.56a.642.642,0,1,1,0,1.284"
          transform="translate(0 -0.779)"
        />
        <path
          id="Path_518"
          data-name="Path 518"
          d="M17.293,8.929l.837-.98L14.758,4.577a.642.642,0,1,0-.908.907Z"
          transform="translate(-0.526 -0.169)"
        />
      </g>
    </svg>
  );
}

export default IconEditPencil;
