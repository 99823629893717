import React from "react";

interface Props {
  value: string;
  onChange(value: string): void;
}

function deserializeString(str: string) {
  if (str.length === 0) {
    return [];
  }

  return str.split(",").map((v) => parseInt(v));
}

function serializeArray(arr: number[]) {
  return arr.join(",");
}

export default function DaysOfWeekControl(props: Props) {
  const selected = React.useMemo(
    () => deserializeString(props.value),
    [props.value]
  );

  function renderButton(title: string, label: string, dayIndex: number) {
    const selectedIndex = selected.indexOf(dayIndex);
    const isSelected = selectedIndex > -1;
    const bgColor = isSelected ? "bg-cornflower-blue-600" : "bg-gray-100";
    const textColor = isSelected ? "text-white" : "text-black";

    const handleClick = () => {
      if (isSelected) {
        selected.splice(selectedIndex, 1);
      } else {
        selected.push(dayIndex);
        selected.sort();
      }

      const serialized = serializeArray(selected);

      if (serialized !== props.value) {
        props.onChange(serialized);
      }
    };

    return (
      <button
        type="button"
        title={title}
        className={`${bgColor} ${textColor} h-7 w-7 m-1 rounded-full text-xs`}
        onClick={handleClick}
      >
        {label}
      </button>
    );
  }

  return (
    <div>
      {renderButton("Sunday", "S", 0)}
      {renderButton("Monday", "M", 1)}
      {renderButton("Tuesday", "T", 2)}
      {renderButton("Wednesday", "W", 3)}
      {renderButton("Thursday", "T", 4)}
      {renderButton("Friday", "F", 5)}
      {renderButton("Saturday", "S", 6)}
    </div>
  );
}
