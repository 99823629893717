import React, { useEffect, useReducer, useState } from "react";

interface Props {
  metadataType: string;
}

export default function NetSuiteInfoMessage(props: Props) {
  const [infoMessage, setInfoMessage] = useState<string>("");
  useEffect(() => {
    switch (props.metadataType) {
      case "Customer":
        setInfoMessage(
          "If creating a customer, NetSuite requires at minimum a Company Name, Subsidiary, and First or Last Name"
        );
        break;
      case "Contact":
        setInfoMessage(
          "If creating a contact, NetSuite requires at minimum a First Name, Last Name, and Subsidiary"
        );
        break;
      case "SupportCase":
        setInfoMessage(
          "If creating a support case, NetSuite requires at minimum a Company Name, Title, and Subsidiary"
        );
        break;
      case "Account":
        setInfoMessage(
          "If creating an account, NetSuite requires at minimum an Account Name, Account Number, and Currency"
        );
        break;
      case "Lead":
        setInfoMessage(
          "If creating a lead, NetSuite requires at minimum a First Name, Last Name, and Subsidiary"
        );
        break;
      case "Prospect":
        setInfoMessage(
          "If creating a prospect, NetSuite requires at minimum a First Name, Last Name, and Subsidiary"
        );
        break;
      case "Order":
        setInfoMessage("If creating an Order, NetSuite requires at minimum a Entity, Item, End User, Order Type and Start Date.");
        break;
      default:
        setInfoMessage("");
        break;
    }
  }, [props.metadataType]);

  return (
    <div
      className="flex flex-col p-1 bg-yellow-100 text-yellow-500 rounded-md font-semibold w-max"
      style={{ fontSize: 12 }}
    >
      {infoMessage}
    </div>
  );
}
