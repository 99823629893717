import Accordion from "@/components/Accordion";
import React, { useMemo } from "react";
import { LanguageConfigModel } from "@/pages/Messaging/types";

interface Props {
  onExpanded(): void;
  workingConfig: LanguageConfigModel;
  update: (updates: Partial<LanguageConfigModel>) => void;
  persist: () => void;
  loading: boolean;
}

export default function FacebookForm(props: Props) {
  const saveButtonText = useMemo(() => {
    return props.loading ? "Saving..." : "Save and set live";
  }, [props.loading]);

  return (
    <Accordion.Item
      eventKey="facebookOpen"
      onExpanded={() => {
        props.onExpanded();
      }}
      onCollapsed={() => {}}
    >
      <Accordion.Header>
        <Accordion.Title>Facebook Messenger Channel</Accordion.Title>
        <Accordion.SubTitle>
          Customize how your visitors will start a Facebook Messenger
          conversation
        </Accordion.SubTitle>
      </Accordion.Header>
      <Accordion.Body>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            props.persist();
          }}
        >
          <div className="mb-4">
            <label className="block mb-2" htmlFor="facebook-messenger-title">
              Facebook Messenger Title
            </label>
            <input
              className="input"
              type="text"
              id="facebook-messenger-title"
              value={props.workingConfig.facebookTitle}
              onChange={(e) => props.update({ facebookTitle: e.target.value })}
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="facebook-messenger-subtitle">
              Facebook Messenger Subtitle
            </label>
            <input
              className="input"
              type="text"
              id="facebook-messenger-subtitle"
              value={props.workingConfig.facebookSubTitle}
              onChange={(e) =>
                props.update({ facebookSubTitle: e.target.value })
              }
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="facebook-messenger-message">
              Response Time Message
            </label>
            <input
              className="input"
              type="text"
              id="facebook-messenger-message"
              value={props.workingConfig.facebookResponseTime}
              onChange={(e) =>
                props.update({ facebookResponseTime: e.target.value })
              }
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="facebook-messenger-header">
              Form Header
            </label>
            <input
              className="input"
              type="text"
              id="facebook-messenger-header"
              value={props.workingConfig.facebookFormHeader}
              onChange={(e) =>
                props.update({ facebookFormHeader: e.target.value })
              }
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="facebook-messenger-footer">
              Form Footer
            </label>
            <input
              className="input"
              type="text"
              id="facebook-messenger-footer"
              value={props.workingConfig.facebookFormFooter}
              onChange={(e) =>
                props.update({ facebookFormFooter: e.target.value })
              }
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="facebook-messenger-button">
              Button Text
            </label>
            <input
              className="input"
              type="text"
              id="facebook-messenger-button"
              value={props.workingConfig.facebookButton}
              onChange={(e) => props.update({ facebookButton: e.target.value })}
            />
          </div>
          <div>
            <button className="btn-green" disabled={props.loading}>
              {saveButtonText}
            </button>
          </div>
        </form>
      </Accordion.Body>
    </Accordion.Item>
  );
}
