import useApi from "@/hooks/useApi";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TeamsContext } from "../context/TeamsContext";
import LoadingSpinner from "@/components/LoadingSpinner";
import { SelectOption } from "@/types";
import { Team } from "@/pages/Messaging/Teams/types";

export function TeamsProvider(props: { children: React.ReactNode }) {
  const api = useApi();
  const [teams, setTeams] = React.useState<Team[]>([]);
  const [loading, setLoading] = useState(true);

  const fetch = useCallback(async () => {
    const response = await api.messaging.get("Teams/List");
    const data = await response.json();
    setTeams(data);
    setLoading(false);
    return data;
  }, [api.messaging]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  function getTeamNameById(teamId: number) {
    return teams?.find((x) => x.id === teamId)?.name || "";
  }

  function getTeamById(teamId: number) {
    return teams?.find((x) => x.id === teamId) || null;
  }

  const teamOptions = useMemo(() => {
    return teams.map((team) => ({
      label: team.name,
      value: team.id
    })) as SelectOption[];
  }, [teams]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <TeamsContext.Provider
      value={{
        teams: teams,
        teamOptions,
        getTeamNameById,
        setTeams,
        getTeamById
      }}
    >
      {props.children}
    </TeamsContext.Provider>
  );
}
