import { createContext } from "react";
import { LanguageConfigModel } from "../../types";

interface Props {
  languageConfigs: LanguageConfigModel[];
  setLanguageConfigs(LanguageConfigs: LanguageConfigModel[]): void;
  loading: boolean;
}

const LanguageConfigsContext = createContext<Props>({} as Props);

export default LanguageConfigsContext;
