import React from "react";

interface Props {
  value: string;
  onChange: (value: string) => void;
}

export default function ColorPicker(props: Props) {
  return (
    <div className="inline-block relative">
      <input
        type="text"
        className="border p-2 pr-10 w-28 font-mono"
        value={props.value}
        onChange={(e) => props.onChange(e.currentTarget.value)}
        maxLength={7}
      />
      <span className="rounded-full border overflow-hidden absolute h-6 w-6 top-2 right-2">
        <input
          type="color"
          className="absolute h-10 w-10"
          style={{ top: -10, left: -10 }}
          value={props.value}
          onChange={(e) => props.onChange(e.currentTarget.value)}
        />
      </span>
    </div>
  );
}
