import * as React from "react";
import { Topic } from "../types";
import useApi from "@/hooks/useApi";

export default function useTopics() {
  const [topics, setTopics] = React.useState<Topic[]>([]);
  const [loadingTopics, setLoadingTopics] = React.useState(true);
  const api = useApi();

  const fetchTopics = React.useCallback(async () => {
    setLoadingTopics(true);
    const response = await api.main.get("KBTopics");
    const json = await response.json();
    setLoadingTopics(false);
    setTopics(json.Topics);
  }, []);

  React.useEffect(() => {
    fetchTopics();
  }, [fetchTopics]);

  const updateTopics = React.useCallback(async (topics: Topic[]) => {
    await api.main.post("KBTopics/UpdateMany", topics);
  }, []);

  function addTopic(topic: Topic) {
    setTopics((oldState) => [...oldState, topic]);
  }

  return {
    topics,
    setTopics,
    loadingTopics,
    addTopic,
    fetchTopics,
    updateTopics
  };
}
