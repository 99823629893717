import useApi from "@/hooks/useApi";
import React, { useCallback, useEffect, useState } from "react";
import { AIConfiguration } from "@/pages/Messaging/Bots/types";
import { AIConfigsContext } from "@/context/AIConfigsContext";

export function AIConfigsProvider(props: { children: React.ReactNode }) {
  const api = useApi();
  const [aiConfigurations, setAiConfigurations] = useState<AIConfiguration[]>(
    []
  );
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(async () => {
    setLoading(true);
    const json = await api.messaging.get("AIConfiguration/List");
    const result = (await json.json()) as AIConfiguration[];
    setAiConfigurations(result);
    setLoading(false);
  }, [api.messaging]);

  const remove = useCallback(
    async (config: AIConfiguration) => {
      await api.messaging.delete("AIConfiguration", {
        id: config.id
      });
      const clone = [...aiConfigurations];
      clone.splice(aiConfigurations.indexOf(config), 1);
      setAiConfigurations(clone);
    },
    [aiConfigurations, api.messaging]
  );

  const add = useCallback(async () => {
    const config: AIConfiguration = {
      id: 0,
      name: "New Configuration",
      prompt: "",
      tone: "",
      aiModel: "",
      language: "",
      replyLength: "Short",
      knowledgeRestriction: false,
      followUp: false,
      rateResponse: false,
      activityCheck: false,
      usageLimit: 0,
      usageLimitWarning: "",
      status: "crawling",
      dataUrls: [],
      deploymentName: "",
      vectorSearchMethod: "None",
      indexName: ""
    };
    setAiConfigurations([...aiConfigurations, config]);
  }, [aiConfigurations]);

  const update = useCallback(
    async (config: AIConfiguration, index: any) => {
      const clone = [...aiConfigurations];
      clone[index] = config;
      setAiConfigurations(clone);
    },
    [aiConfigurations]
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <AIConfigsContext.Provider
      value={{ aiConfigurations, add, update, remove, loading }}
    >
      {props.children}
    </AIConfigsContext.Provider>
  );
}
