import LoadingSpinner from "@/components/LoadingSpinner";
import useWorkflowRules from "@/hooks/useWorkflowConfigs";
import * as React from "react";
import RuleRowItem from "./RuleRowItem";
import { useState } from "react";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import RuleEditModal from "./RuleEditModal";

//shows all rules for site
export default function RuleManager() {
  const { workflowRules, loading } = useWorkflowRules();

  const [workingRuleIndex, setWorkingRuleIndex] = useState<
    number | null | undefined
  >(undefined);

  //group by trigger

  if (loading) {
    return <LoadingSpinner />;
  }

  function renderModal() {
    if (workingRuleIndex === undefined) {
      return null;
    }
    const rule =
      workingRuleIndex === null ? undefined : workflowRules[workingRuleIndex];

    return (
      <RuleEditModal rule={rule} close={() => setWorkingRuleIndex(undefined)} />
    );
  }

  return (
    <>
      <div className="flex items-center mt-2">
        <PrimaryBlueButton
          onClick={() => setWorkingRuleIndex(null)}
          label="New Rule"
        />
      </div>
      <div className="flex pt-10 pl-10 pr-5">
        <div className="flex-1 mr-5 h-30">
          {workflowRules.map((r, i) => (
            <RuleRowItem
              editRule={() => setWorkingRuleIndex(i)}
              key={i}
              rule={r}
            />
          ))}
        </div>
      </div>
      {renderModal()}
    </>
  );
}
