import React from "react";
import { IconProps } from "./types";

function IconHelp(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <g clipPath="url(#clip0_1838_418200)">
        <path
          d="M5.30258 5.25008C5.43973 4.86022 5.71042 4.53148 6.06672 4.32208C6.42302 4.11267 6.84194 4.03613 7.24927 4.106C7.6566 4.17586 8.02606 4.38764 8.29221 4.70381C8.55836 5.01997 8.70403 5.42014 8.70341 5.83341C8.70341 7.00008 6.95341 7.58341 6.95341 7.58341M7.00008 9.91675H7.00591M12.8334 7.00008C12.8334 10.2217 10.2217 12.8334 7.00008 12.8334C3.77842 12.8334 1.16675 10.2217 1.16675 7.00008C1.16675 3.77842 3.77842 1.16675 7.00008 1.16675C10.2217 1.16675 12.8334 3.77842 12.8334 7.00008Z"
          stroke="#CBD5E1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1838_418200">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconHelp;
