import LoadingSpinner from "@/components/LoadingSpinner";
import { SiteContext } from "@/context/SiteContext";
import useApi from "@/hooks/useApi";
import { Site } from "@/types";
import React, { useState } from "react";

export function SiteProvider(props: { children: React.ReactNode }) {
  const [site, setSite] = useState<Site>({
    id: 0,
    companyName: "unknown"
  });

  const [loading, setLoading] = React.useState(true);
  const api = useApi();

  React.useEffect(() => {
    async function load() {
      const response = await api.messaging.get("site");
      setSite(await response.json());
      setLoading(false);
    }

    load();
  }, [api]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <SiteContext.Provider value={{ site }}>
      {props.children}
    </SiteContext.Provider>
  );
}
