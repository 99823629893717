import React from "react";

export default function IllustrationBuildItWithAI() {
  return (
    <svg viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M130.97 48.1899L377.95 56.2199C419.14 57.5599 453.49 89.3299 456.7 127.24L472.72 316.56C475.93 354.47 447.41 391.56 407.03 399.81L164.91 449.24C106.35 461.2 52.42 431.34 48.22 381.77L27.27 134.29C23.08 84.7299 71.23 46.2299 130.96 48.1699L130.97 48.1899Z"
        fill="#EEF4FD"
      />
      <path
        d="M188.98 166.365L182.581 167.562L183.478 172.359L189.877 171.162L188.98 166.365Z"
        fill="black"
      />
      <path
        d="M196.23 240.4L194.64 231.89L201.04 230.69L202.63 239.2L196.23 240.4ZM193.04 223.39L191.45 214.88L197.85 213.68L199.44 222.19L193.04 223.39ZM189.86 206.38L188.27 197.87L194.67 196.67L196.26 205.18L189.86 206.38ZM186.67 189.37L185.08 180.86L191.48 179.66L193.07 188.17L186.67 189.37Z"
        fill="black"
      />
      <path
        d="M204.206 247.725L197.807 248.922L198.705 253.719L205.104 252.522L204.206 247.725Z"
        fill="black"
      />
      <path
        d="M194.518 199.34L189.74 200.332L191.064 206.706L195.842 205.714L194.518 199.34Z"
        fill="black"
      />
      <path
        d="M205.1 203.81L203.78 197.43L213.05 195.51L214.37 201.89L205.1 203.81ZM223.64 199.96L222.32 193.58L231.59 191.66L232.91 198.04L223.64 199.96ZM242.18 196.11L240.86 189.73L250.13 187.81L251.45 194.19L242.18 196.11ZM260.72 192.26L259.4 185.88L268.67 183.96L269.99 190.34L260.72 192.26ZM279.26 188.42L277.94 182.04L287.21 180.12L288.53 186.5L279.26 188.42ZM297.8 184.57L296.48 178.19L305.75 176.27L307.07 182.65L297.8 184.57Z"
        fill="black"
      />
      <path
        d="M319.796 173.333L315.018 174.325L316.342 180.699L321.12 179.707L319.796 173.333Z"
        fill="black"
      />
      <path
        d="M121.83 104.54L130.22 177.54L227.99 156.66L213.8 88.35L121.83 104.54Z"
        fill="black"
      />
      <path
        d="M127.39 181.47L118.24 101.86L216.35 84.59L231.84 159.17L127.4 181.47H127.39ZM125.41 107.22L133.04 173.61L224.14 154.16L211.25 92.11L125.4 107.22H125.41Z"
        fill="black"
      />
      <path
        d="M140.55 251.13L150.11 321.58L259.34 290.11L244.8 228.24L140.55 251.13Z"
        fill="black"
      />
      <path
        d="M147.39 325.76L136.92 248.6L247.24 224.38L263.23 292.39L147.4 325.76H147.39ZM144.18 253.66L152.83 317.41L255.46 287.84L242.36 232.11L144.17 253.66H144.18Z"
        fill="black"
      />
      <path
        d="M130.22 103.06L138.3 172.49L227.99 156.66L213.8 88.35L130.22 103.06Z"
        fill="#AFCFF8"
      />
      <path
        d="M134.38 177.74L125.26 99.38L217.31 83.17L233.32 160.29L134.37 177.74H134.38ZM135.17 106.75L142.21 167.23L222.65 153.04L210.29 93.53L135.17 106.75Z"
        fill="black"
      />
      <path
        d="M148.68 248.48L156.69 314.02L259.34 290.11L246.59 226.94L148.68 248.48Z"
        fill="#629FF0"
      />
      <path
        d="M154.6 317L146 246.58L248.48 224.04L262.19 291.95L154.6 317ZM151.36 250.38L158.77 311.03L256.49 288.27L244.7 229.84L151.37 250.37L151.36 250.38Z"
        fill="black"
      />
      <path
        d="M154.26 320.23L145.15 245.67L251.51 222.27L266.03 294.19L154.26 320.22V320.23ZM155.06 252.7L161.96 309.22L255.49 287.44L244.51 233.03L155.06 252.71V252.7Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M306.95 57.35C308.49 57.35 309.84 58.37 310.26 59.85L313.99 72.91C314.79 75.72 316.3 78.28 318.37 80.35C320.44 82.42 323 83.92 325.81 84.73L338.88 88.47C340.36 88.89 341.38 90.24 341.38 91.78C341.38 93.32 340.36 94.67 338.88 95.09L325.82 98.82C323.01 99.62 320.45 101.13 318.38 103.2C316.31 105.27 314.81 107.83 314 110.64C313.99 110.69 313.97 110.73 313.96 110.78L310.27 123.71C309.85 125.19 308.5 126.21 306.96 126.21C305.42 126.21 304.07 125.19 303.65 123.71L299.92 110.65C299.12 107.84 297.61 105.28 295.54 103.21C293.47 101.14 290.91 99.64 288.1 98.83L275.03 95.09C273.55 94.67 272.53 93.32 272.53 91.78C272.53 90.24 273.55 88.89 275.03 88.47L288.09 84.74C290.9 83.94 293.46 82.43 295.53 80.36C297.6 78.29 299.1 75.73 299.91 72.92L303.65 59.85C304.07 58.37 305.42 57.35 306.96 57.35H306.95ZM306.95 73.31L306.53 74.8C305.4 78.74 303.3 82.32 300.4 85.22C297.5 88.11 293.92 90.22 289.98 91.35L288.5 91.77L289.99 92.19C293.93 93.32 297.51 95.42 300.41 98.32C303.31 101.21 305.41 104.8 306.54 108.74L306.96 110.22L307.38 108.74C307.39 108.69 307.41 108.64 307.43 108.59C308.57 104.71 310.66 101.18 313.52 98.32C316.42 95.43 320 93.32 323.94 92.19L325.42 91.77L323.93 91.35C319.99 90.22 316.41 88.12 313.51 85.22C310.61 82.33 308.51 78.74 307.38 74.8L306.96 73.32L306.95 73.31Z"
        fill="#629FF0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M284.32 293.34C285.06 293.34 285.72 293.83 285.92 294.55L287.73 300.87C288.12 302.23 288.85 303.47 289.85 304.47C290.85 305.47 292.09 306.2 293.45 306.59L299.77 308.4C300.49 308.6 300.98 309.26 300.98 310C300.98 310.74 300.49 311.4 299.77 311.6L293.45 313.41C292.09 313.8 290.85 314.53 289.85 315.53C288.85 316.53 288.12 317.77 287.73 319.13C287.73 319.15 287.72 319.18 287.71 319.2L285.92 325.46C285.72 326.18 285.06 326.67 284.32 326.67C283.58 326.67 282.92 326.18 282.72 325.46L280.91 319.14C280.52 317.78 279.79 316.54 278.79 315.54C277.79 314.54 276.55 313.81 275.19 313.42L268.87 311.61C268.15 311.41 267.66 310.75 267.66 310.01C267.66 309.27 268.15 308.61 268.87 308.41L275.19 306.6C276.55 306.21 277.79 305.48 278.79 304.48C279.79 303.48 280.52 302.24 280.91 300.88L282.72 294.56C282.92 293.84 283.58 293.35 284.32 293.35V293.34Z"
        fill="#C1B4D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M355.86 46.27C356.8 46.27 357.63 46.89 357.88 47.8L360.16 55.78C360.65 57.5 361.57 59.06 362.84 60.33C364.1 61.59 365.67 62.51 367.39 63.01L375.38 65.29C376.28 65.55 376.91 66.37 376.91 67.31C376.91 68.25 376.29 69.07 375.38 69.33L367.4 71.61C365.68 72.1 364.12 73.02 362.85 74.29C361.59 75.55 360.66 77.12 360.17 78.84C360.17 78.87 360.15 78.9 360.14 78.93L357.88 86.83C357.62 87.73 356.8 88.36 355.86 88.36C354.92 88.36 354.09 87.74 353.84 86.83L351.56 78.85C351.07 77.13 350.15 75.57 348.88 74.3C347.62 73.04 346.05 72.12 344.33 71.62L336.34 69.34C335.44 69.08 334.81 68.26 334.81 67.32C334.81 66.38 335.43 65.56 336.34 65.3L344.32 63.02C346.04 62.53 347.6 61.61 348.87 60.34C350.13 59.08 351.06 57.51 351.55 55.79L353.83 47.8C354.09 46.9 354.91 46.27 355.85 46.27H355.86Z"
        fill="#896FB8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M214.4 284.36C216.78 285.87 218.12 288.59 217.86 291.41L205.53 425.6C205.29 428.21 203.71 430.51 201.35 431.67C199 432.83 196.21 432.67 193.99 431.27L165.72 413.32L142.27 450.24C140.04 453.75 135.39 454.79 131.88 452.56L97.26 430.57C93.75 428.34 92.71 423.69 94.94 420.18L118.39 383.26L90.12 365.31C87.91 363.9 86.58 361.45 86.63 358.82C86.67 356.2 88.08 353.79 90.35 352.46L206.55 284.23C208.98 282.8 212.01 282.85 214.4 284.37V284.36Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M211.46 276.58C213.84 278.09 215.18 280.81 214.92 283.63L202.59 417.82C202.35 420.43 200.77 422.73 198.41 423.89C196.06 425.05 193.27 424.89 191.05 423.49L162.78 405.54L139.33 442.46C137.1 445.97 132.45 447.01 128.94 444.78L94.32 422.79C90.81 420.56 89.77 415.91 92 412.4L115.45 375.48L87.18 357.53C84.97 356.12 83.64 353.67 83.69 351.04C83.73 348.42 85.14 346.01 87.41 344.68L203.61 276.45C206.04 275.02 209.07 275.07 211.46 276.59V276.58Z"
        fill="#629FF0"
      />
      <path
        d="M132.97 448.88C131.01 448.88 129.08 448.32 127.39 447.25L92.77 425.26C87.91 422.17 86.46 415.7 89.55 410.83L111.43 376.37L85.63 359.98C82.58 358.04 80.72 354.59 80.78 350.97C80.84 347.36 82.82 343.97 85.94 342.14L202.14 273.91C205.54 271.92 209.71 271.99 213.03 274.1C216.35 276.21 218.2 279.96 217.84 283.88L205.51 418.07C205.18 421.67 202.96 424.9 199.71 426.49C196.46 428.08 192.55 427.87 189.5 425.93L163.7 409.55L141.82 444.01C140.32 446.37 138 448 135.27 448.61C134.51 448.78 133.74 448.86 132.97 448.86V448.88ZM207.43 278.33C206.63 278.33 205.82 278.54 205.1 278.97L88.9 347.2C87.52 348.01 86.65 349.5 86.63 351.09C86.6 352.69 87.42 354.21 88.77 355.06L119.5 374.58L94.49 413.97C93.13 416.11 93.77 418.97 95.91 420.33L130.53 442.32C131.57 442.98 132.8 443.19 134 442.93C135.2 442.66 136.23 441.94 136.89 440.9L161.9 401.51L192.63 421.03C193.98 421.88 195.7 421.98 197.13 421.28C198.56 420.58 199.54 419.15 199.69 417.57L212.02 283.38C212.18 281.65 211.37 280 209.9 279.07C209.14 278.59 208.29 278.35 207.43 278.35V278.33Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M198.36 295.77L105.44 350.33L129.66 365.71C133.17 367.94 134.21 372.59 131.98 376.1L108.53 413.02L130.44 426.93L153.89 390.01C156.12 386.5 160.77 385.46 164.28 387.69L188.5 403.07L198.36 295.76V295.77Z"
        fill="#F9FAFC"
      />
      <path
        d="M396.38 202.24C396.31 199.52 396.01 196.78 395.47 194.03C394.93 191.28 394.17 188.62 393.21 186.08L407.93 169.43L389.04 147.76L370.54 160.08C365.73 157.46 360.46 155.64 354.96 154.73L347.91 133.66L319.7 139.19L321.12 161.36C316.36 164.28 312.18 167.95 308.71 172.19L286.93 167.76L277.61 194.95L297.53 204.81C297.6 207.53 297.9 210.27 298.44 213.02C298.98 215.77 299.74 218.43 300.7 220.97L285.98 237.62L304.87 259.29L323.37 246.97C328.18 249.59 333.45 251.41 338.95 252.32L346 273.39L374.21 267.86L372.79 245.69C377.55 242.77 381.73 239.1 385.2 234.86L406.98 239.29L416.3 212.1L396.38 202.24ZM342.55 225.03C330.68 222.6 323.02 211 325.46 199.13C327.89 187.26 339.49 179.6 351.36 182.04C363.23 184.47 370.89 196.07 368.45 207.94C366.02 219.81 354.42 227.47 342.55 225.03Z"
        fill="#F9FAFC"
      />
      <path
        d="M343.52 277.67L336.12 255.58C331.78 254.68 327.59 253.24 323.6 251.28L304.2 264.2L281.03 237.63L296.47 220.17C295.78 218.07 295.22 215.92 294.79 213.76C294.37 211.6 294.08 209.4 293.92 207.19L273.04 196.85L284.47 163.5L307.31 168.14C310.26 164.83 313.6 161.91 317.28 159.43L315.79 136.19L350.39 129.41L357.79 151.5C362.13 152.4 366.32 153.84 370.31 155.8L389.71 142.88L412.88 169.45L397.44 186.91C398.13 189.01 398.69 191.16 399.12 193.32C399.54 195.48 399.83 197.68 399.99 199.89L420.87 210.23L409.44 243.58L386.6 238.94C383.65 242.25 380.3 245.17 376.63 247.64L378.12 270.89L343.52 277.67ZM323.17 242.66L325.13 243.73C329.64 246.18 334.49 247.85 339.54 248.68L341.75 249.04L348.48 269.14L370.3 264.86L368.95 243.71L370.86 242.54C375.22 239.87 379.09 236.5 382.34 232.52L383.76 230.79L404.54 235.01L411.75 213.98L392.75 204.57L392.69 202.34C392.62 199.79 392.34 197.23 391.85 194.74C391.36 192.25 390.66 189.77 389.76 187.38L388.97 185.29L403.01 169.41L388.4 152.65L370.75 164.41L368.79 163.34C364.28 160.89 359.43 159.22 354.38 158.39L352.17 158.03L345.44 137.93L323.62 142.21L324.97 163.36L323.06 164.53C318.7 167.21 314.83 170.58 311.58 174.55L310.16 176.28L289.38 172.06L282.17 193.09L301.17 202.5L301.23 204.73C301.3 207.28 301.58 209.84 302.07 212.33C302.56 214.83 303.26 217.3 304.16 219.69L304.95 221.78L290.91 237.66L305.52 254.42L323.17 242.66ZM346.96 229.19C345.26 229.19 343.53 229.02 341.8 228.67C327.94 225.83 318.98 212.25 321.82 198.39C324.66 184.53 338.25 175.57 352.1 178.41C358.81 179.79 364.59 183.69 368.36 189.41C372.13 195.13 373.45 201.97 372.08 208.69C369.6 220.82 358.88 229.2 346.96 229.2V229.19ZM346.95 185.3C338.47 185.3 330.86 191.26 329.09 199.88C327.07 209.73 333.44 219.39 343.29 221.41C353.14 223.43 362.8 217.06 364.81 207.21C365.79 202.44 364.85 197.57 362.17 193.51C359.49 189.44 355.38 186.67 350.61 185.69C349.38 185.44 348.15 185.32 346.94 185.32L346.95 185.3Z"
        fill="black"
      />
    </svg>
  );
}
