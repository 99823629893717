import React from "react";
import { IconProps } from "./types";

function IconArrowSquareDownRight({
  className = "stroke-slate-500",
  ...props
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      className={className}
      {...props}
    >
      <path
        d="M20.5002 12.0001V20.0001M20.5002 20.0001H12.5002M20.5002 20.0001L12.5002 11.9999M10.9 28H22.1C24.3402 28 25.4603 28 26.316 27.564C27.0686 27.1805 27.6805 26.5686 28.064 25.816C28.5 24.9603 28.5 23.8402 28.5 21.6V10.4C28.5 8.15979 28.5 7.03969 28.064 6.18404C27.6805 5.43139 27.0686 4.81947 26.316 4.43597C25.4603 4 24.3402 4 22.1 4H10.9C8.65979 4 7.53969 4 6.68404 4.43597C5.93139 4.81947 5.31947 5.43139 4.93597 6.18404C4.5 7.03969 4.5 8.15979 4.5 10.4V21.6C4.5 23.8402 4.5 24.9603 4.93597 25.816C5.31947 26.5686 5.93139 27.1805 6.68404 27.564C7.53969 28 8.65979 28 10.9 28Z"
        stroke="inherit"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconArrowSquareDownRight;
