import React from "react";
import { IconProps } from "./types";

function IconUsersPlus({
  className = "stroke-slate-500",
  ...props
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={className}
      {...props}
    >
      <path
        d="M25.3334 28V20M21.3334 24H29.3334M16.0001 20H10.6667C8.18173 20 6.93922 20 5.9591 20.406C4.65229 20.9473 3.61402 21.9855 3.07272 23.2924C2.66675 24.2725 2.66675 25.515 2.66675 28M20.6667 4.38768C22.6213 5.17886 24.0001 7.09508 24.0001 9.33333C24.0001 11.5716 22.6213 13.4878 20.6667 14.279M18.0001 9.33333C18.0001 12.2789 15.6123 14.6667 12.6667 14.6667C9.72123 14.6667 7.33341 12.2789 7.33341 9.33333C7.33341 6.38781 9.72123 4 12.6667 4C15.6123 4 18.0001 6.38781 18.0001 9.33333Z"
        stroke="inherit"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconUsersPlus;
