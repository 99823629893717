import Accordion from "@/components/Accordion";
import React, { useEffect, useState } from "react";
import WelcomeForm from "../components/WelcomeForm";
import WelcomeOpenConvosForm from "../components/Preview/WelcomeOpenConvosForm";
import FooterForm from "../components/Preview/FooterForm";
import ConversationListForm from "../components/Preview/ConversationListForm";
import ConnectingForm from "../components/Preview/ConnectingForm";
import CalendlyForm from "../components/Preview/CalendlyForm";
import WhatsAppForm from "../components/Preview/WhatsAppForm";
import FacebookForm from "../components/Preview/FacebookForm";
import SMSForm from "../components/Preview/SMSForm";
import useApi from "@/hooks/useApi";
import { LanguageConfigModel } from "../../types";
import useLanguageConfigs from "../hooks/useLanguageConfigs";
import AddLanguageConfigModal from "./AddLanguageConfigModal";
import { Link } from "react-router-dom";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";

interface Props {
  selectedConfigId: number;
  setSelectedConfigId: (id: number) => void;
  setWorkingConfig: (config: LanguageConfigModel) => void;
  workingConfig: LanguageConfigModel;
  setPreviewType: (type: string) => void;
}

export default function LanguageConfig(props: Props) {
  const [channels, setChannels] = useState<string[]>([]);
  const { languageConfigs, setLanguageConfigs } = useLanguageConfigs();
  const [showAddLanguageConfigModal, setShowAddLanguageConfigModal] =
    useState(false);
  const [saving, setSaving] = useState(false);
  const toastAlert = useToastAlert();

  const api = useApi();

  useEffect(() => {
    async function fetchChannels() {
      const resp = await api.messaging.get("Channels");

      if (!resp.ok) {
        alert("an unexpected error occurred while fetching crm configs");
        return;
      }

      const data = await resp.json();
      setChannels(data);
    }

    fetchChannels();
  }, [api.messaging]);

  function handleConfigChange(configId: number) {
    props.setSelectedConfigId(configId);
    props.setWorkingConfig(
      languageConfigs.find((x) => x.id === configId) ||
        ({} as LanguageConfigModel)
    );
  }

  function update(updates: Partial<LanguageConfigModel>) {
    props.setWorkingConfig({ ...props.workingConfig, ...updates });
  }

  async function persist() {
    setSaving(true);

    const resp = await api.messaging.post("MessengerLanguageConfig", {
      ...props.workingConfig
    });
    const result = await resp.json();
    if (resp.ok) {
      const clonedLanguageConfigs = [...languageConfigs];
      const index = clonedLanguageConfigs.findIndex((x) => x.id === result.id);
      clonedLanguageConfigs[index] = result;
      setLanguageConfigs(clonedLanguageConfigs);
      toastAlert.displayToast(
        Severity.Success,
        "Language Config saved successfully"
      );
    } else {
      toastAlert.displayToast(Severity.Error, "Failed to save language config");
    }
    setSaving(false);
  }

  return (
    <>
      <div className="panel bg-white rounded-b-md rounded-tr-md shadow-md p-4 w-full">
        <div className="w-full mt-4 mb-4 flex justify-between">
          <select
            className="w-1/2 border border-gray-300 rounded-md p-2"
            value={props.selectedConfigId}
            onChange={(e) => {
              handleConfigChange(parseInt(e.target.value));
            }}
          >
            {languageConfigs.map((config) => (
              <option key={config.id} value={config.id}>
                {config.name}
              </option>
            ))}
          </select>
          <div className="ml-4">
            <PrimaryBlueButton
              label="Add Language Config"
              onClick={() => setShowAddLanguageConfigModal(true)}
            />
          </div>
        </div>
        <div className="mb-4">
          <label className="block mb-2">Name</label>
          <input
            type="text"
            value={props.workingConfig.name}
            onChange={(e) => update({ name: e.currentTarget.value })}
            className="input w-full"
          />
        </div>
        <div className="mb-4 border-y py-4">
          <label>Use this configuration under the following conditions:</label>
          <div className="flex mt-2 items-center">
            <div className="flex items-center">
              <label className="block whitespace-nowrap">Domain is</label>
              <input
                type="text"
                value={props.workingConfig.domain || ""}
                onChange={(e) => update({ domain: e.currentTarget.value })}
                className="input w-full ml-2"
              />
            </div>
            <Link to={"/rules"}>
              <div className="ml-8 hover:underline text-cornflower-blue-500">
                Or, create a custom rule
              </div>
            </Link>
          </div>
        </div>
        <div className="flex justify-end">
          <PrimaryBlueButton
            label={saving ? "Saving..." : "Save Language Config"}
            onClick={() => persist()}
            disabled={saving}
          />
        </div>
        <div className="overflow-auto">
          <div className="flex pt-4 pl-10 pr-5">
            <div className="flex-1 mr-5">
              <Accordion>
                <WelcomeForm
                  onExpanded={() => props.setPreviewType("Landing")}
                  workingConfig={props.workingConfig}
                  update={update}
                  persist={persist}
                  loading={saving}
                />
                <WelcomeOpenConvosForm
                  onExpanded={() => props.setPreviewType("LandingOpenConvos")}
                  workingConfig={props.workingConfig}
                  update={update}
                  persist={persist}
                  loading={saving}
                />
                <FooterForm
                  onExpanded={() => props.setPreviewType("Landing")}
                  workingConfig={props.workingConfig}
                  update={update}
                  persist={persist}
                  loading={saving}
                />
                <ConversationListForm
                  onExpanded={() => props.setPreviewType("ConvoList")}
                  workingConfig={props.workingConfig}
                  update={update}
                  persist={persist}
                  loading={saving}
                />
                <ConnectingForm
                  onExpanded={() => props.setPreviewType("Connecting")}
                  workingConfig={props.workingConfig}
                  update={update}
                  persist={persist}
                  loading={saving}
                />
                <CalendlyForm
                  onExpanded={() => props.setPreviewType("Calendly")}
                  workingConfig={props.workingConfig}
                  update={update}
                  persist={persist}
                  loading={saving}
                />
                {channels.includes("WhatsApp") && (
                  <WhatsAppForm
                    onExpanded={() => props.setPreviewType("WhatsApp")}
                    workingConfig={props.workingConfig}
                    update={update}
                    persist={persist}
                    loading={saving}
                  />
                )}
                {channels.includes("Facebook") && (
                  <FacebookForm
                    onExpanded={() => props.setPreviewType("Facebook")}
                    workingConfig={props.workingConfig}
                    update={update}
                    persist={persist}
                    loading={saving}
                  />
                )}
                {channels.includes("SMS") && (
                  <SMSForm
                    onExpanded={() => props.setPreviewType("SMS")}
                    workingConfig={props.workingConfig}
                    update={update}
                    persist={persist}
                    loading={saving}
                  />
                )}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      {showAddLanguageConfigModal && (
        <AddLanguageConfigModal
          close={() => setShowAddLanguageConfigModal(false)}
          setSelectedConfigId={props.setSelectedConfigId}
          setWorkingConfig={props.setWorkingConfig}
        />
      )}
    </>
  );
}
