import React from "react";
import { IconProps } from "./types";

function IconCalendar({ className = "stroke-slate-500", ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={className}
      {...props}
    >
      <path
        d="M28 13.3332H4M21.3333 2.6665V7.99984M10.6667 2.6665V7.99984M10.4 29.3332H21.6C23.8402 29.3332 24.9603 29.3332 25.816 28.8972C26.5686 28.5137 27.1805 27.9018 27.564 27.1491C28 26.2935 28 25.1734 28 22.9332V11.7332C28 9.49296 28 8.37286 27.564 7.51721C27.1805 6.76456 26.5686 6.15264 25.816 5.76914C24.9603 5.33317 23.8402 5.33317 21.6 5.33317H10.4C8.15979 5.33317 7.03969 5.33317 6.18404 5.76914C5.43139 6.15264 4.81947 6.76456 4.43597 7.51721C4 8.37286 4 9.49296 4 11.7332V22.9332C4 25.1734 4 26.2935 4.43597 27.1491C4.81947 27.9018 5.43139 28.5137 6.18404 28.8972C7.03969 29.3332 8.15979 29.3332 10.4 29.3332Z"
        stroke="inherit"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconCalendar;
