import { RuleTrigger } from "@/pages/Messaging/Rules/types";
import { Condition, conditionDefs } from "./conditions";

export const comparatorDefs = {
  equals: "is",
  notEquals: "is not",
  contains: "contains",
  notContains: "doesn't contain",
  greaterThan: "is greater than",
  lessThan: "is less than",
  exists: "exists",
  notExists: "doesn't exist"
};

export type Comparator = keyof typeof comparatorDefs;

export type Expression = SerializedCondition[][];

export type SerializedCondition = {
  type: Condition;
  data: string;
};

export type ExpressionType =
  | RuleTrigger //one of the decoupled trigger types
  | "use-workflow" //the workflow trigger node's expression
  | "condition-node" //a condition node's expression
  | "send-invite"; //the invitation's expression

export function getExpressionLabel(
  expression: Expression,
  getTeamNameById = (id: number) => ""
) {
  let text = "";
  expression.forEach((andBlock: SerializedCondition[], i) => {
    if (i > 0) {
      text += " OR ";
    }
    andBlock.forEach((condition: SerializedCondition, j) => {
      if (j > 0) {
        text += " AND ";
      }
      if (!condition.type) {
        text += "--condition is incomplete--";
        return;
      }
      const conditionText = conditionDefs[condition.type].toText(
        condition,
        getTeamNameById
      );
      text += conditionText;
    });
  });
  return text;
}
