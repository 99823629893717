import React from "react";
import AppLoading from "@/components/AppLoading";
import { useNavigate, useLocation } from "react-router";
import useApi from "@/hooks/useApi";

export default function TwitterCallback() {
  const location = useLocation();
  const navigate = useNavigate();
  const api = useApi();

  React.useLayoutEffect(() => {
    async function fetchAccessToken() {
      const response = await api.messaging.post(
        `Twitter/OAuth/AccessToken${location.search}`
      );

      if (response.ok) {
        navigate("/messaging/integrations");
      } else {
        alert("an unexpected error has occurred.");
      }
    }

    fetchAccessToken();
  }, [api.messaging, navigate, location.search]);

  return <AppLoading />;
}
