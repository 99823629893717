import StructuredBreadcrumb from "@/components/StructuredBreadcrumb";
import useApi from "@/hooks/useApi";
import MainLayout from "@/pages/Shared/MainLayout";
import React, { useCallback, useEffect, useState } from "react";

interface IntegrationLog {
  id: number;
  integration: string;
  message: string;
  additionalInfo: string;
  createdAt: number;
}

export default function IntegrationLog() {
  const api = useApi();
  const [company, setCompany] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [token, setToken] = useState("");
  const [tokenSecret, setTokenSecret] = useState("");
  const [restletUrl, setRestletUrl] = useState("");
  const [logs, setLogs] = useState<IntegrationLog[]>([]);

  //   async function getLogs() {
  //     const response = await api.messaging.post("Integration/Logs", {});
  //     const json = response.json();
  //     console.log(json);
  //   }

  const getLogs = useCallback(async () => {
    const response = await api.messaging.get("Integration/Logs");
    const json = (await response.json()) as IntegrationLog[];
    setLogs(json);
  }, [api.messaging]);

  useEffect(() => {
    getLogs();
  }, [getLogs]);

  return (
    <table className="p-4 w-full bg-slate-50">
      <thead>
        <tr>
          <th className="border p-2">Integration</th>
          <th className="border p-2">Message</th>
          <th className="border p-2">Additional Info</th>
          <th className="border p-2">Created At</th>
        </tr>
      </thead>
      <tbody>
        {logs.map((log) => (
          <tr key={log.id}>
            <td className="border p-2">{log.integration}</td>
            <td className="border p-2">{log.message}</td>
            <td className="border p-2">{log.additionalInfo}</td>
            <td className="border p-2">{log.createdAt}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
