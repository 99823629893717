import React from "react";
import { IconProps } from "./types";

function IconUpload({ className = "stroke-slate-500", ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      className={className}
      {...props}
    >
      <path
        d="M28.25 20V21.6C28.25 23.8402 28.25 24.9603 27.814 25.816C27.4305 26.5686 26.8186 27.1805 26.066 27.564C25.2103 28 24.0902 28 21.85 28H10.65C8.40979 28 7.28969 28 6.43404 27.564C5.68139 27.1805 5.06947 26.5686 4.68597 25.816C4.25 24.9603 4.25 23.8402 4.25 21.6V20M22.9167 10.6667L16.25 4M16.25 4L9.58333 10.6667M16.25 4V20"
        stroke="inherit"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconUpload;
