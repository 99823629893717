import React from "react";
import { SerializedCondition } from "../types";
import Input from "@/components/Input";

export type TimeOnPageCondition = {
  seconds: number;
};

export function TimeOnPageEditor({
  condition,
  onUpdate
}: {
  condition: SerializedCondition;
  onUpdate: (condition: SerializedCondition) => void;
}) {
  const timeOnPageCondition = JSON.parse(
    condition.data || "{}"
  ) as TimeOnPageCondition;

  function update(data: TimeOnPageCondition) {
    onUpdate({ ...condition, data: JSON.stringify(data) });
  }

  return (
    <div className="flex gap-2 w-full items-center">
      <Input
        type="number"
        value={timeOnPageCondition.seconds}
        onChange={(seconds) => {
          update({ ...timeOnPageCondition, seconds: Number(seconds) });
        }}
      />
      <span>Seconds</span>
    </div>
  );
}
