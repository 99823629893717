import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAIConfigs from "@/hooks/useAIConfigs";
import LoadingSpinner from "@velaro/velaro-shared/src/components/LoadingSpinner";
import { AIConfiguration } from "../types";

interface Props {
  setSelectedTab: (tab: string) => void;
}

export default function AIChatbotSettingsTrainingTabs(props: Props) {
  const { id } = useParams();
  const { aiConfigurations, loading, update } = useAIConfigs();
  const [config, setConfig] = useState<AIConfiguration>();
  const [configIndex, setConfigIndex] = React.useState<number>();
  const [selectedTab, setSelectedTab] = useState("Website");

  React.useEffect(() => {
    if (aiConfigurations.length > 0 && id) {
      const configIndex = aiConfigurations.findIndex(
        (config) => config.id === parseInt(id)
      );
      if (configIndex) {
        setConfig(aiConfigurations[configIndex]);
        setConfigIndex(configIndex);
      }
    }
  }, [aiConfigurations, id]);
  const selectedStyling = " bg-cornflower-blue-500 text-white";

  if (loading) return <LoadingSpinner />;

  return (
    <div className="border-[1px] flex p-2 rounded-lg w-min">
      <span
        className={`flex items-center justify-between rounded-lg text-sm px-6 py-2 font-medium whitespace-nowrap text-slate-500 cursor-pointer ${
          selectedTab === "Website" ? selectedStyling : ""
        }`}
        onClick={() => {
          setSelectedTab("Website");
          props.setSelectedTab("Website");
        }}
      >
        Website
      </span>
      <span
        className={`flex items-center justify-between rounded-lg text-sm px-6 py-2 font-medium whitespace-nowrap text-slate-500 cursor-pointer ${
          selectedTab === "Site Map" ? selectedStyling : ""
        }`}
        onClick={() => {
          setSelectedTab("Site Map");
          props.setSelectedTab("Site Map");
        }}
      >
        Site Map
      </span>
      <span
        className={`flex items-center justify-between rounded-lg text-sm px-6 py-2 font-medium whitespace-nowrap text-slate-500 cursor-pointer ${
          selectedTab === "File" ? selectedStyling : ""
        }`}
        onClick={() => {
          setSelectedTab("File");
          props.setSelectedTab("File");
        }}
      >
        File
      </span>
      <span
        className={`flex items-center justify-between rounded-lg text-sm px-6 py-2 font-medium whitespace-nowrap text-slate-500 cursor-pointer ${
          selectedTab === "Text" ? selectedStyling : ""
        }`}
        onClick={() => {
          setSelectedTab("Text");
          props.setSelectedTab("Text");
        }}
      >
        Text
      </span>
    </div>
  );
}
