import Accordion from "@/components/Accordion";
import React, { useMemo } from "react";
import { LanguageConfigModel } from "@/pages/Messaging/types";

interface Props {
  onExpanded(): void;
  workingConfig: LanguageConfigModel;
  update: (updates: Partial<LanguageConfigModel>) => void;
  persist: () => void;
  loading: boolean;
}

export default function WelcomeForm(props: Props) {
  const saveButtonText = useMemo(() => {
    return props.loading ? "Saving..." : "Save and set live";
  }, [props.loading]);

  return (
    <Accordion.Item
      eventKey="welcome"
      onExpanded={() => {
        props.onExpanded();
      }}
    >
      <Accordion.Header>
        <Accordion.Title>New Customer Landing</Accordion.Title>
        <Accordion.SubTitle>
          Introduce yourself to your visitors
        </Accordion.SubTitle>
      </Accordion.Header>
      <Accordion.Body>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            props.persist();
          }}
        >
          {/* <div className="mb-4">
            <label className="block mb-2" htmlFor="welcome-lang">
              Language
            </label>
            <select
              className="input"
              id="welcome-lang"
              value={languageConfig.selectedLanguage}
              onChange={(e) =>
                languageConfig.setSelectedLanguage(e.target.value)
              }
            >
              {languageConfig.languageList.map((item) => (
                <option key={item.code} value={item.code}>
                  {item.label}
                </option>
              ))}
            </select>
          </div> */}
          <div className="mb-4">
            <label className="block mb-2" htmlFor="welcome-title">
              Title
            </label>
            <input
              className="input"
              type="text"
              id="welcome-title"
              value={props.workingConfig.title}
              onChange={(e) => props.update({ title: e.target.value })}
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="welcome-subtitle">
              Subtitle
            </label>
            <input
              className="input"
              type="text"
              id="welcome-subtitle"
              value={props.workingConfig.subtitle}
              onChange={(e) => props.update({ subtitle: e.target.value })}
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="welcome-start-title">
              Start Title
            </label>
            <input
              className="input"
              type="text"
              id="welcome-start-title"
              value={props.workingConfig.startTitle}
              onChange={(e) => props.update({ startTitle: e.target.value })}
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="welcome-start-subtitle1">
              Start Subtitle Row 1
            </label>
            <input
              className="input"
              type="text"
              id="welcome-start-subtitle1"
              value={props.workingConfig.startSubtitle1}
              onChange={(e) => props.update({ startSubtitle1: e.target.value })}
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="welcome-start-subtitle2">
              Start Subtitle Row 2
            </label>
            <input
              className="input"
              type="text"
              id="welcome-start-subtitle2"
              value={props.workingConfig.startSubtitle2}
              onChange={(e) => props.update({ startSubtitle2: e.target.value })}
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="welcome-greeting">
              Greeting
            </label>
            <input
              className="input"
              type="text"
              id="welcome-greeting"
              value={props.workingConfig.greeting}
              onChange={(e) => props.update({ greeting: e.target.value })}
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="welcome-intro">
              Team intro
            </label>
            <textarea
              className="input"
              id="welcome-intro"
              value={props.workingConfig.teamIntro}
              onChange={(e) => props.update({ teamIntro: e.target.value })}
            ></textarea>
          </div>
          <div>
            <button className="btn-green" disabled={props.loading}>
              {saveButtonText}
            </button>
          </div>
        </form>
      </Accordion.Body>
    </Accordion.Item>
  );
}
