import * as React from "react";
import Modal from "@velaro/velaro-shared/src/components/Modal";
import FormGroup from "@velaro/velaro-shared/src/components/FormGroup";
// import ModalHeader from "components/Modal/ModalHeader";
// import ModalBody from "components/Modal/ModalBody";
// import ModalFooter from "components/Modal/ModalFooter";
// import ModalTitle from "components/Modal/ModalTitle";
// import FormGroup from "components/FormGroup";
// import RadioLabel from "components/RadioLabel";
import { FilterState, defaultFilterState } from "./types";
import { Topic } from "../types";
import TagSelect from "../shared/TagSelect";
import RadioLabel from "@/components/RadioLabel";

interface Props {
  adminUsers: { userId: number; userName: string }[];
  tags: string[];
  topics: Topic[];
  onApply(filterState: FilterState): void;
  onClose(): void;
  filterState: FilterState;
}

export default function FilterModal(props: Props) {
  const [filterState, setFilterState] = React.useState(defaultFilterState);

  React.useEffect(() => {
    setFilterState(props.filterState);
  }, [props.filterState]);

  function setProp(key: string, value: any) {
    setFilterState((oldState) => ({
      ...oldState,
      [key]: value
    }));
  }

  return (
    <Modal maxWidth="max-w-5xl" show={true}>
      <Modal.Header title={"Filer Articles"} onClose={props.onClose} />
      {/* <Modal.Header>
        <ModalTitle>{App.translate("app/kb/filterarticles")}</ModalTitle>
      </ModalHeader> */}
      <Modal.Body>
        <FormGroup label="Published">
          <>
            <div style={{ marginBottom: 6 }}>
              <RadioLabel checked={filterState.publishStatus === "Published"}>
                <input
                  type="radio"
                  name="pub-status"
                  checked={filterState.publishStatus === "Published"}
                  onChange={(e) => {
                    if (e.currentTarget.checked) {
                      setProp("publishStatus", "Published");
                    }
                  }}
                />
                Published
                {/* {App.translate("app/kb/published")} */}
              </RadioLabel>
            </div>
            <div style={{ marginBottom: 6 }}>
              <RadioLabel checked={filterState.publishStatus === "Unpublished"}>
                <input
                  type="radio"
                  name="pub-status"
                  checked={filterState.publishStatus === "Unpublished"}
                  onChange={(e) => {
                    if (e.currentTarget.checked) {
                      setProp("publishStatus", "Unpublished");
                    }
                  }}
                />
                Unpublished
                {/* {App.translate("app/kb/unpublished")} */}
              </RadioLabel>
            </div>
            <div>
              <RadioLabel checked={filterState.publishStatus === "All"}>
                <input
                  type="radio"
                  name="pub-status"
                  checked={filterState.publishStatus === "All"}
                  onChange={(e) => {
                    if (e.currentTarget.checked) {
                      setProp("publishStatus", "All");
                    }
                  }}
                />
                All
                {/* {App.translate("app/kb/all")} */}
              </RadioLabel>
            </div>
          </>
        </FormGroup>
        <FormGroup label="Visibility">
          <>
            <div style={{ marginBottom: 6 }}>
              <RadioLabel checked={filterState.visibility === "Public"}>
                <input
                  type="radio"
                  name="visibility"
                  checked={filterState.visibility === "Public"}
                  onChange={(e) => {
                    if (e.currentTarget.checked) {
                      setProp("visibility", "Public");
                    }
                  }}
                />
                Public
                {/* {App.translate("app/kb/public")} */}
              </RadioLabel>
            </div>
            <div style={{ marginBottom: 6 }}>
              <RadioLabel checked={filterState.visibility === "Internal"}>
                <input
                  type="radio"
                  name="visibility"
                  checked={filterState.visibility === "Internal"}
                  onChange={(e) => {
                    if (e.currentTarget.checked) {
                      setProp("visibility", "Internal");
                    }
                  }}
                />
                Internal
                {/* {App.translate("app/kb/internal")} */}
              </RadioLabel>
            </div>
            <div>
              <RadioLabel checked={filterState.visibility === "All"}>
                <input
                  type="radio"
                  name="visibility"
                  checked={filterState.visibility === "All"}
                  onChange={(e) => {
                    if (e.currentTarget.checked) {
                      setProp("visibility", "All");
                    }
                  }}
                />
                All
                {/* {App.translate("app/kb/all")} */}
              </RadioLabel>
            </div>
          </>
        </FormGroup>
        <FormGroup label="Last Edited By">
          <>
            {/* <label htmlFor="">{App.translate("app/kb/lasteditedby")}</label> */}
            <label htmlFor="">Last Edited By</label>
            <select
              className="form-control"
              value={filterState.lastEditedByUserId}
              onChange={(e) =>
                setProp("lastEditedByUserId", e.currentTarget.value)
              }
            >
              {/* <option value="">{App.translate("app/kb/all")}</option> */}
              <option value="">All</option>
              {props.adminUsers.map((user) => (
                <option key={user.userId} value={user.userId}>
                  {user.userName}
                </option>
              ))}
            </select>
          </>
        </FormGroup>
        <FormGroup label="Tags">
          <>
            {/* <label htmlFor="">{App.translate("app/kb/tags")}</label> */}
            <label htmlFor="">Tags</label>
            <TagSelect
              allTags={props.tags}
              selectedTags={filterState.tags}
              onChange={(tags) => setProp("tags", tags)}
            />
          </>
        </FormGroup>
        <FormGroup label="Topic">
          <>
            {/* <label htmlFor="">{App.translate("app/kb/topic")}</label> */}
            <label htmlFor="">Topic</label>
            <select
              className="form-control"
              value={filterState.topicId}
              onChange={(e) => setProp("topicId", e.currentTarget.value)}
            >
              {/* <option value="">{App.translate("app/kb/all")}</option> */}
              <option value="">All</option>
              {props.topics.map((topic) => (
                <option key={topic.Id} value={topic.Id}>
                  {topic.Name}
                </option>
              ))}
            </select>
          </>
        </FormGroup>
        <FormGroup label="Order By">
          <>
            {/* <label htmlFor="">{App.translate("app/kb/orderby")}</label> */}
            <label htmlFor="">Order By</label>
            <div className="row">
              <div className="col-md-8">
                <select
                  className="form-control"
                  value={filterState.orderBy}
                  onChange={(e) => setProp("orderBy", e.currentTarget.value)}
                >
                  <option value="CreatedAt">
                    Date Created
                    {/* {App.translate("app/kb/datecreated")} */}
                  </option>
                  <option value="UpdatedAt">
                    Date Updated
                    {/* {App.translate("app/kb/dateupdated")} */}
                  </option>
                </select>
              </div>
              <div className="col-md-4">
                <select
                  className="form-control"
                  value={filterState.orderDir}
                  onChange={(e) => setProp("orderDir", e.currentTarget.value)}
                >
                  <option value="ASC">Ascending</option>
                  <option value="DESC">Decending</option>
                  {/* <option value="ASC">{App.translate("app/kb/asc")}</option>
                  <option value="DESC">{App.translate("app/kb/desc")}</option> */}
                </select>
              </div>
            </div>
          </>
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-default pull-left"
          onClick={() => setFilterState(defaultFilterState)}
        >
          Reset
          {/* {App.translate("app/kb/reset")} */}
        </button>
        <button className="btn btn-default" onClick={() => props.onClose()}>
          Cancel
          {/* {App.translate("app/kb/cancel")} */}
        </button>
        <button
          className="btn btn-success"
          onClick={() => props.onApply(filterState)}
        >
          Apply
          {/* {App.translate("app/kb/apply")} */}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
