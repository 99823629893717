import React, { useCallback, useEffect, useState } from "react";
import useStyleConfigs from "./hooks/useStyleConfigs";
import useLanguageConfigs from "./hooks/useLanguageConfigs";
import Tab from "./components/Tab";
import StyleConfig from "./StyleConfig";
import LanguageConfig from "./LanguageConfig";
import { LanguageConfigModel, StyleConfigModel } from "../types";
import LoadingSpinner from "@velaro/velaro-shared/src/components/LoadingSpinner";
import Preview from "./components/Preview";
import DeployModal from "./components/DeployModal";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";

export default function WebMessenger() {
  const [showDeployModal, setShowDeployModal] = React.useState(false);
  const [selectedTab, setSelectedTab] = useState<tab>("Language");
  const {
    styleConfigs,
    setStyleConfigs,
    loading: loadingStyleConfig
  } = useStyleConfigs();
  const {
    languageConfigs,
    setLanguageConfigs,
    loading: loadingLanguageConfig
  } = useLanguageConfigs();
  const [selectedStyleConfigId, setSelectedStyleConfigId] = useState(0);
  const [selectedLanguageConfigId, setSelectedLanguageConfigId] = useState(0);
  const [workingStyleConfig, setWorkingStyleConfig] =
    useState<StyleConfigModel | null>(null);
  const [workingLanguageConfig, setWorkingLanguageConfig] =
    useState<LanguageConfigModel | null>(null);
  const [previewType, setPreviewType] = React.useState("Landing");

  useEffect(() => {
    if (!loadingStyleConfig && selectedStyleConfigId == 0) {
      setSelectedStyleConfigId(styleConfigs[0]?.id);
      setWorkingStyleConfig(styleConfigs[0]);
    }
  }, [loadingStyleConfig, selectedStyleConfigId, styleConfigs]);

  useEffect(() => {
    if (!loadingLanguageConfig && selectedLanguageConfigId == 0) {
      setSelectedLanguageConfigId(languageConfigs[0]?.id);
      setWorkingLanguageConfig(languageConfigs[0]);
    }
  }, [languageConfigs, loadingLanguageConfig, selectedLanguageConfigId]);

  type tab = "Language" | "Style";

  const renderTabContent = useCallback(() => {
    switch (selectedTab) {
      case "Language":
        return (
          <LanguageConfig
            selectedConfigId={selectedLanguageConfigId}
            setSelectedConfigId={setSelectedLanguageConfigId}
            setWorkingConfig={setWorkingLanguageConfig}
            workingConfig={workingLanguageConfig!} //This is an Alix approved ! - Trout
            setPreviewType={setPreviewType}
          />
        );
      case "Style":
        return (
          <StyleConfig
            selectedConfigId={selectedStyleConfigId}
            setSelectedConfigId={setSelectedStyleConfigId}
            setWorkingConfig={setWorkingStyleConfig}
            workingConfig={workingStyleConfig!} //This is an Alix approved ! - Trout
          />
        );
    }
  }, [
    selectedLanguageConfigId,
    selectedStyleConfigId,
    selectedTab,
    workingLanguageConfig,
    workingStyleConfig
  ]);

  if (
    loadingLanguageConfig ||
    loadingStyleConfig ||
    !workingLanguageConfig ||
    !workingStyleConfig
  ) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="flex">
        <div className="w-[800px]">
          <div className="flex">
            <Tab
              label={"Language Config"}
              selected={selectedTab == "Language"}
              onSelect={() => {
                setSelectedTab("Language");
                setPreviewType("Landing");
              }}
            />
            <Tab
              label="Style Config"
              selected={selectedTab == "Style"}
              onSelect={() => {
                setSelectedTab("Style");
                setPreviewType("Conversation");
              }}
            />
            <div className="w-full">
              <div className="flex justify-end items-center h-full">
                <PrimaryBlueButton
                  label="Deploy"
                  onClick={() => setShowDeployModal(true)}
                />
              </div>
            </div>
          </div>
          <div className="flex">{renderTabContent()}</div>
        </div>
        <div className="px-20 top-30 " style={{ width: 500 }}>
          <Preview
            type={previewType}
            workingStyleConfig={workingStyleConfig}
            workingLanguageConfig={workingLanguageConfig}
          />
        </div>
      </div>

      {showDeployModal && (
        <DeployModal
          show={showDeployModal}
          onClose={() => setShowDeployModal(false)}
        />
      )}
    </>
  );
}
