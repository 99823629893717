import useStyleConfigs from "@/pages/Messaging/WebMessenger/hooks/useStyleConfigs";
import React from "react";
import { Link } from "react-router-dom";

export interface StyleConfigValue {
  styleConfig: string;
}

interface Props {
  updateValue: (value: StyleConfigValue) => void;
  value: StyleConfigValue;
}

export default function StyleConfigRuleForm({ value, updateValue }: Props) {
  const { styleConfigs } = useStyleConfigs();

  if (!styleConfigs || !styleConfigs.length) {
    return (
      <>
        <span>{"You don't have any style configs yet."}</span>
        <Link to="/web_messenger">
          <button type="button" className="btn-link">
            Create one now.
          </button>
        </Link>
      </>
    );
  }

  return (
    <>
      <div>Set the conditions for when a style config should be used.</div>
      <div className="flex flex-col mr-auto">
        <div>
          Select your style config:
          <select
            className="flex flex-row input overflow-ellipsis whitespace-nowrap w-64 ml-2"
            value={value["styleConfig"]}
            onChange={(e) =>
              updateValue({ styleConfig: e.currentTarget.value })
            }
          >
            <option value={""}>{"-- select a style config --"}</option>
            {styleConfigs.map((k, i) => (
              <option key={i} value={k.name}>
                {k.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
}
