import * as React from "react";
import { Category } from "../types";
import useApi from "@/hooks/useApi";

export default function useCategories() {
  const [categories, setCategories] = React.useState<Category[]>([]);
  const [loadingCategories, setLoadingCategories] = React.useState(true);
  const api = useApi();

  const fetchCategories = React.useCallback(async () => {
    setLoadingCategories(true);
    const response = await api.main.get("KBCategories");
    const json = await response.json();
    setLoadingCategories(false);
    setCategories(json.Categories);
  }, []);

  React.useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const addCategory = React.useCallback((category: Category) => {
    setCategories((oldState) => [...oldState, category]);
  }, []);

  const updateCategory = React.useCallback((category: Category) => {
    setCategories((oldState) => {
      const newState = [...oldState];
      const index = newState.findIndex((x) => x.Id === category.Id);

      if (index < 0) {
        return newState;
      }

      newState[index] = category;
      return newState;
    });
  }, []);

  const removeCategory = React.useCallback((id: string) => {
    setCategories((oldState) => {
      const newState = [...oldState];
      const index = newState.findIndex((x) => x.Id === id);

      if (index >= 0) {
        newState.splice(index, 1);
      }

      return newState;
    });

    api.main.delete(`KBCategories?id=${id}`);
  }, []);

  const moveCategoryUp = React.useCallback((index: number) => {
    setCategories((oldState) => {
      const newState = [...oldState];
      const prevItem = newState[index - 1];
      const thisItem = newState[index];
      prevItem.DisplayPriority = thisItem.DisplayPriority;
      thisItem.DisplayPriority = prevItem.DisplayPriority - 1;
      return newState;
    });

    api.main.post(`"KBCategories/MoveUp"`, index);
  }, []);

  const moveCategoryDown = React.useCallback((index: number) => {
    setCategories((oldState) => {
      const newState = [...oldState];
      const thisItem = newState[index];
      const nextItem = newState[index + 1];
      thisItem.DisplayPriority = nextItem.DisplayPriority;
      nextItem.DisplayPriority = thisItem.DisplayPriority - 1;
      return newState;
    });

    api.main.post(`"KBCategories/MoveDown"`, index);
  }, []);

  return {
    categories,
    loadingCategories,
    addCategory,
    updateCategory,
    fetchCategories,
    removeCategory,
    moveCategoryUp,
    moveCategoryDown
  };
}
