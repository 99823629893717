import React from "react";
import { IconProps } from "./types";

function IconText({ className = "text-slate-500", ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M2.66675 5.1665C2.66675 4.54525 2.66675 4.23462 2.76824 3.98959C2.90357 3.66289 3.16313 3.40332 3.48984 3.268C3.73486 3.1665 4.04549 3.1665 4.66675 3.1665H11.3334C11.9547 3.1665 12.2653 3.1665 12.5103 3.268C12.837 3.40332 13.0966 3.66289 13.2319 3.98959C13.3334 4.23462 13.3334 4.54525 13.3334 5.1665M6.00008 13.8332H10.0001M8.00008 3.1665V13.8332"
        stroke="#64748B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconText;
