import React from "react";
import { IconProps } from "./types";

function IconList({ className = "text-slate-500", ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M14 8.50016L6 8.50016M14 4.50016L6 4.50016M14 12.5002L6 12.5002M3.33333 8.50016C3.33333 8.86835 3.03486 9.16683 2.66667 9.16683C2.29848 9.16683 2 8.86835 2 8.50016C2 8.13197 2.29848 7.8335 2.66667 7.8335C3.03486 7.8335 3.33333 8.13197 3.33333 8.50016ZM3.33333 4.50016C3.33333 4.86835 3.03486 5.16683 2.66667 5.16683C2.29848 5.16683 2 4.86835 2 4.50016C2 4.13197 2.29848 3.8335 2.66667 3.8335C3.03486 3.8335 3.33333 4.13197 3.33333 4.50016ZM3.33333 12.5002C3.33333 12.8684 3.03486 13.1668 2.66667 13.1668C2.29848 13.1668 2 12.8684 2 12.5002C2 12.132 2.29848 11.8335 2.66667 11.8335C3.03486 11.8335 3.33333 12.132 3.33333 12.5002Z"
        stroke="#64748B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconList;
