import Modal from "@velaro/velaro-shared/src/components/Modal";
import useApi from "@/hooks/useApi";
import React, { useState } from "react";
import LoadingSpinner from "@/components/LoadingSpinner";
import SkillForm, { defaultSkillModel, SkillModel } from "./SkillForm";
import { SkillListItem } from "@/providers/SkillsProvider";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";

interface Props {
  skillId: number;
  onClose(): void;
  onSkillUpdated(skill: SkillListItem): void;
}

export default function EditSkillModal(props: Props) {
  const api = useApi();

  const [skill, setSkill] = React.useState<SkillModel>(defaultSkillModel);
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);

  React.useEffect(() => {
    async function fetchSkill() {
      const response = api.messaging.get(`Skills/${props.skillId}`);
      setSkill(await (await response).json());
    }

    fetchSkill();
  }, [api.messaging, props.skillId]);

  async function handleSubmit() {
    setSending(true);
    const response = await api.messaging.put(`Skills/${props.skillId}`, skill);
    const newSkill = await response.json();
    setSuccess(response.ok);
    if (response.ok) {
      props.onSkillUpdated(newSkill);
    }
    setSending(false);
  }

  return (
    <Modal show={true}>
      <Modal.Header title="Edit Skill" onClose={() => props.onClose()} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Modal.Body>
          <div className="px-4">
            <SkillForm
              skill={skill}
              onChange={(updates) => {
                setSkill((oldState) => ({ ...oldState, ...updates }));
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="p-4 flex flex-col">
            {success === false && (
              <div className="flex flex-row mb-4">
                There was an error saving your changes. Please see Integration
                Log for more details.
              </div>
            )}
            {sending ? (
              <div className="ml-auto flex">
                Saving Changes...
                <LoadingSpinner />
              </div>
            ) : (
              <div className="ml-auto flex flex-row">
                <PrimaryBlueButton
                  onClick={() => handleSubmit()}
                  label="Save Changes"
                />
              </div>
            )}
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
