import useApi from "./useApi";

export default function useDeactivate() {
  const api = useApi();

  async function deactivateUser(userId: number | undefined) {
    if (!userId) {
      alert("User not found.");
      return;
    }

    return await api.messaging.delete(`Users/${userId}`);
  }

  return { deactivateUser };
}
