import React, { TextareaHTMLAttributes } from "react";

interface Props {}

export default function TextArea(
  props: Props & TextareaHTMLAttributes<HTMLTextAreaElement>
) {
  const { className, ...rest } = props;
  return (
    <textarea
      className={`p-2 bg-white rounded-lg shadow-sm border w-full h-36 text-sm ${className}`}
      {...rest}
    />
  );
}
