import * as React from "react";
import useTopics from "../hooks/useTopics";
import useCategories from "../hooks/useCategories";
import TopicCreateModal from "../shared/TopicCreateModal";
import TopicEditModal from "../shared/TopicEditModal";
import { Category, Topic } from "../types";
import CategoryCreateModal from "./CategoryCreateModal";
import SortableItem from "./SortableItem";
import SortableContainer from "./SortableContainer";
import CategoryEditModal from "./CategoryEditModal";
import _ from "lodash";
import useApi from "@/hooks/useApi";

function getArticleCountString(count: number) {
  if (count === 1) {
    return "One Article";
    //return App.translate("app/kb/onearticle");
  }

  // return `${count} ${App.translate("app/kb/articles")}`;
  return `${count} Articles`;
}

export default function Index() {
  const { topics, setTopics, fetchTopics, updateTopics, loadingTopics } =
    useTopics();

  const {
    categories,
    fetchCategories,
    loadingCategories,
    addCategory,
    updateCategory,
    removeCategory,
    moveCategoryUp,
    moveCategoryDown
  } = useCategories();

  const [showNewTopicModal, setShowNewTopicModal] = React.useState(false);
  const [showNewCategoryModal, setShowNewCategoryModal] = React.useState(false);
  const [editTopic, setEditTopic] = React.useState<Topic>();
  const [editCategory, setEditCategory] = React.useState<Category>();
  const api = useApi();

  const debouncedUpdateTopics = React.useRef(
    _.debounce((topics: Topic[]) => {
      updateTopics(topics);
    }, 1000)
  );

  const newCategoryDisplayPriority = React.useMemo(() => {
    if (categories.length === 0) {
      return 0;
    }

    return categories[categories.length - 1].DisplayPriority + 1;
  }, [categories]);

  const sortedCategories = React.useMemo(() => {
    return categories.sort((a, b) => {
      if (a.DisplayPriority < b.DisplayPriority) {
        return -1;
      }
      if (a.DisplayPriority > b.DisplayPriority) {
        return 1;
      }
      if (a.DisplayPriority === b.DisplayPriority) {
        if (a.Name < b.Name) {
          return -1;
        }
        if (a.Name > b.Name) {
          return 1;
        }
      }

      return 0;
    });
  }, [categories]);

  const categoryTopicsMap = React.useMemo(() => {
    const map: { [categoryId: string]: Topic[] } = {};

    topics.forEach((topic) => {
      if (topic.CategoryId !== null) {
        map[topic.CategoryId] = map[topic.CategoryId] || [];
        map[topic.CategoryId].push(topic);
      }
    });

    return map;
  }, [topics]);

  const uncategorizedTopics = React.useMemo(() => {
    return topics.filter((x) => x.CategoryId === null);
  }, [topics]);

  const deleteTopic = React.useCallback(
    async (topicId: string) => {
      const resp = await api.main.delete(
        `KBTopics?id=${encodeURIComponent(topicId)}`
      );

      fetchTopics();

      if (!resp.ok) {
        console.error(resp);
      }
    },
    [fetchTopics]
  );

  const renderTopics = React.useCallback(
    (topics: Topic[]) => {
      const sortedTopics = topics.sort((a, b) => {
        if (a.DisplayPriority > b.DisplayPriority) {
          return 1;
        }

        if (a.DisplayPriority < b.DisplayPriority) {
          return -1;
        }

        return 0;
      });

      return (
        <>
          {sortedTopics.map((topic) => (
            // <SortableItem
            //   className="sortable-topic-item"
            //   key={topic.Id}
            //   data={topic}
            // >
            <div>
              <div className="sortable-handle">
                <i className="fa fa-arrows-v" />
              </div>
              <div>
                {topic.Name} - contains{" "}
                {getArticleCountString(topic.ArticleCount)}
              </div>
              <div className="topic-actions">
                <a
                  className="btn btn-default btn-sm"
                  href={topic.Url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-external-link-square" />
                </a>{" "}
                <button
                  className="btn btn-default btn-sm"
                  onClick={() => setEditTopic(topic)}
                >
                  <i className="fa fa-edit" />
                </button>{" "}
                <button
                  disabled={topic.ArticleCount > 0}
                  className="btn btn-danger btn-sm"
                  onClick={() => {
                    if (confirm("Confirm delete?")) {
                      {
                        deleteTopic(topic.Id);
                      }
                    }
                  }}
                >
                  <i className="fa fa-times" />
                </button>
              </div>
            </div>
            // </SortableItem>
          ))}
        </>
      );
    },
    [deleteTopic]
  );

  return (
    <React.Fragment>
      <div className="kb-container">
        <div className="kb-header mb-4">
          <h1>KB Topics</h1>
          {/* <h1>{App.translate("app/kb/kbtopics")}</h1> */}
          <div className="kb-actions">
            <button
              className="btn btn-success"
              style={{ marginRight: 6 }}
              onClick={() => setShowNewCategoryModal(true)}
            >
              <i className="fa fa-plus" /> New Category
              {/* <i className="fa fa-plus" /> {App.translate("New Category")} */}
            </button>
            <button
              className="btn btn-success"
              onClick={() => setShowNewTopicModal(true)}
            >
              <i className="fa fa-plus" /> New Topic
              {/* <i className="fa fa-plus" /> {App.translate("app/kb/newtopic")} */}
            </button>
          </div>
        </div>
        <div className="category-container mb-10">
          <div className="category-header">
            <div className="category-name">Uncategorized</div>
          </div>
          {renderTopics(uncategorizedTopics)}
          {/* {uncategorizedTopics.length > 0 &&
            uncategorizedTopics.map((topic) => (
              <div key={topic.Id}>{topic.Name}</div>
            ))} */}
          {/* <SortableContainer
            className="sortable-connected sortable-container"
            connectWith=".sortable-connected"
            onUpdate={(updatedTopics: Topic[]) => {
              setTopics((oldState) => {
                const newState = [...oldState];

                updatedTopics.forEach((updatedTopic, index) => {
                  const currentTopic = newState.find(
                    (x) => x.Id === updatedTopic.Id
                  );

                  if (currentTopic) {
                    currentTopic.CategoryId = null;
                    currentTopic.DisplayPriority = index;
                  }
                });

                debouncedUpdateTopics.current(newState);
                return newState;
              });
            }}
          >
            {renderTopics(uncategorizedTopics)}
          </SortableContainer> */}
        </div>
        Categories
        {sortedCategories.map((category, index) => {
          const topics = categoryTopicsMap[category.Id] || [];
          const deleteDisabled = topics.length > 0;

          return (
            <div className="category-container" key={category.Id}>
              <div className="category-header">
                <div className="category-name">{category.Name}</div>
                <div className="category-actions">
                  <button
                    className="btn btn-sm btn-default"
                    disabled={index === 0}
                    onClick={() => moveCategoryUp(index)}
                  >
                    <div className="fa fa-arrow-up" />
                  </button>
                  <button
                    className="btn btn-sm btn-default"
                    disabled={index === categories.length - 1}
                    onClick={() => moveCategoryDown(index)}
                  >
                    <div className="fa fa-arrow-down" />
                  </button>
                  <button
                    className="btn btn-sm btn-default"
                    onClick={() => setEditCategory(category)}
                  >
                    <i className="fa fa-edit" />
                  </button>
                  <button
                    className="btn btn-sm btn-danger"
                    disabled={deleteDisabled}
                    onClick={() => {
                      if (
                        confirm(
                          "Are you sure you want to delete this category?"
                        )
                      ) {
                        removeCategory(category.Id);
                      }
                    }}
                  >
                    <i className="fa fa-times" />
                  </button>
                </div>
              </div>
              {/* <SortableContainer
                className="sortable-connected sortable-container"
                connectWith=".sortable-connected"
                onUpdate={(updatedTopics: Topic[]) => {
                  setTopics((oldState) => {
                    const newState = [...oldState];

                    updatedTopics.forEach((updatedTopic, index) => {
                      const currentTopic = newState.find(
                        (x) => x.Id === updatedTopic.Id
                      );

                      if (currentTopic) {
                        currentTopic.CategoryId = category.Id;
                        currentTopic.DisplayPriority = index;
                      }
                    });

                    debouncedUpdateTopics.current(newState);
                    return newState;
                  });
                }}
              >
                {renderTopics(topics)}
              </SortableContainer> */}
            </div>
          );
        })}
      </div>
      {showNewCategoryModal && (
        <CategoryCreateModal
          displayPriority={newCategoryDisplayPriority}
          onSubmit={(category: Category) => {
            addCategory(category);
            setShowNewCategoryModal(false);
          }}
          onClose={() => setShowNewCategoryModal(false)}
        />
      )}
      {editCategory && (
        <CategoryEditModal
          category={editCategory}
          onSubmit={(category: Category) => {
            updateCategory(category);
            setEditCategory(undefined);
          }}
          onClose={() => setEditCategory(undefined)}
        />
      )}
      {showNewTopicModal && (
        <TopicCreateModal
          onClose={() => setShowNewTopicModal(false)}
          onSubmit={() => {
            setShowNewTopicModal(false);
            fetchTopics();
          }}
        />
      )}
      {editTopic && (
        <TopicEditModal
          topic={editTopic}
          onClose={() => setEditTopic(undefined)}
          onSubmit={() => {
            setEditTopic(undefined);
            fetchTopics();
          }}
        />
      )}
    </React.Fragment>
  );
}
