export interface Topic {
  Id: string;
  Name: string;
  Slug: string;
  ArticleCount: number;
  Url: string;
  DisplayPriority: number;
  CategoryId: string | null;
}

export interface Category {
  Id: string;
  Name: string;
  DisplayPriority: number;
}

export interface ArticleStatListItem {
  Id: string;
  TopicId: string;
  TopicName: string;
  Slug: string;
  Title: string;
  Description: string;
  HitCount: number;
  VoteCount: number;
  HelpfulCount: number;
  UpdatedAt: string;
  Visibility: "Public" | "Internal";
  IsPublished: boolean;
  Url: string;
}

export enum ArticleVisibility {
  Public,
  Internal
}

export interface Article {
  Id: string;
  TopicId: string;
  Visibility: ArticleVisibility;
  CreatedAt: string;
  Title: string;
  Slug: string;
  Content: string;
  Description: string;
  SearchTags: string;
}

export interface DraftListItem {
  Id: string;
  TopicId: string;
  Slug: string;
  Title: string;
  Description: string;
}
