import Panel from "@/components/Panel";
import useApi from "@/hooks/useApi";
import useNetSuiteConfigs from "@/hooks/useNetSuiteConfigs";
import IconTrash1 from "@/icons/IconTrash1";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select, { SingleValue } from "react-select";
import Option from "react-select/dist/declarations/src/components/Option";
import NetSuiteModal from "./NetSuiteModal";
import LoadingSpinner from "@/components/LoadingSpinner";
import NetSuiteInfoMessage from "./NetSuiteInfoMessage";
import NetSuiteMappingTableRow from "./NetSuiteMappingTableRow";

export interface NetSuiteMap {
  id: number;
  netSuiteField: SingleValue<Option>;
  label: string;
  linkedTo: string;
  create: boolean;
  usedForSearching: boolean;
  showWhenSelected: boolean;
  showInSearch: boolean;
  control: string;
  defaultValue: string;
  recordType: string;
}

interface Option {
  value: any;
  label: string;
  key?: string;
  disabled?: boolean;
}

interface NetSuiteConfig {
  id: number;
  displayName: string;
  company: string;
  teamId: number;
}

interface Props {
  teamId: number;
}

interface NetSuiteListItem {
  value: number;
  text: string;
}

export interface NetSuiteList {
  netSuiteField: string;
  listItems: Option[];
}

export default function NetSuiteMappingTable(props: Props) {
  const [mappedFields, setMappedFields] = useState<NetSuiteMap[]>([]);
  const [metadataType, setMetadataType] = useState<string>("");
  const [selectedConfig, setSelectedConfig] = useState<number | undefined>(
    undefined
  );
  const [metadata, setMetadata] = useState<Option[]>([]);
  const [isLoading, setisLoading] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [passedField, setPassedField] = useState<NetSuiteMap>();
  const { items, loading } = useNetSuiteConfigs(props.teamId);
  const [listFields, setListFields] = useState<NetSuiteList[] | null>(null);
  const api = useApi();

  const metaDataOptions = [
    "",
    "Customer",
    "Account",
    "SupportCase",
    "Lead",
    "Prospect",
    "Contact",
    "Order",
    "Opportunity"
  ].sort();

  useEffect(() => {
    if (!loading) {
      setSelectedConfig(items[0]?.id);
    }
  }, [items, loading]);

  useEffect(() => {
    async function fetchMetadata() {
      //This is the REST method of getting metadata
      //Currently, NetSuite does not support this for all record types, so we are commenting this out and using restlets for now
      // const resp = await api.messaging.get(
      //   `NetSuite/Integrations/GetMetadata/${metadataType}`
      // );
      //setMetadata(json.properties);

      if (!metadataType) {
        setMetadata([]);
        return;
      }
      setisLoading(true);
      let metadataToSend = metadataType;
      if (metadataType === "Ticket") {
        metadataToSend = "supportcase";
      }
      if (metadataType === "Order") {
        metadataToSend = "salesorder";
      }
      const resp = await api.messaging.post(
        `NetSuite/Integrations/RelayToRestlet/${selectedConfig}`,
        {
          relayType: "fields",
          recordType: [metadataToSend]
        }
      );
      //Get the response, sort, and convert to Options[] required by MultiSelect elements
      const json = await resp.json();
      let sortedMetadata = json[metadataToSend];
      sortedMetadata = sortedMetadata.sort();
      const newOptions = Object.values(sortedMetadata).map((key, i) => ({
        value: key,
        label: key
      })) as Option[];
      setMetadata(newOptions);
      setisLoading(false);
    }

    fetchMetadata();
  }, [api.messaging, metadataType, selectedConfig]);

  //Initialize and add a blank row
  const addRow = () => {
    const data = [...mappedFields];
    const newMap: NetSuiteMap = {
      id: -1,
      netSuiteField: {
        value: "",
        label: ""
      },
      label: "",
      linkedTo: "",
      create: false,
      usedForSearching: false,
      showWhenSelected: false,
      showInSearch: false,
      control: "",
      defaultValue: "",
      recordType: metadataType
    };
    data.push(newMap);
    setMappedFields(data);
  };

  function deleteRow(index: number) {
    if (mappedFields[index].id !== -1) {
      deleteRecord(mappedFields[index].id);
    }
    const clone = [...mappedFields];
    clone.splice(index, 1);
    setMappedFields(clone);
  }

  const fetchMapping = useCallback(async () => {
    const resp = await api.messaging.get(
      `NetSuite/Integrations/Mapping/${metadataType}/${selectedConfig}`
    );

    const itemsToBeListed: NetSuiteMap[] = [];
    const json = await resp.json();
    // The NetSuiteField is expected to be an Option, so convert the string to Option
    json.forEach((element: { netSuiteField: { value: any; label: any } }) => {
      const value = {
        value: element.netSuiteField,
        label: element.netSuiteField
      };
      element.netSuiteField = value;
    });
    setMappedFields(json);
  }, [api.messaging, metadataType, selectedConfig]);

  useEffect(() => {
    if (!metadataType) return;
    fetchMapping();
  }, [fetchMapping, metadataType]);

  function isOption(value: any): value is Option {
    return value && typeof value === "object" && "value" in value;
  }

  async function saveRecord() {
    const newMap: {
      netSuiteField: any;
      id: number;
      label: string;
      linkedTo: string;
      create: boolean;
      usedForSearching: boolean;
      showWhenSelected: boolean;
      showInSearch: boolean;
      control: string;
      defaultValue: string | SingleValue<Option>;
      recordType: string;
    }[] = [];
    mappedFields.forEach((element) => {
      newMap.push({
        netSuiteField: element.netSuiteField?.value,
        id: element.id,
        label: element.label,
        linkedTo: element.linkedTo,
        create: element.create,
        usedForSearching: element.usedForSearching,
        showWhenSelected: element.showWhenSelected,
        showInSearch: element.showInSearch,
        control: element.control,
        defaultValue:
          element.defaultValue && isOption(element.defaultValue)
            ? element.defaultValue.value
            : element.defaultValue,
        recordType: element.recordType
      });
    });
    const response = await api.messaging.post(
      `NetSuite/Integrations/Mapping/${selectedConfig}`,
      {
        Mappings: newMap
      }
    );
    fetchMapping();
  }

  async function deleteRecord(id: number) {
    const response = await api.messaging.delete(
      `NetSuite/Integrations/Mapping/${id}`
    );
  }

  const updateMappedField = (index: number, mappedField: NetSuiteMap) => {
    const clone = [...mappedFields];
    clone[index] = mappedField;
    setMappedFields(clone);
  };

  function handleModal(passedField: NetSuiteMap) {
    setShowModal(true);
    setPassedField(passedField);
  }

  const saveLinkedTo = useCallback(
    (linkedField: NetSuiteMap) => {
      const clone = [...mappedFields];
      clone[linkedField.id] = linkedField;
      setMappedFields(clone);
    },
    [mappedFields]
  );

  const fetchList = useCallback(
    async (passedFields: NetSuiteMap[]) => {
      const jsonArray = passedFields.map((passedField) => ({
        recordType: passedField.recordType,
        netSuiteField: passedField.netSuiteField?.value
      }));
      const resp = await api.messaging.post(
        `NetSuite/Integrations/RelayToRestlet/${selectedConfig}`,
        {
          relayType: "getList",
          fields: jsonArray
        }
      );

      const json = await resp.json();
      const clone = [...(listFields || [])];
      //If the element already exists, replace it with the newest value
      passedFields.forEach((passedField) => {
        if (
          clone.filter(
            (item) => item.netSuiteField === passedField.netSuiteField?.value
          ).length > 0
        ) {
          clone.splice(
            clone.findIndex(
              (item) => item.netSuiteField === passedField.netSuiteField?.value
            ),
            1
          );
        }
        let recordType = passedField.recordType;
        if (recordType === "Ticket") {
          recordType = "supportcase";
        }
        if (recordType === "Order") {
          recordType = "salesorder";
        }
        const recordTypeData = json[recordType];
        const netSuiteFieldData =
          recordTypeData?.[passedField.netSuiteField?.value];

        if (netSuiteFieldData) {
          const listItems = netSuiteFieldData.map((item: NetSuiteListItem) => ({
            value: item.value,
            label: item.text.replace(/&nbsp;/gi, "")
          }));

          clone.push({
            netSuiteField: passedField.netSuiteField?.value,
            listItems
          });
        }
      });
      setListFields(clone);
    },
    [api.messaging, listFields, selectedConfig]
  );

  useEffect(() => {
    if (!metadataType) return;
    fetchMapping();
  }, [fetchMapping, metadataType]);

  useEffect(() => {
    if (mappedFields.length === 0 || loading || listFields != null) return;
    const mappedFieldsToBeListed = mappedFields.filter(
      (item) => item.control === "List"
    );
    fetchList(mappedFieldsToBeListed);
  }, [fetchList, listFields, loading, mappedFields]);

  const ready = useMemo(() => !loading, [loading]);
  if (!ready) {
    return <></>;
  }

  return (
    <Panel title="NetSuite Field Mapping">
      <div className="p-4 w-fit ">
        <div>
          <label className="mr-4">
            Select a NetSuite Configuration to edit
          </label>
          <select
            title="netsuite config"
            className="input w-64 mb-3"
            value={selectedConfig}
            onChange={(e) => setSelectedConfig(Number(e.target.value))}
          >
            {items.map((config) => (
              <option key={config.id} value={config.id}>
                {config.displayName}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="mr-4">Select a type of record to map</label>
          <select
            title="record type"
            className="input w-64 mb-3"
            value={metadataType}
            onChange={(e) => setMetadataType(e.target.value)}
          >
            {metaDataOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        {metadataType && (
          <div className="justify-end mb-3">
            <NetSuiteInfoMessage metadataType={metadataType} />
            <button
              type="submit"
              className="btn-green mr-3 mt-2"
              onClick={addRow}
            >
              Add Row
            </button>
            <button
              type="submit"
              className="btn-green mt-2"
              onClick={saveRecord}
            >
              Save Record
            </button>
          </div>
        )}
        <table className="p-4 w-full bg-white">
          <thead>
            <tr>
              <th className="border-b-[1px] p-2 w-80 text-left">
                Netsuite Field
              </th>
              <th className="border-b-[1px] p-2 w-80 text-left">Label</th>
              <th className="border-b-[1px] p-2 w-80 text-left">Linked To</th>
              <th className="border-b-[1px] p-2 w-24 ">Create</th>
              <th className="border-b-[1px] p-2 w-24 ">Used For Searching</th>
              <th className="border-b-[1px] p-2 w-24 ">Show When Selected</th>
              <th className="border-b-[1px] p-2 w-24 ">Show In Search</th>
              <th className="border-b-[1px] p-2 w-80 text-left">Control</th>
              <th className="border-b-[1px] p-2 w-80 text-left">
                Default Value
              </th>
              <th className="border-b-[1px] p-2 w-24"></th>
            </tr>
          </thead>
          <tbody>
            {mappedFields.map((mappedField, i) => (
              <NetSuiteMappingTableRow
                mappedField={mappedField}
                i={i}
                key={i}
                listFields={listFields || []}
                updateMappedField={updateMappedField}
                isLoading={isLoading}
                deleteRow={deleteRow}
                handleModal={handleModal}
                metadata={metadata}
                fetchList={fetchList}
              />
            ))}
          </tbody>
        </table>
      </div>
      {showModal && passedField && (
        <NetSuiteModal
          netSuiteMap={passedField}
          onClose={() => setShowModal(false)}
          saveLinkedField={() => saveLinkedTo}
        />
      )}
    </Panel>
  );
}
