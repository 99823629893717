import * as React from "react";
import useApi from "../../hooks/useApi";

export interface FileListItem {
  Id: string;
  Name: string;
  Size: number;
  Url: string;
}

export interface Tag {
  Id: string;
  Name: string;
}

export interface TagAssociation {
  FileId: string;
  TagId: string;
}

export interface GroupAssociation {
  FileId: string;
  GroupId: number;
}

export default function useStorageFiles() {
  const [fetchingFiles, setFetchingFiles] = React.useState(true);
  const [files, setFiles] = React.useState<FileListItem[]>([]);
  const [tags, setTags] = React.useState<Tag[]>([]);
  const api = useApi();

  const [tagAssociations, setTagAssociations] = React.useState<
    TagAssociation[]
  >([]);

  const [groupAssociations, setGroupAssociations] = React.useState<
    GroupAssociation[]
  >([]);

  const fetchFiles = React.useCallback(async () => {
    setFetchingFiles(true);

    try {
      //const url = App.site.mainApi("storage");
      const response = await api.main.get("storage");
      const json = await response.json();
      setFiles(json.Files);
      setTags(json.Tags);
      setTagAssociations(json.TagAssociations);
      setGroupAssociations(json.GroupAssociations);
    } catch (err) {
      console.error(err);
    } finally {
      setFetchingFiles(false);
    }
  }, []);

  React.useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  return {
    files,
    tags,
    tagAssociations,
    groupAssociations,
    fetchingFiles,
    fetchFiles
  };
}
