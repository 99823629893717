import React from "react";
import { IconProps } from "./types";

function IconHash({ className = "text-slate-500", ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M2.66675 5.83333H13.3334M2.66675 11.1667H13.3334M5.33341 2.5V14.5M10.6667 2.5V14.5"
        stroke="#64748B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconHash;
