import React from "react";
import WizardStepCircle from "@/components/WizardStepCircle";

interface Props {
  stepLabels: string[];
  activeStep: number;
}

export default function WizardStepIndicator(props: Props) {
  function isActive(step: number) {
    if (step == props.activeStep) {
      return true;
    } else return false;
  }

  function getTextColor(active: boolean) {
    if (active) {
      return "text-cornflower-blue-500";
    }
  }

  return (
    <div>
      <div className="flex items-center">
        {props.stepLabels.map((label, index) => (
          <React.Fragment key={index}>
            <WizardStepCircle number={index + 1} active={isActive(index + 1)} />
            <div
              className={`ml-4 text-lg ${getTextColor(isActive(index + 1))}`}
            >
              {label}
            </div>
            {index + 1 !== props.stepLabels.length && (
              <div className="bg-slate-gray-200 my-6 h-1 w-36 mx-4"></div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
