import LoadingSpinner from "@/components/LoadingSpinner";
import useApi from "@/hooks/useApi";
import useRoleListItems from "@/hooks/useRoleListItems";
import useTeams from "@/hooks/useTeams";
import IconArrowCircleLeft from "@/icons/IconArrowCircleLeft";
import IconSendArrow from "@/icons/IconSendArrow";
import FormValidator from "@velaro/velaro-shared/src/components/FormValidator";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserModel } from "../types";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import useToastAlert from "@/hooks/useToastAlert";
import { SecondaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";

interface Props {
  user: UserModel;
  setUser(user: UserModel): void;
  setActiveStep(step: number): void;
  setShowSuccessPanel(show: boolean): void;
}
export default function ReviewWizardStep(props: Props) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const { getTeamNameById } = useTeams();
  const { getRoleNameById } = useRoleListItems();
  const navigate = useNavigate();
  const api = useApi();
  const toastAlert = useToastAlert();

  const headshotUrl = "//az597071.vo.msecnd.net/headshots/default.png";

  function getTeams(user: UserModel | null) {
    if (!user) return null;

    if (user.teamIds?.length === 0) {
      return "None";
    }

    const teams = user.teamIds.map((x) => getTeamNameById(x));

    return teams.join(", ");
  }

  function getRoles(user: UserModel | null) {
    if (user?.roles?.length === 0 || user?.roles === null) {
      return "(None)";
    }

    const roleNames = user?.roles.map((x) => {
      const parsedNumber = parseInt(x);
      if (!isNaN(parsedNumber)) {
        return getRoleNameById(parsedNumber);
      } else {
        return x;
      }
    });
    return roleNames?.join(", ");
  }

  async function handleSubmit() {
    setLoading(true);
    const response = await api.messaging.post("Users", props.user);

    if (response.ok) {
      const newUser = await response.json();
      props.setUser(newUser);
      setLoading(false);
      props.setShowSuccessPanel(true);
    } else if (response.status === 400) {
      const errorResponse = await response.json();
      if (errorResponse.errors == null) {
        toastAlert.displayToast(Severity.Error, errorResponse);
        setLoading(false);
        return;
      }
      setErrors(errorResponse.errors);
      setLoading(false);
    } else {
      toastAlert.displayToast(
        Severity.Error,
        "Something went wrong. Please try again."
      );
      setLoading(false);
    }
  }

  return (
    <div className="flex justify-center">
      <div className="panel bg-white dark:bg-stone-300 rounded-md shadow-md p-8 mt-6 mx-8 w-2/3">
        <div className="flex items-center">
          <div className="w-12 h-12 relative z-0 flex-grow-0 flex-shrink-0">
            <img
              src={headshotUrl}
              alt=""
              className="rounded-full w-12 h-12 absolute z-10"
            />
          </div>
          <div className="pl-4">
            <div className="font-bold">
              {props.user?.displayName || "New User"}
            </div>
            <div className="text-sm text-slate-500">User Profile</div>
          </div>
        </div>
        <hr className="my-4 border-gray-100" />
        <div className="flex">
          <div className="w-1/4 font-semibold">First Name</div>
          <div className="w-1/2">{props.user?.firstName}</div>
          <div
            className="flex justify-end w-1/4 text-cornflower-blue-500 cursor-pointer hover:underline"
            onClick={() => props.setActiveStep(1)}
          >
            Update
          </div>
        </div>
        <hr className="my-4 border-gray-100" />
        <div className="flex">
          <div className="w-1/4 font-semibold">Last Name</div>
          <div className="w-1/2">{props.user?.lastName}</div>
          <div
            className="flex justify-end w-1/4 text-cornflower-blue-500 cursor-pointer hover:underline"
            onClick={() => props.setActiveStep(1)}
          >
            Update
          </div>
        </div>
        <hr className="my-4 border-gray-100" />
        <div className="flex">
          <div className="w-1/4 font-semibold">Email</div>
          <FormValidator error={errors["Email"]} className="w-1/2">
            <div>{props.user?.email}</div>
          </FormValidator>
          <div
            className="flex justify-end w-1/4 text-cornflower-blue-500 cursor-pointer hover:underline"
            onClick={() => props.setActiveStep(1)}
          >
            Update
          </div>
        </div>
        <hr className="my-4 border-gray-100" />
        <div className="flex">
          <div className="w-1/4 font-semibold">Roles</div>
          <div className="w-1/2">{getRoles(props.user)}</div>
          <div
            className="flex justify-end w-1/4 text-cornflower-blue-500 cursor-pointer hover:underline"
            onClick={() => props.setActiveStep(1)}
          >
            Update
          </div>
        </div>
        <hr className="my-4 border-gray-100" />
        <div className="flex ">
          <div className="w-1/4 font-semibold flex-1">Teams</div>
          <div className="w-1/2">{getTeams(props.user)}</div>
          <div
            className="flex justify-end w-1/4 text-cornflower-blue-500 cursor-pointer hover:underline"
            onClick={() => props.setActiveStep(2)}
          >
            Update
          </div>
        </div>
        <hr className="my-4 border-gray-100" />
        <div className="mt-4 flex w-full justify-between items-center">
          <div
            className="flex items-center text-slate-gray-400 cursor-pointer"
            onClick={() => props.setActiveStep(2)}
          >
            <IconArrowCircleLeft />
            <div className="ml-2">Previous Step</div>
          </div>
          <div className="flex">
            <div className="mr-2">
              <SecondaryBlueButton
                label="Cancel"
                onClick={() => navigate("/users")}
              />
            </div>
            <button
              className={
                "bg-cornflower-blue-500 hover:bg-cornflower-blue-700 text-white py-2 px-4 rounded-full text-sm font-medium flex items-center"
              }
              onClick={() => handleSubmit()}
              disabled={loading}
            >
              Create and send invite
              <IconSendArrow className="ml-2" />
            </button>
            {loading && (
              <div className="ml-2">
                <LoadingSpinner />
              </div>
            )}
          </div>
        </div>
        {/* Coming soon */}
        {/* <div className="flex">
          <div className="w-1/4 font-semibold">Skills</div>
          <div className="w-1/2">Skills</div>
          <div className="flex justify-end w-1/4">Update</div>
        </div>
        <hr className="my-4 border-gray-100" />
        <div className="flex">
          <div className="w-1/4 font-semibold">Chat Limit</div>
          <div className="w-1/2">Chat Limit</div>
          <div className="flex justify-end w-1/4">Update</div>
        </div>
        <hr className="my-4 border-gray-100" /> */}
      </div>
    </div>
  );
}
