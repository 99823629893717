import React from "react";
import { Subscription } from "@/providers/SubscriptionsProvider";

export const SubscriptionsContext = React.createContext<{
  subscription: Subscription | null;
  loading: boolean;
}>({
  subscription: {
    id: 0,
    siteId: 0,
    enableWeb: false,
    enableEmail: false,
    enableSms: false,
    enableWhatsapp: false,
    enableIvr: false,
    enableFacebook: false,
    enableNetsuite: false,
    enableAutomation: false,
    enableAI: false,
    enableWorkflowRules: false,
    enableFormstack: false,
    enableContactManager: false,
    enableKnowledgeBase: false,
    enableInvitations: false
  },
  loading: true
});
