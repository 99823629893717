import useApi from "@/hooks/useApi";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Invitation } from "./types";
import LoadingSpinner from "@velaro/velaro-shared/src/components/LoadingSpinner";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import IconPlus from "@velaro/velaro-shared/src/icons/IconPlus";
import InvitationModal from "./InvitationModal";
import TableBase from "@velaro/velaro-shared/src/components/Table/TableBase";
import IconTrash from "@velaro/velaro-shared/src/icons/IconTrash";
import { FormGroup } from "../Workflows/RightAside/RightAsideHelpers";
import IconEditPencil2 from "@/icons/IconEditPencil2";
import MeatballMenu, {
  MeatballMenuItem
} from "@velaro/velaro-shared/src/components/MeatballMenu";
import { get } from "lodash";
import useTeams from "@/hooks/useTeams";
import { getExpressionLabel } from "@/components/Expressions/types";

const columns = [
  {
    label: "Name",
    accessor: "name"
  },
  {
    label: "Description",
    accessor: "description"
  },
  {
    label: "Type",
    accessor: "type"
  },
  {
    label: "Rule",
    accessor: "rule"
  },
  {
    label: "",
    accessor: "edit"
  }
];

export default function Index() {
  const [invitations, setInvitations] = useState<Invitation[] | undefined>();
  const [loading, setLoading] = useState(true);
  const [selectedId, setSelectedId] = useState<number | undefined>();
  const { getTeamNameById } = useTeams();
  const api = useApi();

  useEffect(() => {
    async function fetch() {
      const response = await api.messaging.get("Invitations/List");
      const data = (await response.json()) as Invitation[];
      setInvitations(data);
      setLoading(false);
    }
    fetch();
  }, [api.messaging]);

  const remove = useCallback(
    async (id: number) => {
      setLoading(true);
      const response = await api.messaging.delete(`Invitations/${id}`);
      if (response.ok) {
        setInvitations(invitations?.filter((i) => i.id !== id));
      }
      setLoading(false);
    },
    [api.messaging, invitations]
  );

  const tableData = useMemo(() => {
    if (!invitations) {
      return [];
    }

    function getMeatballItems(id: number): MeatballMenuItem[] {
      return [
        {
          label: "Edit",
          onClick: () => setSelectedId(id),
          icon: IconEditPencil2
        },
        {
          label: "Delete",
          onClick: () => remove(id),
          icon: IconTrash
        }
      ];
    }

    return invitations.map((invitation) => ({
      ...invitation,
      rule: getExpressionLabel(invitation.triggerExpression, getTeamNameById),
      edit: <MeatballMenu menuItems={getMeatballItems(invitation.id!)} />
    }));
  }, [getTeamNameById, invitations, remove]);

  if (loading || !invitations) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <FormGroup>
        <PrimaryBlueButton
          onClick={() => setSelectedId(0)}
          label="Add"
          prefixIcon={<IconPlus />}
        />
      </FormGroup>
      <FormGroup>
        {invitations.length === 0 && <div>You have no invitations</div>}
        {invitations.length > 0 && (
          <TableBase columns={columns} tableData={tableData} />
        )}
      </FormGroup>
      {selectedId !== undefined && (
        <InvitationModal
          onClose={() => setSelectedId(undefined)}
          onUpdate={(invitation) => {
            setSelectedId(undefined);
            const index = invitations.findIndex((i) => i.id === invitation.id);
            if (index === -1) {
              setInvitations([...invitations, invitation]);
              return;
            }
            const clone = [...invitations];
            clone[index] = invitation;
            setInvitations(clone);
          }}
          invitation={invitations.find((i) => i.id === selectedId)}
        />
      )}
    </div>
  );
}
