import LoadingSpinner from "@/components/LoadingSpinner";
import useApi from "@/hooks/useApi";
import React, { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import AddFieldModal from "./AddFieldModal";

export interface FieldOption {
  label: string;
  value: string;
  id: string;
}

export interface FieldDefinition {
  type: "text" | "select" | "checkbox" | "textarea";
  options: Array<FieldOption>;
  label: string;
}

interface CustomFieldDefinition {
  address: Array<FieldDefinition>;
  company: Array<FieldDefinition>;
  contact: Array<FieldDefinition>;
  ticket: Array<FieldDefinition>;
}

export default function CustomFields() {
  const api = useApi();
  const [showAddFieldModal, setShowAddFieldModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [customFieldDefinition, setCustomFieldDefinition] =
    useState<CustomFieldDefinition>({
      address: [],
      company: [],
      contact: [],
      ticket: []
    });
  const [recordType, setRecordType] =
    useState<"address" | "company" | "contact" | "ticket">("address");

  const removeField = useCallback(
    (label: string) => {
      const state = { ...customFieldDefinition };
      state[recordType] = [
        ...state[recordType].filter((x) => x.label !== label)
      ];
      setCustomFieldDefinition(state);
      api.contactcenter.post("customfields", state);
    },
    [customFieldDefinition, recordType, api.contactcenter]
  );

  const renderField = useCallback(
    (field: FieldDefinition) => {
      return (
        <tr key={field.label}>
          <td>{field.label}</td>
          <td>{field.type}</td>
          <td>
            <button
              type="button"
              className="btn-red btn-xs w-1/5 m-1"
              onClick={() => removeField(field.label)}
            >
              <i className="fa fa-trash" />
            </button>
          </td>
        </tr>
      );
    },
    [removeField]
  );

  const renderDefinitionsForRecordType = React.useCallback(() => {
    if (customFieldDefinition === undefined) {
      return null;
    }

    if (customFieldDefinition[recordType].length === 0) {
      return (
        <div className="flex items-center justify-center mt-4">
          No custom fields defined for record type
        </div>
      );
    }

    return (
      <table className="table-fixed w-full text-left">
        <thead>
          <tr>
            <th className="w-2/5">Label</th>
            <th className="w-2/5">Type</th>
            <th className="w-1/5"></th>
          </tr>
        </thead>
        <tbody>{customFieldDefinition[recordType].map(renderField)}</tbody>
      </table>
    );
  }, [customFieldDefinition, recordType, renderField]);

  useEffect(() => {
    api.contactcenter.get("customfields").then(async (result) => {
      setCustomFieldDefinition(await result.json());
      setLoading(false);
    });
  }, [api.contactcenter, loading]);

  const submit = useCallback(
    (definition: FieldDefinition) => {
      setShowAddFieldModal(false);
      setCustomFieldDefinition((oldState) => {
        const state = { ...oldState };
        state[recordType] = [...state[recordType], definition];
        api.contactcenter.post("customfields", state);
        return state;
      });
    },
    [recordType, api.contactcenter]
  );

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <>
      <div className="flex h-full items-center">
        <label className="mr-2">Record Type:</label>
        <select
          className="input"
          value={recordType}
          onChange={(e) => setRecordType(e.currentTarget.value as any)}
        >
          <option value="address">Address</option>
          <option value="company">Company</option>
          <option value="contact">Contact</option>
          <option value="ticket">Ticket</option>
        </select>
        <div className="ml-auto">
          <button
            className="btn-green m-2"
            onClick={() => setShowAddFieldModal(true)}
          >
            Add Field
          </button>
        </div>
      </div>
      {renderDefinitionsForRecordType()}
      {showAddFieldModal && (
        <AddFieldModal
          show={showAddFieldModal}
          onCancel={() => setShowAddFieldModal(false)}
          submit={submit}
        />
      )}
    </>
  );
}
