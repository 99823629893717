import React from "react";
import { IconProps } from "./types";

function IconMessageCheckSquare({
  className = "stroke-slate-500",
  ...props
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      className={className}
      {...props}
    >
      <path
        d="M12.75 14L15.4167 16.6667L21.4167 10.6667M10.0833 24V27.114C10.0833 27.8245 10.0833 28.1797 10.229 28.3622C10.3556 28.5208 10.5477 28.6131 10.7507 28.6129C10.9842 28.6127 11.2616 28.3908 11.8163 27.9469L14.997 25.4024C15.6467 24.8826 15.9716 24.6228 16.3333 24.4379C16.6543 24.274 16.9959 24.1541 17.349 24.0817C17.7469 24 18.1629 24 18.995 24H22.35C24.5902 24 25.7103 24 26.566 23.564C27.3186 23.1805 27.9305 22.5686 28.314 21.816C28.75 20.9603 28.75 19.8402 28.75 17.6V10.4C28.75 8.15979 28.75 7.03968 28.314 6.18404C27.9305 5.43139 27.3186 4.81947 26.566 4.43597C25.7103 4 24.5902 4 22.35 4H11.15C8.90979 4 7.78969 4 6.93404 4.43597C6.18139 4.81947 5.56947 5.43139 5.18597 6.18404C4.75 7.03968 4.75 8.15979 4.75 10.4V18.6667C4.75 19.9066 4.75 20.5266 4.8863 21.0353C5.25617 22.4156 6.33436 23.4938 7.71472 23.8637C8.22339 24 8.84337 24 10.0833 24Z"
        stroke="inherit"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconMessageCheckSquare;
