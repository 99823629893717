import * as React from "react";

interface Props {
  title: string;
  content: string;
}

export default function PreviewButton(props: Props) {
  return (
    <form
      //todo wtf is this link supposed to be
      action={`${import.meta.env.VITE_KB_URL}/preview`}
      method="post"
      target="_blank"
    >
      <input type="hidden" name="Title" value={props.title} />
      <input type="hidden" name="ContentString" value={props.content} />
      <button type="submit" className="btn btn-default">
        <i className="fa fa-external-link-square" /> Preview Article
      </button>
    </form>
  );
}
