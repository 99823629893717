import * as React from "react";
import {
  redirect,
  RouterProvider,
  createBrowserRouter
} from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import ContactCenter from "@/pages/ContactCenter";
import Home from "@/pages/Home";
import LoginCallback from "@/pages/Auth/LoginCallback";
import LoginCallbackError from "@/pages/Auth/LoginCallbackError";
import LoginUnauthorized from "@/pages/Auth/LoginUnauthorized";
import Logout from "@/pages/Auth/Logout";
import LogoutCallback from "@/pages/Auth/LogoutCallback";
import AutoResponses from "@/pages/Messaging/AutoResponses/AutoResponses";
import CannedResponses from "@/pages/Messaging/CannedResponses/CannedResponses";
import TwitterCallback from "@/pages/Messaging/Channels/TwitterCallback";
import IntegrationLog from "@/pages/Messaging/Integrations/Log";
import NetSuite from "@/pages/Messaging/Integrations/NetSuite/NetSuite";
import NetSuiteCallback from "@/pages/Messaging/Integrations/NetSuite/NetSuiteCallback";
import Surveys from "@/pages/Messaging/Surveys/Surveys";
import Teams from "@/pages/Messaging/Teams/Teams";
import UserProfile, {
  BackToUsersButton
} from "@/pages/Messaging/Users/UserProfile";
import WebMessenger from "@/pages/Messaging/WebMessenger";
import WorkSchedules from "@/pages/Messaging/WorkSchedules/WorkSchedules";
import Users from "@/pages/Messaging/Users/Users";
import AddUserWizard from "@/pages/Messaging/Users/AddUserWizard/AddUserWizard";
import Deployments from "@/pages/Messaging/Deployments/Deployments";
import BotManager from "@/pages/Messaging/Bots";
import RuleManager from "@/pages/Messaging/Rules";
import useAuth from "@/hooks/useAuth";
import { useCallback } from "react";
import Landing from "@/pages/Messaging/Integrations/IntegrationsLanding";
import FileStorage from "@/pages/Messaging/FileStorage";
import WorkflowsCreate from "@/pages/Messaging/Workflows/WorkflowsCreate";
import Workflows from "@/pages/Messaging/Workflows/Workflows";
import RouteHeader from "@/pages/Shared/RouteHeader";
import FacebookIntegration from "@/pages/Messaging/Channels/FacebookIntegration";
import SmsIntegration from "@/pages/Messaging/Channels/SmsIntegration";
import WhatsAppIntegration from "@/pages/Messaging/Channels/WhatsAppIntegration";
import ArticlesIndex from "@/kb/articles/Index";
import Skills from "@/pages/Messaging/Skills/Skills";
import ArticlesCreate from "@/kb/articles/Create";
import ArticlesEdit from "@/kb/articles/Edit";
import SettingsIndex from "@/kb/settings/Index";
import TopicsIndex from "@/kb/topics/Index";
import SiteMap from "@/kb/sitemap/Index";
import SetupIndex from "@/kb/setup/Index";
import AI from "@/pages/Messaging/Bots/AI/AI";
import CreateChatbotButton from "@/pages/Messaging/Bots/AI/CreateChatbotButton";
import NewWorkflowButton from "@/pages/Messaging/Workflows/NewWorkflowButton";
import AIChatbotSettingsGeneral from "@/pages/Messaging/Bots/AI/AIChatbotSettingsGeneral";
import StickyRouteHeader from "@/pages/Shared/StickyRouteHeader";
import AIChatbotSettingsTraining from "@/pages/Messaging/Bots/AI/AIChatbotSettingsTraining";
import WorkflowsEditor from "@/pages/Messaging/Workflows/WorkflowsEditor";
import Formstack from "@/pages/Messaging/Integrations/Formstack/Formstack";
import AIChatbotSettingsHandoff from "@/pages/Messaging/Bots/AI/AIChatbotSettingsHandoff";
import SaveChatbotButton from "@/pages/Messaging/Bots/AI/SaveChatbotButton";
import IvrIntegration from "@/pages/Messaging/Channels/IvrIntegration";
import SiteSettings from "@/pages/Messaging/SiteSettings";
import Tags from "@/pages/Messaging/Tags/Tags";
import Invitations from "@/pages/Messaging/Invitations";
import BaseInvitationEditor from "@/pages/Messaging/Invitations/BaseInvitationEditor";

const createAppRoutes = (mainLoader: (params: any) => any) => [
  { path: "login/callback", element: <LoginCallback /> },
  { path: "login/callback/error", element: <LoginCallbackError /> },
  { path: "login/unauthorized", element: <LoginUnauthorized /> },
  { path: "logout", element: <Logout /> },
  { path: "logout/callback", element: <LogoutCallback /> },
  {
    element: <PrivateRoute />,
    children: [
      { path: "/", element: <Home /> },
      {
        path: "/site",
        element: (
          <RouteHeader title="Site">
            <SiteSettings />
          </RouteHeader>
        )
      },
      {
        path: "/users/",
        element: (
          <RouteHeader title="Users">
            <Users />
          </RouteHeader>
        )
      },
      {
        path: "/users/:userId",
        element: (
          <RouteHeader
            title="User Profile"
            headerActions={<BackToUsersButton />}
          >
            <UserProfile />
          </RouteHeader>
        )
      },
      {
        path: "/user/add",
        element: (
          <RouteHeader title="Add User">
            <AddUserWizard />
          </RouteHeader>
        )
      },
      {
        path: "/contact_center",
        element: (
          <RouteHeader title="Contact Center">
            <ContactCenter />
          </RouteHeader>
        )
      },
      {
        path: "/teams",
        element: (
          <RouteHeader title="Teams">
            <Teams />
          </RouteHeader>
        )
      },
      {
        path: "/deployment",
        element: (
          <RouteHeader title="Deployments">
            <Deployments />
          </RouteHeader>
        )
      },
      {
        path: "/tags",
        element: (
          <RouteHeader title="Tags">
            <Tags />
          </RouteHeader>
        )
      },
      {
        path: "/facebook",
        element: (
          <RouteHeader title="Facebook">
            <FacebookIntegration />
          </RouteHeader>
        )
      },
      {
        path: "/sms",
        element: (
          <RouteHeader title="SMS">
            <SmsIntegration />
          </RouteHeader>
        )
      },
      {
        path: "/whatsapp",
        element: (
          <RouteHeader title="WhatsApp">
            <WhatsAppIntegration />
          </RouteHeader>
        )
      },
      {
        path: "/ivr",
        element: (
          <RouteHeader title="IVR">
            <IvrIntegration />
          </RouteHeader>
        )
      },
      {
        path: "/surveys",
        element: (
          <RouteHeader title="Surveys">
            <Surveys />
          </RouteHeader>
        )
      },
      {
        path: "/integrations",
        element: (
          <RouteHeader title="Integrations">
            <Landing />
          </RouteHeader>
        )
      },
      {
        path: "/twitter/callback",
        element: <TwitterCallback />
      },
      {
        path: "/netsuite/oauthcallback",
        element: <NetSuiteCallback />
      },
      {
        path: "/auto_responses",
        element: (
          <RouteHeader title="Auto Responses">
            <AutoResponses />
          </RouteHeader>
        )
      },
      {
        path: "/canned_responses",
        element: (
          <RouteHeader title="Canned Responses">
            <CannedResponses />
          </RouteHeader>
        )
      },
      {
        path: "/web_messenger",
        element: (
          <RouteHeader title="Web Messenger">
            <WebMessenger />
          </RouteHeader>
        )
      },
      {
        path: "/work_schedules",
        element: (
          <RouteHeader title="Work Schedules">
            <WorkSchedules />
          </RouteHeader>
        )
      },
      {
        path: "/integrations/netsuite",
        element: (
          <RouteHeader title="Netsuite">
            <NetSuite />
          </RouteHeader>
        )
      },
      {
        path: "/integrations/formstack",
        element: (
          <RouteHeader title="Formstack">
            <Formstack />
          </RouteHeader>
        )
      },
      {
        path: "/integrations/log",
        element: (
          <RouteHeader title="Integration Log">
            <IntegrationLog />
          </RouteHeader>
        )
      },
      {
        path: "/bots",
        element: (
          <RouteHeader title="Bots">
            <BotManager />
          </RouteHeader>
        )
      },
      {
        path: "/ai",
        element: (
          <StickyRouteHeader
            title="AI Chatbot"
            headerActions={<CreateChatbotButton></CreateChatbotButton>}
          >
            <AI />
          </StickyRouteHeader>
        )
      },
      {
        path: "/ai/settings/general/:id",
        element: (
          <StickyRouteHeader
            title="AI Chatbot"
            headerActions={<SaveChatbotButton></SaveChatbotButton>}
            backLink="/ai"
          >
            <AIChatbotSettingsGeneral />
          </StickyRouteHeader>
        )
      },
      {
        path: "/ai/settings/training/:id",
        element: (
          <StickyRouteHeader
            title="AI Chatbot"
            headerActions={<SaveChatbotButton></SaveChatbotButton>}
            backLink="/ai"
          >
            <AIChatbotSettingsTraining />
          </StickyRouteHeader>
        )
      },
      {
        path: "/ai/settings/handoff/:id",
        element: (
          <StickyRouteHeader
            title="AI Chatbot"
            headerActions={<SaveChatbotButton></SaveChatbotButton>}
            backLink="/ai"
          >
            <AIChatbotSettingsHandoff />
          </StickyRouteHeader>
        )
      },
      // {
      //   path: "/ai/settings/data/:id",
      //   element: (
      //     <StickyRouteHeader
      //       title="AI Chatbot"
      //       headerActions={<SaveChatbotButton></SaveChatbotButton>}
      //       backLink="/ai"
      //     >
      //       <AIChatbotSettingsData />
      //     </StickyRouteHeader>
      //   )
      // },
      // {
      //   path: "/ai/settings/questions/:id",
      //   element: (
      //     <StickyRouteHeader
      //       title="AI Chatbot"
      //       headerActions={<SaveChatbotButton></SaveChatbotButton>}
      //       backLink="/ai"
      //     >
      //       <AIChatbotSettingsStarterQuestions />
      //     </StickyRouteHeader>
      //   )
      // },
      // {
      //   path: "/ai/settings/test/:id",
      //   element: (
      //     <StickyRouteHeader
      //       title="AI Chatbot"
      //       headerActions={<SaveChatbotButton></SaveChatbotButton>}
      //       backLink="/ai"
      //     >
      //       <AIChatbotSettingsTest />
      //     </StickyRouteHeader>
      //   )
      // },
      {
        path: "/rules",
        element: (
          <RouteHeader title="Workflow Rules">
            <RuleManager />
          </RouteHeader>
        )
      },
      {
        path: "/files",
        element: (
          <RouteHeader title="File Upload">
            <FileStorage />
          </RouteHeader>
        )
      },
      {
        path: "/workflows/create",
        element: (
          <RouteHeader title="Create New Workflow">
            <hr className="pb-6" />
            <WorkflowsCreate />
          </RouteHeader>
        )
      },
      {
        path: "/workflows",
        element: (
          <RouteHeader
            title="My workflows"
            headerActions={<NewWorkflowButton />}
          >
            <Workflows />
          </RouteHeader>
        )
      },
      {
        path: "/workflows/:id",
        element: <WorkflowsEditor />
      },
      {
        path: "/invitations",
        element: (
          <RouteHeader title="Invitations">
            <Invitations />
          </RouteHeader>
        )
      },
      {
        path: "/skills",
        element: (
          <RouteHeader title="Skills">
            <Skills />
          </RouteHeader>
        )
      },

      {
        path: "/kb/articles",
        element: (
          <RouteHeader title="KB Articles">
            <ArticlesIndex />
          </RouteHeader>
        )
      },
      {
        path: "/kb/settings",
        element: (
          <RouteHeader title="KB Settings">
            <SettingsIndex />
          </RouteHeader>
        )
      },
      {
        path: "/kb/articles/create",
        element: (
          <RouteHeader title="Create KB Article">
            <ArticlesCreate />
          </RouteHeader>
        )
      },
      //todo this id needs fixed
      {
        path: "/kb/articles/edit/:id",
        element: (
          <RouteHeader title="Edit KB Article">
            <ArticlesEdit />
          </RouteHeader>
        )
      },
      {
        path: "/kb/topics",
        element: (
          <RouteHeader title="KB Topics">
            <TopicsIndex />
          </RouteHeader>
        )
      },
      {
        path: "/kb/map",
        element: (
          <RouteHeader title="KB Site Map">
            <SiteMap />
          </RouteHeader>
        )
      },
      {
        path: "/kb/setup",
        element: (
          <RouteHeader title="KB Setup">
            <SetupIndex />
          </RouteHeader>
        )
      }
    ]
  }
];

export function AppRoutes() {
  const auth = useAuth();

  const mainLoader = useCallback(
    async ({ request }: any) => {
      const url = new URL(request.url);
      const route = url.pathname;
      if (auth.isAuthenticated() && route === "/") {
        return redirect("/");
      }
      return null;
    },
    [auth]
  );

  const routes = createAppRoutes(mainLoader);
  const router = createBrowserRouter(routes);
  return <RouterProvider router={router} />;
}
