import Accordion from "@/components/Accordion";
import IconHelp from "@/icons/IconHelp";
import React, { useMemo } from "react";
import { LanguageConfigModel } from "@/pages/Messaging/types";

interface Props {
  onExpanded(): void;
  workingConfig: LanguageConfigModel;
  update: (updates: Partial<LanguageConfigModel>) => void;
  persist: () => void;
  loading: boolean;
}

export default function ConnectingForm(props: Props) {
  const saveButtonText = useMemo(() => {
    return props.loading ? "Saving..." : "Save and set live";
  }, [props.loading]);

  return (
    <Accordion.Item
      eventKey="connectingOpen"
      onExpanded={() => {
        props.onExpanded();
      }}
      onCollapsed={() => {}}
    >
      <Accordion.Header>
        <Accordion.Title>Connecting Screen</Accordion.Title>
        <Accordion.SubTitle>
          Customize what your visitors see while waiting to be connected to an
          agent
        </Accordion.SubTitle>
      </Accordion.Header>
      <Accordion.Body>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            props.persist();
          }}
        >
          <div className="mb-4">
            <label className="block mb-2" htmlFor="connecting-title">
              Connecting Title
            </label>
            <input
              className="input"
              type="text"
              id="connecting-title"
              value={props.workingConfig.connectingTitle}
              onChange={(e) =>
                props.update({ connectingTitle: e.target.value })
              }
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="connecting-subtitle">
              Connecting Subtitle
            </label>
            <input
              className="input"
              type="text"
              id="connecting-subtitle"
              value={props.workingConfig.connectingSubtitle}
              onChange={(e) =>
                props.update({ connectingSubtitle: e.target.value })
              }
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="connecting-message">
              Connecting Message
            </label>
            <input
              className="input"
              type="text"
              id="connecting-message"
              value={props.workingConfig.connectingMessage}
              onChange={(e) =>
                props.update({ connectingMessage: e.target.value })
              }
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="connecting-submessage">
              Connecting Submessage
            </label>
            <input
              className="input"
              type="text"
              id="connecting-submessage"
              value={props.workingConfig.connectingSubmessage}
              onChange={(e) =>
                props.update({ connectingSubmessage: e.target.value })
              }
            />
          </div>
          <div className="mb-4">
            <div className="flex" title="We support LottieFiles animations">
              <label className="block mb-2 flex" htmlFor="connecting-gif">
                Connecting Animation
              </label>
              <IconHelp className="h-4 flex mt-1"> </IconHelp>
            </div>
            <input
              className="input"
              type="text"
              id="connecting-gif"
              value={props.workingConfig.connectingAnimation}
              onChange={(e) =>
                props.update({ connectingAnimation: e.target.value })
              }
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="connecting-message-log">
              Connecting Message Log Title
            </label>
            <input
              className="input"
              type="text"
              id="connecting-message-log"
              value={props.workingConfig.connectingMessageLog}
              onChange={(e) =>
                props.update({ connectingMessageLog: e.target.value })
              }
            />
          </div>
          <div>
            <button className="btn-green" disabled={props.loading}>
              {saveButtonText}
            </button>
          </div>
        </form>
      </Accordion.Body>
    </Accordion.Item>
  );
}
