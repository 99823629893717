import React from "react";
import { IconProps } from "./types";

function IconTotalChats(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      {...props}
    >
      <g transform="translate(2705 10810)">
        <path
          d="M7,0H25a7,7,0,0,1,7,7V25a7,7,0,0,1-7,7H7a7,7,0,0,1-7-7V7A7,7,0,0,1,7,0"
          transform="translate(-2705 -10810)"
          fill="#2fbf71"
        />
        <path
          d="M19.352,16a1.089,1.089,0,1,0,1.089-1.089A1.089,1.089,0,0,0,19.352,16"
          transform="translate(-2705 -10810)"
          fill="#fff"
        />
        <path
          d="M4.411,27.429a.773.773,0,0,1-.315-.067.852.852,0,0,1-.495-.784V9.435A4.966,4.966,0,0,1,8.438,4.36H22.181a4.966,4.966,0,0,1,4.837,5.075v8.96a4.966,4.966,0,0,1-4.837,5.075H8.625L4.977,27.188a.793.793,0,0,1-.566.241M8.438,6.061A3.3,3.3,0,0,0,5.222,9.435v15.13L7.73,22.009a.8.8,0,0,1,.566-.24H22.181A3.3,3.3,0,0,0,25.4,18.395V9.435a3.3,3.3,0,0,0-3.215-3.374Z"
          transform="translate(-2704.268 -10810)"
          fill="#fff"
        />
        <path
          d="M20.785,12.44H11.507a.859.859,0,1,1,0-1.717h9.278a.859.859,0,1,1,0,1.717"
          transform="translate(-2705 -10810)"
          fill="#fff"
        />
        <path
          d="M16.422,16.859H11.507a.858.858,0,1,1,0-1.717h4.915a.858.858,0,1,1,0,1.717"
          transform="translate(-2705 -10810)"
          fill="#fff"
        />
        <rect
          width="32"
          height="32"
          transform="translate(-2705 -10810)"
          fill="none"
        />
      </g>
    </svg>
  );
}

export default IconTotalChats;
