import React from "react";
import useAuth from "@/hooks/useAuth";
import { Link } from "react-router-dom";

export default function LoginUnauthorized() {
  const auth = useAuth();

  return (
    <div style={{ textAlign: "center", padding: 20 }}>
      <p>You are not authorized to view this application.</p>
      <div>
        <button
          onClick={() => {
            auth.retryLogin();
          }}
        >
          Retry
        </button>
        <span className="m-2">&bull;</span>
        <Link to="/logout">Logout</Link>
      </div>
    </div>
  );
}
