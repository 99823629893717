import React from "react";
import { IconProps } from "./types";

function IconCollapse(props: IconProps) {
  return (
    <svg
      id="collapse-sidebar"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_10462"
            data-name="Rectangle 10462"
            width="24"
            height="24"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="Group_9647" data-name="Group 9647" clipPath="url(#clip-path)">
        <path
          id="Path_2605"
          data-name="Path 2605"
          d="M18.276,11.25H12.607l2.186-2.187A.75.75,0,0,0,13.733,8L10.266,11.47a.751.751,0,0,0,0,1.06L13.733,16a.75.75,0,0,0,1.06-1.061L12.606,12.75h5.67a.75.75,0,0,0,0-1.5"
          fill="#fff"
        />
        <path
          id="Path_2606"
          data-name="Path 2606"
          d="M16.725,23.253H11.67a.879.879,0,0,1,0-1.758h5.055a3.52,3.52,0,0,0,3.516-3.517V6.022a3.488,3.488,0,0,0-1.406-2.813A.879.879,0,0,1,19.891,1.8,5.235,5.235,0,0,1,22,6.022V17.978a5.281,5.281,0,0,1-5.275,5.275"
          fill="#fff"
        />
        <path
          id="Path_2607"
          data-name="Path 2607"
          d="M8.154.748H7.275A5.281,5.281,0,0,0,2,6.022V17.978a5.28,5.28,0,0,0,5.275,5.274h.879a.878.878,0,0,0,.879-.879V1.627A.878.878,0,0,0,8.154.748M7.275,21.494a3.519,3.519,0,0,1-3.516-3.516V6.022A3.52,3.52,0,0,1,7.275,2.506Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default IconCollapse;
