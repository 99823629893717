import Modal from "@velaro/velaro-shared/src/components/Modal";
import useApi from "@/hooks/useApi";
import React, { useState } from "react";

import AutoResponseForm, {
  AutoResponseModel,
  defaultAutoResponseModel
} from "./AutoResponseForm";
import LoadingSpinner from "@/components/LoadingSpinner";
import {
  SecondaryBlueButton,
  PrimaryBlueButton
} from "@velaro/velaro-shared/src/components/Buttons/Buttons";

interface Props {
  show: boolean;
  onCreate(): void;
  onClose(): void;
}

export default function CreateModal(props: Props) {
  const api = useApi();
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);

  const [autoResponse, setAutoResponse] = React.useState<AutoResponseModel>(
    defaultAutoResponseModel
  );

  const handleUpdates = React.useCallback(
    (updates: Partial<AutoResponseModel>) => {
      setAutoResponse((oldState) => ({
        ...oldState,
        ...updates
      }));
    },
    []
  );

  async function handleSubmit() {
    setSending(true);
    const response = await api.messaging.post("AutoResponses", autoResponse);

    setSuccess(response.ok);
    if (response.ok) {
      props.onCreate();
    } else {
      alert(await response.json());
    }
    setSending(false);
  }

  return (
    <Modal show={props.show}>
      <Modal.Header title="New Auto Response" onClose={() => props.onClose()} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Modal.Body>
          <div className="p-4">
            <AutoResponseForm
              autoResponse={autoResponse}
              onChange={handleUpdates}
            />
          </div>
          <div className="p-4 flex flex-col mt-4">
            {success === false && (
              <div className="flex flex-row mb-4">
                There was an error saving your changes. Please see Integration
                Log for more details.
              </div>
            )}
            {sending ? (
              <div className="ml-auto flex">
                Saving Changes...
                <LoadingSpinner />
              </div>
            ) : (
              <div className="ml-auto flex flex-row">
                <div className="mr-2">
                  <SecondaryBlueButton onClick={props.onClose} label="Cancel" />
                </div>
                <PrimaryBlueButton
                  onClick={() => handleSubmit()}
                  label="Save Changes"
                />
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>{}</Modal.Footer>
      </form>
    </Modal>
  );
}
