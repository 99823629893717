﻿import * as React from "react";

interface Props {
  checked: boolean;
  children: React.ReactNode;
}

export default function RadioLabel(props: Props) {
  let className = "form-radio form-normal form-text";

  if (props.checked) {
    className += " active";
  }

  return <label className={className}>{props.children}</label>;
}
