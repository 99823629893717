import React from "react";

export default function IllustrationChatbotLeadGen() {
  return (
    <svg viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M130.97 48.1899L377.95 56.2199C419.14 57.5599 453.49 89.3299 456.7 127.24L472.72 316.56C475.93 354.47 447.41 391.56 407.03 399.81L164.91 449.24C106.35 461.2 52.42 431.34 48.22 381.77L27.27 134.29C23.08 84.7299 71.23 46.2299 130.96 48.1699L130.97 48.1899Z"
        fill="#EEF4FD"
      />
      <path
        d="M229.61 360.55L147.91 469.44L123.61 449.71L177.36 326.48L229.61 360.55Z"
        fill="black"
      />
      <path
        d="M377.65 267.1L443.69 373.77L418.35 397.96L321.92 303.38L377.65 267.1Z"
        fill="black"
      />
      <path
        d="M176.61 320.95L354.73 280.16L366.92 396.46L234.4 434.61L176.61 320.95Z"
        fill="black"
      />
      <path
        d="M353.148 214.727C353.503 174.036 308.569 140.655 252.785 140.168C197.001 139.682 151.491 172.273 151.136 212.964C150.781 253.655 195.715 287.036 251.499 287.523C307.283 288.01 352.793 255.418 353.148 214.727Z"
        fill="black"
      />
      <path
        d="M377.65 267.1L443.69 373.77L420.82 386.42L331.04 294.95L377.65 267.1Z"
        fill="#629FF0"
      />
      <path
        d="M420.09 391.4L324.62 294.13L378.98 261.65L449.31 375.24L420.1 391.4H420.09ZM337.45 295.78L421.54 381.46L438.08 372.31L376.32 272.56L337.45 295.79V295.78Z"
        fill="black"
      />
      <path
        d="M221.26 356.88L147.05 458.03L126.75 441.55L178 324.07L221.26 356.88Z"
        fill="#629FF0"
      />
      <path
        d="M147.79 463.78L121.87 442.73L176.36 317.8L226.82 356.06L147.79 463.77V463.78ZM131.63 440.36L146.3 452.28L215.7 357.69L179.62 330.33L131.62 440.36H131.63Z"
        fill="black"
      />
      <path
        d="M358.425 208.554C358.78 167.863 313.846 134.482 258.061 133.995C202.277 133.508 156.767 166.1 156.412 206.791C156.057 247.481 200.991 280.862 256.775 281.349C312.56 281.836 358.069 249.244 358.425 208.554Z"
        fill="#AFCFF8"
      />
      <path
        d="M257.99 285.35C257.57 285.35 257.15 285.35 256.73 285.35C228.91 285.11 202.78 276.94 183.15 262.36C163.14 247.5 152.22 227.75 152.41 206.76C152.78 163.93 200.24 129.5 258.09 130C285.91 130.24 312.04 138.41 331.67 152.99C351.68 167.85 362.6 187.6 362.41 208.59C362.04 251.11 315.31 285.36 257.99 285.36V285.35ZM256.82 137.98C203.88 137.98 160.74 168.68 160.4 206.82C160.24 225.19 170.01 242.63 187.91 255.93C206.19 269.51 230.65 277.12 256.79 277.34C257.19 277.34 257.59 277.34 257.99 277.34C310.93 277.34 354.07 246.64 354.41 208.5C354.57 190.13 344.8 172.69 326.9 159.39C308.62 145.81 284.16 138.2 258.02 137.98C257.62 137.98 257.22 137.98 256.82 137.98Z"
        fill="black"
      />
      <path
        d="M309.13 236.91L202.85 235.98L198.64 175.92L317.21 176.96L309.13 236.91Z"
        fill="white"
      />
      <path
        d="M312.62 240.94L199.12 239.95L194.36 171.89L321.79 173L312.63 240.94H312.62ZM206.58 232.01L305.64 232.87L312.65 180.91L202.94 179.95L206.58 232V232.01Z"
        fill="black"
      />
      <path
        d="M357.816 110.347L354.392 208.397L362.387 208.676L365.811 110.626L357.816 110.347Z"
        fill="black"
      />
      <path
        d="M362.17 111.06C372.68 111.06 381.2 102.54 381.2 92.03C381.2 81.52 372.68 73 362.17 73C351.66 73 343.14 81.52 343.14 92.03C343.14 102.54 351.66 111.06 362.17 111.06Z"
        fill="white"
      />
      <path
        d="M362.21 115.07C360.45 115.07 358.67 114.87 356.91 114.45C350.92 113.05 345.84 109.39 342.59 104.16C339.35 98.93 338.34 92.75 339.74 86.77C342.64 74.41 355.06 66.71 367.42 69.61C373.41 71.01 378.49 74.67 381.74 79.9C384.98 85.13 385.99 91.31 384.59 97.29C383.19 103.28 379.53 108.36 374.3 111.6C370.61 113.89 366.44 115.07 362.21 115.07ZM362.17 76.99C355.35 76.99 349.16 81.67 347.54 88.59C346.62 92.5 347.28 96.53 349.4 99.94C351.52 103.35 354.83 105.74 358.74 106.65C362.65 107.57 366.68 106.91 370.09 104.79C373.5 102.67 375.89 99.36 376.8 95.45C377.72 91.54 377.06 87.51 374.94 84.1C372.82 80.69 369.51 78.3 365.6 77.39C364.45 77.12 363.3 76.99 362.17 76.99Z"
        fill="black"
      />
      <path
        d="M244.6 205.024C244.656 198.607 240.775 193.37 235.932 193.328C231.088 193.286 227.117 198.454 227.061 204.871C227.005 211.288 230.885 216.525 235.729 216.567C240.572 216.609 244.544 211.441 244.6 205.024Z"
        fill="black"
      />
      <path
        d="M284.841 205.379C284.897 198.961 281.016 193.725 276.173 193.683C271.329 193.64 267.358 198.808 267.302 205.226C267.246 211.643 271.127 216.879 275.97 216.922C280.813 216.964 284.785 211.796 284.841 205.379Z"
        fill="black"
      />
      <path
        d="M184.78 318.45L354.73 280.16L366.92 396.46L234.68 422.25L184.78 318.45Z"
        fill="#AFCFF8"
      />
      <path
        d="M232.41 426.77L179 315.65L358.23 275.27L371.28 399.68L232.41 426.76V426.77ZM190.57 321.25L236.95 417.74L362.56 393.24L351.22 285.06L190.57 321.25Z"
        fill="black"
      />
      <path
        d="M320.16 337.72L251.36 357.24L260.29 417.26L346.53 400.44L320.16 337.72Z"
        fill="white"
      />
      <path
        d="M256.95 421.98L246.89 354.35L322.48 332.91L352.13 403.43L256.96 421.99L256.95 421.98ZM255.83 360.13L263.63 412.53L340.93 397.45L317.84 342.53L255.83 360.12V360.13Z"
        fill="black"
      />
      <path
        d="M334.261 369.2L255.283 384.938L256.847 392.784L335.824 377.046L334.261 369.2Z"
        fill="black"
      />
      <path
        d="M164.55 35.6801L56.31 64.2401L70.72 128.22L136.56 120.11L171.35 142.55L171.06 104.54L184.63 101.93L164.55 35.6801Z"
        fill="black"
      />
      <path
        d="M175.41 149.93L135.61 124.26L67.61 132.64L51.55 61.37L167.25 30.84L189.73 105.02L175.07 107.85L175.4 149.94L175.41 149.93ZM137.51 115.96L167.29 135.17L167.02 101.24L179.5 98.83L161.83 40.52L61.05 67.11L73.82 123.79L137.5 115.94L137.51 115.96Z"
        fill="black"
      />
      <path
        d="M168.55 30.5601L60.31 59.1301L74.72 123.1L139.39 110.64L169.83 134.74L171.06 104.54L184.63 101.93L168.55 30.5601Z"
        fill="white"
      />
      <path
        d="M173.51 142.75L138.35 114.92L71.68 127.77L55.57 56.25L171.55 25.65L189.45 105.09L174.94 107.89L173.53 142.77L173.51 142.75ZM140.44 106.37L166.16 126.73L167.19 101.22L179.82 98.79L165.56 35.5L65.06 62.02L77.77 118.45L140.43 106.38L140.44 106.37Z"
        fill="black"
      />
      <path
        d="M95.4711 92.9254C98.9354 92.1447 100.862 87.5984 99.774 82.771C98.6861 77.9436 94.9958 74.6631 91.5315 75.4438C88.0672 76.2245 86.1407 80.7708 87.2286 85.5982C88.3165 90.4256 92.0068 93.7061 95.4711 92.9254Z"
        fill="black"
      />
      <path
        d="M122.484 86.8462C125.948 86.0654 127.874 81.5192 126.787 76.6918C125.699 71.8644 122.008 68.5839 118.544 69.3646C115.08 70.1453 113.153 74.6916 114.241 79.519C115.329 84.3464 119.019 87.6269 122.484 86.8462Z"
        fill="black"
      />
      <path
        d="M149.97 80.654C153.435 79.8733 155.361 75.327 154.273 70.4996C153.185 65.6722 149.495 62.3917 146.031 63.1725C142.566 63.9532 140.64 68.4994 141.728 73.3268C142.816 78.1543 146.506 81.4347 149.97 80.654Z"
        fill="black"
      />
    </svg>
  );
}
