import useAIConfigs from "@/hooks/useAIConfigs";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AIConfiguration } from "../types";
import useApi from "@/hooks/useApi";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";

export default function SaveChatbotButton() {
  const { id } = useParams();
  const { aiConfigurations, loading, update } = useAIConfigs();
  const [config, setConfig] = useState<AIConfiguration>();
  const api = useApi();
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const toastAlert = useToastAlert();

  const save = useCallback(async () => {
    setSending(true);
    console.log("config", config);
    const resultJson = await api.messaging.post("AIConfiguration", config);
    const result = await resultJson.json();
    setSuccess(result.ok);
    setSending(false);
    if (resultJson.ok) {
      toastAlert.displayToast(Severity.Success, "Chatbot saved successfully");
    } else {
      toastAlert.displayToast(Severity.Error, "Failed to save chatbot");
    }
  }, [api.messaging, config, toastAlert]);

  useEffect(() => {
    if (aiConfigurations.length > 0 && id) {
      const configIndex = aiConfigurations.findIndex(
        (config) => config.id === parseInt(id)
      );
      if (configIndex != null && configIndex != undefined) {
        setConfig(aiConfigurations[configIndex]);
      }
    }
  }, [aiConfigurations, id]);

  return <PrimaryBlueButton label="Save" onClick={() => save()} />;
}
