import * as React from "react";
import ShiftForm from "./ShiftForm";
import { useState } from "react";
import daysOfWeek from "./helpers/daysOfWeek";
import { Shift, WorkSchedule } from "./types";
import Modal from "@velaro/velaro-shared/src/components/Modal";
import {
  SecondaryBlueButton,
  PrimaryBlueButton
} from "@velaro/velaro-shared/src/components/Buttons/Buttons";

interface Props {
  schedule: WorkSchedule;
  onConfirm: (schedule: WorkSchedule) => void;
  onDeleteSchedule: (schedule: WorkSchedule) => void;
  onReturn: () => void;
  isNewSchedule: boolean;
}

export default function ScheduleEditor(props: Props) {
  const [name, setName] = useState<string>(props.schedule.name);
  const [shifts, setShifts] = useState<Shift[]>([...props.schedule.shifts]);
  const [teams, setTeams] = useState<number[]>([]);

  function onAddTeam(teamId: number) {
    setTeams([...teams, teamId].sort());
  }

  function onRemoveTeam(teamId: number) {
    setTeams(teams.filter((x) => x !== teamId));
  }

  function onChangeName(event: any) {
    setName(event.currentTarget.value);
  }

  function onAddShift() {
    if (shifts.length) {
      const newShift: any = { ...shifts[shifts.length - 1] };

      if (newShift.startDayOfWeek !== daysOfWeek.Saturday) {
        newShift.startDayOfWeek++;
      }

      if (newShift.endDayOfWeek !== daysOfWeek.Saturday) {
        newShift.endDayOfWeek++;
      }

      setShifts([...shifts, newShift]);
      return;
    }

    const newShift = {
      startDayOfWeek: daysOfWeek.Monday,
      startHour: 9,
      startMinute: 0,
      endDayOfWeek: daysOfWeek.Monday,
      endHour: 17,
      endMinute: 0
    };
    setShifts([...shifts, newShift]);
  }

  function onChangeShift(shift: Shift, i: number) {
    shifts[i] = shift;
    setShifts([...shifts]);
  }

  function onDeleteShift(i: number) {
    shifts.splice(i, 1);
    setShifts([...shifts]);
  }

  function onDelete() {
    props.onDeleteSchedule(props.schedule);
  }

  function onDiscard() {
    setName(props.schedule.name);
    setShifts([...props.schedule.shifts]);
    // setTeams([]); todo:
    props.onReturn();
  }

  function onConfirm() {
    if (!name) {
      return;
    }

    const changedSchedule = {
      ...props.schedule,
      name,
      shifts,
      teams
    };

    props.onConfirm(changedSchedule);
  }

  function renderShifts() {
    if (!shifts.length) {
      return <span className="shift-form form-team">Please add a shift</span>;
    }

    return (
      <React.Fragment>
        {shifts.map((shift, i) => (
          <ShiftForm
            key={i}
            shift={shift}
            onChange={(shift) => onChangeShift(shift, i)}
            onDelete={() => onDeleteShift(i)}
          />
        ))}
      </React.Fragment>
    );
  }

  function renderEditorActions() {
    return (
      <div className="flex">
        <div>
          <button type="button" className="btn-red" onClick={onDelete}>
            <i className="fa fa-trash mr-2" />
            Delete
          </button>
        </div>
        <div className="ml-auto">
          <div className="ml-auto flex flex-row">
            {!props.isNewSchedule && (
              <div className="mr-2">
                <SecondaryBlueButton onClick={onDiscard} label="Cancel" />
              </div>
            )}
            <PrimaryBlueButton onClick={onConfirm} label="Save Changes" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <form className="schedule-form">
      <div className="flex">
        <div className="editor-section flex-none border-r">
          <div>
            <div className="panel-heading bg-gray-100 border-b p-3">
              <h3 className="panel-title">Name schedule</h3>
            </div>
            <div className="p-3">
              <input
                required
                type="text"
                className="input"
                value={name}
                onChange={onChangeName}
              />
            </div>
          </div>
        </div>
        <div className="editor-section flex-1 shift-section">
          <div className="panel-heading flex bg-gray-100 border-b p-3">
            <h3 className="panel-title">Modify schedule shift</h3>
            <button
              type="button"
              className="ml-auto underline"
              onClick={onAddShift}
            >
              Add shift
            </button>
          </div>
          <div className="panel-body">
            <div className="p-3">{renderShifts()}</div>
          </div>
        </div>
        {/* <div className="editor-section flex-none team-section">
          <div>
            <div className="panel-heading bg-gray-100 border-b p-3">
              Select users
            </div>
            <div className="panel-body">
              <TeamForm
                teams={teams}
                onAddTeam={onAddTeam}
                onRemoveTeam={onRemoveTeam}
              />
            </div>
          </div>
        </div> */}
      </div>
      <Modal.Footer>
        <div className="p-3">{renderEditorActions()}</div>
      </Modal.Footer>
    </form>
  );
}
