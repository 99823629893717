import { RoleListItem } from "@/providers/RoleListItemsProvider";
import React from "react";
export const RoleListItemsContext = React.createContext<{
  customRoles: RoleListItem[];
  defaultRoles: RoleListItem[];
  roles: RoleListItem[];
  getRoleNameById: (RoleId: number) => string;
  loading: boolean;
}>({
  customRoles: [],
  defaultRoles: [],
  roles: [],
  getRoleNameById: () => "",
  loading: true
});
