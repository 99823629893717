import useApi from "@/hooks/useApi";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function NetSuiteCallback() {
  const api = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    async function executeCallback() {
      const url = "NetSuite/Auth/Callback" + window.location.search;
      const resp = await api.messaging.post(url, {});

      if (resp.ok) {
        navigate("/messaging/integrations");
      } else {
        alert("an unexpected error occurred.");
      }
    }

    executeCallback();
  }, [api.messaging, navigate]);

  return null;
}
