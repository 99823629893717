import React, { useRef } from "react";

interface Props {
  onChange: (file: File) => void;
}

export default function UploadButton(props: Props) {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <button
        className="btn btn-primary"
        onClick={() => {
          if (inputRef.current) {
            inputRef.current.click();
          }
        }}
      >
        Upload File
      </button>
      <input
        type="file"
        accept="image/png, image/gif, image/jpeg, image/svg+xml"
        ref={inputRef}
        style={{ display: "none" }}
        value=""
        onChange={(e) => {
          if (!e.currentTarget.files) return;
          const file = e.currentTarget.files[0];
          props.onChange(file);
          e.currentTarget.value = "";
        }}
      />
    </>
  );
}
