import React, { useState, MouseEvent } from "react";
import { NavLink } from "react-router-dom";

interface MainLinkProps {
  to?: string;
  label: string;
  icon: React.ReactNode;
  onVisit: () => void;
  showTooltip: boolean;
  toggleSidebar: boolean;
}

const styles = {
  mainLink: "h-10 rounded-lg w-full text-left flex items-center hover:bg-cornflower-blue-400 dark:hover:bg-stone-200",
  mainLinkActive: "h-10 rounded-lg border-lg w-full text-left flex items-center bg-cornflower-blue-700",
  navLink: "h-10 rounded-lg w-full text-left flex items-center hover:bg-cornflower-blue-400 dark:hover:bg-stone-200 border-transparent",
  tooltip: "absolute left-full ml-2 p-4 rounded bg-cornflower-blue-500 text-white whitespace-nowrap z-1000 -translate-y-full",
};

export default function MainLink({
  to,
  label,
  icon,
  onVisit,
  showTooltip,
  toggleSidebar
}: MainLinkProps) {
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (!toggleSidebar) {
      e.preventDefault();
    } else {
      onVisit();
    }
  };

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
       <NavLink
          to={to || ""}
          className={({ isActive }) => (isActive ? styles.mainLinkActive : styles.navLink)}
          onClick={handleClick}
        >
          <div className="flex items-center ml-2">
            {icon}
            {showTooltip && <span className="pl-4 text-sm">{label}</span>}
          </div>
       </NavLink>

      {!showTooltip && isHovered && (
        <div>
          <div className="absolute left-12 h-14 w-4 bg-transparent -translate-y-full"/>
          <div 
            className={styles.tooltip}
            >
            <NavLink to={to || ""}>
                {label}
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
}
