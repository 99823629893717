import * as React from "react";

const ENTER_KEY = 13;
const COMMA_KEY = 188;

interface Props {
  value: string;
  onChange(value: string): void;
}

export default function TagEditor(props: Props) {
  const [tags, setTags] = React.useState<string[]>([]);
  const [inputValue, setInputValue] = React.useState("");

  React.useEffect(() => {
    console.log(props.value);
    if (!props.value) {
      return;
    }
    setTags(
      props.value
        .split(",")
        .filter((t) => t !== "")
        .map((t) => t.trim())
    );
  }, [props.value]);

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (inputValue && (e.keyCode === ENTER_KEY || e.keyCode === COMMA_KEY)) {
      e.preventDefault();

      let newValue = "";

      if (props.value === "") {
        newValue = inputValue;
      } else {
        newValue = `${props.value}, ${inputValue}`;
      }

      props.onChange(newValue);
      setInputValue("");
    }
  }

  function removeTag(index: number) {
    const tagsCopy = [...tags];
    tagsCopy.splice(index, 1);
    props.onChange(tagsCopy.join(", "));
  }

  return (
    <div className="kb-tag-editor">
      <input
        type="text"
        className="form-control"
        value={inputValue}
        onChange={(e) => setInputValue(e.currentTarget.value)}
        onKeyDown={handleKeyDown}
      />
      <div>
        {tags.map((tag, index) => (
          <span key={index} className="label label-primary">
            {tag}
            <button onClick={() => removeTag(index)}>
              <i className="fa fa-times" />
            </button>
          </span>
        ))}
      </div>
    </div>
  );
}
