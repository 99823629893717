import React from "react";
import { SkillListItem } from "@/providers/SkillsProvider";
export const SkillListItemsContext = React.createContext<{
  skills: SkillListItem[];
  getSkillNameById: (SkillId: number) => string;
  setSkills: (skills: SkillListItem[]) => void;
  getSkillById: (SkillId: number) => SkillListItem | null;
}>({
  skills: [],
  getSkillNameById: () => "",
  setSkills: () => {},
  getSkillById: () => null
});
