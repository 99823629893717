import React from "react";
import IllustrationNewAiChatBot from "@/icons/illustrations/IllustrationNewAiChatbot";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import CreateChatbotButton from "./CreateChatbotButton";

export default function NewAILanding() {
  //Todo translate
  return (
    <div>
      <div className="bg-white mx-6 border-[1px] rounded-2xl flex flex-col items-center">
        <div className="flex flex-row p-4 mt-16">
          <IllustrationNewAiChatBot />
        </div>
        <div className="flex flex-col py-4 items-center">
          <div className="text-lg text-slate-800 font-semibold pb-2">
            New AI Chatbot
          </div>
          <div className="flex flex-row text-slate-500 text-slate">
            Looks like you haven&apos;t set up any AI Chatbots yet.
          </div>
          <div className="flex flex-row text-slate-500 text-slate">
            Start customizing your customer interactions with your first
            AI-driven assistant
          </div>
        </div>
        <div className="mb-16">
          {/* <PrimaryBlueButton
            onClick={() => console.log("create test")}
            label="Create"
          /> */}
          {/* <CreateChatbotButton /> This line is commented out because it is experiencing issues with state updates.  Will fix later */}
        </div>
      </div>
    </div>
  );
}
