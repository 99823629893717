import React from "react";
import { crmDefs, crmRecordDefs } from "../types";

export interface CreateCrmRecordValue {
  crmType: string;
  recordType: string;
}

interface Props {
  updateValue: (value: CreateCrmRecordValue) => void;
  value: CreateCrmRecordValue;
}

export default function CreateCrmRecordRuleForm({ value, updateValue }: Props) {
  return (
    <>
      <div>
        Select a CRM type
        <select
          className="flex flex-row input overflow-ellipsis whitespace-nowrap w-64 ml-2"
          value={value?.crmType || ""}
          onChange={(e) =>
            updateValue({
              ...value,
              crmType: e.currentTarget.value
            })
          }
        >
          {Object.keys(crmDefs).map((c, i) => (
            <option key={i} value={c}>
              {c}
            </option>
          ))}
        </select>
      </div>
      <div>
        Select a Record type
        <select
          className="flex flex-row input overflow-ellipsis whitespace-nowrap w-64 ml-2"
          value={value?.recordType || ""}
          onChange={(e) =>
            updateValue({
              ...value,
              recordType: e.currentTarget.value
            })
          }
        >
          {Object.keys(crmRecordDefs).map((c, i) => (
            <option key={i} value={c}>
              {c}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}
