import React from "react";
import { IconProps } from "./types";

function IconMessageChatSquare({
  className = "stroke-slate-500",
  ...props
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      className={className}
      {...props}
    >
      <path
        d="M13.8334 19.9998L9.73307 24.1514C9.16113 24.7305 8.87516 25.0201 8.62935 25.0405C8.4161 25.0582 8.2073 24.9725 8.06803 24.81C7.90749 24.6228 7.90749 24.2158 7.90749 23.4019V21.322C7.90749 20.5918 7.30951 20.0634 6.58702 19.9576V19.9576C4.83841 19.7015 3.46512 18.3282 3.20898 16.5796C3.16675 16.2912 3.16675 15.9472 3.16675 15.2591V9.0665C3.16675 6.82629 3.16675 5.70619 3.60272 4.85054C3.98622 4.09789 4.59814 3.48597 5.35079 3.10248C6.20643 2.6665 7.32654 2.6665 9.56675 2.6665H19.4334C21.6736 2.6665 22.7937 2.6665 23.6494 3.10248C24.402 3.48597 25.0139 4.09789 25.3974 4.85054C25.8334 5.70619 25.8334 6.82629 25.8334 9.0665V14.6665M25.8334 29.3332L22.9315 27.3157C22.5236 27.0321 22.3197 26.8903 22.0977 26.7897C21.9007 26.7005 21.6935 26.6356 21.4808 26.5964C21.2412 26.5522 20.9928 26.5522 20.496 26.5522H18.1001C16.6066 26.5522 15.8599 26.5522 15.2894 26.2616C14.7877 26.0059 14.3797 25.598 14.1241 25.0962C13.8334 24.5258 13.8334 23.779 13.8334 22.2856V18.9332C13.8334 17.4397 13.8334 16.693 14.1241 16.1225C14.3797 15.6208 14.7877 15.2128 15.2894 14.9572C15.8599 14.6665 16.6066 14.6665 18.1001 14.6665H25.5667C27.0602 14.6665 27.807 14.6665 28.3774 14.9572C28.8792 15.2128 29.2871 15.6208 29.5428 16.1225C29.8334 16.693 29.8334 17.4397 29.8334 18.9332V22.5522C29.8334 23.7947 29.8334 24.416 29.6304 24.906C29.3598 25.5595 28.8406 26.0786 28.1872 26.3492C27.6972 26.5522 27.0759 26.5522 25.8334 26.5522V29.3332Z"
        stroke="inherit"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconMessageChatSquare;
