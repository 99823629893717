import React from "react";
import { IconProps } from "./types";

function IconSendArrow(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24.001"
      viewBox="0 0 24 24.001"
      {...props}
    >
      <path
        fill="currentColor"
        d="M12 24A12 12 0 013.515 3.515a12 12 0 0116.97 16.971A11.922 11.922 0 0112 24zM5.472 5.185l-.007 5.445 11.666 1.555-11.666 1.556.007 5.444 16.326-7-16.326-7z"
        data-name="Subtraction 7"
      ></path>
    </svg>
  );
}

export default IconSendArrow;
