import { getWeekNameByIndex } from "./daysOfWeek";
import { Shift } from "../types";

function getTimeString(hour: number, min: number) {
  let period = "am";
  if (hour >= 12) {
    period = "pm";
  }

  if (hour >= 12) {
    hour -= 12;
  }

  if (hour === 0) {
    hour = 12;
  }

  let minText = min.toString();
  if (min < 10) {
    minText = "0" + minText;
  }

  return `${hour}:${minText} ${period}`;
}

export function getReadableDateForShift(shift: Shift) {
  const startDay = getWeekNameByIndex(shift.startDayOfWeek);
  const startTime = getTimeString(shift.startHour, shift.startMinute);

  const endDay = getWeekNameByIndex(shift.endDayOfWeek);
  const endTime = getTimeString(shift.endHour, shift.endMinute);

  return `${startDay} ${startTime} to ${endDay} ${endTime}`;
}
