import * as React from "react";
import { Topic } from "../types";
import useModelState from "../hooks/useModelState";
import useApi from "@/hooks/useApi";
import Modal from "@velaro/velaro-shared/src/components/Modal";
import FormGroup from "@velaro/velaro-shared/src/components/FormGroup";

interface Props {
  topic: Topic;
  onClose(): void;
  onSubmit(topic: Topic): void;
}

export default function TopicEditModal(props: Props) {
  const [saving, setSaving] = React.useState(false);
  const [name, setName] = React.useState("");
  const [slug, setSlug] = React.useState("");
  const [modelState, setModelState] = useModelState();
  const api = useApi();

  React.useEffect(() => {
    setName(props.topic.Name);
    setSlug(props.topic.Slug);
  }, [props.topic]);

  async function handleSubmit() {
    setSaving(true);
    const response = await api.main.put(
      `KBTopics?id=${encodeURIComponent(props.topic.Id)}`,
      {
        name,
        slug,
        displayPriority: props.topic.DisplayPriority,
        categoryId: props.topic.CategoryId
      }
    );
    const json = await response.json();
    props.onSubmit(json);
    if (response.status === 400) {
      console.error(response);
    }

    setSaving(false);
  }

  return (
    <Modal show={true}>
      <Modal.Header title="Edit Topic" onClose={() => handleSubmit()} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Modal.Body>
          <FormGroup label="Name">
            {/* <label htmlFor="topic-name">{App.translate("app/kb/name")}</label> */}
            <input
              type="text"
              id="topic-name"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
            />
          </FormGroup>
          <FormGroup label="Slug">
            {/* <label htmlFor="topic-slug">{App.translate("app/kb/slug")}</label> */}
            <input
              type="text"
              id="topic-slug"
              className="form-control"
              value={slug}
              onChange={(e) => setSlug(e.currentTarget.value)}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-default"
            disabled={saving}
            onClick={() => props.onClose()}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-success" disabled={saving}>
            Save Topic
            {/* {App.translate("app/kb/savetopic")} */}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
