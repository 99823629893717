import React from "react";
import { IconProps } from "./types";

function IconFileCheck({
  className = "stroke-slate-500",
  ...props
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      className={className}
      {...props}
    >
      <path
        d="M27.4166 16.6665V9.0665C27.4166 6.82629 27.4166 5.70619 26.9806 4.85054C26.5971 4.09789 25.9852 3.48597 25.2325 3.10248C24.3769 2.6665 23.2568 2.6665 21.0166 2.6665H12.4833C10.243 2.6665 9.12294 2.6665 8.26729 3.10248C7.51464 3.48597 6.90272 4.09789 6.51923 4.85054C6.08325 5.70619 6.08325 6.82629 6.08325 9.0665V22.9332C6.08325 25.1734 6.08325 26.2935 6.51923 27.1491C6.90272 27.9018 7.51464 28.5137 8.26729 28.8972C9.12294 29.3332 10.243 29.3332 12.4833 29.3332H16.7499M19.4166 14.6665H11.4166M14.0833 19.9998H11.4166M22.0833 9.33317H11.4166M20.0833 25.3332L22.7499 27.9998L28.7499 21.9998"
        stroke="inherit"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconFileCheck;
