import useApi from "@/hooks/useApi";
import * as React from "react";

export function useTags() {
  const [tags, setTags] = React.useState<string[]>([]);
  const api = useApi();

  React.useEffect(() => {
    async function fetchTags() {
      const response = await api.main.get("KBTags");
      const json = await response.json();
      setTags(json);
    }

    fetchTags();
  }, []);

  return { tags };
}
