import useLanguageConfigs from "@/pages/Messaging/WebMessenger/hooks/useLanguageConfigs";
import React from "react";
import { Link } from "react-router-dom";

export interface LanguageConfigValue {
  languageConfig: string;
}

interface Props {
  updateValue: (value: LanguageConfigValue) => void;
  value: LanguageConfigValue;
}

export default function LanguageConfigRuleForm({ value, updateValue }: Props) {
  const { languageConfigs } = useLanguageConfigs();

  if (!languageConfigs || !languageConfigs.length) {
    return (
      <>
        <span>{"You don't have any language configs yet."}</span>
        <Link to="/web_messenger">
          <button type="button" className="btn-link">
            Create one now.
          </button>
        </Link>
      </>
    );
  }

  return (
    <>
      <div>Set the conditions for when a language config should be used.</div>
      <div className="flex flex-col mr-auto">
        <div>
          Select your language config:
          <select
            className="flex flex-row input overflow-ellipsis whitespace-nowrap w-64 ml-2"
            value={value["languageConfig"]}
            onChange={(e) =>
              updateValue({ languageConfig: e.currentTarget.value })
            }
          >
            <option value={""}>{"-- select a language config --"}</option>
            {languageConfigs.map((k, i) => (
              <option key={i} value={k.name}>
                {k.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
}
