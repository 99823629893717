import * as React from "react";
import ArticleForm from "../shared/ArticleForm";
import useModelState from "../hooks/useModelState";
import useArticleFormModel from "../hooks/useArticleFormModel";
// import useConfirmNavigation from "../../../hooks/useConfirmNavigation";
import PreviewButton from "./PreviewButton";
import useApi from "@/hooks/useApi";

export default function Create() {
  const [saving, setSaving] = React.useState(false);
  const [article, setArticle] = useArticleFormModel();
  const [modelState, setModelState] = useModelState();
  // const { setIsDirty } = useConfirmNavigation();
  const api = useApi();

  async function save(publish: boolean) {
    setSaving(true);
    const resp = await api.main.post("KBArticles", {
      ...article,
      isPublished: publish
    });

    setSaving(false);
    if (resp.status === 400) {
      //setModelState(err.responseJSON.ModelState);
      console.error(resp);
      return;
    } else {
      //alert(App.translate("app/kb/error"));
      console.log(resp);
    }

    window.location.href = "/kb/articles";
  }

  return (
    <div className="kb-container">
      <div className="kb-header">
        {/* <h1>{App.translate("app/kb/newarticle")}</h1> */}
        <h1>New Article</h1>
        <div className="kb-actions">
          <PreviewButton title={article.title} content={article.content} />
        </div>
      </div>
      <ArticleForm
        article={article}
        onChange={(props) => {
          setArticle((oldState) => ({ ...oldState, ...props }));
          // setIsDirty(true);
        }}
        modelState={modelState}
      />
      <button
        type="button"
        className="btn btn-primary"
        disabled={saving}
        onClick={() => save(false)}
      >
        {/* {App.translate("app/kb/savedraft")} */}
        Save Draft
      </button>{" "}
      <button
        type="button"
        className="btn btn-success"
        disabled={saving}
        onClick={() => save(true)}
      >
        {/* {App.translate("app/kb/saveandpublish")} */}
        Save and Publish
      </button>{" "}
      <a href="/kb/articles" className="btn btn-link">
        {/* {App.translate("app/kb/backtoarticles")} */}
        Back to Articles
      </a>
    </div>
  );
}
