import React from "react";
import { IconProps } from "./types";

function IconFlag({ className, ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M3.33337 13C3.33337 13 4.16671 12.1667 6.66671 12.1667C9.16671 12.1667 10.8334 13.8334 13.3334 13.8334C15.8334 13.8334 16.6667 13 16.6667 13V3.00002C16.6667 3.00002 15.8334 3.83335 13.3334 3.83335C10.8334 3.83335 9.16671 2.16669 6.66671 2.16669C4.16671 2.16669 3.33337 3.00002 3.33337 3.00002L3.33337 18.8334"
        stroke="#94A3B8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconFlag;
