import { AIConfiguration } from "@/pages/Messaging/Bots/types";
import React from "react";
export const AIConfigsContext = React.createContext<{
  aiConfigurations: AIConfiguration[];
  loading: boolean;
  add(): void;
  update(config: AIConfiguration, index: number): void;
  remove(config: AIConfiguration, index: number): void;
}>({
  aiConfigurations: [],
  loading: false,
  add() {},
  update() {},
  remove() {}
});
