import useApi from "@/hooks/useApi";
import React from "react";
import { AutoResponse } from "../types";
import CreateModal from "./CreateModal";
import EditModal from "./EditModal";

export default function AutoResponseSettings() {
  const [showCreateModal, setShowCreateModal] = React.useState(false);
  const [editId, setEditId] = React.useState<number | undefined>();
  const [autoResponses, setAutoResponses] = React.useState<AutoResponse[]>([]);
  const api = useApi();

  const fetchAutoResponses = React.useCallback(async () => {
    const response = await api.messaging.get("AutoResponses/List");
    setAutoResponses(await response.json());
  }, [api.messaging]);

  React.useEffect(() => {
    fetchAutoResponses();
  }, [fetchAutoResponses]);

  async function deleteObject(id: number) {
    if (confirm("Are you sure you want to delete this?")) {
      await api.messaging.delete(`AutoResponses/${id}`);
      await fetchAutoResponses();
    }
  }

  return (
    <>
      <div className="ml-auto">
        <button
          className="btn-green m-2"
          onClick={() => setShowCreateModal(true)}
        >
          New Auto Response
        </button>
      </div>
      <div className="m-4">
        <table className="w-full">
          <thead>
            <tr>
              <th className={thClassName}>Title</th>
              <th className={thClassName}>Days of Week</th>
              <th className={thClassName}>All Day</th>
              <th className={thClassName} style={{ width: 1 }}></th>
            </tr>
          </thead>
          <tbody>
            {autoResponses.map((autoResponse) => (
              <tr key={autoResponse.id}>
                <td className={tdClassName}>{autoResponse.title}</td>
                <td className={tdClassName}>{autoResponse.daysOfWeek}</td>
                <td className={tdClassName}>
                  {autoResponse.isAllDay.toString()}
                </td>
                <td className={`${tdClassName} text-sm whitespace-nowrap`}>
                  <button
                    className="underline"
                    onClick={() => setEditId(autoResponse.id)}
                  >
                    Edit
                  </button>
                  <span className="mx-2">&bull;</span>
                  <button
                    className="underline"
                    onClick={() => deleteObject(autoResponse.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showCreateModal && (
        <CreateModal
          show={showCreateModal}
          onClose={() => setShowCreateModal(false)}
          onCreate={() => {
            fetchAutoResponses();
            setShowCreateModal(false);
          }}
        />
      )}
      {editId && (
        <EditModal
          autoResponseId={editId}
          onClose={() => setEditId(undefined)}
          onUpdate={() => {
            setEditId(undefined);
            fetchAutoResponses();
          }}
        />
      )}
    </>
  );

  // return (
  //   <>
  //     <div>
  //       <div>
  //         <button
  //           className="btn-green"
  //           onClick={() => setShowCreateModal(true)}
  //         >
  //           New Auto Response
  //         </button>
  //       </div>
  //       <table>
  //         <thead>
  //           <tr>
  //             <th>Title</th>
  //             <th>Days of Week</th>
  //             <th>All Day</th>
  //             <th></th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {autoResponses.map((autoResponse) => (
  //             <tr key={autoResponse.id}>
  //               <td>{autoResponse.title}</td>
  //               <td>{autoResponse.daysOfWeek}</td>
  //               <td>{autoResponse.isAllDay.toString()}</td>
  //               <th>
  //                 <button onClick={() => setEditId(autoResponse.id)}>
  //                   Edit
  //                 </button>
  //                 <button onClick={() => deleteObject(autoResponse.id)}>
  //                   Delete
  //                 </button>
  //               </th>
  //             </tr>
  //           ))}
  //         </tbody>
  //       </table>
  //     </div>
  //     {showCreateModal && (
  //       <CreateModal
  //         show={showCreateModal}
  //         onClose={() => setShowCreateModal(false)}
  //         onCreate={() => {
  //           fetchAutoResponses();
  //           setShowCreateModal(false);
  //         }}
  //       />
  //     )}
  //     {editId && (
  //       <EditModal
  //         autoResponseId={editId}
  //         onClose={() => setEditId("")}
  //         onUpdate={() => {
  //           setEditId("");
  //           fetchAutoResponses();
  //         }}
  //       />
  //     )}
  //   </>
  // );
}

const thClassName = "border p-2 text-left";
const tdClassName = "border p-2";
