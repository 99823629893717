import React from "react";
import { IconProps } from "./types";

function IconKnowledgeBase(props: IconProps) {
  return (
    <svg
      id="knowledge-base"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_10215"
            data-name="Rectangle 10215"
            width="24"
            height="24"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g
        id="Group_9318"
        data-name="Group 9318"
        clipPath="url(#clip-path)"
        fill="currentColor"
      >
        <path
          id="Path_2329"
          data-name="Path 2329"
          d="M14.992,12.428a.857.857,0,0,0-.857-.857H7.283a.857.857,0,1,0,0,1.714h6.852a.857.857,0,0,0,.857-.857"
        />
        <path
          id="Path_2330"
          data-name="Path 2330"
          d="M7.283,15a.857.857,0,1,0,0,1.713h3.426a.857.857,0,1,0,0-1.713Z"
        />
        <path
          id="Path_2331"
          data-name="Path 2331"
          d="M16.7,19.709H4.713V5.147H9A.857.857,0,0,0,9,3.434H3.856A.857.857,0,0,0,3,4.291V20.566a.856.856,0,0,0,.856.856H17.562a.856.856,0,0,0,.856-.856V13.713a.856.856,0,1,0-1.713,0Z"
        />
        <path
          id="Path_2332"
          data-name="Path 2332"
          d="M20.731,11.4,18.547,9.217a4.162,4.162,0,0,0,.728-2.356,4.283,4.283,0,1,0-8.352,1.285H7.283a.857.857,0,1,0,0,1.713h4.668a4.33,4.33,0,0,0,3.041,1.285,4.157,4.157,0,0,0,2.355-.728L19.532,12.6a.848.848,0,1,0,1.239-1.159c-.013-.014-.026-.027-.04-.04m-8.309-4.54a2.57,2.57,0,1,1,2.57,2.57,2.57,2.57,0,0,1-2.57-2.57"
        />
      </g>
    </svg>
  );
}

export default IconKnowledgeBase;
