import Accordion from "@/components/Accordion";
import useApi from "@/hooks/useApi";
import React, { useCallback, useEffect, useState } from "react";
import EditBotProfileModal from "./EditBotProfileModal";
import { BotProfile } from "./types";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import { PrimaryGrayButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import IconPlus from "@velaro/velaro-shared/src/icons/IconPlus";

export default function BotProfilesEditor() {
  const api = useApi();
  const [profiles, setProfiles] = useState<BotProfile[]>([]);
  const [idToEdit, setIdToEdit] = useState<null | number>(null);
  const { displayToast } = useToastAlert();
  const fetchProfiles = useCallback(async () => {
    const response = await api.messaging.get("BotProfiles/List");
    setProfiles(await response.json());
  }, [api.messaging]);

  const deleteProfile = useCallback(
    async (profile: BotProfile) => {
      const result = await api.messaging.delete("BotProfiles", {
        id: profile.id
      });
      if (!result.ok) {
        const error = await result.json();
        displayToast(Severity.Error, error);
        return;
      }
      const clone = [...profiles];
      clone.splice(profiles.indexOf(profile), 1);
      setProfiles(clone);
    },
    [api.messaging, displayToast, profiles]
  );

  useEffect(() => {
    fetchProfiles();
  }, [fetchProfiles]);

  const profileToEdit = profiles.find((p) => p.id === idToEdit);
  return (
    <>
      <Accordion.Item eventKey="botprofiles">
        <Accordion.Header>
          <Accordion.Title>Bot Profiles</Accordion.Title>
          <Accordion.SubTitle>
            Customize the appearance of your bot. Profiles will be chosen at
            random for each conversation.
          </Accordion.SubTitle>
        </Accordion.Header>
        <Accordion.Body>
          <PrimaryGrayButton
            label="New profile"
            prefixIcon={<IconPlus />}
            onClick={() => setIdToEdit(0)}
          />
          <div className="m-4 flex gap-4">
            {profiles.map((p, i) => (
              <BotProfileCard
                key={i}
                profile={p}
                onDelete={deleteProfile}
                onSelect={() => setIdToEdit(p.id)}
              />
            ))}
          </div>
        </Accordion.Body>
      </Accordion.Item>
      {idToEdit != null && (
        <EditBotProfileModal
          botProfile={profileToEdit}
          onClose={() => setIdToEdit(null)}
          onSave={(profile) => {
            if (!profileToEdit) {
              // Add new profile
              setProfiles((p) => [...p, profile]);
            } else {
              // Update existing profile
              setProfiles((p) =>
                p.map((x) => (x.id === profile.id ? profile : x))
              );
            }
            setIdToEdit(null);
          }}
        />
      )}
    </>
  );
}

function BotProfileCard({
  onSelect,
  profile,
  onDelete
}: {
  onSelect: () => void;
  profile: BotProfile;
  onDelete(p: BotProfile): void;
}) {
  return (
    <div className="flex flex-col justify-center items-center group">
      <div
        className="flex flex-col justify-center items-center cursor-pointer w-20"
        onClick={onSelect}
      >
        <img
          className="h-12 w-12 rounded-full border group-hover:border-2 group-hover:border-blue-400"
          src={profile.picture}
          alt=""
        ></img>
        <span className="overflow-hidden w-full overflow-ellipsis whitespace-nowrap group-hover:text-blue-400 group-hover:underline">
          {profile.name}
        </span>
      </div>
      <span className="invisible group-hover:visible">
        <i
          className="fa fa-trash text-crimson-red-500 cursor-pointer"
          onClick={() => onDelete(profile)}
        />
      </span>
    </div>
  );
}
