import * as React from "react";
import useModelState from "../hooks/useModelState";
import { Category } from "../types";
import useApi from "@/hooks/useApi";
import Modal from "@velaro/velaro-shared/src/components/Modal";
import FormGroup from "@velaro/velaro-shared/src/components/FormGroup";

interface Props {
  category: Category;
  onClose(): void;
  onSubmit(category: Category): void;
}

export default function CategoryEditModal(props: Props) {
  const [saving, setSaving] = React.useState(false);
  const [modelState, setModelState] = useModelState();
  const [category, setCategory] = React.useState<Category>(props.category);
  const api = useApi();

  const handleSubmit = React.useCallback(async () => {
    setSaving(true);
    const response = await api.main.put("KBCategories", category);
    const json = await response.json();

    setSaving(false);
    props.onSubmit(json);

    setSaving(false);

    if (response.status === 400) {
      console.error(response);
    }
  }, [category, props, setModelState]);

  return (
    <Modal show={true}>
      <Modal.Header title="Edit Category" onClose={() => handleSubmit()} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Modal.Body>
          <FormGroup label="Name">
            {/* <label htmlFor="category-name">Name</label> */}
            <input
              autoFocus
              id="category-name"
              type="text"
              className="form-control"
              value={category.Name}
              onChange={(e) =>
                setCategory({ ...category, Name: e.currentTarget.value })
              }
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-default"
            onClick={() => props.onClose()}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-success" disabled={saving}>
            Save Category
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
