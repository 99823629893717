import Select from "@/components/Select";
import React, { useMemo } from "react";
import { Comparator, comparatorDefs, SerializedCondition } from "../types";
import MultiSelect, { MultiValue } from "react-select";
import useTeams from "@/hooks/useTeams";

export type TeamCondition = {
  teamIds: number[];
  comparator: Comparator;
};

export function TeamEditor({
  condition,
  onUpdate
}: {
  condition: SerializedCondition;
  onUpdate: (condition: SerializedCondition) => void;
}) {
  const { teams, getTeamNameById } = useTeams();
  const teamCondition = JSON.parse(condition.data || "{}") as TeamCondition;

  const selectedTeams = useMemo(
    () =>
      (teamCondition.teamIds || []).map((t: number) => {
        return { value: t, label: getTeamNameById(t) };
      }),
    [getTeamNameById, teamCondition.teamIds]
  );

  const teamOptions = useMemo(() => {
    return Object.values(teams).map((key) => ({
      value: key.id,
      label: key.name
    }));
  }, [teams]);

  function update(data: TeamCondition) {
    onUpdate({ ...condition, data: JSON.stringify(data) });
  }

  function updateTeams(
    selectedOptions: MultiValue<{ label: string; value: number }>
  ) {
    const selectedTeamIds = selectedOptions.map((option) => option.value);
    update({
      ...teamCondition,
      teamIds: selectedTeamIds
    });
  }

  const validComparatorOptions: Record<string, string> = {
    equals: comparatorDefs.equals,
    notEquals: comparatorDefs.notEquals
  };

  return (
    <>
      <Select
        options={validComparatorOptions}
        value={teamCondition.comparator}
        onChange={(v) => update({ ...teamCondition, comparator: v })}
      />
      <MultiSelect
        isMulti
        name="teams"
        options={teamOptions}
        value={selectedTeams}
        className="basic-multi-select w-full"
        classNamePrefix="select"
        onChange={(e) => updateTeams(e)}
      />
    </>
  );
}
