import { Expression } from "@/components/Expressions/types";
import BubbleInvitationEditor from "./BubbleInvitationEditor";
import React from "react";

export interface Invitation {
  id?: number;
  siteId: number;
  type: "bubble";
  name: string;
  description: string;
  triggerExpression: Expression;
}

export interface BubbleInvitation extends Invitation {
  messages: string[];
  messageDelaySeconds: number;
}

export const invitationTypeDefs = [
  {
    label: "Bubble",
    value: "bubble",
    editor: ({
      invitation,
      errors,
      onUpdate
    }: {
      invitation: Invitation;
      errors: Record<string, string>;
      onUpdate(invitation: BubbleInvitation): void;
    }) => {
      return (
        <BubbleInvitationEditor
          invitation={invitation as BubbleInvitation}
          errors={errors}
          onUpdate={onUpdate}
        />
      );
    }
  }
];
