import React from "react";
import { IconProps } from "./types";

function IconContactsCrmSmall(props: IconProps) {
  return (
    <svg
      id="contacts-crm-small"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_1617"
            data-name="Rectangle 1617"
            width="24"
            height="24"
          />
        </clipPath>
      </defs>
      <g id="Group_691" data-name="Group 691" clipPath="url(#clip-path)">
        <g id="CRM" transform="translate(1.714 1.714)">
          <path
            id="Path_1130"
            data-name="Path 1130"
            d="M8.8,19.18H4.442a.554.554,0,0,1-.528-.574v-.191a.554.554,0,0,1,.528-.574H8.8a.555.555,0,0,1,.528.574v.191a.554.554,0,0,1-.528.574"
            transform="translate(-1.038 -4.488)"
          />
          <path
            id="Path_1131"
            data-name="Path 1131"
            d="M12.28,22.305H4.441a.554.554,0,0,1-.528-.574V21.54a.555.555,0,0,1,.528-.574H12.28a.555.555,0,0,1,.528.574v.191a.554.554,0,0,1-.528.574"
            transform="translate(-1.038 -5.117)"
          />
          <path
            id="Path_1132"
            data-name="Path 1132"
            d="M18.39,13a.722.722,0,0,0-.69.75v6.766a.5.5,0,0,1-.474.514H1.854a.5.5,0,0,1-.474-.514v-9.6H7.686a.753.753,0,0,0,0-1.5H1.38V7.088A.274.274,0,0,1,1.641,6.8H8.408a.753.753,0,0,0,0-1.5H1.641A1.719,1.719,0,0,0,0,7.088V20.521a1.942,1.942,0,0,0,1.854,2.014H17.226a1.942,1.942,0,0,0,1.854-2.014V13.754a.722.722,0,0,0-.69-.75"
            transform="translate(0 -1.963)"
          />
          <path
            id="Path_1133"
            data-name="Path 1133"
            d="M16.5,1.121c-3.682,0-6.678,3.256-6.678,7.259s3,7.259,6.678,7.259,6.678-3.256,6.678-7.259-3-7.259-6.678-7.259M21.856,8.38A5.61,5.61,0,0,1,16.5,14.2,5.61,5.61,0,0,1,11.144,8.38,5.61,5.61,0,0,1,16.5,2.558,5.61,5.61,0,0,1,21.856,8.38"
            transform="translate(-2.606 -1.121)"
          />
          <path
            id="Path_1134"
            data-name="Path 1134"
            d="M18.066,9.871a2.894,2.894,0,0,0,2.764-3,2.894,2.894,0,0,0-2.764-3,2.893,2.893,0,0,0-2.762,3,2.893,2.893,0,0,0,2.762,3m-1.624-3a1.63,1.63,0,1,1,3.248,0,1.63,1.63,0,1,1-3.248,0"
            transform="translate(-4.06 -1.673)"
          />
          <path
            id="Path_1135"
            data-name="Path 1135"
            d="M20.282,12.719a17.184,17.184,0,0,1,1.843,1.228.882.882,0,0,1,.182.227,1.157,1.157,0,0,1,.14.577v.636h1.3V14.75a2.66,2.66,0,0,0-.339-1.329,2.273,2.273,0,0,0-.471-.58A18.918,18.918,0,0,0,20.9,11.472a.6.6,0,0,0-.5-.057.664.664,0,0,0-.388.342.742.742,0,0,0,.262.962"
            transform="translate(-5.29 -3.188)"
          />
          <path
            id="Path_1136"
            data-name="Path 1136"
            d="M14,15.386V14.75a1.157,1.157,0,0,1,.14-.577.882.882,0,0,1,.182-.227,17.185,17.185,0,0,1,1.843-1.228.742.742,0,0,0,.262-.962.627.627,0,0,0-.884-.285A18.794,18.794,0,0,0,13.5,12.841a2.283,2.283,0,0,0-.471.579,2.665,2.665,0,0,0-.339,1.33v.636Z"
            transform="translate(-3.368 -3.188)"
          />
        </g>
      </g>
    </svg>
  );
}

export default IconContactsCrmSmall;
