import React from "react";

export default function IllustrationChatTransfersReAssignments1() {
  return (
    <svg viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M130.97 48.1901L377.95 56.2201C419.14 57.5601 453.49 89.3301 456.7 127.24L472.72 316.56C475.93 354.47 447.41 391.56 407.03 399.81L164.91 449.24C106.35 461.2 52.42 431.34 48.22 381.77L27.27 134.29C23.08 84.7301 71.23 46.2301 130.96 48.1701L130.97 48.1901Z"
        fill="#EEF4FD"
      />
      <path
        d="M367.846 107.776L363.891 141.907L371.838 142.828L375.793 108.697L367.846 107.776Z"
        fill="black"
      />
      <path
        d="M414.791 213.354L411.143 220.474L452.188 241.505L455.836 234.386L414.791 213.354Z"
        fill="black"
      />
      <path
        d="M462.78 143.392L420.882 163.7L424.372 170.899L466.269 150.591L462.78 143.392Z"
        fill="black"
      />
      <path
        d="M413.72 296.56L243.65 286.08L232.9 390.33L344.96 390.74L371.3 417.68L378.85 387.75L415.09 389.53L413.72 296.56Z"
        fill="black"
      />
      <path
        d="M413.72 296.56L253.39 286.68L248.47 381.07L339.51 385.17L371.3 417.68L378.85 387.75L415.09 389.53L413.72 296.56Z"
        fill="white"
      />
      <path
        d="M373.08 424.31L338.04 388.46L244.95 384.26L250.21 283.12L417.03 293.4L418.49 393.05L381.43 391.23L373.08 424.3V424.31ZM340.99 381.88L369.51 411.06L376.27 384.27L411.68 386.01L410.41 299.72L256.55 290.24L251.99 377.87L340.98 381.88H340.99Z"
        fill="black"
      />
      <path
        d="M319.657 317.261L319.406 323.966L381.182 326.285L381.434 319.58L319.657 317.261Z"
        fill="black"
      />
      <path
        d="M276.5 345.635L276.025 352.328L378.288 359.587L378.763 352.894L276.5 345.635Z"
        fill="black"
      />
      <path
        d="M412.22 179.75L336.07 139.52C334.14 138.5 331.83 138.65 330.07 139.89C328.29 141.14 327.37 143.24 327.66 145.39L330.33 165.01L224.88 176.88L224.69 176.92C223.18 177.26 222 177.97 221.17 179.04C220.22 180.26 219.8 181.83 220 183.36L225.26 222.06L213.11 223.71L210.17 202.05C209.85 199.73 208.19 197.85 205.94 197.26C203.68 196.65 201.29 197.47 199.87 199.34L139.9 278.17C138.87 279.52 138.49 281.24 138.86 282.91C139.23 284.57 140.3 285.97 141.8 286.76L217.94 326.99C219.01 327.56 220.21 327.77 221.41 327.61C222.32 327.49 223.19 327.14 223.94 326.62C225.72 325.37 226.64 323.27 226.35 321.12L223.68 301.5L328.86 289.66C330.44 289.52 331.87 288.74 332.85 287.47C333.8 286.25 334.22 284.68 334.02 283.15L328.76 244.45L340.91 242.8L343.85 264.47C344.17 266.79 345.83 268.67 348.08 269.27C350.3 269.86 352.75 269.02 354.15 267.19L414.12 188.36C415.15 187.01 415.53 185.29 415.16 183.62C414.79 181.96 413.72 180.56 412.22 179.77V179.75Z"
        fill="black"
      />
      <path
        d="M410.16 186.27L334.01 146.04C332.08 145.02 329.77 145.17 328.01 146.41C326.23 147.66 325.31 149.76 325.6 151.91L328.27 171.53L222.82 183.4L222.63 183.44C221.12 183.78 219.94 184.49 219.11 185.56C218.16 186.78 217.74 188.35 217.94 189.88L223.2 228.58L211.05 230.23L208.11 208.57C207.79 206.25 206.13 204.37 203.88 203.78C201.62 203.17 199.23 203.99 197.81 205.86L137.84 284.69C136.81 286.04 136.43 287.76 136.8 289.43C137.17 291.09 138.24 292.49 139.74 293.28L215.88 333.51C216.95 334.08 218.15 334.29 219.35 334.13C220.26 334.01 221.13 333.66 221.88 333.14C223.66 331.89 224.58 329.79 224.29 327.64L221.62 308.02L326.8 296.18C328.38 296.04 329.81 295.26 330.79 293.99C331.74 292.77 332.16 291.2 331.96 289.67L326.7 250.97L338.85 249.32L341.79 270.99C342.11 273.31 343.77 275.19 346.02 275.79C348.24 276.38 350.69 275.54 352.09 273.71L412.06 194.88C413.09 193.53 413.47 191.81 413.1 190.14C412.73 188.48 411.66 187.08 410.16 186.29V186.27Z"
        fill="black"
      />
      <path
        d="M345.53 234.01L237.03 248.75L228.31 184.61L337.09 172.37C337.69 172.28 338.27 171.99 338.65 171.49C339.03 171 339.2 170.38 339.12 169.76L336.26 148.74L406.04 185.61L351.09 257.84L348.12 235.98C347.95 234.72 346.79 233.83 345.53 234.01Z"
        fill="white"
      />
      <path
        d="M208.48 232.48L229.77 229.59L232.77 251.66C232.94 252.92 234.1 253.81 235.36 253.63L320.28 242.09L325.69 281.87L216.91 294.11C216.33 294.2 215.73 294.49 215.35 294.99C214.97 295.48 214.8 296.1 214.88 296.72L217.74 317.74L147.96 280.87L202.91 208.64L205.88 230.5C206.05 231.76 207.21 232.65 208.47 232.47L208.48 232.48Z"
        fill="#629FF0"
      />
      <path
        d="M125.69 197.03L289.24 173.97L272.18 81.2L33.78 105.4L33.73 219.68L66.56 215.03L69.53 253.5L125.69 197.03Z"
        fill="black"
      />
      <path
        d="M126.16 195.44L302.82 171.56L285.75 78.79L47.3499 102.99L47.3099 206.1L79.1399 201.8L82.9099 238.93L126.16 195.44Z"
        fill="#AFCFF8"
      />
      <path
        d="M80.69 245.2L76.61 205.01L44.46 209.36L44.51 100.42L288.08 75.6899L306.16 173.98L127.5 198.13L80.69 245.19V245.2ZM81.68 198.58L85.14 232.65L124.83 192.75L299.48 169.14L283.43 81.8799L50.2 105.56L50.16 202.84L81.69 198.58H81.68Z"
        fill="black"
      />
      <path
        d="M152.065 122.55L90.4537 127.626L91.0047 134.313L152.616 129.237L152.065 122.55Z"
        fill="black"
      />
      <path
        d="M94.84 156.45L94.29 149.76L204.8 140.07L205.35 146.75L94.84 156.45Z"
        fill="black"
      />
    </svg>
  );
}
