import useApi from "@/hooks/useApi";
import LoadingSpinner from "@velaro/velaro-shared/src/components/LoadingSpinner";
import * as React from "react";
import { useEffect, useState } from "react";
import { UploadedFile } from "./types";
import UploadButton from "./UploadButton";
import NewFileEditor from "./NewFileEditor";
import FileDropHandler from "./FileDropHandler";
import UploadedFileEditor from "./UploadedFileEditor";
import Panel from "@/components/Panel";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import IconUploadCloud from "@velaro/velaro-shared/src/icons/IconUploadCloud";
import IconUpload from "@/icons/IconUpload";
import FileUpload from "./FileUpload";

export default function FileStorage() {
  const [files, setFiles] = useState<UploadedFile[]>([]);
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(true);
  const toast = useToastAlert();
  const api = useApi();
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  async function save() {
    setLoading(true);
    const formData = new FormData();

    for (const file of newFiles) {
      formData.append("files", file);
    }

    const result = await api.messaging.postFormData("uploadedFiles", formData);
    if (result.status !== 200) {
      setLoading(false);
      toast.displayToast(
        Severity.Error,
        "Error uploading files: " + (await result.text())
      );
      return;
    }

    setFiles([...files, ...(await result.json())]);
    setNewFiles([]);
    toast.displayToast(Severity.Success, "Files uploaded successfully");
    setLoading(false);
  }

  async function remove(file: UploadedFile) {
    await api.messaging.delete("uploadedFiles", { id: file.id });
    const clone = [...files];
    clone.splice(files.indexOf(file), 1);
    setFiles(clone);
  }

  useEffect(() => {
    async function fetchFiles() {
      const response = await api.messaging.get("uploadedFiles/list");
      setFiles(await response.json());
      setLoading(false);
    }

    fetchFiles();
  }, [api.messaging]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <FileUpload onAddFiles={setNewFiles} />
      <div className="m-4">
        <span className="flex items-center justify-end">
          <PrimaryBlueButton label="Save" onClick={save} />
        </span>
      </div>

      <Panel title={"Uploaded"}>
        <div className="p-4">
          <UploadedFileEditor files={files} onRemove={remove} />
        </div>
      </Panel>
    </>
  );
}
