import * as React from "react";
import daysOfWeek from "../helpers/daysOfWeek";

interface Props {
  onChange: (value: number) => void;
  value: number;
}

export default function DayOfWeekSelect(props: Props) {
  function onChange(event: any) {
    props.onChange(Number(event.currentTarget.value));
  }

  return (
    <select
      value={props.value}
      onChange={onChange}
      className="text-right appearance-none underline"
    >
      {Object.keys(daysOfWeek).map((key, i) => (
        <option key={i} value={i}>
          {key}
        </option>
      ))}
    </select>
  );
}
