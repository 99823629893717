import useApi from "@/hooks/useApi";
import React, { useCallback, useEffect, useState } from "react";
import LoadingSpinner from "@/components/LoadingSpinner";
import { SelectOption } from "@/types";
import { User } from "@/pages/Messaging/Users/types";
import { UsersContext } from "@/context/UsersContext";

export function UsersProvider(props: { children: React.ReactNode }) {
  const api = useApi();
  const [users, setUsers] = React.useState<User[]>([]);
  const [loading, setLoading] = useState(true);

  const fetch = useCallback(async () => {
    const response = await api.messaging.get("Users/List");
    const data = await response.json();
    setUsers(data);
    setLoading(false);
    return data;
  }, [api.messaging]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const getDisplayNameForUser = useCallback(
    (userId: number) => {
      const user = users.find((u) => u.id === userId);
      return user?.displayName || "Unknown User";
    },
    [users]
  );

  const userOptions = users.map((user) => ({
    label: user.displayName,
    value: user.id
  })) as SelectOption[];

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <UsersContext.Provider
      value={{
        users,
        userOptions,
        getDisplayNameForUser
      }}
    >
      {props.children}
    </UsersContext.Provider>
  );
}
