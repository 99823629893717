import React from "react";
import { addMinutes, format } from "date-fns";

const arr: { value: number; label: string }[] = [];
let date = new Date(0, 0, 0);

for (let i = 0; i < 60 * 24; i += 15) {
  arr.push({
    value: i,
    label: format(date, "h:mmaaa")
  });

  date = addMinutes(date, 15);
}

interface Props {
  value: number;
  onChange(value: number): void;
  disabled?: boolean;
  className?: string;
}

export default function TimePicker(props: Props) {
  return (
    <select
      className={props.className}
      value={props.value}
      onChange={(e) => props.onChange(parseInt(e.currentTarget.value))}
      disabled={props.disabled}
    >
      {arr.map((item) => (
        <option key={item.value} value={item.value}>
          {item.label}
        </option>
      ))}
    </select>
  );
}
