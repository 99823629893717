import React from "react";
import { IconProps } from "./types";

function IconActiveEngagements(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        d="M7,0H25a7,7,0,0,1,7,7V25a7,7,0,0,1-7,7H7a7,7,0,0,1-7-7V7A7,7,0,0,1,7,0"
        fill="#3383ec"
      />
      <path
        d="M27.38,17.782H22.9a2.643,2.643,0,0,0-2.64,2.641v7.019a1.1,1.1,0,0,0,.657,1.012,1.119,1.119,0,0,0,.454.1,1.1,1.1,0,0,0,.734-.283l.829-.729H27.38a2.643,2.643,0,0,0,2.64-2.64V20.423a2.644,2.644,0,0,0-2.64-2.641M28.972,24.9a1.594,1.594,0,0,1-1.592,1.592H22.756a.469.469,0,0,0-.334.105l-.778.685a.2.2,0,0,1-.132.05.188.188,0,0,1-.081-.018.2.2,0,0,1-.118-.182V20.423A1.593,1.593,0,0,1,22.9,18.831H27.38a1.594,1.594,0,0,1,1.592,1.592Z"
        fill="#fff"
      />
      <path
        d="M23.1,23.986a2.592,2.592,0,0,0,2.034,1.154,2.649,2.649,0,0,0,2.054-1.154.4.4,0,0,0-.681-.433,1.588,1.588,0,0,1-2.727,0,.4.4,0,1,0-.68.433"
        fill="#fff"
      />
      <path
        d="M23.56,22.378a.4.4,0,0,0,.4-.4V20.866a.4.4,0,0,0-.806,0v1.109a.4.4,0,0,0,.4.4"
        fill="#fff"
      />
      <path
        d="M27.088,21.975V20.866a.4.4,0,0,0-.807,0v1.109a.4.4,0,0,0,.807,0"
        fill="#fff"
      />
      <path
        d="M17.234,20.152a.8.8,0,1,0-1.04,1.2L18.565,23.4a.8.8,0,1,0,1.04-1.2Z"
        fill="#fff"
      />
      <path
        d="M6.735,20.049,2.247,24.036a.8.8,0,0,0-.267.6v2.8a.795.795,0,1,0,1.59,0v-2.44l4.221-3.751a.8.8,0,1,0-1.056-1.189"
        fill="#fff"
      />
      <path
        d="M21.7,14.114v-.908a2.48,2.48,0,0,0-1.577-2.3,8.11,8.11,0,0,0-16.166,0,2.479,2.479,0,0,0-1.577,2.3v.908a2.484,2.484,0,0,0,2.481,2.48h1.19c.75,3.038,3.148,5.27,5.989,5.27a5.572,5.572,0,0,0,3.078-.945.8.8,0,1,0-.879-1.326,3.929,3.929,0,0,1-2.2.68c-2.562,0-4.646-2.554-4.646-5.693s2.084-5.692,4.646-5.692,4.646,2.554,4.646,5.692a6.947,6.947,0,0,1-.086,1.093.77.77,0,0,0,.922.9.754.754,0,0,0,.105.022h.789a.969.969,0,0,1-.966.934H14.249a.8.8,0,1,0,0,1.591h3.205a2.567,2.567,0,0,0,2.565-2.564v-.1A2.476,2.476,0,0,0,21.7,14.114m-1.591,0a.89.89,0,0,1-.89.889h-.959c.007-.141.017-.281.017-.423a8.335,8.335,0,0,0-.313-2.263h1.177a.811.811,0,0,0,.22.044c.014,0,.026-.007.039-.008a.879.879,0,0,1,.709.853ZM4.865,15a.89.89,0,0,1-.89-.889v-.908a.879.879,0,0,1,.709-.853c.014,0,.025.008.039.008a.8.8,0,0,0,.22-.044H6.12a8.335,8.335,0,0,0-.313,2.263c0,.143.011.282.018.423ZM12.044,7.3a6.1,6.1,0,0,0-5.282,3.429H5.579a6.519,6.519,0,0,1,12.931,0H17.326A6.1,6.1,0,0,0,12.044,7.3"
        fill="#fff"
      />
    </svg>
  );
}

export default IconActiveEngagements;
