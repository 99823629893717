import React from "react";
import AuthService from "../services/AuthService";
import RestService from "../services/RestService";

export default function useApi() {
  return React.useMemo(
    () => ({
      main: new RestService({
        rootUrl: `${import.meta.env.VITE_MAIN_API_URL!}/v1/${
          AuthService.profile.siteId
        }`,
        tokenAccessor: () => AuthService.legacyToken.token,
        includeSiteId: false
      }),
      admin: new RestService({
        rootUrl: import.meta.env.VITE_ADMIN_API_URL! as string,
        tokenAccessor: () => AuthService.token.token
      }),
      messaging: new RestService({
        rootUrl: import.meta.env.VITE_MESSAGING_API_URL! as string,
        tokenAccessor: () => AuthService.token.token
      }),
      contactcenter: new RestService({
        rootUrl: import.meta.env.VITE_CONTACTCENTER_API_URL! as string,
        tokenAccessor: () => AuthService.token.token
      })
    }),
    []
  );
}
