import { PrivateRoute } from "@/routes/PrivateRoute";
import React from "react";
import { Routes, Route } from "react-router-dom";
import CustomFields from "./CustomFields";
import Landing from "./Landing";

export default function ContactCenter() {
  return (
    <div className="flex h-full">
      <div className="w-full">
        <Routes>
          <Route
            path="/contact_center/custom_fields"
            element={<CustomFields />}
          />
          <Route path="/contact_center" element={<Landing />} />
        </Routes>
      </div>
    </div>
  );
}
