import useApi from "@/hooks/useApi";
import useAsync from "@/hooks/useAsync";
import IconActiveEngagements from "@/icons/IconActiveEngagements";
import IconRatings from "@/icons/IconRatings";
import IconTotalChats from "@/icons/IconTotalChats";
import React, { useCallback, useEffect, useState } from "react";
import { User } from "./types";

interface Props {
  user: User | null;
}

export default function UserProfileBanner(props: Props) {
  const [activeChatCount, setActiveChatCount] = useState<number>(0);
  const [lastSevenDayChatCount, setLastSevenDayChatCount] = useState<number>(0);
  const api = useApi();

  const fetchActiveChatCount = useCallback(async () => {
    const response = await api.messaging.get(
      `Conversations/${props.user?.id}/Active`
    );
    return await response.json();
  }, [api.messaging, props.user?.id]);

  useAsync({
    asyncFunction: fetchActiveChatCount,
    setFunction: setActiveChatCount,
    immediate: true
  });

  const fetchLastSevenDayChatCount = useCallback(async () => {
    const response = await api.messaging.get(
      `Conversations/${props.user?.id}/LastSevenDays`
    );
    return await response.json();
  }, [api.messaging, props.user?.id]);

  useAsync({
    asyncFunction: fetchLastSevenDayChatCount,
    setFunction: setLastSevenDayChatCount,
    immediate: true
  });

  return (
    <div className="panel bg-white dark:bg-stone-300 rounded-md shadow-md mb-4 flex p-4">
      <div className="flex justify-between w-full">
        <div className="flex items-center">
          <div className="w-12 h-12 relative z-0 flex-grow-0 flex-shrink-0">
            <img
              src={props.user?.picture}
              alt=""
              className="rounded-full w-12 h-12 absolute z-10"
            />
          </div>
          <div className="pl-4">
            <div className="font-bold">
              {props.user?.firstName + " " + props.user?.lastName}
            </div>
            <div className="text-sm text-slate-500">
              {props.user?.licenseStatus}
            </div>
          </div>
        </div>
        <div className="flex gap-10">
          <div className="flex items-center">
            <IconActiveEngagements className="mr-2" />
            <div className="text-xs w-24">Total Active Engagements</div>
            <div className="text-2xl ml-2">{activeChatCount}</div>
          </div>
          <div className="flex items-center">
            <IconTotalChats className="mr-2" />
            <div className="text-xs w-20">Total Chats Last 7 Days</div>
            <div className="text-2xl ml-2">{lastSevenDayChatCount}</div>
          </div>
          {/* <div className="flex items-center">
            <IconRatings className="mr-2" />
            <div className="text-xs w-24">Average Ratings Last 7 Days</div>
            <div className="text-2xl ml-2">4.2</div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
