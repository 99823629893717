import React from "react";
import { IconProps } from "./types";

function IconChevronRight(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.17574 2.77583C4.94142 3.01015 4.94142 3.39005 5.17574 3.62436L9.55147 8.0001L5.17574 12.3758C4.94142 12.6101 4.94142 12.99 5.17574 13.2244C5.41005 13.4587 5.78995 13.4587 6.02426 13.2244L10.8243 8.42436C11.0586 8.19005 11.0586 7.81015 10.8243 7.57583L6.02426 2.77583C5.78995 2.54152 5.41005 2.54152 5.17574 2.77583Z"
        fill="#64748B"
      />
    </svg>
  );
}

export default IconChevronRight;
