import React from "react";
import { IconProps } from "./types";

function IconPencil(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill="currentColor"
        data-name="Group 356"
        transform="translate(.45 .5)"
      >
        <path
          d="M20.685 1.418a3.315 3.315 0 00-4.58 0L4.155 13.367a1.552 1.552 0 000 2.191l2.388 2.388a1.55 1.55 0 002.191 0L18.08 8.6v-.007h.007L20.685 6a3.238 3.238 0 000-4.58m-1.21 3.37l-2.594 2.592v.007h-.007l-9.23 9.23-2.161-2.16L17.315 2.628a1.563 1.563 0 012.16 0 1.526 1.526 0 010 2.16"
          data-name="Path 515"
          transform="translate(-.094)"
        ></path>
        <path
          d="M4.784 19.987a2.075 2.075 0 01-1.468-3.544l1.128-1.128a.642.642 0 11.908.907l-1.13 1.129a.793.793 0 001.122 1.122l1.129-1.129a.642.642 0 01.907.908L6.252 19.38a2.067 2.067 0 01-1.468.607"
          data-name="Path 516"
          transform="translate(-.05 -.636)"
        ></path>
        <path
          d="M13.56 23.28H3.635a2.085 2.085 0 01-1.475-3.559l1.1-1.1a.642.642 0 01.907.908l-1.1 1.1A.8.8 0 003.635 22h9.925a.642.642 0 110 1.284"
          data-name="Path 517"
          transform="translate(0 -.779)"
        ></path>
        <path
          d="M17.293 8.929l.837-.98-3.372-3.372a.642.642 0 10-.908.907z"
          data-name="Path 518"
          transform="translate(-.526 -.169)"
        ></path>
      </g>
    </svg>
  );
}

export default IconPencil;
