import Accordion from "@/components/Accordion";
import React, { useMemo } from "react";
import { LanguageConfigModel } from "@/pages/Messaging/types";

interface Props {
  onExpanded(): void;
  workingConfig: LanguageConfigModel;
  update: (updates: Partial<LanguageConfigModel>) => void;
  persist: () => void;
  loading: boolean;
}

export default function WelcomeOpenConvosForm(props: Props) {
  const saveButtonText = useMemo(() => {
    return props.loading ? "Saving..." : "Save and set live";
  }, [props.loading]);

  return (
    <Accordion.Item
      eventKey="welcomeOpen"
      onExpanded={() => {
        props.onExpanded();
      }}
    >
      <Accordion.Header>
        <Accordion.Title>Existing Customer Landing</Accordion.Title>
        <Accordion.SubTitle>Welcome back your visitors</Accordion.SubTitle>
      </Accordion.Header>
      <Accordion.Body>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            props.persist();
          }}
        >
          <div className="mb-4">
            <label className="block mb-2" htmlFor="welcome-start-title">
              Welcome Back Title
            </label>
            <input
              className="input"
              type="text"
              id="welcome-start-title"
              value={props.workingConfig.welcomeTitle}
              onChange={(e) => props.update({ welcomeTitle: e.target.value })}
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="welcome-start-subtitle1">
              Welcome Back Subtitle
            </label>
            <input
              className="input"
              type="text"
              id="welcome-start-subtitle1"
              value={props.workingConfig.welcomeSubtitle}
              onChange={(e) =>
                props.update({ welcomeSubtitle: e.target.value })
              }
            />
          </div>
          <div>
            <button className="btn-green" disabled={props.loading}>
              {saveButtonText}
            </button>
          </div>
        </form>
      </Accordion.Body>
    </Accordion.Item>
  );
}
