import React, { useEffect, useMemo } from "react";
import TeamSelectDeprecated from "@/components/messaging/TeamSelect";
import useTeams from "@/hooks/useTeams";
import LoadingSpinner from "@/components/LoadingSpinner";

export interface CannedResponseModel {
  teamId: number | undefined;
  shortCode: string;
  messageText: string;
}

export const defaultCannedResponseModel: CannedResponseModel = {
  teamId: undefined,
  shortCode: "",
  messageText: ""
};

interface Props {
  cannedResponse: CannedResponseModel;
  onChange(team: Partial<CannedResponseModel>): void;
}

export default function Form({ cannedResponse, onChange }: Props) {
  return (
    <>
      {/* <div className="flex mb-4">
        <div className="w-1/3 font-semibold">Team</div>
        <TeamSelect
          id="cr-team"
          className="input w-2/3"
          value={cannedResponse.teamId}
          onChange={(e) =>
            onChange({ teamId: parseInt(e.currentTarget.value) })
          }
        />
      </div> */}
      <div className="flex mb-4">
        <div className="w-1/3 font-semibold">Short code</div>
        <div className="flex items-center w-2/3">
          <span className="flex bg-gray-300 h-8 w-8 justify-center items-center mr-1 rounded">
            /
          </span>
          <input
            type="text"
            id="cr-short-code"
            className="input w-full"
            required
            value={cannedResponse.shortCode}
            onChange={(e) => onChange({ shortCode: e.currentTarget.value })}
          />
        </div>
      </div>
      <div className="flex mb-4">
        <div className="w-1/3 font-semibold">Message text</div>
        <textarea
          id="cr-message-text"
          className="input w-2/3 h-48"
          required
          maxLength={8000}
          value={cannedResponse.messageText}
          onChange={(e) => onChange({ messageText: e.currentTarget.value })}
        />
      </div>
    </>
  );
}
