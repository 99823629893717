import React from "react";

export default function IllustrationChatPausedIdleDelay() {
  return (
    <svg viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M130.97 48.1899L377.95 56.2199C419.14 57.5599 453.49 89.3299 456.7 127.24L472.72 316.56C475.93 354.47 447.41 391.56 407.03 399.81L164.91 449.24C106.35 461.2 52.42 431.34 48.22 381.77L27.27 134.29C23.08 84.7299 71.23 46.2299 130.96 48.1699L130.97 48.1899Z"
        fill="#EEF4FD"
      />
      <path
        d="M416.868 92.1128L383.645 104.015L386.343 111.546L419.566 99.644L416.868 92.1128Z"
        fill="black"
      />
      <path
        d="M357.726 41.502L350.31 71.4473L358.075 73.3704L365.491 43.4251L357.726 41.502Z"
        fill="black"
      />
      <path
        d="M294.805 57.2454L287.853 61.2042L300.264 82.9982L307.216 79.0394L294.805 57.2454Z"
        fill="black"
      />
      <path
        d="M145 127.76L175.76 458.5L416.43 422.07L355.25 100.65L145 127.76Z"
        fill="black"
      />
      <path
        d="M151.65 126.37L181.56 442.09L412.26 415.92L353.15 101.65L151.65 126.37Z"
        fill="white"
      />
      <path
        d="M178.43 445.96L147.87 123.33L355.97 97.8L416.38 418.97L178.43 445.96ZM155.44 129.42L184.69 438.24L408.14 412.89L350.33 105.51L155.44 129.42Z"
        fill="black"
      />
      <path
        d="M353.15 101.65L151.65 126.37L156.95 182.33L362.77 152.81L353.15 101.65Z"
        fill="#AFCFF8"
      />
      <path
        d="M153.83 186.29L147.87 123.32L355.97 97.79L366.87 155.74L153.83 186.29ZM155.44 129.42L160.08 178.36L358.68 149.88L350.33 105.51L155.44 129.42Z"
        fill="black"
      />
      <path
        d="M401.23 357.26L176.41 387.77L181.56 442.09L412.26 415.92L401.23 357.26Z"
        fill="#AFCFF8"
      />
      <path
        d="M178.43 445.96L172.63 384.78L404.04 353.37L416.38 418.97L178.43 445.96ZM180.2 390.78L184.69 438.24L408.14 412.89L398.41 361.17L180.19 390.79L180.2 390.78Z"
        fill="black"
      />
      <path
        d="M364.69 391.54L391.19 387.47L363.48 406.45L364.69 391.54Z"
        fill="white"
      />
      <path
        d="M358.08 375.37L390.91 385.45L364.36 389.53L358.08 375.37Z"
        fill="white"
      />
      <path
        d="M300.322 132.411L206.139 143.642L206.964 150.563L301.147 139.332L300.322 132.411Z"
        fill="black"
      />
      <path
        d="M180.208 202.45L339.781 180.762C349.006 179.508 357.504 185.972 358.758 195.198C360.012 204.423 353.548 212.921 344.322 214.175L201.456 233.593C192.231 234.846 183.733 228.382 182.479 219.157L180.208 202.45Z"
        fill="#629FF0"
      />
      <path
        d="M341.039 188.507L197.756 207.981C195.972 208.224 194.723 209.866 194.965 211.65L194.966 211.66C195.209 213.444 196.852 214.694 198.636 214.452L341.918 194.977C343.702 194.735 344.952 193.092 344.71 191.308L344.708 191.298C344.466 189.514 342.823 188.264 341.039 188.507Z"
        fill="white"
      />
      <path
        d="M268.519 209.999L199.306 219.407C197.522 219.649 196.272 221.292 196.515 223.076L196.516 223.086C196.758 224.87 198.401 226.12 200.185 225.877L269.399 216.47C271.183 216.227 272.433 214.585 272.19 212.801L272.189 212.791C271.946 211.007 270.304 209.757 268.519 209.999Z"
        fill="white"
      />
      <path
        d="M296.952 206.124L283.338 207.974C281.554 208.217 280.304 209.86 280.546 211.644L280.548 211.654C280.79 213.438 282.433 214.687 284.217 214.445L297.832 212.594C299.616 212.352 300.866 210.709 300.623 208.925L300.622 208.915C300.379 207.131 298.736 205.881 296.952 206.124Z"
        fill="white"
      />
      <path
        d="M195.595 315.735L298.102 301.803C307.327 300.549 315.825 307.013 317.079 316.239C318.333 325.464 311.869 333.962 302.643 335.216L216.842 346.877C207.617 348.131 199.119 341.667 197.865 332.442L195.595 315.735Z"
        fill="#629FF0"
      />
      <path
        d="M299.368 309.536L213.151 321.255C211.367 321.497 210.117 323.14 210.36 324.924L210.361 324.934C210.604 326.718 212.246 327.968 214.031 327.725L300.248 316.007C302.032 315.764 303.282 314.122 303.039 312.338L303.038 312.328C302.795 310.544 301.152 309.294 299.368 309.536Z"
        fill="white"
      />
      <path
        d="M237.789 329.546L214.701 332.684C212.917 332.926 211.668 334.569 211.91 336.353L211.912 336.363C212.154 338.147 213.797 339.397 215.581 339.154L238.669 336.016C240.453 335.774 241.702 334.131 241.46 332.347L241.459 332.337C241.216 330.553 239.573 329.303 237.789 329.546Z"
        fill="white"
      />
      <path
        d="M255.273 327.158L252.598 327.522C250.814 327.764 249.564 329.407 249.806 331.191L249.808 331.201C250.05 332.985 251.693 334.235 253.477 333.992L256.152 333.629C257.937 333.386 259.186 331.743 258.944 329.959L258.942 329.949C258.7 328.165 257.057 326.916 255.273 327.158Z"
        fill="white"
      />
      <path
        d="M352.001 270.845L265.06 282.662C255.835 283.915 247.337 277.451 246.083 268.226C244.829 259.001 251.294 250.503 260.519 249.249L364.166 235.161L366.437 251.868C367.691 261.093 361.226 269.591 352.001 270.845Z"
        fill="#AFCFF8"
      />
      <path
        d="M262.252 263.497L349.609 251.623C351.393 251.381 352.643 249.738 352.401 247.954L352.399 247.944C352.157 246.16 350.514 244.911 348.73 245.153L261.373 257.026C259.589 257.269 258.339 258.912 258.582 260.696L258.583 260.705C258.826 262.489 260.468 263.739 262.252 263.497Z"
        fill="#629FF0"
      />
      <path
        d="M337.863 264.859L351.151 263.053C352.935 262.811 354.185 261.168 353.942 259.384L353.941 259.374C353.698 257.59 352.056 256.34 350.271 256.583L336.984 258.389C335.2 258.631 333.95 260.274 334.192 262.058L334.194 262.068C334.436 263.852 336.079 265.102 337.863 264.859Z"
        fill="#629FF0"
      />
      <path
        d="M296.419 270.492L327.86 266.219C329.644 265.976 330.894 264.333 330.651 262.549L330.65 262.539C330.407 260.755 328.764 259.506 326.98 259.748L295.539 264.022C293.755 264.264 292.506 265.907 292.748 267.691L292.75 267.701C292.992 269.485 294.635 270.735 296.419 270.492Z"
        fill="#629FF0"
      />
      <path
        d="M80.44 291.05C80.44 249.7 114.08 216.05 155.44 216.05C196.8 216.05 230.44 249.69 230.44 291.05C230.44 332.41 196.8 366.05 155.44 366.05C114.08 366.05 80.44 332.4 80.44 291.05Z"
        fill="black"
      />
      <path
        d="M155.44 351.7C190.07 351.7 218.25 323.52 218.25 288.89C218.25 254.26 190.07 226.08 155.44 226.08C120.81 226.08 92.63 254.26 92.63 288.89C92.63 323.52 120.8 351.7 155.44 351.7Z"
        fill="#AFCFF8"
      />
      <path
        d="M155.44 231.39C187.15 231.39 212.95 257.19 212.95 288.89C212.95 320.59 187.15 346.39 155.44 346.39C123.73 346.39 97.94 320.59 97.94 288.89C97.94 257.19 123.74 231.39 155.44 231.39Z"
        fill="white"
      />
      <path d="M111.39 286.32H100.03V291.46H111.39V286.32Z" fill="black" />
      <path d="M210.73 286.32H199.37V291.46H210.73V286.32Z" fill="black" />
      <path d="M158.01 233.54H152.87V244.9H158.01V233.54Z" fill="black" />
      <path d="M158.01 332.83H152.87V344.19H158.01V332.83Z" fill="black" />
      <path
        d="M128.08 277.11L151.1 292.03C152.09 292.73 153.45 292.66 154.38 291.86L190.43 259.86L193.26 263.23L154.37 298.31L126.3 279.08L128.08 277.11Z"
        fill="black"
      />
    </svg>
  );
}
