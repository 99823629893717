import * as React from "react";
import useApi from "@/hooks/useApi";

interface LoginResponse {
  status: string;

  authResponse: {
    accessToken: string;
    data_access_expiration_time: number;
    expiresIn: number;
    graphDomain: string;
    signedRequest: string;
    userID: string;
  };
}

interface Props {
  onProvision(): void;
}

export default function FacebookLogin(props: Props) {
  const [sdkLoaded, setSdkLoaded] = React.useState(false);
  const api = useApi();

  const provision = React.useCallback(
    async (userId: string, userAccessToken: string) => {
      const response = await api.messaging.post("Facebook/Provision", {
        userId,
        userAccessToken
      });

      console.log(response);
      props.onProvision();
    },
    [api.messaging, props]
  );

  React.useEffect(() => {
    if ((window as any).FB) {
      setSdkLoaded(true);
      console.log("facebook sdk already loaded");
      return;
    }

    console.log("facebook sdk loading");

    const script = document.createElement("script");
    script.id = "facebook-jssdk";
    script.src = "https://connect.facebook.net/en_US/sdk.js";

    script.addEventListener("load", () => {
      setSdkLoaded(true);
      console.log("facebook sdk loaded");
    });

    document.body.appendChild(script);
  }, []);

  React.useEffect(() => {
    if (sdkLoaded) {
      const appId = import.meta.env.VITE_FACEBOOK_APP_ID;

      if (!appId) {
        throw new Error("VITE_FACEBOOK_APP_ID not set.");
      }

      (window as any).FB.init({
        appId: import.meta.env.VITE_FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: "v6.0"
      });
    }
  }, [sdkLoaded]);

  const handleLoginResponse = React.useCallback(
    (response: LoginResponse) => {
      console.log(response);

      if (response.status !== "connected") {
        console.log("connection failed.", response);
        return;
      }

      const userId = response.authResponse.userID;
      const userAccessToken = response.authResponse.accessToken;

      provision(userId, userAccessToken);
    },
    [provision]
  );

  const fblogin = React.useCallback(() => {
    if (!sdkLoaded) {
      console.error("sdk not loaded.");
      return;
    }

    (window as any).FB.login(handleLoginResponse, { scope: "pages_messaging" });
  }, [handleLoginResponse, sdkLoaded]);

  return (
    <button className="btn-blue" onClick={() => fblogin()}>
      Manage Pages
    </button>
  );
}
