import useApi from "@/hooks/useApi";
import React, { useState } from "react";
import Panel from "@/components/Panel";
import IconTrash from "@velaro/velaro-shared/src/icons/IconTrash";
import FormstackModal from "./FormstackModal";

interface FormstackConfig {
  displayName: string;
  token: string;
}

export default function Formstack() {
  const [config, setConfig] = React.useState<FormstackConfig>({
    displayName: "",
    token: ""
  });
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const api = useApi();

  const fetchConfig = React.useCallback(async () => {
    const response = await api.messaging.get(
      `Formstack/Integrations/GetConfig`
    );
    if (response.ok) {
      setConfig(await response.json());
    }
  }, [api.messaging]);

  React.useEffect(() => {
    fetchConfig();
  }, [fetchConfig]);

  async function handleSubmit(config: FormstackConfig) {
    const response = await api.messaging.post(
      "Formstack/Integrations/SaveConfig",
      {
        displayName: config.displayName,
        token: config.token
      }
    );
    if (response.ok) {
      setConfig(config);
    }
    setShowEditModal(false);
    setShowAddModal(false);
  }

  function handleModalClose() {
    setShowAddModal(false);
    setShowEditModal(false);
  }

  const deleteConfig = React.useCallback(async () => {
    if (confirm("are you sure you want to delete this integration?")) {
      await api.messaging.delete(`Formstack/Integrations/DeleteConfig`);
      setConfig({ displayName: "", token: "" });
    }
  }, [api.messaging]);

  return (
    <Panel title="Formstack Configuration">
      <div className="p-4">
        <table className="p-4 w-full bg-slate-50">
          <thead>
            <tr>
              <th className="border-b-[1px] p-2 text-left">Display Name</th>
              <th className="border-b-[1px] p-2 text-left">
                Formstack Access Token
              </th>
              <th className="border-b-[1px] p-2"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border-b-[1px] p-2">{config.displayName}</td>
              <td className="border-b-[1px] p-2">{config.token}</td>
              <td className="border-b-[1px] p-2 justify-center flex">
                {
                  <div
                    className="cursor-pointer"
                    onClick={() => deleteConfig()}
                  >
                    <IconTrash />
                  </div>
                }
              </td>
            </tr>
          </tbody>
        </table>
        {config.displayName == "" && config.token == "" && (
          <button
            type="submit"
            className="btn-green mt-4"
            onClick={() => setShowAddModal(true)}
          >
            Add New Formstack Configuration
          </button>
        )}
        {config.displayName != "" && config.token != "" && (
          <button
            type="submit"
            className="btn-green mt-4"
            onClick={() => setShowEditModal(true)}
          >
            Edit Formstack Configuration
          </button>
        )}
      </div>
      {showEditModal && !showAddModal && (
        <FormstackModal
          config={config}
          modalType="edit"
          handleSubmit={handleSubmit}
          handleModalClose={() => handleModalClose()}
        />
      )}
      {showAddModal && !showEditModal && (
        <FormstackModal
          config={config}
          modalType="add"
          handleSubmit={handleSubmit}
          handleModalClose={() => handleModalClose()}
        />
      )}
    </Panel>
  );
}
