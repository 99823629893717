import { uniqueId } from "lodash";
import React from "react";
import { FieldDefinition, FieldOption } from ".";
import OptionsControl from "./OptionsControl";


export const defaultfieldModel: FieldDefinition = {
  label: "New Field",
  type: "text",
  options: []
};

interface Props {
  field: FieldDefinition;
  onChange(field: Partial<FieldDefinition>): void;
}

export default function FieldForm({ field, onChange }: Props) {
  return (
    <>
    <div className="mb-3">
        <label htmlFor="label" className="block mb-1">
          Label
        </label>
        <input
        type="text"
          id="label"
          className="input w-full"
          autoFocus
          value={field.label}
          onChange={(e) => onChange({ label: e.currentTarget.value})}
        />
      </div>
    <div className="mb-3">
      <label htmlFor="type" className="block mb-1">
        Type
      </label>
      <select
        id="type"
        className="input w-full"
        value={field.type}
        onChange={(e) => onChange({ type: e.currentTarget.value as any })}
      >
      <option value="text">Textbox</option>
        <option value="checkbox">Checkbox</option>
        <option value="textarea">Textarea</option>
        <option value="select">Select List</option>
        </select>
    </div> 
    {(field.type === "select") && <div className="mb-3">
        <OptionsControl
          options={field.options}
          onChange={(o: Array<FieldOption>) => onChange({ options: o })}
        />
    </div>}    
      </>
  );
}
