import FormValidator from "@velaro/velaro-shared/src/components/FormValidator";
import useApi from "@/hooks/useApi";
import useAsync from "@/hooks/useAsync";
import useTeams from "@/hooks/useTeams";
import useToastAlert from "@/hooks/useToastAlert";
import IconArrowBack2 from "@/icons/IconArrowBack2";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import React from "react";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import { SecondaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";

interface Props {
  onClose(): void;
  onDeploymentAdded(): void;
}

interface AddDeploymentModel {
  displayName: string;
  deploymentId: string;
  teamId: number;
}

export default function EditDeploymentPanel(props: Props) {
  const { teams, getTeamNameById } = useTeams();

  const defaultDeploymentModel: AddDeploymentModel = {
    displayName: "",
    deploymentId: "",
    teamId: teams[0].id
  };

  const [deployment, setDeployment] = React.useState<AddDeploymentModel>(
    defaultDeploymentModel
  );
  const [selectedTeam, setSelectedTeam] = React.useState<number | null>(null);
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const api = useApi();
  const toastAlert = useToastAlert();

  const {
    execute: save,
    pending: saving,
    error: saveError
  } = useAsync({ asyncFunction: handleSubmit });

  async function handleSubmit() {
    if (deployment === null) return;

    setErrors({});

    const newErrors: Record<string, string> = {};

    if (deployment.displayName === "" || /^\s*$/.test(deployment.displayName)) {
      newErrors["DisplayName"] = "Display name required";
    }

    if (
      deployment.deploymentId === "" ||
      /^\s*$/.test(deployment.deploymentId)
    ) {
      newErrors["DeploymentId"] = "Deployment Id required";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length !== 0) {
      return;
    }

    const response = await api.messaging.post(`Deployment`, deployment);

    if (response.ok) {
      props.onDeploymentAdded();
      toastAlert.displayToast(
        Severity.Success,
        "Deployment updated successfully"
      );
    }

    if (response.status === 400) {
      const errorResponse = await response.json();
      if (typeof errorResponse.errors === "object") {
        setErrors(errorResponse.errors);
      }
      toastAlert.displayToast(Severity.Error, errorResponse);
    }

    if (response.status === 401) {
      toastAlert.displayToast(
        Severity.Error,
        "You do not have permission to edit this deployment"
      );
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDeployment({ ...deployment, [name]: value });
  };

  const handleTeamChange = (event: any) => {
    setSelectedTeam(event.target.value);
    setDeployment({ ...deployment, teamId: event.target.value });
  };

  return (
    <div>
      <div className="flex justify-between">
        <div className="mb-4 font-bold text-lg">Add Deployment</div>
        <div className="flex cursor-pointer" onClick={() => props.onClose()}>
          <IconArrowBack2 />
          <div className="ml-2 hover:underline">Back to all deployments</div>
        </div>
      </div>
      <div className="flex justify-center items-center h-full">
        <div className="panel bg-white dark:bg-stone-300 rounded-md shadow-md mb-4 p-4 pt-8 w-1/2">
          <div className="flex">
            <div className="w-1/3 font-semibold">Display name</div>
            <FormValidator error={errors["DisplayName"]} className="w-2/3">
              <input
                name="displayName"
                type="text"
                className="input w-full"
                autoFocus
                value={deployment?.displayName}
                onChange={handleChange}
              />
            </FormValidator>
          </div>
          <hr className="my-4 border-gray-100" />
          <div className="flex">
            <div className="w-1/3 font-semibold">Deployment Id</div>
            <FormValidator error={errors["DeploymentId"]} className="w-2/3">
              <input
                name="deploymentId"
                type="text"
                className="input w-full"
                value={deployment?.deploymentId}
                onChange={handleChange}
              />
            </FormValidator>
          </div>
          <hr className="my-4 border-gray-100" />
          <div className="flex">
            <div className="w-1/3 font-semibold">Team</div>
            <select className="input w-2/3" onChange={handleTeamChange}>
              {teams.map((team) => (
                <option key={team.id} value={team.id}>
                  {team.name}
                </option>
              ))}
            </select>
          </div>
          <hr className="my-4 border-gray-100" />
          <div className="py-4 flex mt-4">
            <div className="ml-auto flex">
              <div className="mr-2">
                <SecondaryBlueButton label="Cancel" onClick={props.onClose} />
              </div>
              <PrimaryBlueButton label="Save" onClick={save} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
