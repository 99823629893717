import FormValidator from "@velaro/velaro-shared/src/components/FormValidator";
import useApi from "@/hooks/useApi";
import useAsync from "@/hooks/useAsync";
import useRoleListItems from "@/hooks/useRoleListItems";
import useTeams from "@/hooks/useTeams";
import useToastAlert from "@/hooks/useToastAlert";
import IconArrowBack2 from "@/icons/IconArrowBack2";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import React, { useEffect } from "react";
import Select, { MultiValue } from "react-select";
import useSkillListItems from "@/hooks/useSkillListItems";
import {
  SecondaryBlueButton,
  PrimaryBlueButton
} from "@velaro/velaro-shared/src/components/Buttons/Buttons";

interface Props {
  userId: number;
  onClose(): void;
  onUserUpdated(): void;
}

export interface SkillOption {
  value: number;
  label: string;
}

interface EditUserModel {
  displayName: string;
  firstName: string;
  lastName: string;
  email: string;
  picture: string;
  teamIds: number[];
  roles: string[];
  licenseStatus: string;
  skillIds: number[];
}
const defaultUserModel: EditUserModel = {
  displayName: "",
  firstName: "",
  lastName: "",
  email: "",
  picture: "",
  teamIds: [],
  roles: [],
  licenseStatus: "",
  skillIds: []
};

interface RoleOption {
  value: string;
  label: string;
}

interface TeamOption {
  value: number;
  label: string;
}

export default function EditUserPanel(props: Props) {
  const [user, setUser] = React.useState<EditUserModel>(defaultUserModel);
  const [selectedTeams, setSelectedTeams] = React.useState<TeamOption[]>([]);
  const [selectedSkills, setSelectedSkills] = React.useState<SkillOption[]>([]);
  const [selectedRoles, setSelectedRoles] = React.useState<RoleOption[]>([]);
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const { teams, getTeamNameById } = useTeams();
  const { skills, getSkillNameById } = useSkillListItems();
  const { roles, getRoleNameById } = useRoleListItems();
  const api = useApi();
  const toastAlert = useToastAlert();

  useEffect(() => {
    async function fetchUser() {
      const response = api.messaging.get(`Users/${props.userId}`);
      const editUser: EditUserModel = await (await response).json();
      setUser(editUser);

      if (editUser.teamIds?.length !== 0) {
        const teams = editUser.teamIds.map((x) => ({
          value: x,
          label: getTeamNameById(x)
        }));

        setSelectedTeams(teams);
      }

      if (editUser.skillIds?.length !== 0) {
        const skills = editUser.skillIds.map((x) => ({
          value: x,
          label: getSkillNameById(x)
        }));

        setSelectedSkills(skills);
      }

      if (editUser.roles?.length !== 0 && editUser.roles !== null) {
        const roles = editUser.roles.map((x) => {
          const parsedNumber = parseInt(x);
          if (!isNaN(parsedNumber)) {
            return {
              value: x,
              label: getRoleNameById(parsedNumber)
            };
          } else {
            return { value: x, label: x };
          }
        });

        setSelectedRoles(roles);
      }
    }

    fetchUser();
  }, [
    api.messaging,
    getRoleNameById,
    getSkillNameById,
    getTeamNameById,
    props.userId
  ]);

  const {
    execute: save,
    pending: saving,
    error: saveError
  } = useAsync({ asyncFunction: handleSubmit });

  async function handleSubmit() {
    setErrors({});

    const newErrors: Record<string, string> = {};

    if (user.roles.length === 0) {
      newErrors["Roles"] = "Select at least one role";
    }

    if (user.teamIds.length === 0) {
      newErrors["TeamIds"] = "Select at least one team";
    }

    if (user.displayName === "" || /^\s*$/.test(user.displayName)) {
      newErrors["DisplayName"] = "Display name required";
    }

    if (user.email === "") {
      newErrors["Email"] = "Email required";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length !== 0) {
      return;
    }

    const response = await api.messaging.put(`Users/${props.userId}`, user);

    if (response.ok) {
      //props.onUserUpdated();
      toastAlert.displayToast(Severity.Success, "User updated successfully");
    }

    if (response.status === 400) {
      const errorResponse = await response.json();
      if (typeof errorResponse.errors === "object") {
        setErrors(errorResponse.errors);
      }
      toastAlert.displayToast(Severity.Error, errorResponse);
    }

    if (response.status === 401) {
      toastAlert.displayToast(
        Severity.Error,
        "You do not have permission to edit this user"
      );
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const teamOptions = teams.map((x) => ({ value: x.id, label: x.name }));
  const skillOptions = skills.map((x) => ({ value: x.id, label: x.name }));
  const roleOptions = roles.map((x) => ({ value: x.name, label: x.name }));

  function handleTeamChange(selectedOptions: MultiValue<TeamOption>) {
    setSelectedTeams(selectedOptions as TeamOption[]);
    const selectedTeamIds = (selectedOptions as TeamOption[]).map(
      (option) => option.value
    );
    setUser({ ...user, teamIds: selectedTeamIds as number[] });
  }

  function handleSkillChange(selectedOptions: MultiValue<SkillOption>) {
    setSelectedSkills(selectedOptions as SkillOption[]);
    const selectedSkillIds = (selectedOptions as SkillOption[]).map(
      (option) => option.value
    );
    setUser({ ...user, skillIds: selectedSkillIds });
  }

  function handleRoleChange(selectedOptions: MultiValue<RoleOption>) {
    setSelectedRoles(selectedOptions as RoleOption[]);

    const selectedRoleNames = (selectedOptions as RoleOption[]).map(
      (option) => option.value
    );

    setUser({ ...user, roles: selectedRoleNames as string[] });
  }

  return (
    <div>
      <div className="flex justify-between">
        <div className="mb-4 font-bold text-lg"></div>
        <div className="flex cursor-pointer" onClick={() => props.onClose()}>
          <IconArrowBack2 />
          <div className="ml-2 hover:underline">Back to User Profile</div>
        </div>
      </div>
      <div className="flex justify-center items-center h-full">
        <div className="panel bg-white dark:bg-stone-300 rounded-md shadow-md mb-4 p-4 w-1/2">
          <div className="flex items-center">
            <div className="w-12 h-12 relative z-0 flex-grow-0 flex-shrink-0">
              <img
                src={user?.picture}
                alt=""
                className="rounded-full w-12 h-12 absolute z-10"
              />
            </div>
            <div className="pl-4">
              <div className="font-bold">
                {user?.firstName + " " + user?.lastName}
              </div>
              <div className="text-sm text-slate-500">
                {user?.licenseStatus}
              </div>
            </div>
          </div>
          <hr className="my-4 border-gray-100" />
          <div className="flex">
            <div className="w-1/3 font-semibold">First Name</div>
            <input
              name="firstName"
              type="text"
              className="input w-2/3"
              autoFocus
              value={user?.firstName}
              onChange={handleChange}
            />
          </div>
          <hr className="my-4 border-gray-100" />
          <div className="flex">
            <div className="w-1/3 font-semibold">Last Name</div>
            <input
              name="lastName"
              type="text"
              className="input w-2/3"
              autoFocus
              value={user?.lastName}
              onChange={handleChange}
            />
          </div>
          <hr className="my-4 border-gray-100" />
          <div className="flex">
            <div className="w-1/3 font-semibold">Email</div>
            <FormValidator error={errors["Email"]} className="w-2/3">
              <input
                name="email"
                type="text"
                className="input w-full"
                autoFocus
                value={user?.email}
                onChange={handleChange}
              />
            </FormValidator>
          </div>
          <hr className="my-4 border-gray-100" />
          <div className="flex">
            <div className="w-1/3 font-semibold">Display name</div>
            <FormValidator error={errors["DisplayName"]} className="w-2/3">
              <input
                name="displayName"
                type="text"
                className="input w-full"
                autoFocus
                value={user?.displayName}
                onChange={handleChange}
              />
            </FormValidator>
          </div>
          <hr className="my-4 border-gray-100" />
          <div className="flex items-center">
            <div className="w-1/3 font-semibold">Roles</div>
            <FormValidator error={errors["Roles"]} className="w-2/3">
              <Select
                isMulti
                name="roles"
                options={roleOptions}
                value={selectedRoles}
                className="basic-multi-select w-full"
                classNamePrefix="select"
                onChange={(e) => handleRoleChange(e)}
              />
            </FormValidator>
          </div>
          <hr className="my-4 border-gray-100" />
          <div className="flex items-center">
            <div className="w-1/3 font-semibold">Teams</div>
            <FormValidator error={errors["TeamIds"]} className="w-2/3">
              <Select
                isMulti
                name="teams"
                options={teamOptions}
                value={selectedTeams}
                className="basic-multi-select w-full"
                classNamePrefix="select"
                onChange={(e) => handleTeamChange(e)}
              />
            </FormValidator>
          </div>
          <hr className="my-4 border-gray-100" />
          <div className="flex items-center">
            <div className="w-1/3 font-semibold">Skills</div>
            <FormValidator error={errors["SkillIds"]} className="w-2/3">
              <Select
                isMulti
                name="skills"
                options={skillOptions}
                value={selectedSkills}
                className="basic-multi-select w-full"
                classNamePrefix="select"
                onChange={(e) => handleSkillChange(e)}
              />
            </FormValidator>
          </div>
          {/* <div className="flex">
          <div className="w-1/3 font-semibold">Roles</div>
          <input
            disabled
            name="email"
            type="text"
            className="input w-2/3"
            autoFocus
            value={"Admin"}
            //onChange={(e) => props.onChange({ name: e.currentTarget.value })}
          />
        </div> */}
          {/* <div className="flex ">
          <div className="w-1/3 font-semibold flex-1">Teams</div>
          <input
            id="fullName"
            type="text"
            className="input w-2/3"
            autoFocus
            value={getTeams(user)}
            onChange={(e) => props.onChange({ name: e.currentTarget.value })}
          />
        </div> */}
          {/* <hr className="my-4 border-gray-100" />
        <div className="flex">
          <div className="w-1/3 font-semibold">Skills</div>
          <input
            id="fullName"
            type="text"
            className="input w-2/3"
            autoFocus
            value={user?.name}
            onChange={(e) => props.onChange({ name: e.currentTarget.value })}
          />
        </div>
        <hr className="my-4 border-gray-100" />
        <div className="flex">
          <div className="w-1/3 font-semibold">Schedule</div>
          <input
            id="fullName"
            type="text"
            className="input w-2/3"
            autoFocus
            value={user?.name}
            onChange={(e) => props.onChange({ name: e.currentTarget.value })}
          />
        </div>
        <hr className="my-4 border-gray-100" />
        <div className="flex">
          <div className="w-1/3 font-semibold">Chat Limit</div>
          <input
            id="fullName"
            type="text"
            className="input w-2/3"
            autoFocus
            value={user?.name}
            onChange={(e) => props.onChange({ name: e.currentTarget.value })}
          />
        </div>
        <hr className="my-4 border-gray-100" /> */}
          <div className="py-4 flex mt-4">
            <div className="ml-auto flex">
              <div className="mr-2">
                <SecondaryBlueButton
                  onClick={() => props.onClose()}
                  label="Cancel"
                />
              </div>
              <PrimaryBlueButton onClick={save} label="Save Changes" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
