import * as React from "react";
import ShiftCalendarView from "./ShiftCalendarView";
import { WorkSchedule } from "./types";

import "./ScheduleRow.css";

interface Props {
  row: number;
  schedule: WorkSchedule;
  onSelectSchedule: () => void;
  onToggleScheduleEnabled: () => void;
}

export default function ScheduleRow(props: Props) {
  const scheduleDisabled = !props.schedule.enabled;

  function renderTeamText() {
    // const teams = props.schedule.teams;
    // const count = teams.length;
    const count = 0;

    if (!count) {
      return null;
      // return (
      //   <span
      //     className="no-teams-text"
      //     style={{
      //       color: scheduleDisabled ? "grey" : "black"
      //     }}
      //   >
      //     <i className="fa fa-exclamation-circle" />
      //     No teams
      //   </span>
      // );
    }

    let text = "teams";
    if (count === 1) {
      text = "team";
    }

    return (
      <span
        className="team-text"
        style={{
          color: scheduleDisabled ? "grey" : "black"
        }}
      >
        {count} {text}
        <span className="team-text-tooltip">
          {/* <ul>
            {teams.map((teamId, i) => (
              <li key={i}>{App.teams.getNameForTeam(teamId)}</li>
            ))}
          </ul> */}
        </span>
      </span>
    );
  }

  const backgroundColor = props.row % 2 === 0 ? "#f1f1f1" : "white";

  return (
    <React.Fragment>
      <div
        style={{
          paddingLeft: "5px",
          position: "relative",
          gridColumnStart: "select",
          gridRowStart: props.row,
          backgroundColor: backgroundColor
        }}
      >
        {/* <input
          type="checkbox"
          className="form-control"
          checked={props.schedule.enabled}
          onChange={props.onToggleScheduleEnabled}
        /> */}
      </div>
      <div
        className="name-col"
        style={{
          gridRowStart: props.row,
          backgroundColor: backgroundColor
        }}
      >
        <button
          className="underline"
          disabled={scheduleDisabled}
          onClick={props.onSelectSchedule}
        >
          {props.schedule.name}
        </button>
      </div>
      <div
        className="teams-col"
        style={{
          gridRowStart: props.row,
          backgroundColor: backgroundColor
        }}
      >
        {renderTeamText()}
      </div>
      <div
        className="calendar-col"
        style={{
          gridRowStart: props.row,
          backgroundColor: backgroundColor
        }}
      >
        <ShiftCalendarView
          shifts={props.schedule.shifts}
          scheduleDisabled={scheduleDisabled}
        />
      </div>
    </React.Fragment>
  );
}
