import React from "react";
import { IconProps } from "./types";

function IconEditPencil3(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      stroke="#64748B"
      {...props}
    >
      <path
        d="M12 20.5002H21M3 20.5002H4.67454C5.16372 20.5002 5.40832 20.5002 5.63849 20.445C5.84256 20.396 6.03765 20.3152 6.2166 20.2055C6.41843 20.0818 6.59138 19.9089 6.93729 19.563L19.5 7.00023C20.3285 6.1718 20.3285 4.82865 19.5 4.00023C18.6716 3.1718 17.3285 3.1718 16.5 4.00023L3.93726 16.563C3.59136 16.9089 3.4184 17.0818 3.29472 17.2837C3.18506 17.4626 3.10425 17.6577 3.05526 17.8618C3 18.0919 3 18.3365 3 18.8257V20.5002Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconEditPencil3;
