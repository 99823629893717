import { IconProps } from "@/icons/types";
import React from "react";

function IllustrationNewAiChatBot(props: IconProps) {
  return (
    <svg
      width="130"
      height="130"
      viewBox="0 0 130 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="velaro_ai-chatbot-build-it 1">
        <path
          id="Vector"
          d="M34.0522 12.5293L98.267 14.6171C108.976 14.9655 117.907 23.2257 118.742 33.0823L122.907 82.3055C123.742 92.1621 116.327 101.806 105.828 103.951L42.8766 116.802C27.651 119.912 13.6292 112.148 12.5372 99.2601L7.09021 34.9153C6.00081 22.0297 18.5198 12.0197 34.0496 12.5241L34.0522 12.5293Z"
          fill="#EEF4FD"
        />
        <path
          id="Vector_2"
          d="M36.6418 95.6644L15.3998 123.973L9.08441 118.843L23.0594 86.8062L36.6418 95.6644Z"
          fill="black"
        />
        <path
          id="Vector_3"
          d="M66.8382 78.0286L94.0524 60.0444L100.534 66.443L76.6974 92.2324L66.8382 78.0286Z"
          fill="black"
        />
        <path
          id="Vector_4"
          d="M22.8644 85.3656L69.173 74.7603L72.345 104.998L37.8898 114.917L22.8644 85.3656Z"
          fill="black"
        />
        <path
          id="Vector_5"
          d="M68.7659 57.7481C68.8583 47.1685 57.1754 38.4894 42.6715 38.3628C28.1676 38.2363 16.3351 46.7101 16.2427 57.2897C16.1504 67.8693 27.8333 76.5484 42.3372 76.675C56.841 76.8016 68.6736 68.3277 68.7659 57.7481Z"
          fill="black"
        />
        <path
          id="Vector_6"
          d="M66.8382 78.0286L94.0524 60.0444L97.5182 65.8918L74.4354 89.9288L66.8382 78.0286Z"
          fill="#629FF0"
        />
        <path
          id="Vector_7"
          d="M98.8156 66.04L74.2716 91.6006L65.4108 77.7244L94.3878 58.5728L98.813 66.04H98.8156ZM74.6018 88.2544L96.2182 65.741L93.7118 61.5134L68.263 78.3328L74.5992 88.2544H74.6018Z"
          fill="black"
        />
        <path
          id="Vector_8"
          d="M34.4735 94.7074L15.1763 121.006L9.90088 116.722L23.2233 86.1768L34.4735 94.7074Z"
          fill="#629FF0"
        />
        <path
          id="Vector_9"
          d="M15.3686 122.504L8.62939 117.031L22.7968 84.5493L35.9164 94.4969L15.3686 122.502V122.504ZM11.1696 116.412L14.9838 119.512L33.0278 94.9181L23.647 87.8045L11.167 116.412H11.1696Z"
          fill="black"
        />
        <path
          id="Vector_10"
          d="M70.1351 56.1431C70.2275 45.5635 58.5446 36.8844 44.0407 36.7579C29.5368 36.6313 17.7043 45.1051 17.6119 55.6847C17.5196 66.2644 29.2025 74.9434 43.7064 75.07C58.2102 75.1966 70.0428 66.7227 70.1351 56.1431Z"
          fill="#AFCFF8"
        />
        <path
          id="Vector_11"
          d="M44.0232 76.1097C43.914 76.1097 43.8048 76.1097 43.6956 76.1097C36.4624 76.0473 29.6686 73.9231 24.5648 70.1323C19.3622 66.2687 16.523 61.1337 16.5724 55.6763C16.6686 44.5405 29.0082 35.5887 44.0492 35.7187C51.2824 35.7811 58.0762 37.9053 63.18 41.6961C68.3826 45.5597 71.2218 50.6947 71.1724 56.1521C71.0762 67.2073 58.9264 76.1123 44.0232 76.1123V76.1097ZM43.719 37.7961C29.9546 37.7961 18.7382 45.7781 18.6498 55.6945C18.6082 60.4707 21.1484 65.0051 25.8024 68.4631C30.5552 71.9939 36.9148 73.9725 43.7112 74.0297C43.8152 74.0297 43.9192 74.0297 44.0232 74.0297C57.7876 74.0297 69.004 66.0477 69.0924 56.1313C69.134 51.3551 66.5938 46.8207 61.9398 43.3627C57.187 39.8319 50.8274 37.8533 44.031 37.7961C43.927 37.7961 43.823 37.7961 43.719 37.7961Z"
          fill="black"
        />
        <path
          id="Vector_12"
          d="M57.3196 63.5154L29.6868 63.2762L28.5922 47.6606L59.4204 47.9284L57.3196 63.5154Z"
          fill="white"
        />
        <path
          id="Vector_13"
          d="M58.227 64.5634L28.717 64.306L27.4794 46.6104L60.6112 46.899L58.2296 64.5634H58.227ZM30.6566 62.2441L56.4122 62.4677L58.2348 48.9582L29.7102 48.7085L30.6566 62.2415V62.2441Z"
          fill="black"
        />
        <path
          id="Vector_14"
          d="M69.9808 30.6075L69.0905 56.1006L71.1692 56.1732L72.0595 30.6801L69.9808 30.6075Z"
          fill="black"
        />
        <path
          id="Vector_15"
          d="M71.11 30.7945C73.8426 30.7945 76.0578 28.5793 76.0578 25.8467C76.0578 23.1141 73.8426 20.8989 71.11 20.8989C68.3774 20.8989 66.1622 23.1141 66.1622 25.8467C66.1622 28.5793 68.3774 30.7945 71.11 30.7945Z"
          fill="white"
        />
        <path
          id="Vector_16"
          d="M71.1204 31.8369C70.6628 31.8369 70.2 31.7849 69.7424 31.6757C68.185 31.3117 66.8642 30.3601 66.0192 29.0003C65.1768 27.6405 64.9142 26.0337 65.2782 24.4789C66.0322 21.2653 69.2614 19.2633 72.475 20.0173C74.0324 20.3813 75.3532 21.3329 76.1982 22.6927C77.0406 24.0525 77.3032 25.6593 76.9392 27.2141C76.5752 28.7715 75.6236 30.0923 74.2638 30.9347C73.3044 31.5301 72.2202 31.8369 71.1204 31.8369ZM71.11 21.9387C69.3368 21.9387 67.7274 23.1555 67.3062 24.9547C67.067 25.9713 67.2386 27.0191 67.7898 27.9057C68.341 28.7923 69.2016 29.4137 70.2182 29.6503C71.2348 29.8895 72.2826 29.7179 73.1692 29.1667C74.0558 28.6155 74.6772 27.7549 74.9138 26.7383C75.153 25.7217 74.9814 24.6739 74.4302 23.7873C73.879 22.9007 73.0184 22.2793 72.0018 22.0427C71.7028 21.9725 71.4038 21.9387 71.11 21.9387Z"
          fill="black"
        />
        <path
          id="Vector_17"
          d="M40.5407 55.2253C40.5553 53.5568 39.5462 52.1953 38.2869 52.1843C37.0277 52.1733 35.995 53.517 35.9805 55.1855C35.9659 56.854 36.9749 58.2155 38.2342 58.2264C39.4935 58.2374 40.5261 56.8938 40.5407 55.2253Z"
          fill="black"
        />
        <path
          id="Vector_18"
          d="M51.0034 55.3175C51.018 53.649 50.0089 52.2875 48.7496 52.2766C47.4904 52.2656 46.4577 53.6092 46.4432 55.2777C46.4286 56.9462 47.4376 58.3077 48.6969 58.3187C49.9562 58.3297 50.9888 56.986 51.0034 55.3175Z"
          fill="black"
        />
        <path
          id="Vector_19"
          d="M24.9886 84.7157L69.173 74.7603L72.345 104.998L37.9626 111.704L24.9886 84.7157Z"
          fill="#AFCFF8"
        />
        <path
          id="Vector_20"
          d="M37.3724 112.879L23.4858 83.9876L70.0856 73.4888L73.4786 105.835L37.3724 112.876V112.879ZM26.4914 85.4436L38.5502 110.531L71.2088 104.161L68.2604 76.0342L26.4914 85.4436Z"
          fill="black"
        />
        <path
          id="Vector_21"
          d="M60.1874 89.7261L42.2994 94.8013L44.6212 110.406L67.0436 106.033L60.1874 89.7261Z"
          fill="white"
        />
        <path
          id="Vector_22"
          d="M43.7528 111.636L41.1372 94.0524L60.7906 88.478L68.4996 106.813L43.7554 111.639L43.7528 111.636ZM43.4616 95.5526L45.4896 109.177L65.5876 105.256L59.5842 90.9766L43.4616 95.55V95.5526Z"
          fill="black"
        />
        <path
          id="Vector_23"
          d="M63.8541 97.91L43.3201 102.002L43.7266 104.042L64.2606 99.9499L63.8541 97.91Z"
          fill="black"
        />
        <path
          id="Vector_24"
          d="M9.789 62.1424C5.967 55.4786 10.361 50.7076 10.4052 50.6582L11.921 52.083C11.7832 52.2338 8.5592 55.8166 11.5934 61.105L9.789 62.1398V62.1424Z"
          fill="black"
        />
        <path
          id="Vector_25"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M81.8428 6.02686C82.2432 6.02686 82.5942 6.29206 82.7034 6.67686L83.6732 10.0725C83.8812 10.8031 84.2738 11.4687 84.812 12.0069C85.3502 12.5451 86.0158 12.9351 86.7464 13.1457L90.1446 14.1181C90.5294 14.2273 90.7946 14.5783 90.7946 14.9787C90.7946 15.3791 90.5294 15.7301 90.1446 15.8393L86.749 16.8091C86.0184 17.0171 85.3528 17.4097 84.8146 17.9479C84.2764 18.4861 83.8864 19.1517 83.6758 19.8823C83.6732 19.8953 83.668 19.9057 83.6654 19.9187L82.706 23.2805C82.5968 23.6653 82.2458 23.9305 81.8454 23.9305C81.445 23.9305 81.094 23.6653 80.9848 23.2805L80.015 19.8849C79.807 19.1543 79.4144 18.4887 78.8762 17.9505C78.338 17.4123 77.6724 17.0223 76.9418 16.8117L73.5436 15.8393C73.1588 15.7301 72.8936 15.3791 72.8936 14.9787C72.8936 14.5783 73.1588 14.2273 73.5436 14.1181L76.9392 13.1483C77.6698 12.9403 78.3354 12.5477 78.8736 12.0095C79.4118 11.4713 79.8018 10.8057 80.0124 10.0751L80.9848 6.67686C81.094 6.29206 81.445 6.02686 81.8454 6.02686H81.8428ZM81.8428 10.1791L81.7336 10.5665C81.4398 11.5909 80.8938 12.5217 80.1398 13.2757C79.3858 14.0271 78.455 14.5757 77.4306 14.8695L77.0458 14.9787L77.4332 15.0879C78.4576 15.3817 79.3884 15.9277 80.1424 16.6817C80.8964 17.4331 81.4424 18.3665 81.7362 19.3909L81.8454 19.7757L81.9546 19.3909C81.9572 19.3779 81.9624 19.3649 81.9676 19.3519C82.264 18.3431 82.8074 17.4253 83.551 16.6817C84.305 15.9303 85.2358 15.3817 86.2602 15.0879L86.645 14.9787L86.2576 14.8695C85.2332 14.5757 84.3024 14.0297 83.5484 13.2757C82.7944 12.5243 82.2484 11.5909 81.9546 10.5665L81.8454 10.1817L81.8428 10.1791Z"
          fill="#629FF0"
        />
        <path
          id="Vector_26"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.8792 72.9248C17.0716 72.9248 17.2432 73.0522 17.2952 73.2394L17.7658 74.8826C17.8672 75.2362 18.057 75.5586 18.317 75.8186C18.577 76.0786 18.8994 76.2684 19.253 76.3698L20.8962 76.8404C21.0834 76.8924 21.2108 77.064 21.2108 77.2564C21.2108 77.4488 21.0834 77.6204 20.8962 77.6724L19.253 78.143C18.8994 78.2444 18.577 78.4342 18.317 78.6942C18.057 78.9542 17.8672 79.2766 17.7658 79.6302C17.7658 79.6354 17.7632 79.6432 17.7606 79.6484L17.2952 81.276C17.2432 81.4632 17.0716 81.5906 16.8792 81.5906C16.6868 81.5906 16.5152 81.4632 16.4632 81.276L15.9926 79.6328C15.8912 79.2792 15.7014 78.9568 15.4414 78.6968C15.1814 78.4368 14.859 78.247 14.5054 78.1456L12.8622 77.675C12.675 77.623 12.5476 77.4514 12.5476 77.259C12.5476 77.0666 12.675 76.895 12.8622 76.843L14.5054 76.3724C14.859 76.271 15.1814 76.0812 15.4414 75.8212C15.7014 75.5612 15.8912 75.2388 15.9926 74.8852L16.4632 73.242C16.5152 73.0548 16.6868 72.9274 16.8792 72.9274V72.9248Z"
          fill="#C1B4D9"
        />
        <path
          id="Vector_27"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M91.572 20.9585C91.8164 20.9585 92.0322 21.1197 92.0972 21.3563L92.69 23.4311C92.8174 23.8783 93.0566 24.2839 93.3868 24.6141C93.7144 24.9417 94.1226 25.1809 94.5698 25.3109L96.6472 25.9037C96.8812 25.9713 97.045 26.1845 97.045 26.4289C97.045 26.6733 96.8838 26.8865 96.6472 26.9541L94.5724 27.5469C94.1252 27.6743 93.7196 27.9135 93.3894 28.2437C93.0618 28.5713 92.82 28.9795 92.6926 29.4267C92.6926 29.4345 92.6874 29.4423 92.6848 29.4501L92.0972 31.5041C92.0296 31.7381 91.8164 31.9019 91.572 31.9019C91.3276 31.9019 91.1118 31.7407 91.0468 31.5041L90.454 29.4293C90.3266 28.9821 90.0874 28.5765 89.7572 28.2463C89.4296 27.9187 89.0214 27.6795 88.5742 27.5495L86.4968 26.9567C86.2628 26.8891 86.099 26.6759 86.099 26.4315C86.099 26.1871 86.2602 25.9739 86.4968 25.9063L88.5716 25.3135C89.0188 25.1861 89.4244 24.9469 89.7546 24.6167C90.0822 24.2891 90.324 23.8809 90.4514 23.4337L91.0442 21.3563C91.1118 21.1223 91.325 20.9585 91.5694 20.9585H91.572Z"
          fill="#896FB8"
        />
        <path
          id="Vector_28"
          d="M98.826 39.1142L87.5498 37.8506L92.9474 46.9896L90.7114 49.8652L83.1714 44.673L85.9898 56.2664L96.018 54.384L106.311 63.0576C105.563 67.072 108.056 71.0318 112.07 72.0432C116.246 73.0962 120.487 70.5638 121.54 66.3882C122.593 62.2126 120.06 57.972 115.885 56.919C114.166 56.4848 112.437 56.6616 110.924 57.3064L99.281 49.0878L98.8312 39.1116L98.826 39.1142ZM117.065 65.291C116.662 66.8874 115.042 67.8546 113.448 67.4516C111.852 67.0486 110.885 65.4288 111.288 63.835C111.691 62.2386 113.311 61.2714 114.904 61.6744C116.501 62.0774 117.468 63.6972 117.065 65.291Z"
          fill="black"
        />
        <path
          id="Vector_29"
          d="M99.0574 37.5234L87.5472 37.851L93.6104 44.2574L90.3604 48.4356L82.8204 43.2434L85.7064 55.1176L95.7346 53.2352L105.96 61.6254C105.212 65.6398 107.705 69.5996 111.719 70.611C115.895 71.664 120.136 69.1316 121.189 64.956C122.242 60.7804 119.709 56.5398 115.534 55.4868C113.815 55.0526 112.086 55.2294 110.573 55.8742L98.93 47.6556L99.06 37.5156L99.0574 37.5234ZM116.719 63.8614C116.316 65.4578 114.696 66.425 113.103 66.022C111.506 65.619 110.539 63.9992 110.942 62.4054C111.345 60.809 112.965 59.8418 114.559 60.2448C116.152 60.6478 117.122 62.2676 116.719 63.8614Z"
          fill="#AFCFF8"
        />
        <path
          id="Vector_30"
          d="M113.641 71.6351C112.936 71.6351 112.229 71.5467 111.53 71.3725C107.302 70.3065 104.562 66.2245 105.121 61.9501L95.5214 54.0721L85.124 56.0221L81.5802 41.4439L90.1992 47.3771L92.586 44.3065L85.787 37.1201L99.8504 36.7197L99.7152 47.2601L110.679 54.9977C112.302 54.3997 114.036 54.3087 115.726 54.7351C117.949 55.2941 119.818 56.6877 120.994 58.6533C122.169 60.6215 122.507 62.9277 121.948 65.1481C121.389 67.3711 119.995 69.2405 118.03 70.4157C116.683 71.2217 115.175 71.6325 113.646 71.6325L113.641 71.6351ZM95.9478 52.4055L106.811 61.3209L106.725 61.7733C106.046 65.4029 108.324 68.9571 111.909 69.8593C113.727 70.3169 115.614 70.0413 117.224 69.0793C118.833 68.1173 119.972 66.5885 120.429 64.7685C120.887 62.9511 120.611 61.0635 119.649 59.4541C118.687 57.8447 117.159 56.7059 115.339 56.2483C113.838 55.8687 112.294 55.9909 110.874 56.5967L110.474 56.7683L98.1396 48.0635L98.2644 38.3265L89.31 38.5813L94.6348 44.2077L90.5216 49.4961L84.058 45.0449L86.2862 54.2151L95.9452 52.4029L95.9478 52.4055ZM113.828 66.8927C113.524 66.8927 113.217 66.8563 112.91 66.7783C110.9 66.2713 109.676 64.2251 110.183 62.2127C110.69 60.2029 112.736 58.9783 114.748 59.4853C116.758 59.9923 117.983 62.0385 117.476 64.0509C117.047 65.7539 115.51 66.8927 113.831 66.8927H113.828ZM113.831 60.9335C112.848 60.9335 111.948 61.5991 111.699 62.5949C111.402 63.7701 112.117 64.9687 113.292 65.2651C114.468 65.5615 115.666 64.8465 115.963 63.6713C116.259 62.4961 115.544 61.2975 114.369 61.0011C114.189 60.9569 114.01 60.9335 113.831 60.9335Z"
          fill="black"
        />
        <path
          id="Vector_31"
          d="M99.3216 51.3082L98.5494 52.354L106.035 57.8812L106.808 56.8354L99.3216 51.3082Z"
          fill="black"
        />
      </g>
    </svg>
  );
}

export default IllustrationNewAiChatBot;
