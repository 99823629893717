import React from "react";
import { RuleValue } from "../types";
import AIRuleform, { UseAIValue } from "./AIRuleForm";
import AutomationRuleForm, { UseAutomationValue } from "./AutomationRuleForm";
import AutoResolveChatRuleForm, {
  AutoResolveChatValue
} from "./AutoResolveChatRuleForm";
import CreateCrmRecordRuleForm, {
  CreateCrmRecordValue
} from "./CreateCrmRecordRuleForm";
import LanguageConfigRuleForm, {
  LanguageConfigValue
} from "./LanguageConfigRuleForm";
import StyleConfigRuleForm, { StyleConfigValue } from "./StyleConfigRuleForm";

interface Props {
  trigger: string;
  value: RuleValue;
  updateValue: (value: RuleValue) => void;
}

export default function RuleFormFactory({
  trigger,
  value,
  updateValue
}: Props) {
  switch (trigger) {
    case "":
      return <span>Please select a trigger</span>;
    case "use-ai":
      return (
        <AIRuleform updateValue={updateValue} value={value as UseAIValue} />
      );
    case "use-automation":
      return (
        <AutomationRuleForm
          updateValue={updateValue}
          value={value as UseAutomationValue}
        />
      );
    case "resolve-chat":
      return (
        <AutoResolveChatRuleForm
          updateValue={updateValue}
          value={value as AutoResolveChatValue}
        />
      );
    case "create-crm-record":
      return (
        <CreateCrmRecordRuleForm
          updateValue={updateValue}
          value={value as CreateCrmRecordValue}
        />
      );
    case "determine-language-config":
      return (
        <LanguageConfigRuleForm
          updateValue={updateValue}
          value={value as LanguageConfigValue}
        />
      );
    case "determine-style-config":
      return (
        <StyleConfigRuleForm
          updateValue={updateValue}
          value={value as StyleConfigValue}
        />
      );
    default:
      return <div>no form for {trigger}</div>;
  }
}
