import Modal from "@velaro/velaro-shared/src/components/Modal";
import IconNotificationsIndicator from "@/icons/IconNotificationsIndicator";
import React from "react";
import {
  SecondaryBlueButton,
  PrimaryBlueButton
} from "@velaro/velaro-shared/src/components/Buttons/Buttons";

interface Props {
  show: boolean;
  onClose(): void;
  onDeactivate(): void;
}

export default function DeactivateDeploymentModal(props: Props) {
  function handleSubmit() {
    props.onDeactivate();
    props.onClose();
  }

  return (
    <Modal show={props.show}>
      <Modal.Body>
        <div className="flex justify-center p-8">
          <div>
            <div className="flex justify-center">
              <IconNotificationsIndicator className="w-8 h-8 text-sweet-orange-500" />
            </div>
            <div className="m-4">
              Are you sure you want to deactivate this deployment?
            </div>
            <div className="p-4 flex mt-8 justify-center">
              <div className="mr-2">
                <SecondaryBlueButton
                  label="Cancel"
                  onClick={() => props.onClose()}
                />
              </div>
              <PrimaryBlueButton
                onClick={() => handleSubmit()}
                label="Yes, deactivate deployment"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
