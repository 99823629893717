import AuthService from "../services/AuthService";

interface Config {
  rootUrl: string;
  tokenAccessor: () => string;
  includeSiteId?: boolean;
}

export default class RestService {
  rootUrl = "";
  tokenAccessor: () => string = () => "";
  includeSiteId: boolean = true;

  constructor(config: Config) {
    this.rootUrl = config.rootUrl;
    this.tokenAccessor = config.tokenAccessor;
    this.includeSiteId = config.includeSiteId ?? true;
  }

  get accessToken() {
    return this.tokenAccessor();
  }

  getCommonHeaders() {
    const headers: any = {
      Authorization: `Bearer ${this.accessToken}`
    };
    if (this.includeSiteId) {
      headers.SiteId = AuthService.profile.siteId.toString();
    }

    return headers;
  }

  get(endpoint: string, data?: any) {
    return fetch(`${this.rootUrl}/${endpoint}`, {
      method: "get",
      body: data ? JSON.stringify(data) : undefined,
      headers: {
        "Content-Type": "application/json",
        ...this.getCommonHeaders()
      }
    });
  }

  post(endpoint: string, data?: any) {
    return fetch(`${this.rootUrl}/${endpoint}`, {
      method: "post",
      body: data ? JSON.stringify(data) : undefined,
      headers: {
        "Content-Type": "application/json",
        ...this.getCommonHeaders()
      }
    });
  }

  postFormData(endpoint: string, data: FormData) {
    return fetch(`${this.rootUrl}/${endpoint}`, {
      method: "post",
      body: data,
      headers: {
        ...this.getCommonHeaders()
      }
    });
  }

  put(endpoint: string, data?: any) {
    return fetch(`${this.rootUrl}/${endpoint}`, {
      method: "put",
      body: data ? JSON.stringify(data) : undefined,
      headers: {
        "Content-Type": "application/json",
        ...this.getCommonHeaders()
      }
    });
  }

  delete(endpoint: string, data?: any) {
    return fetch(`${this.rootUrl}/${endpoint}`, {
      method: "delete",
      body: data ? JSON.stringify(data) : undefined,
      headers: {
        "Content-Type": "application/json",
        ...this.getCommonHeaders()
      }
    });
  }
}
