import useAIConfigs from "@/hooks/useAIConfigs";
import useApi from "@/hooks/useApi";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AIConfiguration } from "../types";

export default function CreateChatbotButton() {
  const { aiConfigurations, add, update } = useAIConfigs();
  const api = useApi();
  const [aiAdded, setAiAdded] = React.useState(false);
  const navigate = useNavigate();

  function handleSave() {
    add();
    setAiAdded(true);
  }

  useEffect(() => {
    const saveAi = async () => {
      console.log("aiAdded useeffect", aiAdded);
      if (aiAdded) {
        const response = await api.messaging.post(
          "AIConfiguration",
          aiConfigurations[aiConfigurations.length - 1]
        );
        const config = await response.json();
        update(config, aiConfigurations.length - 1);

        //navigate to workflows/id
        navigate(`/ai/settings/general/${config.id}`);
        setAiAdded(false);
      }
    };
    saveAi();
  }, [aiAdded, aiConfigurations, api.messaging, navigate, update]);

  return (
    <PrimaryBlueButton
      label="+ Create Chatbot"
      onClick={() => {
        handleSave();
      }}
    />
  );
}
