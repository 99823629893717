import { useEffect, useState } from "react";
import useApi from "./useApi";
import { Tag } from "@/pages/Messaging/Tags/types";

export default function useContactTags(contactId?: number) {
  const [all, setAll] = useState<Tag[]>([]);
  const [loading, setLoading] = useState(true);
  //const [selected, setSelected] = useState<Tag[]>([]);
  const api = useApi();

  useEffect(() => {
    async function fetchAll() {
      const resp = await api.messaging.get("Tags/Contacts");
      setAll(await resp.json());
      setLoading(false);
    }

    fetchAll();
  }, [api.messaging]);

  function handleDelete(id: number) {
    const resp = api.messaging.delete(`Tags/Contact/Delete/${id}`);
    setAll(all.filter((tag) => tag.id !== id));
  }

  // useEffect(() => {
  //   async function fetchSelected() {
  //     if (!contactId) {
  //       return;
  //     }
  //     const resp = await api.messaging.get(
  //       `TagAssociation/Contact/Selected?contactId=${contactId}`
  //     );
  //     setSelected(await resp.json());
  //   }

  //   fetchSelected();
  // }, [api.messaging, contactId]);

  // function save(tags: Tag[]) {
  //   if (!contactId) {
  //     return;
  //   }
  //   setSelected([...selected, ...tags]);

  //   api.messaging.post("TagAssociation/Contact/Associate", {
  //     contactId,
  //     contactTags: tags
  //   });
  // }

  // function remove(tag: Tag) {
  //   if (!contactId) {
  //     return;
  //   }
  //   setSelected(selected.filter((t) => t.id !== tag.id));

  //   api.messaging.post("TagAssociation/Contact/Remove", {
  //     contactId,
  //     tagId: tag.id
  //   });
  // }

  return { all, loading, handleDelete };
}
