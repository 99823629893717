import React, { useEffect, useLayoutEffect, useState } from "react";

interface Props {
  onDrop(files: File[]): void;
}

export default function FileDropHandler(props: Props) {
  // Because of this webkit but, dragenter and dragleave fire every time a file is dragged over a child
  // element. The best solution appears to be keeping track of enter/leave events and display the overlay
  // if the count is greater than zero.

  // https://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element

  const [dragEventCount, setDragEventCount] = useState(0);

  useLayoutEffect(() => {
    function handleDragEnter(e: DragEvent) {
      e.preventDefault();
      setDragEventCount((cnt) => cnt + 1);
    }

    document.addEventListener("dragenter", handleDragEnter);
    return () => window.removeEventListener("dragenter", handleDragEnter);
  }, []);

  useLayoutEffect(() => {
    function handleDragLeave(e: DragEvent) {
      e.preventDefault();
      setDragEventCount((cnt) => cnt - 1);
    }

    document.addEventListener("dragleave", handleDragLeave);
    return () => window.removeEventListener("dragleave", handleDragLeave);
  }, []);

  useEffect(() => {
    function handleDragOver(e: DragEvent) {
      e.preventDefault();
    }

    window.addEventListener("dragover", handleDragOver);
    return () => window.removeEventListener("dragover", handleDragOver);
  }, []);

  useEffect(() => {
    function handleDrop(e: DragEvent) {
      const files: File[] = [];

      e.preventDefault();
      setDragEventCount(0);

      if (!e.dataTransfer) {
        return;
      }

      if (e.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        for (let i = 0; i < e.dataTransfer.items.length; i++) {
          // If dropped items aren't files, reject them
          if (e.dataTransfer.items[i].kind === "file") {
            const file = e.dataTransfer.items[i].getAsFile();
            if (file) {
              files.push(file);
            }
          }
        }
      } else {
        // Use DataTransfer interface to access the file(s)
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
          files.push(e.dataTransfer.files[i]);
        }
      }

      props.onDrop(files);
    }

    window.addEventListener("drop", handleDrop);
    return () => window.removeEventListener("drop", handleDrop);
  }, [props]);

  if (dragEventCount === 0) {
    return null;
  }

  return (
    <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-60 text-white flex justify-center items-center text-3xl">
      Drop files to upload.
    </div>
  );
}
