import React from "react";

export default function IllustrationPreChatSurvey() {
  return (
    <svg viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M130.97 48.19L377.95 56.22C419.14 57.56 453.49 89.33 456.7 127.24L472.72 316.56C475.93 354.47 447.41 391.56 407.03 399.81L164.91 449.24C106.35 461.2 52.42 431.34 48.22 381.77L27.27 134.29C23.08 84.73 71.23 46.23 130.96 48.17L130.97 48.19Z"
        fill="#EEF4FD"
      />
      <path
        d="M385.261 89.1188L352.039 101.021L354.737 108.552L387.959 96.6501L385.261 89.1188Z"
        fill="black"
      />
      <path
        d="M326.127 38.5189L318.71 68.4642L326.476 70.3874L333.892 40.442L326.127 38.5189Z"
        fill="black"
      />
      <path
        d="M263.197 54.2594L256.245 58.2182L268.656 80.0122L275.608 76.0534L263.197 54.2594Z"
        fill="black"
      />
      <path
        d="M113.4 124.77L144.15 455.51L384.82 419.09L323.65 97.67L113.4 124.77Z"
        fill="black"
      />
      <path
        d="M120.05 123.39L149.96 439.11L380.66 412.94L321.55 98.67L120.05 123.39Z"
        fill="white"
      />
      <path
        d="M146.82 442.97L116.26 120.34L324.36 94.81L384.77 415.98L146.82 442.97ZM123.84 126.43L153.09 435.25L376.54 409.9L318.73 102.52L123.84 126.43Z"
        fill="black"
      />
      <path
        d="M321.55 98.67L120.05 123.39L125.35 179.34L331.17 149.82L321.55 98.67Z"
        fill="#AFCFF8"
      />
      <path
        d="M122.23 183.31L116.27 120.34L324.37 94.81L335.27 152.76L122.23 183.31ZM123.84 126.43L128.48 175.37L327.08 146.89L318.73 102.52L123.84 126.43Z"
        fill="black"
      />
      <path
        d="M369.62 354.28L144.81 384.79L149.96 439.11L380.66 412.94L369.62 354.28Z"
        fill="#AFCFF8"
      />
      <path
        d="M146.82 442.97L141.02 381.79L372.43 350.38L384.77 415.98L146.82 442.97ZM148.6 387.79L153.1 435.25L376.55 409.9L366.82 358.18L148.6 387.8V387.79Z"
        fill="black"
      />
      <path
        d="M333.09 388.56L359.59 384.48L331.88 403.46L333.09 388.56Z"
        fill="white"
      />
      <path
        d="M326.48 372.39L359.3 382.47L332.76 386.55L326.48 372.39Z"
        fill="white"
      />
      <path
        d="M268.731 129.433L174.539 140.665L175.364 147.585L269.557 136.354L268.731 129.433Z"
        fill="black"
      />
      <path
        d="M243.84 183.147L149.648 194.379L150.24 199.344L244.432 188.112L243.84 183.147Z"
        fill="#AFCFF8"
      />
      <path
        d="M193.06 318L192.18 362.5L305.45 345.72L298.55 304.06L193.06 318Z"
        fill="black"
      />
      <path
        d="M197.02 318.19L196.32 358.63L303.43 343.54L297.02 304.83L197.02 318.19Z"
        fill="#3383EC"
      />
      <path
        d="M195.26 359.83L196 317.29L297.88 303.67L304.62 344.42L195.26 359.83ZM198.04 319.1L197.37 357.44L302.23 342.67L296.16 305.99L198.04 319.11V319.1Z"
        fill="black"
      />
      <path d="M308.59 304.36H300.78V307.14H308.59V304.36Z" fill="black" />
      <path
        d="M300.454 292.17L295.766 298.751L298.03 300.364L302.718 293.782L300.454 292.17Z"
        fill="black"
      />
      <path
        d="M209.59 341.2C208.49 340.39 207.79 339.14 207.5 337.45C207.44 337.1 207.36 336.52 207.25 335.71C207.14 334.9 207.07 334.3 207.03 333.93C206.87 332.23 207.23 330.85 208.1 329.77C208.97 328.7 210.28 328.04 212.05 327.81C213.2 327.66 214.24 327.73 215.15 328.02C216.06 328.31 216.79 328.77 217.35 329.4C217.91 330.03 218.26 330.78 218.4 331.65V331.69C218.42 331.8 218.39 331.89 218.31 331.97C218.23 332.05 218.15 332.1 218.04 332.12L216.19 332.36C216.03 332.38 215.91 332.36 215.83 332.31C215.75 332.26 215.66 332.14 215.59 331.96C215.27 331.16 214.85 330.62 214.33 330.34C213.81 330.06 213.14 329.98 212.34 330.09C210.47 330.34 209.64 331.53 209.84 333.66C209.87 334.02 209.94 334.57 210.04 335.3C210.14 336.03 210.22 336.6 210.28 336.98C210.64 339.09 211.75 340.03 213.62 339.78C214.42 339.67 215.05 339.42 215.49 339.01C215.93 338.6 216.2 337.98 216.28 337.13C216.3 336.94 216.34 336.81 216.42 336.73C216.5 336.65 216.61 336.6 216.77 336.58L218.62 336.34C218.74 336.32 218.84 336.35 218.94 336.42C219.04 336.49 219.08 336.58 219.09 336.7C219.18 337.57 219.04 338.39 218.66 339.14C218.28 339.89 217.7 340.53 216.89 341.04C216.09 341.56 215.11 341.89 213.95 342.04C212.16 342.28 210.71 341.99 209.61 341.18L209.59 341.2Z"
        fill="white"
      />
      <path
        d="M221.16 340.77C221.06 340.69 221 340.58 220.98 340.45L219.26 327.35C219.24 327.21 219.27 327.08 219.35 326.99C219.43 326.89 219.53 326.84 219.66 326.82L221.39 326.59C221.53 326.57 221.65 326.59 221.75 326.67C221.85 326.74 221.9 326.85 221.92 327L222.51 331.51C223.16 330.46 224.15 329.84 225.47 329.67C226.66 329.51 227.67 329.79 228.49 330.49C229.31 331.19 229.81 332.23 229.99 333.6L230.72 339.16C230.74 339.29 230.72 339.41 230.64 339.51C230.57 339.61 230.46 339.67 230.32 339.69L228.57 339.92C228.44 339.94 228.32 339.91 228.22 339.83C228.12 339.75 228.06 339.64 228.04 339.51L227.32 334.07C227.22 333.29 226.95 332.71 226.53 332.33C226.1 331.95 225.54 331.81 224.84 331.9C224.14 331.99 223.64 332.28 223.29 332.77C222.95 333.26 222.82 333.89 222.92 334.65L223.64 340.09C223.66 340.22 223.63 340.34 223.56 340.44C223.49 340.54 223.38 340.6 223.24 340.62L221.51 340.85C221.38 340.87 221.26 340.84 221.16 340.75V340.77Z"
        fill="white"
      />
      <path
        d="M233.65 339.05C233.06 338.85 232.58 338.54 232.2 338.12C231.82 337.7 231.6 337.21 231.52 336.66C231.4 335.76 231.66 334.99 232.3 334.34C232.94 333.69 233.87 333.19 235.09 332.83L237.7 332.08L237.65 331.66C237.57 331.08 237.37 330.66 237.05 330.41C236.73 330.16 236.25 330.07 235.61 330.16C235.18 330.22 234.85 330.34 234.6 330.54C234.36 330.74 234.17 330.93 234.03 331.11C233.9 331.3 233.81 331.43 233.76 331.49C233.73 331.66 233.64 331.75 233.5 331.77L232 331.97C231.88 331.99 231.78 331.96 231.68 331.89C231.58 331.82 231.53 331.72 231.51 331.59C231.48 331.26 231.59 330.86 231.84 330.4C232.09 329.94 232.51 329.51 233.11 329.13C233.7 328.74 234.46 328.49 235.38 328.37C236.91 328.17 238.07 328.37 238.86 328.98C239.65 329.59 240.12 330.46 240.27 331.59L241.09 337.8C241.11 337.93 241.09 338.05 241.01 338.15C240.94 338.25 240.83 338.31 240.69 338.33L239.11 338.54C238.98 338.56 238.86 338.53 238.76 338.45C238.66 338.37 238.6 338.26 238.58 338.13L238.48 337.36C238.25 337.82 237.89 338.22 237.4 338.56C236.91 338.9 236.28 339.12 235.5 339.22C234.84 339.31 234.21 339.25 233.63 339.05H233.65ZM237.6 336.31C237.99 335.78 238.13 335.05 238.01 334.14L237.96 333.72L236.06 334.29C235.32 334.51 234.78 334.77 234.43 335.09C234.08 335.4 233.93 335.75 233.98 336.13C234.04 336.55 234.25 336.86 234.64 337.05C235.02 337.24 235.46 337.3 235.95 337.24C236.66 337.15 237.22 336.83 237.61 336.3L237.6 336.31Z"
        fill="white"
      />
      <path
        d="M244.67 336.86C244 336.31 243.58 335.43 243.42 334.22L242.83 329.73L241.29 329.93C241.16 329.95 241.04 329.91 240.94 329.83C240.84 329.74 240.78 329.63 240.76 329.5L240.61 328.37C240.59 328.24 240.62 328.12 240.7 328.02C240.78 327.92 240.88 327.86 241.01 327.84L242.55 327.64L242.12 324.36C242.1 324.22 242.13 324.09 242.21 324C242.29 323.9 242.39 323.85 242.52 323.83L244.1 323.62C244.24 323.6 244.36 323.62 244.46 323.7C244.56 323.77 244.61 323.88 244.63 324.03L245.06 327.31L247.49 326.99C247.63 326.97 247.75 326.99 247.85 327.07C247.95 327.14 248 327.25 248.02 327.4L248.17 328.53C248.19 328.68 248.16 328.8 248.08 328.9C248 329 247.9 329.07 247.77 329.08L245.34 329.4L245.91 333.69C245.98 334.26 246.14 334.67 246.37 334.94C246.6 335.2 246.95 335.31 247.4 335.25L248.74 335.07C248.87 335.05 248.99 335.08 249.09 335.16C249.19 335.24 249.25 335.35 249.27 335.48L249.43 336.71C249.45 336.84 249.42 336.96 249.35 337.06C249.28 337.16 249.17 337.22 249.03 337.24L247.45 337.45C246.27 337.61 245.34 337.41 244.67 336.86Z"
        fill="white"
      />
      <path
        d="M254.63 336.36C254.53 336.28 254.47 336.17 254.45 336.04L252.75 323.16C252.73 323.01 252.75 322.89 252.83 322.79C252.9 322.69 253.01 322.63 253.15 322.61L254.65 322.41C254.81 322.39 254.93 322.41 255.01 322.45C255.1 322.5 255.18 322.57 255.25 322.65L262.02 330.78L260.88 322.09C260.86 321.94 260.88 321.82 260.96 321.72C261.03 321.62 261.14 321.56 261.28 321.54L262.91 321.32C263.05 321.3 263.17 321.33 263.28 321.41C263.38 321.49 263.44 321.6 263.46 321.75L265.15 334.61C265.17 334.76 265.14 334.88 265.06 334.98C264.98 335.08 264.88 335.14 264.75 335.16L263.23 335.36C263.01 335.39 262.81 335.31 262.62 335.12L255.9 327.19L257.02 335.7C257.04 335.85 257.01 335.97 256.93 336.06C256.85 336.16 256.74 336.22 256.6 336.23L254.97 336.45C254.84 336.47 254.72 336.44 254.62 336.36H254.63Z"
        fill="white"
      />
      <path
        d="M268.15 333.71C267.22 333.02 266.62 331.99 266.38 330.61L266.25 329.74L266.15 328.87C266.03 327.49 266.35 326.34 267.08 325.43C267.82 324.51 268.93 323.96 270.42 323.76C271.91 323.56 273.13 323.81 274.08 324.51C275.03 325.2 275.63 326.23 275.87 327.6C275.9 327.76 275.95 328.05 276 328.47C276.05 328.89 276.09 329.18 276.09 329.34C276.21 330.73 275.9 331.88 275.18 332.79C274.45 333.7 273.33 334.25 271.82 334.45C270.3 334.65 269.08 334.41 268.14 333.72L268.15 333.71ZM273.08 331.61C273.4 331.12 273.52 330.44 273.45 329.58C273.45 329.45 273.42 329.19 273.37 328.81C273.32 328.43 273.28 328.17 273.25 328.04C273.1 327.19 272.81 326.57 272.37 326.17C271.94 325.78 271.37 325.63 270.69 325.72C270.01 325.81 269.5 326.1 269.18 326.59C268.86 327.08 268.74 327.76 268.81 328.62L268.89 329.39L269.01 330.16C269.16 331.01 269.45 331.63 269.89 332.03C270.32 332.42 270.89 332.57 271.57 332.48C272.25 332.39 272.76 332.1 273.08 331.61Z"
        fill="white"
      />
      <path
        d="M280.2 333.01C280.09 332.94 279.99 332.83 279.91 332.68L275.92 323.87C275.92 323.87 275.89 323.77 275.88 323.69C275.86 323.57 275.89 323.46 275.97 323.37C276.04 323.27 276.13 323.22 276.23 323.2L277.67 323.01C277.81 322.99 277.93 323.01 278.04 323.08C278.14 323.15 278.21 323.23 278.25 323.31L281.02 329.61L282.19 322.85C282.21 322.73 282.27 322.62 282.35 322.52C282.44 322.42 282.56 322.36 282.71 322.34L283.68 322.21C283.84 322.19 283.97 322.21 284.08 322.29C284.19 322.36 284.27 322.46 284.32 322.57L287.2 328.79L288.24 321.99C288.24 321.9 288.28 321.8 288.37 321.71C288.46 321.62 288.56 321.56 288.69 321.55L290.13 321.36C290.25 321.34 290.35 321.37 290.45 321.45C290.55 321.52 290.6 321.62 290.62 321.74L290.6 321.92L289.03 331.47C289 331.64 288.94 331.77 288.85 331.87C288.76 331.97 288.64 332.03 288.47 332.05L287.2 332.22C286.86 332.26 286.63 332.13 286.49 331.81L283.64 325.82L282.44 332.34C282.38 332.68 282.18 332.88 281.84 332.92L280.59 333.08C280.42 333.1 280.28 333.08 280.17 333L280.2 333.01Z"
        fill="white"
      />
      <path
        d="M152.88 210.35L152.74 245.17L319.05 222.94L313.57 192.26L152.88 210.35Z"
        fill="black"
      />
      <path
        d="M157.88 208.89L157.72 238.31L319.57 219.37L314.57 191.26L157.88 208.89Z"
        fill="white"
      />
      <path
        d="M156.96 239.16L157.13 208.21L315.19 190.43L320.45 220.02L156.96 239.16ZM158.63 209.56L158.48 237.46L318.69 218.71L313.96 192.08L158.64 209.55L158.63 209.56Z"
        fill="black"
      />
      <path
        d="M252.464 244.425L158.272 255.657L158.864 260.622L253.056 249.39L252.464 244.425Z"
        fill="#AFCFF8"
      />
      <path
        d="M162.51 270.63L161.37 306.44L328.68 283.21L323.2 252.53L162.51 270.63Z"
        fill="black"
      />
      <path
        d="M166.5 270.16L166.34 299.58L328.19 280.64L323.2 252.53L166.5 270.16Z"
        fill="white"
      />
      <path
        d="M165.59 300.43L165.76 269.48L323.82 251.7L329.08 281.29L165.59 300.43ZM167.25 270.83L167.1 298.73L327.31 279.98L322.58 253.36L167.26 270.83H167.25Z"
        fill="black"
      />
      <path
        d="M169.38 401.86L209.67 410.61L198.94 447.87L255.8 460.52L262.95 414.81L301.58 424.15L250.7 340.25L169.38 401.86Z"
        fill="black"
      />
      <path
        d="M177.91 398.37L214.94 405.49L205.02 442.99L251.48 450.83L257.84 410.15L295.82 419.72L249.81 343.38L177.91 398.37Z"
        fill="white"
      />
      <path
        d="M253.21 453.22L202.44 444.66L212.38 407.11L173 399.54L250.41 340.34L300.21 422.97L259.56 412.73L253.23 453.23L253.21 453.22ZM207.6 441.33L249.76 448.44L256.15 407.59L291.45 416.48L249.23 346.43L182.84 397.21L217.51 403.88L207.6 441.33Z"
        fill="black"
      />
    </svg>
  );
}
