import Modal from "@velaro/velaro-shared/src/components/Modal";
import Panel from "@/components/Panel";
import useApi from "@/hooks/useApi";
import IconTrash1 from "@/icons/IconTrash1";
import React, { useEffect, useState } from "react";
import LoadingSpinner from "@/components/LoadingSpinner";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";

interface NetSuiteConfig {
  id: number;
  displayName: string;
  company: string;
  teamId: number;
}

interface Props {
  configs: NetSuiteConfig[];
  deleteConfig(id: number): void;
  addConfig(): void;
  teamId: number;
}

export default function NetSuiteConfigTable(props: Props) {
  const [showSetupModal, setShowSetupModal] = useState(false);
  const api = useApi();
  const [company, setCompany] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [token, setToken] = useState("");
  const [tokenSecret, setTokenSecret] = useState("");
  const [restletUrl, setRestletUrl] = useState("");
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);

  async function handleSubmit() {
    setSending(true);
    const response = await api.messaging.post("NetSuite/Auth/SaveCredentials", {
      tenantId: tenantId,
      displayName: displayName,
      company: company,
      token: token,
      tokenSecret: tokenSecret,
      restletUrl: restletUrl,
      teamId: props.teamId
    });
    setSuccess(response.ok);
    if (response.ok) {
      props.addConfig();
      setCompany("");
      setDisplayName("");
      setToken("");
      setTokenSecret("");
      setRestletUrl("");
      setShowSetupModal(false);
    }
    setSending(false);
  }

  return (
    <Panel title="Netsuite Configurations">
      <div className="p-4">
        <table className="p-4 w-full bg-slate-50">
          <thead>
            <tr>
              <th className="border-b-[1px] p-2 text-left">Display Name</th>
              <th className="border-b-[1px] p-2 text-left">
                NetSuite Company ID
              </th>
              <th className="border-b-[1px] p-2"></th>
            </tr>
          </thead>
          <tbody>
            {props.configs.map((config) => (
              <tr key={config.id}>
                <td className="border-b-[1px] p-2">{config.displayName}</td>
                <td className="border-b-[1px] p-2">{config.company}</td>
                <td className="border-b-[1px] p-2 justify-center flex">
                  {
                    <div
                      className="cursor-pointer"
                      onClick={() => props.deleteConfig(config.id)}
                    >
                      <IconTrash1 />
                    </div>
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button
          type="submit"
          className="btn-green mt-4"
          onClick={() => setShowSetupModal(!showSetupModal)}
        >
          Add New Netsuite Configuration
        </button>
      </div>
      {showSetupModal && (
        <Modal show={true}>
          <Modal.Header
            title="Add New Netsuite Configuration"
            onClose={() => setShowSetupModal(!showSetupModal)}
          />
          <form
            // className="px-4"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Modal.Body>
              <div className="flex mb-4">
                <div className="w-1/3 font-semibold">Display Name</div>
                <input
                  name="name"
                  type="text"
                  className="input w-2/3"
                  autoFocus
                  value={displayName}
                  onChange={(e) => setDisplayName(e.currentTarget.value)}
                />
              </div>
              <div className="flex mb-4">
                <div className="w-1/3 font-semibold"> NetSuite Company ID</div>
                <input
                  name="email"
                  type="text"
                  className="input w-2/3"
                  autoFocus
                  value={company}
                  onChange={(e) => setCompany(e.currentTarget.value)}
                />
              </div>
              <div className="flex mb-4">
                <div className="w-1/3 font-semibold"> Token</div>
                <input
                  name="email"
                  type="text"
                  className="input w-2/3"
                  autoFocus
                  value={token}
                  onChange={(e) => setToken(e.currentTarget.value)}
                />
              </div>
              <div className="flex mb-4">
                <div className="w-1/3 font-semibold"> Token Secret</div>
                <input
                  name="email"
                  type="text"
                  className="input w-2/3"
                  autoFocus
                  value={tokenSecret}
                  onChange={(e) => setTokenSecret(e.currentTarget.value)}
                />
              </div>
              <div className="flex mb-4">
                <div className="w-1/3 font-semibold"> Restlet URL</div>
                <input
                  name="email"
                  type="text"
                  className="input w-2/3"
                  autoFocus
                  value={restletUrl}
                  onChange={(e) => setRestletUrl(e.currentTarget.value)}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="py-3 flex flex-col">
                {success === false && (
                  <div className="flex flex-row mb-4">
                    There was an error saving your changes. Please see
                    Integration Log for more details.
                  </div>
                )}
                {sending ? (
                  <div className="ml-auto flex">
                    Saving Changes...
                    <LoadingSpinner />
                  </div>
                ) : (
                  <div className="ml-auto flex flex-row">
                    <PrimaryBlueButton
                      onClick={() => handleSubmit()}
                      label="Save Changes"
                    />
                  </div>
                )}
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      )}
    </Panel>
  );
}
