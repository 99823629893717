export interface Survey {
  id?: number;
  teamId: number;
  type?: string;
  questions: SurveyQuestion[];
}

export interface SurveyQuestion {
  id?: number;
  type: QuestionType;
  text: string;
  options: string[];
  variable: string;
  required: boolean;
}

export const questionTypeDefs = {
  name: "Name",
  email: "Email",
  text: "Text",
  choice: "Multiple Choice"
};
export type QuestionType = keyof typeof questionTypeDefs;
