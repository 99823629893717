import useApi from "@/hooks/useApi";

class CKEditorUploadAdapter {
  url: string;
  loader: any;
  xhr: any;

  constructor(loader: any, url: string) {
    this.loader = loader;
    this.url = url;
  }

  upload() {
    return this.loader.file.then(
      (      file : any) =>
        new Promise((resolve, reject) => {
          if (file.size > 10000000) {
            reject("This file is too large.");
            return;
          }

          this._initRequest();
          this._initListeners(resolve, reject, file);
          this._sendRequest(file);
        })
    );
  }

  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  _initRequest() {
    const api = useApi();
    const xhr = (this.xhr = new XMLHttpRequest());
    xhr.open("POST", this.url, true);
    xhr.setRequestHeader("Authorization", `Bearer ${api.main.accessToken}`);
    xhr.responseType = "json";
  }

  _initListeners(resolve: { (value: unknown): void; (arg0: { default: any; }): void; }, reject: { (reason?: any): void; (arg0: string | undefined): any; }, file: { size?: number; name?: any; }) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;

    xhr.addEventListener("error", () => reject(genericErrorText));
    xhr.addEventListener("abort", () => reject());
    xhr.addEventListener("load", () => {
      const response = xhr.response;

      // This example assumes the XHR server's "response" object will come with
      // an "error" which has its own "message" that can be passed to reject()
      // in the upload promise.
      //
      // Your integration may handle upload errors in a different way so make sure
      // it is done properly. The reject() function must be called when the upload fails.
      if (!response || response.error) {
        return reject(
          response && response.error ? response.error.message : genericErrorText
        );
      }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      // This URL will be used to display the image in the content. Learn more in the
      // UploadAdapter#upload documentation.
      resolve({
        default: response.url
      });
    });

    // Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
    // properties which are used e.g. to display the upload progress bar in the editor
    // user interface.
    if (xhr.upload) {
      xhr.upload.addEventListener("progress", (evt: { lengthComputable: any; total: any; loaded: any; }) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  _sendRequest(file: string | Blob) {
    const data = new FormData();
    data.append("file", file);

    // Important note: This is the right place to implement security mechanisms
    // like authentication and CSRF protection. For instance, you can use
    // XMLHttpRequest.setRequestHeader() to set the request headers containing
    // the CSRF token generated earlier by your application.

    // Send the request.
    this.xhr.send(data);
  }
}

export default function ckImageUploadPlugin(url: string) {
  return function(editor: { plugins: { get: (arg0: string) => { (): any; new(): any; createUploadAdapter: (loader: any) => CKEditorUploadAdapter; }; }; }) {
    editor.plugins.get("FileRepository").createUploadAdapter = loader => {
      return new CKEditorUploadAdapter(loader, url);
    };
  };
}
