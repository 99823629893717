import * as React from "react";
import { ArticleStatListItem } from "../types";
import { FilterState, defaultFilterState } from "../articles/types";
import useApi from "@/hooks/useApi";

const FILTER_STATE_KEY = "kb:articles:filterState";

export default function useGroupedArticles() {
  const [articles, setArticles] = React.useState<ArticleStatListItem[]>([]);
  const [loadingArticles, setLoadingArticles] = React.useState(true);

  const [filterState, setFilterState] =
    React.useState<FilterState>(defaultFilterState);
  const api = useApi();

  const [filterStateLoaded, setFilterStateLoaded] = React.useState(false);

  const fetchArticles = React.useCallback(async () => {
    setLoadingArticles(true);

    const response = await api.main.post("KBArticles/Search", filterState);
    const json = await response.json();

    setLoadingArticles(false);
    setArticles(json.Articles);
  }, [filterState]);

  /**
   * fetch articles when filter state changes
   */

  React.useEffect(() => {
    if (filterStateLoaded) {
      fetchArticles();
    }
  }, [filterStateLoaded, fetchArticles]);

  /**
   * load filter state from localStorage
   */

  React.useEffect(() => {
    const json = localStorage.getItem(FILTER_STATE_KEY);

    if (!json) {
      setFilterStateLoaded(true);
      return;
    }

    try {
      const state = JSON.parse(json);

      // Visibility filter was added later, migrate the state if it's not there.

      if (state.visibility === undefined) {
        state.visibility = "All";
      }

      setFilterState(state);
    } catch (err) {
      console.error(err);
    } finally {
      setFilterStateLoaded(true);
    }
  }, []);

  /** persist filterState when it changes */

  React.useEffect(() => {
    localStorage.setItem(FILTER_STATE_KEY, JSON.stringify(filterState));
  }, [filterState]);

  return {
    articles,
    loadingArticles,
    filterState,
    setFilterState
  };
}
