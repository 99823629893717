import React from "react";
import { IconProps } from "./types";

function IconEmail(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      data-name="email-gray 300"
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill="currentColor"
          d="M19 4H5a3 3 0 00-3 3v10a3 3 0 003 3h14a3 3 0 003-3V7a3 3 0 00-3-3zm-.67 2L12 10.75 5.67 6zM19 18H5a1 1 0 01-1-1V7.25l7.4 5.55a1 1 0 001.2 0L20 7.25V17a1 1 0 01-1 1z"
          data-name="Path 177"
        ></path>
      </g>
    </svg>
  );
}

export default IconEmail;
