import * as React from "react";
import ScheduleRow from "./ScheduleRow";
import { WorkSchedule } from "./types";

import "./SchedulesList.css";

interface Props {
  schedules: WorkSchedule[];
  onSelectSchedule: (index: number) => void;
  onToggleScheduleEnabled: (index: number) => void;
}

export default function SchedulesList(props: Props) {
  return (
    <React.Fragment>
      <div className="schedule-list-grid">
        <span className="week-span">
          <span />
          <span>S</span>
          <span className="week-span-divider" />
          <span>M</span>
          <span className="week-span-divider" />
          <span>T</span>
          <span className="week-span-divider" />
          <span>W</span>
          <span className="week-span-divider" />
          <span>T</span>
          <span className="week-span-divider" />
          <span>F</span>
          <span className="week-span-divider" />
          <span>S</span>
          <span />
        </span>
        {props.schedules.map((x, i) => {
          return (
            <ScheduleRow
              key={i}
              row={i + 2}
              schedule={x}
              onSelectSchedule={() => {
                props.onSelectSchedule(i);
              }}
              onToggleScheduleEnabled={() => {
                props.onToggleScheduleEnabled(i);
              }}
            />
          );
        })}
      </div>
    </React.Fragment>
  );
}
