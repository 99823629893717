import useApi from "@/hooks/useApi";
import React, { useCallback, useEffect, useState } from "react";
import { WorkflowRulesContext } from "@/context/WorkflowRulesContext";
import { WorkflowRule } from "@/pages/Messaging/Rules/types";

export function WorkflowRulesProvider(props: { children: React.ReactNode }) {
  const api = useApi();
  const [workflowRules, setWorkflowRules] = useState<WorkflowRule[]>([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const fetch = useCallback(async () => {
    setLoading(true);
    const json = await api.messaging.get("WorkflowRules/List");
    const result = (await json.json()) as WorkflowRule[];

    setWorkflowRules(result);
    setLoading(false);
  }, [api.messaging]);

  async function remove(rule: WorkflowRule) {
    const clone = [...workflowRules];

    if (rule.id === undefined) {
      //hasn't been saved yet
      setWorkflowRules(clone);
      return;
    }

    const i = workflowRules.findIndex((x) => x.id === rule.id);
    clone.splice(i, 1)[0];

    try {
      const res = await api.messaging.delete("WorkflowRules", rule);
      if (res.ok) {
        setWorkflowRules(clone);
      } else {
        console.error(res.statusText);
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function add(rule: WorkflowRule) {
    setSaving(true);
    try {
      const res = await api.messaging.post("WorkflowRules", rule);
      if (res.ok) {
        const data = await res.json();
        setWorkflowRules([...workflowRules, data]);
      } else {
        console.error(res.statusText);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setSaving(false);
    }
  }

  async function update(rule: WorkflowRule) {
    setSaving(true);
    try {
      const res = await api.messaging.put("WorkflowRules", rule);
      if (res.ok) {
        const data = await res.json();
        const clone = [...workflowRules];
        const i = workflowRules.findIndex((x) => x.id === rule.id);
        clone[i] = data;
        setWorkflowRules(clone);
      } else {
        console.error(res.statusText);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setSaving(false);
    }
  }

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <WorkflowRulesContext.Provider
      value={{ workflowRules, saving, loading, add, update, remove }}
    >
      {props.children}
    </WorkflowRulesContext.Provider>
  );
}
