import React, { ReactNode, useState, useRef, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

const styles = {
  tooltip:
    "absolute left-full ml-2 p-2 rounded bg-cornflower-blue-500 text-white whitespace-nowrap z-1000",
};

type SubLink = {
  label: string;
  to: string;
};

type ExpandableMenuLinkProps = {
  label: string;
  icon: ReactNode;
  onExpand: () => void;
  expanded: boolean;
  children: ReactNode;
  toggleSidebar: boolean;
  to?: string;
};

export default function ExpandableMenuLink({
  label,
  icon,
  onExpand,
  expanded,
  children,
  toggleSidebar,
  to,
}: ExpandableMenuLinkProps) {
  const location = useLocation();
  const [isHovered, setIsHovered] = useState(false);
  const [tooltipHeight, setTooltipHeight] = useState(0);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const linkRef = useRef<HTMLDivElement | null>(null);

  const iconClassName = `transition fa-solid fa-angle-down ${
    expanded ? "fa-rotate-180" : ""
  }`;

  const linkStyling = "h-9 mb-1 mt-1 rounded-lg w-full text-left flex items-center";

  const subLinks: SubLink[] =
    React.Children.map(children, (child) => {
      if (React.isValidElement(child) && child.props) {
        const { label, to } = child.props;
        return { label, to };
      }
      return null;
    })?.filter((link): link is SubLink => link !== null) || [];

    useEffect(() => {
      const updateTooltipPosition = () => {
        if (linkRef.current && tooltipRef.current) {
          const linkRect = linkRef.current.getBoundingClientRect();
          const viewportHeight = window.innerHeight;
          const tooltipHeight = tooltipRef.current.offsetHeight;
  
          let topPosition =
            linkRect.top + window.scrollY - tooltipHeight / 2 + linkRect.height / 2;
  
          if (topPosition < 10) topPosition = 10;
  
          if (topPosition + tooltipHeight > viewportHeight) {
            topPosition = viewportHeight - tooltipHeight - 10;
          }
  
          setTooltipPosition({
            top: topPosition,
            left: linkRect.right + window.scrollX,
          });
          setTooltipHeight(tooltipHeight);
        }
      };
  
      if (isHovered && !toggleSidebar) {
        updateTooltipPosition();
        window.addEventListener("resize", updateTooltipPosition);
      }
  
      return () => window.removeEventListener("resize", updateTooltipPosition);
    }, [isHovered, toggleSidebar]);

  const isActive = to
    ? location.pathname.startsWith(to)
    : subLinks.some((subLink) => location.pathname.startsWith(subLink.to));

  return (
    <>
      <div ref={linkRef}>
        <div
          className={`${
            isActive ? "bg-cornflower-blue-700" : "hover:bg-cornflower-blue-400"
          } ${linkStyling}`}
          onClick={onExpand}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className="flex w-full py-2">
            <div className="flex items-center ml-2">{icon}</div>
            <div className="flex items-center pl-4 w-full text-sm">
              {label}
              {toggleSidebar && (
                <div className="flex ml-auto pr-4">
                  <i className={iconClassName}></i>
                </div>
              )}
            </div>
          </div>

          {isHovered && !toggleSidebar && (
            <>
              <div
                className="fixed left-[70%] ml-2 bg-transparent"
                style={{
                  top: `${tooltipPosition.top}px`,
                  height: `${tooltipHeight}px`,
                  width: "100px",
                }}
              />
              <div
                ref={tooltipRef}
                className={styles.tooltip}
                style={{
                  position: "fixed",
                  top: `${tooltipPosition.top}px`,
                }}
              >
                {subLinks.map((subLink) => (
                  <NavLink
                    end
                    key={subLink.to}
                    to={subLink.to}
                    className={({ isActive }) =>
                      `${
                        isActive ? "bg-cornflower-blue-700" : ""
                      } block px-2 py-2 hover:bg-cornflower-blue-300 rounded`
                    }
                  >
                    {subLink.label}
                  </NavLink>
                ))}
              </div>
            </>
          )}
        </div>
      </div>

      {toggleSidebar && expanded && (
        <div className="flex flex-col flex-none">{children}</div>
      )}
    </>
  );
}
