import React, { DetailedHTMLProps, SelectHTMLAttributes } from "react";

let cachedTimezones: string[] = [];

export default function TimezonePicker(
  props: DetailedHTMLProps<
    SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  >
) {
  const [timezones, setTimezones] = React.useState<string[]>(cachedTimezones);

  React.useEffect(() => {
    async function fetchTimezones() {
      const { default: data } = await import("@/data/timezones.json");
      setTimezones(data);
      cachedTimezones = data;
    }

    if (timezones.length === 0) {
      fetchTimezones();
    }
  }, [timezones.length]);

  return (
    <select {...props}>
      {timezones.map((timezone) => (
        <option key={timezone} value={timezone}>
          {timezone}
        </option>
      ))}
    </select>
  );
}
