import React, { createContext, useContext, useEffect } from "react";

const AccordionContext = createContext({
  expandedKey: "",
  setExpandedKey(key: string) {}
});

const AccordionItemContext = createContext({
  eventKey: "",
  setEventKey(key: string) {}
});

function Accordion({ children }: { children: React.ReactNode }) {
  const [expandedKey, setExpandedKey] = React.useState("");

  return (
    <AccordionContext.Provider value={{ expandedKey, setExpandedKey }}>
      {children}
    </AccordionContext.Provider>
  );
}

function AccordionItem({
  eventKey,
  children,
  onExpanded,
  onCollapsed
}: {
  eventKey: string;
  children: React.ReactNode;
  onExpanded?: () => void;
  onCollapsed?: () => void;
}) {
  const { setEventKey } = useContext(AccordionItemContext);
  const { expandedKey } = useContext(AccordionContext);
  const expanded = eventKey === expandedKey;

  useEffect(() => {
    setEventKey(eventKey);
  }, [eventKey, setEventKey]);

  useEffect(() => {
    if (expanded && onExpanded) {
      onExpanded();
    }
  }, [expanded, onExpanded]);

  useEffect(() => {
    if (!expanded && onCollapsed) {
      onCollapsed();
    }
  }, [expanded, onCollapsed]);

  const borderColor = expanded ? "border-blue" : "";

  return (
    <AccordionItemContext.Provider value={{ eventKey, setEventKey }}>
      <div className={`mb-5 border bg-white ${borderColor}`}>{children}</div>
    </AccordionItemContext.Provider>
  );
}

function AccordionHeader({ children }: { children: React.ReactNode }) {
  const { eventKey } = useContext(AccordionItemContext);
  const { expandedKey, setExpandedKey } = useContext(AccordionContext);
  const expanded = eventKey === expandedKey;

  return (
    <div
      className="p-4 cursor-pointer flex"
      onClick={() => {
        if (expandedKey === eventKey) {
          setExpandedKey("");
        } else {
          setExpandedKey(eventKey);
        }
      }}
    >
      <div>{children}</div>
      <div className="ml-auto">
        {expanded ? (
          <i className="fas fa-chevron-down"></i>
        ) : (
          <i className="fas fa-chevron-right"></i>
        )}
      </div>
    </div>
  );
}

function AccordionBody({ children }: { children: React.ReactNode }) {
  const { eventKey } = useContext(AccordionItemContext);
  const { expandedKey } = useContext(AccordionContext);
  const expanded = eventKey === expandedKey;

  if (!expanded) {
    return null;
  }

  return <div className="p-4">{children}</div>;
}

function AccordionTitle({ children }: { children: React.ReactNode }) {
  return <h2 className="font-semibold">{children}</h2>;
}

function AccordionSubTitle({ children }: { children: React.ReactNode }) {
  return <h3 className="font-light">{children}</h3>;
}

Accordion.Item = AccordionItem;
Accordion.Header = AccordionHeader;
Accordion.Body = AccordionBody;
Accordion.Title = AccordionTitle;
Accordion.SubTitle = AccordionSubTitle;

export default Accordion;
