import React from "react";
import FacebookLogin from "./FacebookLogin";
import useApi from "@/hooks/useApi";
import FacebookIntegrationEditModal from "./FacebookIntegrationEditModal";
import AppLoading from "@/components/AppLoading";

interface ListItem {
  id: number;
  pageName: string;
  teamName: string;
}

export default function FacebookIntegration() {
  const [loading, setLoading] = React.useState(true);
  const [editId, setEditId] = React.useState<number | undefined>();
  const [integrations, setIntegrations] = React.useState<ListItem[]>([]);
  const api = useApi();

  const fetchIntegrations = React.useCallback(async () => {
    const response = await api.messaging.get("Facebook/Integrations");
    setIntegrations(await response.json());
    setLoading(false);
  }, [api.messaging]);

  React.useEffect(() => {
    fetchIntegrations();
  }, [fetchIntegrations]);

  if (loading) {
    return <AppLoading />;
  }

  async function removeIntegration(integrationId: number) {
    if (window.confirm("Are you sure you want to remove this integration?")) {
      await api.messaging.delete(`Facebook/Integrations/${integrationId}`);
      await fetchIntegrations();
    }
  }

  return (
    <>
      <div className="my-5">
        <table className="w-1/2 bg-white">
          <thead>
            <tr>
              <th className="border p-2 text-left">Page</th>
              <th className="border p-2 text-left">Team</th>
              <th className="border p-2 text-left" style={{ width: 1 }}></th>
            </tr>
          </thead>
          <tbody>
            {integrations.map((integration) => (
              <tr key={integration.id}>
                <td className="border p-2">{integration.pageName}</td>
                <td className="border p-2">{integration.teamName}</td>
                <td className="border p-2 text-sm whitespace-nowrap">
                  <button
                    className="underline"
                    onClick={() => setEditId(integration.id)}
                  >
                    Edit
                  </button>
                  <span className="mx-2">&bull;</span>
                  <button
                    className="underline"
                    onClick={() => removeIntegration(integration.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <FacebookLogin onProvision={() => fetchIntegrations()} />
      {editId && (
        <FacebookIntegrationEditModal
          integrationId={editId}
          onClose={() => setEditId(undefined)}
          onUpdate={() => {
            setEditId(undefined);
          }}
        />
      )}
    </>
  );
}
