import React from "react";
import { IconProps } from "./types";

function IconDeactivateUserPerson(props: IconProps) {
  return (
    <svg
      id="deactivate-user-person"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_10464"
            data-name="Rectangle 10464"
            width="24"
            height="24"
          />
        </clipPath>
      </defs>
      <g id="Group_9650" data-name="Group 9650" clipPath="url(#clip-path)">
        <path
          id="Path_2612"
          data-name="Path 2612"
          d="M4.211,17.483a1.915,1.915,0,0,1,.83-1.548l2.4-1.738a.73.73,0,0,0,.2-1.013.672.672,0,0,0-.081-.1.98.98,0,0,0-1.271-.113l-2.4,1.738a3.43,3.43,0,0,0-1.482,2.776V24h1.8Z"
        />
        <path
          id="Path_2613"
          data-name="Path 2613"
          d="M7.17,19.657l.012-1.49a.848.848,0,0,0-.891-.8.87.87,0,0,0-.9.787L5.384,24H7.17Z"
        />
        <path
          id="Path_2614"
          data-name="Path 2614"
          d="M17.108,7.877a5.878,5.878,0,1,0-5.878,5.878,5.878,5.878,0,0,0,5.878-5.878m-5.877,4.082a4.082,4.082,0,1,1,4.082-4.082,4.083,4.083,0,0,1-4.082,4.082"
        />
        <path
          id="Path_2615"
          data-name="Path 2615"
          d="M15.774,14.738a4.754,4.754,0,0,1,1.764-.772l-1.369-.993a.974.974,0,0,0-.569-.18.947.947,0,0,0-.7.29.728.728,0,0,0-.2.57.762.762,0,0,0,.329.545Z"
        />
        <path
          id="Path_2616"
          data-name="Path 2616"
          d="M18.876,14.182A4.716,4.716,0,1,0,23.591,18.9a4.715,4.715,0,0,0-4.715-4.715m2.89,6.687a.65.65,0,0,1-.919.919l-1.971-1.972L16.9,21.788a.664.664,0,0,1-.919,0,.649.649,0,0,1,0-.92L17.957,18.9l-1.972-1.971a.649.649,0,0,1,0-.92.666.666,0,0,1,.919,0l1.972,1.972,1.971-1.972a.65.65,0,1,1,.919.919L19.794,18.9Z"
        />
      </g>
    </svg>
  );
}

export default IconDeactivateUserPerson;
