import * as React from "react";
import DayOfWeekSelect from "./FormComponents/DayOfWeekSelect";
import HourSelect from "./FormComponents/HourSelect";
import MinuteSelect from "./FormComponents/MinuteSelect";
import { Shift } from "./types";

interface Props {
  shift: Shift;
  onChange: (shift: Shift) => void;
  onDelete: () => void;
}

export default function ShiftForm(props: Props) {
  return (
    <div className="flex border p-2 mb-2">
      <div className="flex">
        <DayOfWeekSelect
          value={props.shift.startDayOfWeek}
          onChange={(value) =>
            props.onChange({
              ...props.shift,
              startDayOfWeek: value,
              endDayOfWeek: value
            })
          }
        />
        <div className="mx-2">at</div>
        <HourSelect
          value={props.shift.startHour}
          onChange={(value) =>
            props.onChange({ ...props.shift, startHour: value })
          }
        />
        <div>:</div>
        <MinuteSelect
          value={props.shift.startMinute}
          onChange={(value) =>
            props.onChange({ ...props.shift, startMinute: value })
          }
        />
      </div>
      <div className="mx-4">to</div>
      <div className="flex">
        <DayOfWeekSelect
          value={props.shift.endDayOfWeek}
          onChange={(value) =>
            props.onChange({ ...props.shift, endDayOfWeek: value })
          }
        />
        <div className="mx-2">at</div>
        <HourSelect
          value={props.shift.endHour}
          onChange={(value) =>
            props.onChange({ ...props.shift, endHour: value })
          }
        />
        <div>:</div>
        <MinuteSelect
          value={props.shift.endMinute}
          onChange={(value) =>
            props.onChange({ ...props.shift, endMinute: value })
          }
        />
      </div>
      <div className="ml-2">
        <button
          type="button"
          onClick={() => props.onDelete()}
          className="shift-form-text"
          title="Delete this shift"
        >
          <i className="fa fa-trash fa-sm" />
        </button>
      </div>
    </div>
  );
}
