import useApi from "@/hooks/useApi";
import * as React from "react";

interface Article {
  id: string;
  title: string;
}

interface Topic {
  id: string;
  name: string;
  displayPriority: number;
  articles: Article[];
}

interface Category {
  id: string;
  name: string;
  displayPriority: number;
  topics: Topic[];
}

export default function Index() {
  const [categories, setCategories] = React.useState<Category[]>([]);
  const api = useApi();

  React.useEffect(() => {
    async function fetchMap() {
      const res = await api.main.get("KBMap");
      const json = await res.json();
      setCategories(json.payload);
    }

    fetchMap();
  }, []);

  return (
    <div>
      <ul>
        {categories.map((category) => (
          <li key={category.id}>
            {category.name}
            <ul>
              {category.topics.map((topic) => (
                <li key={topic.id}>
                  {topic.name}
                  <ul>
                    {topic.articles.map((article) => (
                      <li key={article.id}>
                        <a
                          href={`/kb/articles/edit/${article.id}`}
                          style={{
                            color: "#4c75ab",
                            textDecoration: "underline"
                          }}
                        >
                          {article.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
}
