import * as React from "react";
import useArticles from "../hooks/useArticles";
import { ArticleStatListItem } from "../types";
import FilterModal from "./FilterModal";
import useTopics from "../hooks/useTopics";
import { useTags } from "../hooks/useTags";
import useAdminUsers from "../hooks/useAdminUsers";
import LoadingSpinner from "@/components/LoadingSpinner";

function getHelpfulPercentage(article: ArticleStatListItem) {
  if (article.VoteCount === 0) {
    return "0%";
  }

  return `${Math.round((article.HelpfulCount / article.VoteCount) * 100)}%`;
}

function formatTimeAgo(isoDate: string) {
  return new Date(isoDate).getDate; //"this needs fixed"; //moment(new Date(isoDate)).fromNow()
}

function getVisibilityClassName(isPublished: boolean, visibility: string) {
  if (!isPublished) {
    return "label label-default";
  }

  switch (visibility) {
    case "Public":
      return "label label-success";
    case "Internal":
      return "label label-info";
  }
}

export default function Index() {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [showFilterModal, setShowFilterModal] = React.useState(false);
  const { topics } = useTopics();
  const { tags } = useTags();
  const { adminUsers } = useAdminUsers();

  const { articles, loadingArticles, filterState, setFilterState } =
    useArticles();

  React.useEffect(() => {
    setSearchTerm(filterState.searchTerm);
  }, [filterState]);

  return (
    <React.Fragment>
      <div className="kb-container">
        <div className="kb-header">
          <h1>KB Articles</h1>
          {/* <h1>{App.translate("app/kb/kbarticles")}</h1> */}
          <div className="kb-actions">
            <button
              className="btn btn-default"
              style={{ marginRight: 6 }}
              onClick={() => setShowFilterModal(true)}
            >
              <i className="fa fa-filter" />
            </button>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setFilterState({ ...filterState, searchTerm });
              }}
            >
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  // placeholder={App.translate("app/kb/searchplaceholder")}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.currentTarget.value)}
                />
                <span className="input-group-btn">
                  <button type="submit" className="btn btn-default">
                    <i className="fa fa-search" />
                  </button>
                </span>
              </div>
            </form>
            <a
              href="/kb/articles/create"
              className="btn btn-success"
              style={{ marginLeft: 6 }}
            >
              {/* <i className="fa fa-plus" /> {App.translate("app/kb/newarticle")} */}
              <i className="fa fa-plus" /> New Article
            </a>
          </div>
        </div>
        <div className="kb-article-list">
          {articles.length !== 0 && loadingArticles && (
            <div className="kb-articles-loading-cover">
              <LoadingSpinner />
            </div>
          )}
          {articles.length === 0 && loadingArticles && (
            <div style={{ marginTop: 60 }}>
              <LoadingSpinner />
            </div>
          )}
          {articles.map((article) => (
            <div key={article.Id} className="kb-article-list-item">
              <div className="col" style={{ width: "40%" }}>
                <div className="top">
                  <a href={`/kb/articles/edit/${article.Id}`}>
                    <strong>{article.Title}</strong>
                  </a>
                </div>
                <div className="bottom">
                  {article.TopicName || "No Topic"} &#8226; Updated{" "}
                  {/* {formatTimeAgo(article.UpdatedAt)} */}
                  {article.UpdatedAt}
                </div>
              </div>
              <div className="col">
                <div className="top">
                  <span
                    className={getVisibilityClassName(
                      article.IsPublished,
                      article.Visibility
                    )}
                  >
                    {article.IsPublished === false && "Draft"}
                    {article.IsPublished === true && <>{article.Visibility}</>}
                  </span>
                </div>
              </div>
              <div className="col text-center">
                <div className="top">
                  <strong>{article.HitCount.toLocaleString()}</strong>
                </div>
                <div className="bottom">Views</div>
                {/* <div className="bottom">{App.translate("app/kb/views")}</div> */}
              </div>
              <div className="col text-center">
                <div className="top">
                  <strong>{getHelpfulPercentage(article)}</strong>
                </div>
                <div className="bottom">Helpful</div>
                {/* <div className="bottom">{App.translate("app/kb/helpful")}</div> */}
              </div>
              <div className="col" style={{ flex: 0 }}>
                <div style={{ width: 32 }}>
                  {article.IsPublished && article.Visibility === "Public" && (
                    <a
                      className="btn btn-sm btn-default"
                      href={article.Url}
                      target="_blank"
                      rel="noopener noreferrer"
                      data-desktop="new-browser"
                    >
                      <i className="fa fa-external-link-square" />
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
          {!loadingArticles && articles.length === 0 && (
            <div className="list-group-item">
              No articles found
              {/* {App.translate("app/kb/noarticles")} */}
            </div>
          )}
        </div>
      </div>
      {showFilterModal && (
        <FilterModal
          topics={topics}
          tags={tags}
          adminUsers={adminUsers}
          onApply={(filterState) => {
            setFilterState(filterState);
            setShowFilterModal(false);
          }}
          onClose={() => setShowFilterModal(false)}
          filterState={filterState}
        />
      )}
    </React.Fragment>
  );
}
