import * as React from "react";
import useArticleFormModel from "../hooks/useArticleFormModel";
import useModelState from "../hooks/useModelState";
import ArticleForm from "../shared/ArticleForm";
// import useConfirmNavigation from "../../../hooks/useConfirmNavigation";
import PreviewButton from "./PreviewButton";
import useApi from "@/hooks/useApi";
import LoadingSpinner from "@/components/LoadingSpinner";
import { useParams } from "react-router-dom";

// interface Props {
//   id: string;
// }

export default function Edit() {
  const [saving, setSaving] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [article, setArticle] = useArticleFormModel();
  const [modelState, setModelState] = useModelState();
  const { id: stringId } = useParams<{ id: string }>();
  const id = parseInt(stringId!);
  // const { setIsDirty } = useConfirmNavigation();
  const api = useApi();

  React.useEffect(() => {
    async function fetchArticle() {
      setLoading(true);

      // const response = await api.main.get("KBArticles", {
      //   id: props.id
      // });
      const response = await api.main.get(`KBArticles?id=${id}`);
      const result = await response.json();

      if (response.status === 404) {
        console.error(response);
        return;
      }

      setArticle({
        title: result.Title,
        slug: result.Slug,
        topicId: result.TopicId,
        description: result.Description,
        content: result.Content,
        searchTags: result.SearchTags,
        visibility: result.Visibility,
        isPublished: result.IsPublished,
        url: result.Url,
        displayPriority: result.DisplayPriority
      });

      setLoading(false);
    }

    fetchArticle();
  }, [id, setArticle]);

  async function save(publish: boolean) {
    setSaving(true);

    const resp = await api.main.put(
      `KBArticles?id=${encodeURIComponent(id)}`,
      {
        ...article,
        isPublished: publish
      }
    );

    if (resp.status === 400) {
      //setModelState(err.responseJSON.ModelState);
      console.error(resp);
      return;
    } else {
      //alert(App.translate("app/kb/error"));
      console.log(resp);
    }

    window.location.href = "/kb/articles";
  }

  async function deleteArticle() {
    if (
      confirm("Are you sure you want to delete this article?")
      //(App.translate("app/kb/confirmdeletearticle"))
    ) {
      const resp = await api.main.delete(
        `KBArticles?id=${encodeURIComponent(id)}`
      );

      window.location.href = "/kb/articles";
    }
  }

  if (loading) {
    return (
      <div style={{ marginTop: 100 }}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="kb-container">
      <div className="kb-header">
        {/* <h1>{App.translate("app/kb/editarticle")}</h1> */}
        <h1>Edit Article</h1>
        <div className="kb-actions">
          <PreviewButton title={article.title} content={article.content} />
          <button
            className="btn btn-danger"
            style={{ marginLeft: 4 }}
            onClick={() => deleteArticle()}
          >
            {/* {App.translate("app/kb/deletearticle")} */}
            Delete Article
          </button>
        </div>
      </div>
      <ArticleForm
        article={article}
        onChange={(props) => {
          setArticle((oldState) => ({ ...oldState, ...props }));
          //setIsDirty(true);
        }}
        modelState={modelState}
      />
      {article.isPublished && (
        <>
          <button
            className="btn btn-primary"
            disabled={saving}
            onClick={() => save(true)}
          >
            {/* {App.translate("app/shared/save")} */}
            Save
          </button>{" "}
        </>
      )}
      {article.isPublished === false && (
        <>
          <button
            className="btn btn-primary"
            disabled={saving}
            onClick={() => save(false)}
          >
            {/* {App.translate("app/kb/savedraft")} */}
            Save Draft
          </button>{" "}
          <button
            className="btn btn-success"
            disabled={saving}
            onClick={() => save(true)}
          >
            {/* {App.translate("app/kb/saveandpublish")} */}
            Save and Publish
          </button>{" "}
        </>
      )}
      <a href="/kb/articles" className="btn btn-link">
        {/* {App.translate("app/kb/backtoarticles")} */}
        Back to Articles
      </a>{" "}
    </div>
  );
}
