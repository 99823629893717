import React from "react";

interface Props {
  number: number;
  active: boolean;
}

export default function WizardStepCircle(props: Props) {
  let bgColor = "bg-slate-gray-200";
  if (props.active) bgColor = "bg-cornflower-blue-500";
  return (
    <div
      className={`flex items-center justify-center w-12 h-12 rounded-full ${bgColor}`}
    >
      <span className="text-white text-xl">{props.number}</span>
    </div>
  );
}
