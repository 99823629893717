import React from "react";
import FormGroup from "@velaro/velaro-shared/src/components/FormGroup";
import UserSelectControl from "../Teams/UserSelectControl";

export interface SkillModel {
  name: string;
  userIds: number[];
  autoRoute: boolean;
  autoReassign: boolean;
  routingType: string;
  autoUnassign: boolean;
  unassignTime: number;
}

export const defaultSkillModel: SkillModel = {
  name: "",
  userIds: [],
  autoRoute: false,
  autoReassign: false,
  routingType: "",
  autoUnassign: false,
  unassignTime: 0
};

interface Props {
  skill: SkillModel;
  onChange(skill: Partial<SkillModel>): void;
}

export default function SkillForm({ skill, onChange }: Props) {
  return (
    <>
      <FormGroup label="Skill Name">
        <input
          type="text"
          required
          value={skill.name}
          onChange={(e) => onChange({ name: e.currentTarget.value })}
          className="border"
        />
      </FormGroup>
      <FormGroup label="Users">
        <UserSelectControl
          selectedUserIds={skill.userIds}
          onChange={(userIds) => onChange({ userIds })}
        />
      </FormGroup>
      <FormGroup label="Auto route chat requests">
        <input
          id="autoRoute"
          type="checkbox"
          className="input"
          checked={skill.autoRoute}
          onChange={() => onChange({ ...skill, autoRoute: !skill.autoRoute })}
        />
      </FormGroup>
      {/* {skill.autoRoute && (
        <FormGroup label="Auto Reassign after Unassign">
          <input
            id="autoReassign"
            type="checkbox"
            className="input"
            checked={skill.autoReassign}
            onChange={() =>
              onChange({ ...skill, autoReassign: !skill.autoReassign })
            }
          />
        </FormGroup>
      )} */}
      {skill.autoRoute && (
        <FormGroup label="Routing Type">
          <select
            id="routeType"
            className="input"
            onChange={(e) => onChange({ routingType: e.currentTarget.value })}
            value={skill.routingType}
          >
            <option value=""></option>
            <option value="roundRobin">Round Robin</option>
            <option value="leastBusy">Round Robin Least Busy</option>
          </select>
        </FormGroup>
      )}
      <FormGroup label="Auto unassign inactive chats">
        <input
          id="autoUnassign"
          type="checkbox"
          className="input"
          checked={skill.autoUnassign}
          onChange={() =>
            onChange({ ...skill, autoUnassign: !skill.autoUnassign })
          }
        />
      </FormGroup>
      {skill.autoUnassign && (
        <div>
          <FormGroup label={"Inactive Unassign Time"}>
            <>
              <input
                id="unassignTime"
                type="text"
                className="mx-2 input"
                required
                value={skill.unassignTime}
                onChange={(e) =>
                  onChange({ unassignTime: Number(e.currentTarget.value) })
                }
              />
              <div className="">Minutes</div>
            </>
          </FormGroup>
        </div>
      )}
    </>
  );
}
