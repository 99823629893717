import { uniqueId } from "lodash";
import React from "react";
import { useState } from "react";
import { useCallback } from "react";
import { FieldOption } from ".";

interface Props {
  options: Array<FieldOption>;
  onChange(options: Array<FieldOption>): void;
}

export default function OptionsControl({ options, onChange }: Props) {
  const [state, setState] = useState<FieldOption>({
    label: "",
    value: "",
    id: uniqueId()
  });

  const removeOption = useCallback(
    (id: string) => {
      onChange(options.filter((x) => x.id !== id));
    },
    [options, onChange]
  );

  const renderOption = useCallback(
    (fieldOption: FieldOption) => {
      return (
        <div className="flex" key={fieldOption.id}>
          <label className="ml-2 w-2/5">{fieldOption.label}</label>
          <label className="ml-2 w-2/5">{fieldOption.value}</label>
          <button
            type="button"
            className="btn-red btn-xs w-1/5 m-1"
            onClick={() => removeOption(fieldOption.id)}
          >
            <i className="fa fa-trash" />
          </button>
        </div>
      );
    },
    [removeOption]
  );

  const addOption = React.useCallback(() => {
    options.push(state);
    onChange(options);
    setState({ label: "", value: "", id: uniqueId() });
  }, [options, onChange, state]);

  function update(updates: Partial<FieldOption>) {
    setState((oldState) => ({
      ...oldState,
      ...updates
    }));
  }

  return (
    <>
      <label htmlFor="options" className="block mb-1">
        Options
      </label>
      <div>
        <div className="flex">
          <label className="w-2/5 ml-1">Label</label>
          <label className="w-2/5 ml-1">Value</label>
        </div>
        <div className="flex">
          <input
            className="input m-1 w-2/5"
            type="text"
            value={state.label}
            onChange={(e) => update({ label: e.currentTarget.value })}
          />
          <input
            className="input m-1 w-2/5"
            type="text"
            value={state.value}
            onChange={(e) => update({ value: e.currentTarget.value })}
          />
          <button
            type="button"
            className="btn-green btn-xs w-1/5 m-1"
            onClick={addOption}
          >
            Add
          </button>
        </div>
        <div
          className="mt-2"
          style={{ overflowY: "scroll", maxHeight: "100px" }}
        >
          {options.map(renderOption)}
        </div>
      </div>
    </>
  );
}
