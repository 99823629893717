import { User } from "./../pages/Messaging/Users/types";
import { SelectOption } from "@/types";
import React from "react";
export const UsersContext = React.createContext<{
  users: User[];
  userOptions: SelectOption[];
  getDisplayNameForUser: (userId: number) => string;
}>({
  users: [],
  userOptions: [],
  getDisplayNameForUser: () => "Unknown User"
});
