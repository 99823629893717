import React from "react";
import { IconProps } from "./types";

//#64748B
function IconMessageNotificationSquare({
  className = "stroke-slate-500",
  ...props
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={className}
      {...props}
    >
      <path
        d="M14.6667 5.33333H10.4C8.15979 5.33333 7.03969 5.33333 6.18404 5.76931C5.43139 6.1528 4.81947 6.76472 4.43597 7.51737C4 8.37302 4 9.49312 4 11.7333V18.6667C4 19.9066 4 20.5266 4.1363 21.0353C4.50617 22.4156 5.58436 23.4938 6.96472 23.8637C7.47339 24 8.09337 24 9.33333 24V27.114C9.33333 27.8245 9.33333 28.1797 9.47897 28.3622C9.60563 28.5208 9.79769 28.6131 10.0007 28.6129C10.2342 28.6127 10.5116 28.3908 11.0663 27.9469L14.247 25.4024C14.8967 24.8826 15.2216 24.6228 15.5833 24.4379C15.9043 24.274 16.2459 24.1541 16.599 24.0817C16.9969 24 17.4129 24 18.245 24H20.2667C22.5069 24 23.627 24 24.4826 23.564C25.2353 23.1805 25.8472 22.5686 26.2307 21.816C26.6667 20.9603 26.6667 19.8402 26.6667 17.6V17.3333M26.8284 5.17157C28.3905 6.73367 28.3905 9.26633 26.8284 10.8284C25.2663 12.3905 22.7337 12.3905 21.1716 10.8284C19.6095 9.26633 19.6095 6.73367 21.1716 5.17157C22.7337 3.60948 25.2663 3.60948 26.8284 5.17157Z"
        stroke="inherit"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconMessageNotificationSquare;
