import React, { useState } from "react";

interface Props {
  title: string;
  children: React.ReactNode;
}

export default function Panel(props: Props) {
  const [expanded, setExpanded] = useState(true);

  let iconClassName = "transition fa-solid fa-angle-down";

  if (expanded) {
    iconClassName += " fa-rotate-180";
  }

  return (
    <div className="panel bg-gray-100 dark:bg-stone-300 rounded-md shadow-md mb-4">
      <div
        className="panel-header flex items-center h-16 px-4 bg-cornflower-blue-400 hover:bg-cornflower-blue-500 cursor-pointer"
        onClick={() => setExpanded((s) => !s)}
      >
        <div className="text-slate-50 dark:text-white font-medium text-lg">
          {props.title}
        </div>
        <div
          className="ml-auto dark:text-white"
          onClick={() => setExpanded((s) => !s)}
        >
          {/* <button className="h-8 w-8">
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </button> */}
          <button
            className="h-8 w-8 text-slate-100"
            onClick={() => setExpanded((s) => !s)}
          >
            <i className={iconClassName}></i>
          </button>
        </div>
      </div>
      {expanded && (
        <div className="panel-body border-t animated fadeIn dark:border-stone-100">
          {props.children}
        </div>
      )}
    </div>
  );
}
