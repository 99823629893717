import Modal from "@velaro/velaro-shared/src/components/Modal";
import useApi from "@/hooks/useApi";
import React, { useState } from "react";

import AutoResponseForm, {
  AutoResponseModel,
  defaultAutoResponseModel
} from "./AutoResponseForm";
import LoadingSpinner from "@/components/LoadingSpinner";
import {
  SecondaryBlueButton,
  PrimaryBlueButton
} from "@velaro/velaro-shared/src/components/Buttons/Buttons";

interface Props {
  autoResponseId: number;
  onClose(): void;
  onUpdate(): void;
}

export default function EditModal(props: Props) {
  const [autoResponse, setAutoResponse] = React.useState<AutoResponseModel>(
    defaultAutoResponseModel
  );

  const api = useApi();
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);

  React.useEffect(() => {
    async function fetchAutoResponse() {
      const response = await api.messaging.get(
        `AutoResponses/${props.autoResponseId}`
      );
      setAutoResponse(await response.json());
    }

    fetchAutoResponse();
  }, [api.messaging, props.autoResponseId]);

  async function save() {
    setSending(true);
    const response = await api.messaging.put(
      `AutoResponses/${props.autoResponseId}`,
      autoResponse
    );
    setSuccess(response.ok);
    if (response.ok) {
      props.onUpdate();
    } else {
      alert("error");
    }
    setSending(false);
  }

  return (
    <Modal show={true}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          save();
        }}
      >
        <Modal.Header
          title="Edit Auto Response"
          onClose={() => props.onClose()}
        />
        <Modal.Body>
          <div className="p-4">
            <AutoResponseForm
              autoResponse={autoResponse}
              onChange={(updates) =>
                setAutoResponse((oldState) => ({ ...oldState, ...updates }))
              }
            />
          </div>
          <div className="p-4 flex flex-col mt-4">
            {success === false && (
              <div className="flex flex-row mb-4">
                There was an error saving your changes. Please see Integration
                Log for more details.
              </div>
            )}
            {sending ? (
              <div className="ml-auto flex">
                Saving Changes...
                <LoadingSpinner />
              </div>
            ) : (
              <div className="ml-auto flex flex-row">
                <div className="mr-2">
                  <SecondaryBlueButton onClick={props.onClose} label="Cancel" />
                </div>
                <PrimaryBlueButton
                  onClick={() => save()}
                  label="Save Changes"
                />
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>{}</Modal.Footer>
      </form>
    </Modal>
  );
}
