import React from "react";

export default function IllustrationCreateNewWorkflow1() {
  return (
    <svg viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M130.97 48.1901L377.95 56.2201C419.14 57.5601 453.49 89.3301 456.7 127.24L472.72 316.56C475.93 354.47 447.41 391.56 407.03 399.81L164.91 449.24C106.35 461.2 52.42 431.34 48.22 381.77L27.27 134.29C23.08 84.7301 71.23 46.2301 130.96 48.1701L130.97 48.1901Z"
        fill="#EEF4FD"
      />
      <path
        d="M210.641 122.717L204.37 123.891L205.251 128.599L211.522 127.425L210.641 122.717Z"
        fill="black"
      />
      <path
        d="M217.74 195.31L216.18 186.97L222.45 185.8L224.01 194.14L217.74 195.31ZM214.62 178.63L213.06 170.29L219.33 169.12L220.89 177.46L214.62 178.63ZM211.5 161.95L209.94 153.61L216.21 152.44L217.77 160.78L211.5 161.95ZM208.37 145.28L206.81 136.94L213.08 135.77L214.64 144.11L208.37 145.28Z"
        fill="black"
      />
      <path
        d="M225.568 202.48L219.297 203.653L220.178 208.361L226.449 207.188L225.568 202.48Z"
        fill="black"
      />
      <path
        d="M216.054 155.076L211.364 156.049L212.661 162.296L217.351 161.322L216.054 155.076Z"
        fill="black"
      />
      <path
        d="M226.45 159.43L225.15 153.18L234.24 151.29L235.54 157.54L226.45 159.43ZM244.62 155.66L243.32 149.41L252.41 147.52L253.71 153.77L244.62 155.66ZM262.8 151.89L261.5 145.64L270.59 143.75L271.89 150L262.8 151.89ZM280.97 148.12L279.67 141.87L288.76 139.98L290.06 146.23L280.97 148.12ZM299.15 144.34L297.85 138.09L306.94 136.2L308.24 142.45L299.15 144.34ZM317.32 140.57L316.02 134.32L325.11 132.43L326.41 138.68L317.32 140.57Z"
        fill="black"
      />
      <path
        d="M337.22 137.97L336.96 136.49L335.5 136.8L334.2 130.55L342.11 128.91L343.51 136.86L337.22 137.97Z"
        fill="black"
      />
      <path
        d="M344.28 178.03L342.52 168.02L348.81 166.91L350.57 176.92L344.28 178.03ZM340.75 158L338.99 147.99L345.28 146.88L347.04 156.89L340.75 158Z"
        fill="black"
      />
      <path
        d="M352.329 186.937L346.046 188.044L346.877 192.761L353.16 191.655L352.329 186.937Z"
        fill="black"
      />
      <path
        d="M144.8 62.11L153.03 133.68L248.88 113.22L234.98 46.24L144.8 62.11Z"
        fill="black"
      />
      <path
        d="M150.26 137.54L141.29 59.4901L237.47 42.5601L252.65 115.67L150.25 137.53L150.26 137.54ZM148.31 64.7401L155.79 129.83L245.11 110.76L232.48 49.9201L148.32 64.7401H148.31Z"
        fill="black"
      />
      <path
        d="M163.15 205.83L172.53 274.9L279.62 244.05L265.36 183.39L163.15 205.83Z"
        fill="black"
      />
      <path
        d="M169.86 278.99L159.59 203.34L267.74 179.6L283.41 246.28L169.85 279L169.86 278.99ZM166.71 208.31L175.19 270.81L275.81 241.82L262.97 187.18L166.71 208.31Z"
        fill="black"
      />
      <path
        d="M413.41 152.36L425.71 214.42L322.66 239.07L313.11 169.48L413.41 152.36Z"
        fill="black"
      />
      <path
        d="M319.98 242.99L309.53 166.85L415.94 148.7L429.44 216.81L319.98 242.99ZM316.69 172.1L325.34 235.14L421.98 212.02L410.88 156.03L316.69 172.1Z"
        fill="black"
      />
      <path
        d="M153.03 60.67L160.95 128.73L248.88 113.22L234.98 46.24L153.03 60.67Z"
        fill="#AFCFF8"
      />
      <path
        d="M157.11 133.88L148.17 57.05L238.42 41.16L254.12 116.76L157.11 133.87V133.88ZM157.88 64.28L164.78 123.58L243.65 109.67L231.53 51.32L157.88 64.29V64.28Z"
        fill="black"
      />
      <path
        d="M171.13 203.23L178.98 267.48L279.62 244.05L267.12 182.12L171.13 203.23Z"
        fill="#629FF0"
      />
      <path
        d="M177.49 269.6L169.22 201.88L268.46 180.05L281.64 245.35L177.49 269.6ZM173.04 204.58L180.46 265.36L277.59 242.74L265.77 184.18L173.03 204.58H173.04Z"
        fill="black"
      />
      <path
        d="M176.6 273.58L167.67 200.49L271.95 177.55L286.18 248.06L176.6 273.58ZM177.39 207.36L184.16 262.77L275.86 241.42L265.09 188.07L177.39 207.36Z"
        fill="black"
      />
      <path
        d="M413.41 152.36L425.71 214.42L327.99 231.61L318.46 168.43L413.41 152.36Z"
        fill="#629FF0"
      />
      <path
        d="M324.31 236.73L313.46 164.81L416.9 147.3L430.91 217.97L324.31 236.73ZM323.46 172.05L331.67 226.49L420.51 210.85L409.92 157.41L323.46 172.04V172.05Z"
        fill="black"
      />
      <path
        d="M138.54 316.15L150.21 324.66C171.25 294.95 157.43 265.07 157.43 265.07L245.61 207.82L266.21 331.42C263.72 331.04 237.08 315.59 214.09 360.23L227.53 370.95L195.41 453.76L74.28 358.33L138.53 316.16L138.54 316.15Z"
        fill="black"
      />
      <path
        d="M152.62 252.26L248.28 211.45L261.41 318.61C261.41 318.61 222.25 320.9 204.67 355.04L145.41 311.85C166.45 282.14 152.63 252.26 152.63 252.26H152.62Z"
        fill="white"
      />
      <path
        d="M205.66 359.26L141.49 312.49L143.09 310.22C162.96 282.16 150.18 253.73 150.05 253.44L148.83 250.81L250.6 207.39L264.55 321.25L261.55 321.42C261.18 321.44 223.76 324.07 207.15 356.33L205.64 359.25L205.66 359.26ZM149.26 311.18L203.73 350.88C219.62 323.34 248.82 317.35 258.26 316.11L245.94 215.51L156.25 253.77C159.12 261.77 165.58 286.15 149.26 311.17V311.18Z"
        fill="black"
      />
      <path
        d="M245.855 210.061L202.87 283.24L207.724 286.091L250.71 212.912L245.855 210.061Z"
        fill="black"
      />
      <path
        d="M198.46 305.64C204.878 305.64 210.08 300.437 210.08 294.02C210.08 287.602 204.878 282.4 198.46 282.4C192.042 282.4 186.84 287.602 186.84 294.02C186.84 300.437 192.042 305.64 198.46 305.64Z"
        fill="#629FF0"
      />
      <path
        d="M198.42 308.46C195.39 308.46 192.45 307.51 189.95 305.69C183.52 301 182.1 291.96 186.79 285.53C191.48 279.1 200.52 277.68 206.95 282.37C213.38 287.06 214.8 296.1 210.11 302.53C207.84 305.65 204.49 307.69 200.68 308.29C199.92 308.41 199.16 308.47 198.41 308.47L198.42 308.46ZM191.34 288.84C189.96 290.74 189.39 293.07 189.76 295.39C190.13 297.71 191.37 299.75 193.27 301.14C197.19 304 202.71 303.13 205.57 299.21C208.43 295.29 207.56 289.77 203.64 286.91C199.72 284.05 194.2 284.92 191.34 288.84Z"
        fill="black"
      />
      <path
        d="M133.73 303.35L219.69 365.99L190.61 440.95L76.96 361.96L133.73 303.35Z"
        fill="#AFCFF8"
      />
      <path
        d="M110.222 326.972L106.979 331.574L204.794 400.499L208.037 395.897L110.222 326.972Z"
        fill="black"
      />
      <path
        d="M191.94 445.31L72.63 362.38L133.41 299.63L223.09 364.99L191.94 445.31ZM81.28 361.54L189.27 436.6L216.27 366.99L134.04 307.06L81.28 361.53V361.54Z"
        fill="black"
      />
      <path
        d="M254.019 361.06L246.11 362.262L250.689 392.396L258.598 391.194L254.019 361.06Z"
        fill="black"
      />
      <path
        d="M301.403 283.645L271.826 291.57L273.896 299.298L303.473 291.373L301.403 283.645Z"
        fill="black"
      />
      <path
        d="M288.978 336.362L285.411 343.523L310.044 355.794L313.611 348.633L288.978 336.362Z"
        fill="black"
      />
    </svg>
  );
}
