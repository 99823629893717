import React from "react";
interface Props {
  icon: any;
  text: string;
  action: () => void;
}

export default function AIConfigurationTablePopoverRow(props: Props) {
  return (
    <div
      className="flex p-2 hover:bg-slate-100 rounded-md hover:text-cornflower-blue-500 hover:stroke-cornflower-blue-500"
      onClick={props.action}
    >
      <div className="pr-2">{props.icon}</div>
      {props.text}
    </div>
  );
}
