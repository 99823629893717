import React from "react";

export default function IllustrationMyWorkflows() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="130"
      height="130"
      viewBox="0 0 130 130"
      fill="none"
    >
      <path
        d="M34.052 12.5293L98.2668 14.6171C108.976 14.9655 117.907 23.2257 118.742 33.0823L122.907 82.3055C123.742 92.1621 116.326 101.806 105.828 103.951L42.8764 116.802C27.6508 119.912 13.629 112.148 12.537 99.2601L7.09003 34.9153C6.00063 22.0297 18.5196 12.0197 34.0494 12.5241L34.052 12.5293Z"
        fill="#EEF4FD"
      />
      <path
        d="M52.1847 33.7461L50.5542 34.0513L50.7833 35.2754L52.4138 34.9703L52.1847 33.7461Z"
        fill="black"
      />
      <path
        d="M54.0332 52.6187L53.6276 50.4503L55.2578 50.1461L55.6634 52.3145L54.0332 52.6187ZM53.2194 48.2819L52.8138 46.1135L54.444 45.8093L54.8496 47.9777L53.2194 48.2819ZM52.4082 43.9451L52.0026 41.7767L53.6328 41.4725L54.0384 43.6409L52.4082 43.9451ZM51.597 39.6083L51.1914 37.4399L52.8216 37.1357L53.2272 39.3041L51.597 39.6083Z"
        fill="black"
      />
      <path
        d="M56.066 54.4844L54.4355 54.7896L54.6646 56.0137L56.2951 55.7086L56.066 54.4844Z"
        fill="black"
      />
      <path
        d="M58.319 41.1779L55.9565 41.668L56.2935 43.2922L58.656 42.8021L58.319 41.1779Z"
        fill="black"
      />
      <path
        d="M63.0446 40.1964L60.6821 40.6865L61.0191 42.3107L63.3816 41.8206L63.0446 40.1964Z"
        fill="black"
      />
      <path
        d="M67.7721 39.215L65.4097 39.7056L65.7469 41.3297L68.1093 40.8392L67.7721 39.215Z"
        fill="black"
      />
      <path
        d="M72.4953 38.2345L70.1328 38.7246L70.4698 40.3488L72.8323 39.8587L72.4953 38.2345Z"
        fill="black"
      />
      <path
        d="M77.2218 37.2514L74.8594 37.7419L75.1966 39.3661L77.559 38.8756L77.2218 37.2514Z"
        fill="black"
      />
      <path
        d="M81.9465 36.2748L79.584 36.7649L79.9209 38.3891L82.2834 37.899L81.9465 36.2748Z"
        fill="black"
      />
      <path
        d="M86.6472 35.3114L84.2847 35.8015L84.6216 37.4257L86.9841 36.9356L86.6472 35.3114Z"
        fill="black"
      />
      <path
        d="M90.2072 36.8655L90.1396 36.4807L89.422 36.5951L89.084 34.9701L91.4786 34.5073L91.8426 36.5769L90.2072 36.8655Z"
        fill="black"
      />
      <path
        d="M91.7646 46.3319L91.307 43.7293L92.9424 43.4407L93.4 46.0433L91.7646 46.3319ZM90.8468 41.1241L90.3892 38.5215L92.0246 38.2329L92.4822 40.8355L90.8468 41.1241Z"
        fill="black"
      />
      <path
        d="M93.8568 48.6492L92.2231 48.937L92.4392 50.1635L94.0728 49.8758L93.8568 48.6492Z"
        fill="black"
      />
      <path
        d="M93.8551 47.0387L92.231 47.376L92.4841 48.5954L94.1083 48.2581L93.8551 47.0387Z"
        fill="black"
      />
      <path
        d="M92.9761 50.9547L94.6011 50.6167L95.0925 52.9801L93.4675 53.3181L92.9761 50.9547ZM93.9563 55.6815L95.5813 55.3435L96.0727 57.7069L94.4477 58.0449L93.9563 55.6815ZM94.9365 60.4057L96.5615 60.0677L97.0529 62.4311L95.4279 62.7691L94.9365 60.4057ZM95.9167 65.1325L97.5417 64.7945L98.0331 67.1579L96.4081 67.4959L95.9167 65.1325ZM96.8995 69.8567L98.5245 69.5187L99.0159 71.8821L97.3909 72.2201L96.8995 69.8567Z"
        fill="black"
      />
      <path
        d="M35.0688 17.9868L37.206 36.595L62.1296 31.2728L58.513 13.8606L35.0688 17.9868Z"
        fill="black"
      />
      <path
        d="M36.486 37.5986L34.1538 17.3056L59.1606 12.9038L63.1074 31.9124L36.4834 37.596L36.486 37.5986ZM35.9816 18.668L37.9264 35.5914L61.1496 30.6332L57.8658 14.8148L35.9842 18.668H35.9816Z"
        fill="black"
      />
      <path
        d="M39.8398 55.3513L42.276 73.3121L70.1194 65.2885L66.4144 49.5195L39.8398 55.3513Z"
        fill="black"
      />
      <path
        d="M41.5843 74.3756L38.9141 54.7066L67.0331 48.5342L71.1073 65.871L41.5817 74.3782L41.5843 74.3756ZM40.7653 55.9988L42.9701 72.2488L69.1313 64.7114L65.7929 50.505L40.7653 55.9988Z"
        fill="black"
      />
      <path
        d="M104.908 41.4519L108.106 57.5849L81.31 63.9965L78.8296 45.9005L104.908 41.4519Z"
        fill="black"
      />
      <path
        d="M80.613 65.0156L77.896 45.2192L105.563 40.5002L109.073 58.2088L80.613 65.0156ZM79.7602 46.5842L82.0092 62.9746L107.136 56.9634L104.25 42.406L79.7602 46.5842Z"
        fill="black"
      />
      <path
        d="M37.2061 17.6098L39.2653 35.308L62.1297 31.2728L58.5131 13.8606L37.2061 17.6098Z"
        fill="#AFCFF8"
      />
      <path
        d="M38.2692 36.6443L35.9448 16.6685L59.4098 12.5371L63.4918 32.1931L38.2692 36.6417V36.6443ZM38.4694 18.5509L40.2634 33.9689L60.7696 30.3523L57.6184 15.1813L38.4694 18.5535V18.5509Z"
        fill="black"
      />
      <path
        d="M41.9121 54.678L43.9531 71.383L70.1195 65.2886L66.8695 49.1868L41.9121 54.678Z"
        fill="#629FF0"
      />
      <path
        d="M43.5682 71.9341L41.418 54.3269L67.2204 48.6511L70.6472 65.6291L43.5682 71.9341ZM42.4086 55.0289L44.3378 70.8317L69.5916 64.9505L66.5184 49.7249L42.406 55.0289H42.4086Z"
        fill="black"
      />
      <path
        d="M43.334 72.9663L41.0122 53.9629L68.125 47.9985L71.8248 66.3311L43.334 72.9663ZM43.5394 55.7517L45.2996 70.1583L69.1416 64.6073L66.3414 50.7363L43.5394 55.7517Z"
        fill="black"
      />
      <path
        d="M104.907 41.4519L108.105 57.5849L82.698 62.0569L80.2202 45.6301L104.907 41.4519Z"
        fill="#629FF0"
      />
      <path
        d="M81.739 63.388L78.918 44.6888L105.812 40.1362L109.455 58.5104L81.739 63.388ZM81.5206 46.5712L83.6552 60.7256L106.754 56.6592L104 42.7648L81.5206 46.5686V46.5712Z"
        fill="black"
      />
      <path
        d="M65.7898 109.308L63.7334 109.62L64.9239 117.455L66.9803 117.143L65.7898 109.308Z"
        fill="black"
      />
      <path
        d="M78.1089 89.179L70.4189 91.2395L70.9573 93.2486L78.6472 91.1881L78.1089 89.179Z"
        fill="black"
      />
      <path
        d="M74.8801 102.886L73.9526 104.748L80.3572 107.938L81.2846 106.077L74.8801 102.886Z"
        fill="black"
      />
      <path
        d="M64.3632 99.3643L51.4854 91.2184L43.4098 105.134L25.6336 91.8502L36.3716 76.9756L22.9634 69.0612L57.2782 60.4084L64.3632 99.3643Z"
        fill="black"
      />
      <path
        d="M43.6592 106.345L24.4946 92.0244L35.1754 77.2252L20.8208 68.7518L57.928 59.397L65.507 101.062L51.766 92.3702L43.6566 106.345H43.6592ZM26.7748 91.676L43.1626 103.922L51.2018 90.0666L63.2164 97.669L56.6228 61.4224L25.1004 69.3706L37.5622 76.7286L26.7722 91.6786L26.7748 91.676Z"
        fill="black"
      />
      <path
        d="M62.678 96.8735L50.0134 88.3923L42.2004 101.99L26.616 91.0287L36.3556 77.5321L22.7212 69.4825L57.6496 60.6243L62.678 96.8735Z"
        fill="white"
      />
      <path
        d="M42.4683 103.205L25.4409 91.2263L35.1363 77.7843L20.5425 69.1679L58.3491 59.5791L63.7623 98.6051L50.2891 89.5831L42.4657 103.202L42.4683 103.205ZM27.7913 90.8337L41.9301 100.779L49.7327 87.1963L61.5913 95.1367L56.9477 61.6643L24.8949 69.7919L37.5699 77.2747L27.7913 90.8311V90.8337Z"
        fill="black"
      />
      <path
        d="M100.402 85.2252C103.26 84.9182 105.304 82.1253 104.967 78.9872C104.63 75.849 102.04 73.5539 99.1813 73.8609C96.323 74.1679 94.2791 76.9607 94.6161 80.0989C94.9531 83.237 97.5435 85.5321 100.402 85.2252Z"
        fill="black"
      />
      <path
        d="M99.8843 85.6205C98.5921 85.6205 97.3441 85.1369 96.3197 84.2295C95.1575 83.1999 94.4243 81.7465 94.2501 80.1371C94.0759 78.5277 94.4867 76.9521 95.4045 75.6989C96.3275 74.4353 97.6561 73.6527 99.1433 73.4915C102.198 73.1613 104.978 75.6105 105.334 78.9463C105.693 82.2821 103.498 85.2617 100.441 85.5919C100.254 85.6127 100.069 85.6231 99.8843 85.6231V85.6205ZM99.6919 74.1987C99.5359 74.1987 99.3799 74.2065 99.2213 74.2247C97.9447 74.3625 96.7981 75.0385 95.9999 76.1331C95.1913 77.2381 94.8325 78.6317 94.9859 80.0565C95.1393 81.4839 95.7867 82.7683 96.8111 83.6757C97.8251 84.5753 99.0861 84.9939 100.365 84.8561C103.017 84.5701 104.918 81.9545 104.603 79.0243C104.307 76.2683 102.154 74.1987 99.6945 74.1987H99.6919Z"
        fill="black"
      />
      <path
        d="M101.061 84.876C103.919 84.5691 105.963 81.7762 105.626 78.6381C105.289 75.4999 102.698 73.2048 99.84 73.5118C96.9817 73.8188 94.9378 76.6116 95.2748 79.7498C95.6118 82.8879 98.2022 85.183 101.061 84.876Z"
        fill="white"
      />
      <path
        d="M100.544 85.2721C99.2523 85.2721 98.0043 84.7885 96.9799 83.8811C95.8177 82.8515 95.0845 81.3981 94.9103 79.7887C94.7361 78.1793 95.1469 76.6037 96.0647 75.3505C96.9877 74.0869 98.3163 73.3043 99.8035 73.1431C102.858 72.8129 105.638 75.2621 105.994 78.5979C106.353 81.9337 104.158 84.9133 101.101 85.2435C100.914 85.2643 100.729 85.2747 100.544 85.2747V85.2721ZM100.352 73.8529C100.196 73.8529 100.04 73.8607 99.8815 73.8789C98.6049 74.0167 97.4583 74.6927 96.6601 75.7873C95.8515 76.8923 95.4927 78.2859 95.6461 79.7107C95.7995 81.1381 96.4469 82.4225 97.4713 83.3299C98.4853 84.2295 99.7463 84.6455 101.025 84.5103C103.677 84.2243 105.578 81.6087 105.263 78.6785C104.967 75.9225 102.814 73.8529 100.355 73.8529H100.352Z"
        fill="black"
      />
      <path
        d="M103.986 78.1073L97.5586 79.457L97.7104 80.1797L104.138 78.8299L103.986 78.1073Z"
        fill="black"
      />
      <path
        d="M100.589 75.8642L99.8667 76.0159L101.215 82.4435L101.938 82.2919L100.589 75.8642Z"
        fill="black"
      />
    </svg>
  );
}
