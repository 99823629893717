import * as React from "react";

interface Props {
  allTags: string[];
  selectedTags: string[];
  onChange(selectedTags: string[]): void;
}

export default function TagSelect(props: Props) {
  function selectTag(tag: string) {
    if (!tag) {
      return;
    }

    if (props.selectedTags.indexOf(tag) === -1) {
      props.onChange([...props.selectedTags, tag]);
    }
  }

  function removeTag(index: number) {
    const tags = [...props.selectedTags];
    tags.splice(index, 1);
    props.onChange(tags);
  }

  const unselectedTags = React.useMemo(() => {
    return props.allTags.filter(tag => !props.selectedTags.includes(tag));
  }, [props.allTags, props.selectedTags]);

  return (
    <>
      <select
        className="form-control"
        value=""
        onChange={e => selectTag(e.currentTarget.value)}
      >
        <option value="" />
        {unselectedTags.map(tag => (
          <option key={tag} value={tag}>
            {tag}
          </option>
        ))}
      </select>
      {props.selectedTags.map((tag, index) => (
        <span
          className="label label-default"
          style={{
            display: "inline-block",
            marginTop: 4,
            marginRight: 4,
            cursor: "pointer"
          }}
          key={tag}
          onClick={() => removeTag(index)}
        >
          {tag} <i className="fa fa-times" />
        </span>
      ))}
    </>
  );
}
