import React from "react";

interface Props {
  title: string;
  subtitle: string;
  input: any;
}

export default function AIChatbotSettingsRow(props: Props) {
  return (
    <div className="py-3">
      <div className="flex items-center pb-1">
        {props.input}
        <div className="font-semibold text-sm pl-3">{props.title}</div>
      </div>
      <div className="text-slate-500 text-sm pb-2">{props.subtitle}</div>
    </div>
  );
}
