import useApi from "@/hooks/useApi";
import * as React from "react";

export default function useAdminUsers() {
  const [adminUsers, setAdminUsers] = React.useState<
    { userId: number; userName: string }[]
  >([]);
  const api = useApi();

  React.useEffect(() => {
    async function fetchAdminUsers() {
      const resp = await api.main.get("Account/GetAllUsers");

      const users: any[] = await resp.json();

      const admins = users
        .filter((user) => user.IsAdministrator)
        .map((user) => ({
          userId: user.UserId,
          userName: user.UserName
        }))
        .sort((a, b) => (a.userName > b.userName ? 1 : -1));

      setAdminUsers(admins);
    }

    fetchAdminUsers();
  }, []);

  return { adminUsers };
}
