import FormValidator from "@velaro/velaro-shared/src/components/FormValidator";
import useApi from "@/hooks/useApi";
import useAsync from "@/hooks/useAsync";
import useTeams from "@/hooks/useTeams";
import useToastAlert from "@/hooks/useToastAlert";
import IconArrowBack2 from "@/icons/IconArrowBack2";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import React, { useEffect } from "react";
import {
  SecondaryBlueButton,
  PrimaryBlueButton
} from "@velaro/velaro-shared/src/components/Buttons/Buttons";

interface Props {
  Id: number;
  onClose(): void;
}

interface EditDeploymentModel {
  displayName: string;
  deploymentId: string;
  teamId: number;
}
const defaultDeploymentModel: EditDeploymentModel = {
  displayName: "",
  deploymentId: "",
  teamId: 0
};

export default function EditDeploymentPanel(props: Props) {
  const [deployment, setDeployment] = React.useState<EditDeploymentModel>(
    defaultDeploymentModel
  );
  const [selectedTeam, setSelectedTeam] = React.useState<number>(
    deployment.teamId
  );
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const { teams, getTeamNameById } = useTeams();
  const api = useApi();
  const toastAlert = useToastAlert();

  useEffect(() => {
    async function fetchDeployment() {
      const response = api.messaging.get(`Deployment/${props.Id}`);
      const editDeployment: EditDeploymentModel = await (await response).json();
      setDeployment(editDeployment);
      setSelectedTeam(editDeployment.teamId);
    }

    fetchDeployment();
  }, [api.messaging, getTeamNameById, props.Id]);

  const {
    execute: save,
    pending: saving,
    error: saveError
  } = useAsync({ asyncFunction: handleSubmit });

  async function handleSubmit() {
    setErrors({});

    const newErrors: Record<string, string> = {};

    if (deployment.displayName === "" || /^\s*$/.test(deployment.displayName)) {
      newErrors["DisplayName"] = "Display name required";
    }

    if (
      deployment.deploymentId === "" ||
      /^\s*$/.test(deployment.deploymentId)
    ) {
      newErrors["DeploymentId"] = "Deployment Id required";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length !== 0) {
      return;
    }

    const response = await api.messaging.put(
      `Deployment/${props.Id}`,
      deployment
    );

    if (response.ok) {
      //props.onDeploymentUpdated();
      toastAlert.displayToast(
        Severity.Success,
        "Deployment updated successfully"
      );
    }

    if (response.status === 400) {
      const errorResponse = await response.json();
      if (typeof errorResponse.errors === "object") {
        setErrors(errorResponse.errors);
      }
      toastAlert.displayToast(Severity.Error, errorResponse);
    }

    if (response.status === 401) {
      toastAlert.displayToast(
        Severity.Error,
        "You do not have permission to edit this deployment"
      );
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDeployment({ ...deployment, [name]: value });
  };

  const handleTeamChange = (event: any) => {
    setSelectedTeam(event.target.value);
    setDeployment({ ...deployment, teamId: event.target.value });
  };

  return (
    <div>
      <div className="flex justify-between">
        <div className="mb-4 font-bold text-lg">Edit Deployment</div>
        <div className="flex cursor-pointer" onClick={() => props.onClose()}>
          <IconArrowBack2 />
          <div className="ml-2 hover:underline">Back to all deployments</div>
        </div>
      </div>
      <div className="flex justify-center items-center h-full">
        <div className="panel bg-white dark:bg-stone-300 rounded-md shadow-md mb-4 p-4 pt-8 w-1/2">
          <div className="flex">
            <div className="w-1/3 font-semibold">Display name</div>
            <FormValidator error={errors["DisplayName"]} className="w-2/3">
              <input
                name="displayName"
                type="text"
                className="input w-full"
                autoFocus
                value={deployment?.displayName}
                onChange={handleChange}
              />
            </FormValidator>
          </div>
          <hr className="my-4 border-gray-100" />
          <div className="flex">
            <div className="w-1/3 font-semibold">Deployment Id</div>
            <FormValidator error={errors["DeploymentId"]} className="w-2/3">
              <input
                name="deploymentId"
                type="text"
                className="input w-full"
                value={deployment?.deploymentId}
                onChange={handleChange}
              />
            </FormValidator>
          </div>
          <hr className="my-4 border-gray-100" />
          <div className="flex">
            <div className="w-1/3 font-semibold">Team</div>
            <select
              className="input w-2/3"
              value={selectedTeam}
              onChange={handleTeamChange}
            >
              {teams.map((team) => (
                <option key={team.id} value={team.id}>
                  {team.name}
                </option>
              ))}
            </select>
          </div>
          <hr className="my-4 border-gray-100" />
          <div className="py-4 flex mt-4">
            <div className="ml-auto flex">
              <div className="mr-2">
                <SecondaryBlueButton label="Cancel" onClick={props.onClose} />
              </div>
              <PrimaryBlueButton label="Save Changes" onClick={save} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
