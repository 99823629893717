import Accordion from "@/components/Accordion";
import React, { useEffect, useMemo, useState } from "react";
import useLanguageConfigs from "../../hooks/useLanguageConfigs";
import { LanguageConfigModel } from "@/pages/Messaging/types";

interface Props {
  onExpanded(): void;
  workingConfig: LanguageConfigModel;
  update: (updates: Partial<LanguageConfigModel>) => void;
  persist: () => void;
  loading: boolean;
}

export default function FooterForm(props: Props) {
  const saveButtonText = useMemo(() => {
    return props.loading ? "Saving..." : "Save and set live";
  }, [props.loading]);

  return (
    <Accordion.Item
      eventKey="footerOpen"
      onExpanded={() => {
        props.onExpanded();
      }}
      onCollapsed={() => {}}
    >
      <Accordion.Header>
        <Accordion.Title>Footer</Accordion.Title>
        <Accordion.SubTitle>Customize the footer</Accordion.SubTitle>
      </Accordion.Header>
      <Accordion.Body>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            props.persist();
          }}
        >
          <div className="mb-4">
            <label className="block mb-2" htmlFor="privacy-title">
              Privacy Policy Title
            </label>
            <input
              className="input"
              type="text"
              id="privacy-title"
              value={props.workingConfig.privacyTitle}
              onChange={(e) => props.update({ privacyTitle: e.target.value })}
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="privacy-url">
              Privacy Policy URL
            </label>
            <input
              className="input"
              type="text"
              id="privacy-url"
              value={props.workingConfig.privacyUrl}
              onChange={(e) => props.update({ privacyUrl: e.target.value })}
            />
          </div>
          <div>
            <button className="btn-green" disabled={props.loading}>
              {saveButtonText}
            </button>
          </div>
        </form>
      </Accordion.Body>
    </Accordion.Item>
  );
}
