import * as React from "react";
import { QuestionType, SurveyQuestion, questionTypeDefs } from "./types";
import Select from "@/components/Select";
import IconTrash1 from "@/icons/IconTrash1";
import { useMemo } from "react";
import FormValidator from "@velaro/velaro-shared/src/components/FormValidator";

interface Props {
  question: SurveyQuestion;
  errors: Record<string, string>;
  update(question: SurveyQuestion): void;
  remove(): void;
}

//some question types will have hard-cded variables associated with them
function hasFixedVariable(type: QuestionType) {
  switch (type) {
    case "name":
    case "email":
      return true;
    default:
      return false;
  }
}

export default function QuestionEditor({
  question,
  errors,
  update,
  remove
}: Props) {
  const variableFixed = hasFixedVariable(question.type);

  let fixedVariableName = null;
  if (variableFixed) {
    fixedVariableName = question.variable;
  }

  function changeType(type: QuestionType) {
    const wasFixed = variableFixed;
    const isFixed = hasFixedVariable(type);

    if (isFixed) {
      //force variable for this question type
      update({ ...question, type: type, variable: type });
      return;
    }

    if (wasFixed) {
      //clear variable if switching off of forced variable type
      update({ ...question, type: type, variable: "" });
      return;
    }

    //otherwise leave variable alone
    update({ ...question, type: type });
  }

  const optionsList = useMemo(
    () => (question.options ? question.options.join("\n") : ""),
    [question.options]
  );
  return (
    <tr>
      <td>
        <FormValidator error={errors["text"]} errorClassName="absolute">
          <input
            type="text"
            className="input w-full"
            required
            value={question.text}
            onChange={(e) =>
              update({ ...question, text: e.currentTarget.value })
            }
          />
        </FormValidator>
      </td>
      <td>
        <div className="flex flex-col">
          <Select
            options={questionTypeDefs}
            className="input w-full"
            value={question.type || "text"}
            onChange={changeType}
          />
          {question.type === "choice" && (
            <textarea
              className="input mt-2"
              placeholder={`choice 1\nchoice 2\nchoice 3`}
              value={optionsList}
              onChange={(e) => {
                const val = e.currentTarget.value.split("\n");
                update({ ...question, options: val });
              }}
            />
          )}
        </div>
      </td>
      <td>
        <input
          disabled={variableFixed}
          type="text"
          className="input w-full"
          value={fixedVariableName || question.variable || ""}
          onChange={(e) =>
            update({ ...question, variable: e.currentTarget.value })
          }
        />
      </td>
      <td>
        <input
          type="checkbox"
          className="input w-full"
          checked={question.required}
          onChange={() => update({ ...question, required: !question.required })}
        />
      </td>
      <td>
        <button className="cursor-pointer" onClick={remove}>
          <IconTrash1 />
        </button>
      </td>
    </tr>
  );
}
