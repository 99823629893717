import React from "react";
import { SerializedCondition } from "../types";

export type TimeOfDayCondition = {
  from: string;
  to: string;
};

export function TimeOfDayEditor({
  condition,
  onUpdate
}: {
  condition: SerializedCondition;
  onUpdate: (condition: SerializedCondition) => void;
}) {
  const timeOfDayCondition = JSON.parse(
    condition.data || "{}"
  ) as TimeOfDayCondition;

  function update(data: TimeOfDayCondition) {
    onUpdate({ ...condition, data: JSON.stringify(data) });
  }

  return (
    <>
      <div className="w-full flex justify-between gap-4">
        <DateTimeInput
          onChange={(value) => {
            update({ ...timeOfDayCondition, from: value });
          }}
          value={timeOfDayCondition.from || ""}
          type="time"
          label="From"
        />
        <DateTimeInput
          onChange={(value) => {
            update({ ...timeOfDayCondition, to: value });
          }}
          value={timeOfDayCondition.to || ""}
          type="time"
          label="To"
        />
      </div>
    </>
  );
}

function DateTimeInput({
  type,
  label,
  value,
  onChange
}: {
  type: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
}) {
  return (
    <div className="w-full">
      <div className="text-slate-500 text-sm mb-1">{label}</div>
      <input
        type={type}
        value={value}
        onChange={(e) => {
          onChange(e.currentTarget.value);
        }}
        className="border rounded-md p-2 w-full"
      />
    </div>
  );
}
