import {
  LanguageConfigModel,
  StyleConfigModel
} from "@/pages/Messaging/types.js";
import throttle from "lodash/throttle";
import React, { useEffect, useRef, useState } from "react";
import initPreview from "./initPreview.js";

interface Props {
  type: string;
  workingStyleConfig: StyleConfigModel;
  workingLanguageConfig: LanguageConfigModel;
  aiConfigId?: string;
}

export default function Preview(props: Props) {
  const el = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (el.current) {
      initPreview(el.current);
    }

    return () => {
      (window as any).MessengerPreview.call("destroy");
      (window as any).MessengerPreview = undefined;
      (window as any).__messenger_preview = undefined;

      const script = document.getElementById("messenger-preview-script");

      if (script) {
        script.remove();
      }
    };
  }, []);

  const updateStyleConfig = useRef(
    throttle((styleConfig: StyleConfigModel) => {
      const mp = (window as any).MessengerPreview;

      if (mp && styleConfig) {
        mp.call("updateStyleConfig", styleConfig);
      }
    }, 25)
  );

  useEffect(() => {
    updateStyleConfig.current(props.workingStyleConfig);
  }, [props.workingStyleConfig]);

  const updateLanguageConfig = useRef(
    throttle((languageConfig: LanguageConfigModel) => {
      const mp = (window as any).MessengerPreview;

      if (mp && languageConfig) {
        mp.call("updateLanguageConfig", languageConfig);
      }
    }, 25)
  );

  useEffect(() => {
    updateLanguageConfig.current(props.workingLanguageConfig);
  }, [props.workingLanguageConfig]);

  useEffect(() => {
    const mp = (window as any).MessengerPreview;

    if (mp && props.type) {
      mp.call("updatePreview", props.type);
    }
  }, [props.type]);

  useEffect(() => {
    const mp = (window as any).MessengerPreview;

    if (mp && props.aiConfigId) {
      console.log("updateAiConfigId:", props.aiConfigId);
      mp.call("updateAiConfigId", props.aiConfigId);
    }
  }, [props.aiConfigId]);

  return <div ref={el}></div>;
}
