import Modal from "@velaro/velaro-shared/src/components/Modal";
import React from "react";
import { FieldDefinition } from ".";
import FieldForm, { defaultfieldModel } from "./FieldForm";

interface Props {
  show: boolean;
  onCancel(): void;
  submit(definition: FieldDefinition): void;
}

export default function CreateFieldModal(props: Props) {
  const [field, setField] = React.useState<FieldDefinition>(defaultfieldModel);

  return (
    <Modal show={props.show}>
      <Modal.Header title="Add Field" onClose={() => props.onCancel()} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          props.submit(field);
          setField(defaultfieldModel);
        }}
      >
        <Modal.Body>
          <div className="p-4">
            <FieldForm
              field={field}
              onChange={(updates) => {
                setField((oldState) => ({ ...oldState, ...updates }));
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="p-4 flex">
            <div className="ml-auto">
              <button
                type="button"
                onClick={props.onCancel}
                className="btn-link mr-2"
              >
                Cancel
              </button>
              <button type="submit" className="btn-green">
                Continue
              </button>
            </div>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
