import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import { ToastAlertSettings } from "../types";
import React from "react";
import { bool } from "yup";

interface Props {
  toastSettings: ToastAlertSettings;
  displayToast(severity: Severity, message: string): void;
  loading: boolean;
}

const ToastAlertContext = React.createContext<Props>({
  toastSettings: {
    stickyAlerts: false,
    stickyDuration: 5000,
  },
  displayToast() {},
  loading: true,
});

export default ToastAlertContext;
