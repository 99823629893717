import { Site } from "@/types";
import React from "react";
export const SiteContext = React.createContext<{
  site: Site;
}>({
  site: {
    id: 0,
    companyName: "unknown"
  }
});
