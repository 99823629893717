import React from "react";

export default function Input({
  type = "text",
  required = false,
  placeholder,
  className,
  value,
  prefixIcon,
  suffixIcon,
  onChange
}: {
  type?: "text" | "number";
  required?: boolean;
  placeholder?: string;
  className?: string;
  value: string | number;
  prefixIcon?: React.ReactNode;
  suffixIcon?: React.ReactNode;
  onChange(value: string): void;
}) {
  return (
    <div
      className={`px-3 w-full h-10 bg-white rounded-lg border border-slate-300 justify-start items-center flex gap-2 text-sm ${className}`}
    >
      {prefixIcon}
      <input
        placeholder={placeholder}
        required={required}
        type={type}
        className="w-full hover:border-none focus:border-none focus:outline-none"
        value={value || ""}
        onChange={(e) => onChange(e.currentTarget.value)}
      />
      {suffixIcon}
    </div>
  );
}
