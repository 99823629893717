import {
  channelDefs,
  dayOfWeekDefs,
  dayOfWeekKeys,
  SelectOption
} from "@/types";
import {
  ChannelCondition,
  ChannelEditor
} from "./ConditionEditors/ChannelEditor";
import { DomainCondition, DomainEditor } from "./ConditionEditors/DomainEditor";
import {
  DayOfWeekCondition,
  DayOfWeekEditor
} from "./ConditionEditors/DayOfWeekEditor";
import {
  TimeOfDayCondition,
  TimeOfDayEditor
} from "./ConditionEditors/TimeOfDayEditor";
import { DateCondition, DateEditor } from "./ConditionEditors/DateEditor";
import { UrlCondition, UrlEditor } from "./ConditionEditors/UrlEditor";
import {
  AttributeCondition,
  AttributeEditor
} from "./ConditionEditors/AttributeEditor";
import { TeamCondition, TeamEditor } from "./ConditionEditors/TeamEditor";
import { comparatorDefs, ExpressionType, SerializedCondition } from "./types";
import {
  TimeOnPageCondition,
  TimeOnPageEditor
} from "./ConditionEditors/TimeOnPageEditor";

interface ConditionProps {
  condition: SerializedCondition;
  onUpdate: (condition: SerializedCondition) => void;
  onRemove?: () => void;
}

interface ConditionDef {
  label: string;
  editor: (props: ConditionProps) => JSX.Element;
  toText: (
    condition: SerializedCondition,
    getTeamNameById: (id: number) => string
  ) => string;
  whitelist?: ExpressionType[]; //if this has a value, only these expression types will have this condition
  blacklist?: ExpressionType[]; //if this has a value, these expression types will not have this condition
}

export const conditionDefs: Record<string, ConditionDef> = {
  channel: {
    label: "Channel",
    editor: ChannelEditor,
    toText: (condition: SerializedCondition) => {
      if (!condition || !condition.data || !condition.type) {
        return "--condition is incomplete--";
      }
      const channelCondition = JSON.parse(condition.data) as ChannelCondition;
      if (!channelCondition.comparator || !channelCondition.channel) {
        return "--condition is incomplete--";
      }
      return `channel ${comparatorDefs[channelCondition.comparator]} ${
        channelDefs[channelCondition.channel]
      }`;
    },
    blacklist: [
      "determine-language-config",
      "determine-style-config",
      "send-invite"
    ]
  },
  timeOnPage: {
    label: "Time On Page",
    editor: TimeOnPageEditor,
    toText: (condition: SerializedCondition) => {
      if (!condition || !condition.data || !condition.type) {
        return "--condition is incomplete--";
      }
      const timeOnPage = JSON.parse(condition.data) as TimeOnPageCondition;
      return `time on page is ${timeOnPage.seconds} seconds`;
    },
    whitelist: ["send-invite"]
  },
  domain: {
    label: "Domain",
    editor: DomainEditor,
    toText: (condition: SerializedCondition) => {
      if (!condition || !condition.data || !condition.type) {
        return "--condition is incomplete--";
      }
      const domainCondition = JSON.parse(condition.data) as DomainCondition;
      return `domain ${comparatorDefs[domainCondition.comparator]} ${
        domainCondition.domain
      }`;
    }
  },
  url: {
    label: "Url",
    editor: UrlEditor,
    toText: (condition: SerializedCondition) => {
      if (!condition || !condition.data || !condition.type) {
        return "--condition is incomplete--";
      }
      const urlCondition = JSON.parse(condition.data) as UrlCondition;

      return `url ${comparatorDefs[urlCondition.comparator]} ${
        urlCondition.url
      }`;
    }
  },
  dayOfWeek: {
    label: "Day of Week",
    editor: DayOfWeekEditor,
    toText: (condition: SerializedCondition) => {
      if (!condition || !condition.data || !condition.type) {
        return "--condition is incomplete--";
      }
      const dayOfWeekCondition = JSON.parse(
        condition.data
      ) as DayOfWeekCondition;

      let daysText = "";
      const daysOfWeekSorted = dayOfWeekCondition.daysOfWeek.sort(
        (a, b) => dayOfWeekKeys.indexOf(a) - dayOfWeekKeys.indexOf(b)
      );
      const count = daysOfWeekSorted.length;

      if (count > 1) {
        daysOfWeekSorted.forEach((day, i) => {
          const dayName = dayOfWeekDefs[day];

          const last = i === daysOfWeekSorted.length - 1;

          if (last) {
            daysText += `or ${dayName}`;
          } else {
            daysText += `${dayName}`;
            if (count > 2) {
              daysText += ", ";
            } else {
              daysText += " ";
            }
          }
        });
      } else {
        daysText = dayOfWeekDefs[daysOfWeekSorted[0]];
      }

      return `day of week is ${daysText}`;
    }
  },
  timeOfDay: {
    label: "Time of Day",
    editor: TimeOfDayEditor,
    toText: (condition: SerializedCondition) => {
      if (!condition || !condition.data || !condition.type) {
        return "--condition is incomplete--";
      }
      const timeOfDayCondition = JSON.parse(
        condition.data
      ) as TimeOfDayCondition;
      return `time of day is ${timeOfDayCondition.from} to ${timeOfDayCondition.to}`;
    }
  },
  date: {
    label: "Date",
    editor: DateEditor,
    toText: (condition: SerializedCondition) => {
      if (!condition || !condition.data || !condition.type) {
        return "--condition is incomplete--";
      }
      const dateCondition = JSON.parse(condition.data) as DateCondition;
      return `date ${comparatorDefs[dateCondition.comparator]} ${
        dateCondition.date
      }`;
    }
  },
  attribute: {
    label: "Attribute",
    editor: AttributeEditor,
    toText: (condition: SerializedCondition) => {
      if (!condition || !condition.data || !condition.type) {
        return "--condition is incomplete--";
      }
      const attributeCondition = JSON.parse(
        condition.data
      ) as AttributeCondition;

      return `${attributeCondition.attribute} ${
        comparatorDefs[attributeCondition.comparator]
      } ${attributeCondition.value}`;
    }
  },
  team: {
    label: "Team",
    editor: TeamEditor,
    toText: (condition: SerializedCondition, getTeamNameById) => {
      if (!condition || !condition.data || !condition.type) {
        return "--condition is incomplete--";
      }
      const teamCondition = JSON.parse(condition.data) as TeamCondition;

      let teamsText = "";
      if (teamCondition.teamIds.length > 1) {
        teamCondition.teamIds.forEach((teamId, i) => {
          const teamName = getTeamNameById(teamId);
          if (i === teamCondition.teamIds.length - 1) {
            teamsText += `or ${teamName}`;
          } else {
            teamsText += `${teamName}`;
            if (teamCondition.teamIds.length > 2) {
              teamsText += ", ";
            } else {
              teamsText += " ";
            }
          }
        });
      } else {
        teamsText = getTeamNameById(teamCondition.teamIds[0]);
      }

      return `team ${comparatorDefs[teamCondition.comparator]} ${teamsText}`;
    }
  }
};

export type Condition = keyof typeof conditionDefs;
export const conditionOptions: SelectOption[] = Object.keys(conditionDefs).map(
  (key: Condition) => {
    return {
      label: conditionDefs[key].label,
      value: key
    };
  }
);
