import { Team } from "@/pages/Messaging/Teams/types";
import { SelectOption } from "@/types";
import React from "react";

export const TeamsContext = React.createContext<{
  teams: Team[];
  teamOptions: SelectOption[];
  getTeamNameById: (teamId: number) => string;
  setTeams: (teams: Team[]) => void;
  getTeamById: (TeamId: number) => Team | null;
}>({
  teams: [],
  teamOptions: [],
  getTeamNameById: () => "",
  setTeams: () => {},
  getTeamById: () => null
});
