import { SelectedTeamContext } from "@/context/SelectedTeamContext";
import React, { useState } from "react";
import useLocalStorage from "@velaro/velaro-shared/src/hooks/useLocalStorage";
import useTeams from "@/hooks/useTeams";

interface SelectedTeam {
  teamId: number;
}

export function SelectedTeamProvider(props: { children: React.ReactNode }) {
  const { teams } = useTeams();
  const [storageTeamId, setStorageTeamId] = useLocalStorage<SelectedTeam>(
    "selected_team",
    {
      teamId: teams[0].id
    }
  );
  const [teamId, setTeamId] = useState(storageTeamId.teamId);

  function updateTeamId(teamId: number) {
    setStorageTeamId({ teamId });
    setTeamId(teamId);
  }

  return (
    <SelectedTeamContext.Provider value={{ teamId, updateTeamId }}>
      {props.children}
    </SelectedTeamContext.Provider>
  );
}
