import SelectAllCheckbox from "@/components/SelectAllCheckbox";
import useApi from "@/hooks/useApi";
import React from "react";

interface UserListItem {
  id: number;
  displayName: string;
  email: string;
  picture: string;
}

interface Props {
  selectedUserIds: number[];
  onChange(userIds: number[]): void;
}

export default function UserSelectControl(props: Props) {
  const [users, setUsers] = React.useState<UserListItem[]>([]);
  const api = useApi();

  const selectedUserMap = React.useMemo(() => {
    const map: { [key: string]: boolean } = {};

    props.selectedUserIds.forEach((id) => {
      map[id] = true;
    });

    return map;
  }, [props.selectedUserIds]);

  React.useEffect(() => {
    async function fetchUserList() {
      const response = await api.messaging.get("Users/List");
      setUsers(await response.json());
    }

    fetchUserList();
  }, [api.messaging]);

  function selectAll() {
    props.onChange(users.map((x) => x.id));
  }

  function deselectAll() {
    props.onChange([]);
  }

  return (
    <div className="border p-3 rounded max-h-48 overflow-y-auto">
      <div>
        <SelectAllCheckbox
          id="usr-select-all"
          allSelected={users.length === props.selectedUserIds.length}
          someSelected={
            users.length > props.selectedUserIds.length &&
            props.selectedUserIds.length > 0
          }
          onChange={(checked) => {
            if (checked) {
              selectAll();
            } else {
              deselectAll();
            }
          }}
        />
        <label htmlFor="usr-select-all" className="ml-2">
          Select All
        </label>
      </div>
      {users.map((user) => (
        <div key={user.id}>
          <input
            type="checkbox"
            id={`usr-select-${user.id}`}
            checked={!!selectedUserMap[user.id]}
            onChange={(e) => {
              if (e.currentTarget.checked) {
                const newArr = [...props.selectedUserIds];
                newArr.push(user.id);
                props.onChange(newArr);
              } else {
                const newArr = [...props.selectedUserIds].filter(
                  (id) => id !== user.id
                );
                props.onChange(newArr);
              }
            }}
          />
          <label htmlFor={`usr-select-${user.id}`} className="ml-2">
            {user.displayName}
          </label>
        </div>
      ))}
    </div>
  );
}
