import * as React from "react";
import { getReadableDateForShift } from "./helpers/dateTime";
import { Shift } from "./types";

import "./ShiftCalendarView.css";

interface Props {
  shifts: Shift[];
  scheduleDisabled: boolean;
}

export default function ShiftCalendarView(props: Props) {
  const backgroundColorStyle = props.scheduleDisabled ? "#d6d6d6" : "#505050";

  function getBarPercentage(hour: number, minute: number, dayOfWeek: number) {
    const minutesInWeek = 10080;
    const minutesInDay = 1440;
    const minutesInHour = 60;
    const minuiteOfWeek =
      dayOfWeek * minutesInDay + hour * minutesInHour + minute;
    return (minuiteOfWeek / minutesInWeek) * 100;
  }

  function renderShiftBar(shift: Shift, style: any) {
    return (
      <div style={style} className="shift-bar">
        <span className="shift-tooltip">{getReadableDateForShift(shift)}</span>
      </div>
    );
  }

  function renderShift(shift: Shift, i: number) {
    const leftPercentage = getBarPercentage(
      shift.startHour,
      shift.startMinute,
      shift.startDayOfWeek
    );

    const rightPercentage = getBarPercentage(
      shift.endHour,
      shift.endMinute,
      shift.endDayOfWeek
    );

    //A chronologically backwards shift will instead stretched into "next week"
    //This needs to be displayed using two bars
    if (leftPercentage >= rightPercentage) {
      const leftStyle = {
        borderColor: backgroundColorStyle,
        left: `${leftPercentage}%`,
        right: `${0}%`
      };

      const rightStyle: any = {
        borderColor: backgroundColorStyle,
        left: `${0}%`,
        right: `${100 - rightPercentage}%`
      };

      if (rightPercentage === 0) {
        rightStyle["display"] = "none";
      }

      return (
        <React.Fragment key={i}>
          {renderShiftBar(shift, leftStyle)}
          {renderShiftBar(shift, rightStyle)}
        </React.Fragment>
      );
    }

    const style = {
      borderColor: backgroundColorStyle,
      left: `${leftPercentage}%`,
      right: `${100 - rightPercentage}%`
    };

    return (
      <React.Fragment key={i}>{renderShiftBar(shift, style)}</React.Fragment>
    );
  }
  return <React.Fragment>{props.shifts.map(renderShift)}</React.Fragment>;
}
