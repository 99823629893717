import * as React from "react";
import ckImageUploadPlugin from "@/kb/helpers/ckImageUploadPlugin";
import StorageModal from "./StorageModal";
import InternalLinkModal from "./InternalLinkModal";
import useApi from "@/hooks/useApi";
import { CKEditor } from "@ckeditor/ckeditor5-react";
//import useSubscription from "../hooks/useSubscription";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import useSubscriptions from "@/hooks/useSubscriptions";

interface Props {
  value: string;
  onChange(value: string): void;
}

export default function HtmlEditor(props: Props) {
  const [showStorageModal, setShowStorageModal] = React.useState(false);
  const [showInternalLinkModal, setShowInternalLinkModal] =
    React.useState(false);
  const storageCallback = React.useRef<any>();
  const internalLinkCallback = React.useRef<any>();
  const subscription = useSubscriptions();
  const api = useApi();

  return (
    <>
      <CKEditor
        editor={ClassicEditor}
        config={{
          toolbar: [
            "heading",
            "|",
            "alignment",
            "fontFamily",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            //todo
            //subscription.EnableStorage ? "storage" : "",
            "internalLink",
            "imageUpload",
            "blockQuote",
            "insertTable",
            "mediaEmbed",
            "undo",
            "redo"
          ],
          heading: {
            options: [
              {
                model: "paragraph",
                title: "Paragraph",
                class: "ck-heading_paragraph"
              },
              {
                model: "heading1",
                view: "h2",
                title: "Heading 1",
                class: "ck-heading_heading1"
              },
              {
                model: "heading2",
                view: "h3",
                title: "Heading 2",
                class: "ck-heading_heading2"
              },
              {
                model: "heading3",
                view: "h4",
                title: "Heading 3",
                class: "ck-heading_heading3"
              }
            ]
          },
          extraPlugins: [ckImageUploadPlugin("KBArticles/UploadImage")],
          mediaEmbed: {
            previewsInData: true,
            extraProviders: [
              {
                name: "mp4Provider",
                url: /(.*)\.mp4$/,
                html: (match: any[]) => {
                  const url = match[0];
                  return `<video width="100%" controls><source src="${url}" type="video/mp4"></video>`;
                }
              }
            ]
          }
          // storage: {
          //   onExecute(callback: any) {
          //     storageCallback.current = callback;
          //     setShowStorageModal(true);
          //   }
          // },
          // internalLink: {
          //   onExecute(callback: any) {
          //     internalLinkCallback.current = callback;
          //     setShowInternalLinkModal(true);
          //   }
          // }
        }}
        data={props.value}
        onChange={(event: any, editor: { getData: () => string }) => {
          props.onChange(editor.getData());
        }}
      />
      {showStorageModal && (
        <StorageModal
          onSelect={(payload) => {
            storageCallback.current(payload);
            setShowStorageModal(false);
          }}
          onClose={() => setShowStorageModal(false)}
        />
      )}
      {showInternalLinkModal && (
        <InternalLinkModal
          onSelect={(payload) => {
            internalLinkCallback.current(payload);
            setShowInternalLinkModal(false);
          }}
          onClose={() => setShowInternalLinkModal(false)}
        />
      )}
    </>
  );
}
