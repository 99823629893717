import React from "react";
import { AuthContext } from "../context/AuthContext";
import AuthService from "../services/AuthService";

export function AuthProvider(props: { children: React.ReactNode }) {
  return (
    <AuthContext.Provider value={AuthService}>
      {props.children}
    </AuthContext.Provider>
  );
}
