import { SubscriptionsContext } from "@/context/SubscriptionsContext";
import useApi from "@/hooks/useApi";
import React, { useCallback, useEffect, useState } from "react";

export interface Subscription {
  id: number;
  siteId: number;
  enableWeb: boolean;
  enableSms: boolean;
  enableEmail: boolean;
  enableWhatsapp: boolean;
  enableFacebook: boolean;
  enableIvr: boolean;
  enableNetsuite: boolean;
  enableAutomation: boolean;
  enableAI: boolean;
  enableWorkflowRules: boolean;
  enableFormstack: boolean;
  enableContactManager: boolean;
  enableKnowledgeBase: boolean;
  enableInvitations: boolean;
}

export function SubscriptionsProvider(props: { children: React.ReactNode }) {
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [loading, setLoading] = useState(true);
  const api = useApi();

  const fetch = useCallback(async () => {
    const response = await api.messaging.get("Subscription");
    const data = await response.json();
    setSubscription(data);
    setLoading(false);
    return data;
  }, [api.messaging]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <SubscriptionsContext.Provider value={{ subscription, loading }}>
      {props.children}
    </SubscriptionsContext.Provider>
  );
}
