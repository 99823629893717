import * as React from "react";
import useArticles from "../hooks/useArticles";
import Modal from "@velaro/velaro-shared/src/components/Modal";
import LoadingSpinner from "@velaro/velaro-shared/src/components/LoadingSpinner";

interface Props {
  onClose(): void;
  onSelect(payload: { text: string; url: string }): void;
}

export default function InternalLinkModal(props: Props) {
  const { articles, loadingArticles } = useArticles();
  const [searchText, setSearchText] = React.useState("");

  const filteredArticles = React.useMemo(() => {
    if (!searchText) {
      return articles;
    }

    try {
      return articles.filter(
        (article) =>
          article.Title.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
      );
    } catch (err) {
      console.error("filter failed:");
      console.error(err);
      return articles;
    }
  }, [articles, searchText]);

  return (
    <Modal show={true}>
      <Modal.Header title="Articles" onClose={() => props.onClose()} />
      <Modal.Body>
        {loadingArticles && <LoadingSpinner />}
        {!loadingArticles && (
          <>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setSearchText(e.currentTarget.value)}
              />
            </div>
            <table className="table table-condensed table-hover">
              <tbody>
                {filteredArticles.map((article) => (
                  <tr
                    key={article.Id}
                    onClick={() =>
                      props.onSelect({ text: article.Title, url: article.Url })
                    }
                  >
                    <td>{article.Title}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
