import React from "react";
import { IconProps } from "./types";

function IconWorkflows(props: IconProps) {
  return (
    <svg
      id="workflows"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_10214"
            data-name="Rectangle 10214"
            width="24"
            height="24"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g
        id="Group_9316"
        data-name="Group 9316"
        clipPath="url(#clip-path)"
        fill="currentColor"
      >
        <path
          id="Path_2328"
          data-name="Path 2328"
          d="M20.727,15.491h-.872V12a.873.873,0,0,0-.873-.873H12.873V8.509h.872a.873.873,0,0,0,.873-.873V4.145a.872.872,0,0,0-.873-.872h-3.49a.872.872,0,0,0-.873.872V7.636a.873.873,0,0,0,.873.873h.872v2.618H5.018A.873.873,0,0,0,4.145,12v3.491H3.273a.873.873,0,0,0-.873.873v3.491a.872.872,0,0,0,.873.872H6.764a.871.871,0,0,0,.872-.872V16.364a.872.872,0,0,0-.872-.873H5.891V12.873h5.236v2.618h-.872a.873.873,0,0,0-.873.873v3.491a.872.872,0,0,0,.873.872h3.49a.872.872,0,0,0,.873-.872V16.364a.873.873,0,0,0-.873-.873h-.872V12.873h5.236v2.618h-.873a.872.872,0,0,0-.872.873v3.491a.871.871,0,0,0,.872.872h3.491a.872.872,0,0,0,.873-.872V16.364a.873.873,0,0,0-.873-.873M5.891,18.982H4.146V17.237H5.891ZM11.127,5.018h1.745V6.763H11.127Zm1.746,13.964H11.128V17.237h1.745Zm6.982,0H18.11V17.237h1.745Z"
        />
      </g>
    </svg>
  );
}

export default IconWorkflows;
