import Accordion from "@/components/Accordion";
import React, { useMemo } from "react";
import { LanguageConfigModel } from "@/pages/Messaging/types";

interface Props {
  onExpanded(): void;
  workingConfig: LanguageConfigModel;
  update: (updates: Partial<LanguageConfigModel>) => void;
  persist: () => void;
  loading: boolean;
}

export default function ConversationListForm(props: Props) {
  const saveButtonText = useMemo(() => {
    return props.loading ? "Saving..." : "Save and set live";
  }, [props.loading]);

  return (
    <Accordion.Item
      eventKey="convoListOpen"
      onExpanded={() => {
        props.onExpanded();
      }}
      onCollapsed={() => {}}
    >
      <Accordion.Header>
        <Accordion.Title>Conversation List</Accordion.Title>
        <Accordion.SubTitle>Customize the conversation list</Accordion.SubTitle>
      </Accordion.Header>
      <Accordion.Body>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            props.persist();
          }}
        >
          <div className="mb-4">
            <label className="block mb-2" htmlFor="convo-list-title">
              Conversation List Title
            </label>
            <input
              className="input"
              type="text"
              id="convo-list-title"
              value={props.workingConfig.convoListTitle}
              onChange={(e) => props.update({ convoListTitle: e.target.value })}
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="convo-list-subtitle">
              Conversation List Subtitle
            </label>
            <input
              className="input"
              type="text"
              id="convo-list-subtitle"
              value={props.workingConfig.convoListSubtitle}
              onChange={(e) =>
                props.update({ convoListSubtitle: e.target.value })
              }
            />
          </div>
          <div>
            <button className="btn-green" disabled={props.loading}>
              {saveButtonText}
            </button>
          </div>
        </form>
      </Accordion.Body>
    </Accordion.Item>
  );
}
