import React from "react";
import { IconProps } from "./types";

function IconAgentTimeout({
  className = "stroke-slate-500 fill-slate-500",
  ...props
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      className={className}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6835 6.25985C14.1904 6.25985 11.9315 8.73175 11.9315 12.0889C11.9315 15.446 14.1904 17.9179 16.6835 17.9179C17.2796 17.9179 17.846 17.7911 18.3635 17.5481C18.7751 17.3547 19.2656 17.5317 19.459 17.9433C19.6523 18.355 19.4754 18.8455 19.0637 19.0389C18.3231 19.3867 17.5171 19.5649 16.6835 19.5649C13.0197 19.5649 10.2844 16.0705 10.2844 12.0889C10.2844 8.10721 13.0197 4.61279 16.6835 4.61279C20.3473 4.61279 23.0826 8.10721 23.0826 12.0889L23.0826 12.1041C23.0826 12.2731 23.0826 12.4868 23.0601 12.7076C23.0395 12.9622 23.0093 13.2127 22.9809 13.4395C22.9245 13.8909 22.5129 14.211 22.0616 14.1546C21.6103 14.0982 21.2902 13.6866 21.3466 13.2353C21.3751 13.0075 21.4016 12.785 21.4191 12.5664L21.421 12.5459C21.4348 12.4149 21.4355 12.277 21.4355 12.0889C21.4355 8.73175 19.1766 6.25985 16.6835 6.25985Z"
        fill="inherit"
        stroke="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.693 2.31356C13.0019 2.31356 10.0062 5.30925 10.0062 9.00035C10.0062 9.45517 9.63748 9.82388 9.18266 9.82388C8.72784 9.82388 8.35913 9.45517 8.35913 9.00035C8.35913 4.3996 12.0922 0.666504 16.693 0.666504C21.2937 0.666504 25.0268 4.39961 25.0268 9.00035C25.0268 9.45517 24.6581 9.82388 24.2033 9.82388C23.7485 9.82388 23.3798 9.45517 23.3798 9.00035C23.3798 5.30925 20.3841 2.31356 16.693 2.31356Z"
        fill="inherit"
        stroke="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7876 8.95292C20.7876 8.4981 21.1563 8.12939 21.6111 8.12939H24.051C25.4589 8.12939 26.5996 9.27009 26.5996 10.678V11.612C26.5996 13.0199 25.4589 14.1606 24.051 14.1606H22.4212C21.9664 14.1606 21.5977 13.7919 21.5977 13.3371C21.5977 12.8823 21.9664 12.5136 22.4212 12.5136H24.051C24.5493 12.5136 24.9526 12.1103 24.9526 11.612V10.678C24.9526 10.1797 24.5493 9.77645 24.051 9.77645H21.6111C21.1563 9.77645 20.7876 9.40774 20.7876 8.95292Z"
        fill="inherit"
        stroke="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.7771 10.678C6.7771 9.2701 7.9178 8.12939 9.32571 8.12939H11.7656C12.2204 8.12939 12.5891 8.4981 12.5891 8.95292C12.5891 9.40774 12.2204 9.77645 11.7656 9.77645H9.32571C8.82744 9.77645 8.42415 10.1797 8.42415 10.678V11.612C8.42415 12.1103 8.82744 12.5136 9.32571 12.5136H11.0508C11.5056 12.5136 11.8743 12.8823 11.8743 13.3371C11.8743 13.7919 11.5056 14.1606 11.0508 14.1606H9.32571C7.9178 14.1606 6.7771 13.0199 6.7771 11.612V10.678Z"
        fill="inherit"
        stroke="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0509 12.5132C24.5057 12.5132 24.8744 12.8819 24.8744 13.3367V13.7773C24.8744 14.1285 24.8744 14.4793 24.847 14.7869C24.8199 15.0922 24.7603 15.4389 24.5912 15.7551C24.1843 16.5161 23.401 16.6954 22.5927 16.6954H18.0369C17.5821 16.6954 17.2134 16.3267 17.2134 15.8719C17.2134 15.4171 17.5821 15.0484 18.0369 15.0484H22.5927C22.9066 15.0484 23.0575 15.0112 23.1208 14.9854C23.1296 14.9818 23.1357 14.9789 23.1397 14.9767C23.1545 14.948 23.187 14.86 23.2065 14.6409C23.2261 14.4202 23.2274 14.1475 23.2274 13.7751V13.3367C23.2274 12.8819 23.5961 12.5132 24.0509 12.5132Z"
        fill="inherit"
        stroke="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.7756 21.497C23.8268 21.497 22.247 23.0768 22.247 25.0256C22.247 26.9745 23.8268 28.5543 25.7756 28.5543C27.7245 28.5543 29.3043 26.9745 29.3043 25.0256C29.3043 23.0768 27.7245 21.497 25.7756 21.497ZM20.75 25.0256C20.75 22.2501 23.0001 20 25.7756 20C28.5512 20 30.8013 22.2501 30.8013 25.0256C30.8013 27.8012 28.5512 30.0513 25.7756 30.0513C23.0001 30.0513 20.75 27.8012 20.75 25.0256Z"
        fill="inherit"
        stroke="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.2412 22.4595C25.6546 22.4595 25.9897 22.7946 25.9897 23.208V25.3117L27.2868 25.9602C27.6565 26.1451 27.8064 26.5947 27.6215 26.9644C27.4366 27.3342 26.987 27.484 26.6173 27.2992L24.9064 26.4437C24.6529 26.3169 24.4927 26.0578 24.4927 25.7743V23.208C24.4927 22.7946 24.8278 22.4595 25.2412 22.4595Z"
        fill="inherit"
        stroke="none"
      />
      <path
        d="M4.75 30V27.3333C4.75 25.9188 5.3119 24.5623 6.3121 23.5621C7.31229 22.5619 8.66885 22 10.0833 22H15.4167"
        stroke="inherit"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconAgentTimeout;
