import React from "react";
import { IconProps } from "./types";

function IconChannels(props: IconProps) {
  return (
    <svg
      id="Chat_icon"
      data-name="Chat icon"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_2155"
            data-name="Rectangle 2155"
            width="24"
            height="24"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="Group_1066" data-name="Group 1066" clipPath="url(#clip-path)" fill="currentColor">
        <path
          id="Path_2162"
          data-name="Path 2162"
          d="M21.387,9.1H12.543a1.861,1.861,0,0,0-1.757,1.947V17.4a1.863,1.863,0,0,0,1.757,1.949h3.842l3.64,3.553V19.344h1.362A1.862,1.862,0,0,0,23.143,17.4V11.052A1.86,1.86,0,0,0,21.387,9.1"
        />
        <path
          id="Path_2163"
          data-name="Path 2163"
          d="M4.723,20.412a.75.75,0,0,1-.75-.75V16.007H2.992A3.066,3.066,0,0,1,.107,12.83V5A3.066,3.066,0,0,1,2.991,1.822H14.038A3.066,3.066,0,0,1,16.922,5v7.823a3.064,3.064,0,0,1-2.885,3.18h-4.5L5.247,20.2A.75.75,0,0,1,4.723,20.412ZM3.056,14.507H4.723a.75.75,0,0,1,.75.75v2.624L8.71,14.72a.75.75,0,0,1,.524-.213h4.739a1.56,1.56,0,0,0,1.45-1.628q0-.018,0-.036V4.986q0-.018,0-.036a1.562,1.562,0,0,0-1.45-1.629H3.056A1.562,1.562,0,0,0,1.606,4.95q0,.018,0,.036v7.86q0,.017,0,.035A1.561,1.561,0,0,0,3.056,14.507Z"
        />
        <path
          id="Path_2164"
          data-name="Path 2164"
          d="M12.231,7.971H4.62a.686.686,0,0,1,0-1.371h7.61a.686.686,0,1,1,0,1.371"
        />
        <path
          id="Path_2165"
          data-name="Path 2165"
          d="M8.534,10.769H4.621a.686.686,0,1,1,0-1.371H8.534a.686.686,0,1,1,0,1.371Z"
        />
      </g>
    </svg>
  );
}

export default IconChannels;
