import React from "react";

export default function IllustrationChatbotSupportService() {
  return (
    <svg viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M130.97 48.1899L377.95 56.2199C419.14 57.5599 453.49 89.3299 456.7 127.24L472.72 316.56C475.93 354.47 447.41 391.56 407.03 399.81L164.91 449.24C106.35 461.2 52.42 431.34 48.22 381.77L27.27 134.29C23.08 84.7299 71.23 46.2299 130.96 48.1699L130.97 48.1899Z"
        fill="#EEF4FD"
      />
      <path
        d="M358.67 312.43L390.05 179.97L359.34 173.93L299.05 294.09L358.67 312.43Z"
        fill="black"
      />
      <path
        d="M123.55 326.21L120.66 451.64L86.22 458.08L57.05 326.2L123.55 326.21Z"
        fill="black"
      />
      <path
        d="M118.47 329.38L296.58 288.59L308.78 404.89L176.26 443.04L118.47 329.38Z"
        fill="black"
      />
      <path
        d="M200.509 292.424C254.93 280.159 291.795 238.036 282.849 198.34C273.903 158.643 222.533 136.404 168.112 148.669C113.691 160.933 76.8255 203.056 85.7716 242.753C94.7176 282.45 146.087 304.688 200.509 292.424Z"
        fill="black"
      />
      <path
        d="M61.84 192.54L54.34 189.75C73.29 138.89 128.45 116.62 130.79 115.7L133.73 123.14C133.06 123.41 79.5 145.14 61.84 192.55V192.54Z"
        fill="black"
      />
      <path
        d="M123.55 326.21L120.66 451.64L94.58 449.76L69.29 324.11L123.55 326.21Z"
        fill="#629FF0"
      />
      <path
        d="M124.56 455.93L91.26 453.52L64.37 319.9L127.64 322.36L124.56 455.92V455.93ZM97.9 445.99L116.75 447.35L119.46 330.06L74.22 328.3L97.9 445.99Z"
        fill="black"
      />
      <path
        d="M354.76 300.74L384.99 178.98L359.34 173.93L301.86 288.49L354.76 300.74Z"
        fill="#629FF0"
      />
      <path
        d="M357.7 305.53L296.01 291.24L357.13 169.42L389.89 175.87L357.7 305.53ZM307.72 285.74L351.83 295.96L380.1 182.1L361.55 178.45L307.72 285.75V285.74Z"
        fill="black"
      />
      <path
        d="M204.23 285.202C258.652 272.938 295.517 230.815 286.571 191.118C277.625 151.422 226.255 129.183 171.834 141.448C117.412 153.712 80.5472 195.835 89.4932 235.532C98.4393 275.228 149.809 297.467 204.23 285.202Z"
        fill="#AFCFF8"
      />
      <path
        d="M174.56 292.57C130.43 292.57 93.27 270.55 85.58 236.42C76.17 194.63 114.46 150.28 170.95 137.56C198.09 131.45 225.4 133.43 247.84 143.15C270.71 153.05 285.85 169.79 290.47 190.27C295.08 210.75 288.58 232.36 272.17 251.11C256.06 269.51 232.25 283.01 205.11 289.13C194.75 291.46 184.49 292.57 174.58 292.57H174.56ZM201.76 142.07C192.24 142.07 182.47 143.16 172.7 145.36C120.52 157.12 84.93 197.18 93.38 234.66C100.23 265.07 134 284.59 174.36 284.59C183.75 284.59 193.48 283.54 203.33 281.32C228.83 275.58 251.13 262.97 266.13 245.84C280.82 229.06 286.69 209.94 282.65 192.02C278.61 174.1 265.12 159.35 244.65 150.49C231.76 144.91 217.09 142.08 201.76 142.08V142.07Z"
        fill="black"
      />
      <path
        d="M245.04 230L141.35 253.36L123.55 195.85L239.23 169.79L245.04 230Z"
        fill="white"
      />
      <path
        d="M138.63 258.08L118.45 192.9L242.77 164.89L249.36 233.13L138.63 258.08ZM128.65 198.8L144.08 248.65L240.72 226.88L235.68 174.69L128.65 198.8Z"
        fill="black"
      />
      <path
        d="M271.432 94.1262L263.584 95.6787L282.623 191.924L290.471 190.371L271.432 94.1262Z"
        fill="black"
      />
      <path
        d="M263.61 95.8701C274.12 95.8701 282.64 87.35 282.64 76.8401C282.64 66.3301 274.12 57.8101 263.61 57.8101C253.1 57.8101 244.58 66.3301 244.58 76.8401C244.58 87.35 253.1 95.8701 263.61 95.8701Z"
        fill="white"
      />
      <path
        d="M263.61 99.8701C250.91 99.8701 240.58 89.5401 240.58 76.8401C240.58 64.1401 250.91 53.8101 263.61 53.8101C276.31 53.8101 286.64 64.1401 286.64 76.8401C286.64 89.5401 276.31 99.8701 263.61 99.8701ZM263.61 61.8101C255.32 61.8101 248.58 68.5501 248.58 76.8401C248.58 85.1301 255.32 91.8701 263.61 91.8701C271.9 91.8701 278.64 85.1301 278.64 76.8401C278.64 68.5501 271.9 61.8101 263.61 61.8101Z"
        fill="black"
      />
      <path
        d="M168.947 226.942C173.672 225.878 176.359 219.939 174.948 213.679C173.537 207.418 168.563 203.206 163.838 204.271C159.113 205.336 156.426 211.274 157.837 217.535C159.248 223.795 164.222 228.007 168.947 226.942Z"
        fill="black"
      />
      <path
        d="M208.203 218.098C212.928 217.033 215.615 211.094 214.204 204.834C212.793 198.573 207.819 194.361 203.094 195.426C198.369 196.491 195.682 202.429 197.093 208.69C198.504 214.95 203.478 219.162 208.203 218.098Z"
        fill="black"
      />
      <path
        d="M126.64 326.88L296.58 288.59L308.78 404.89L176.54 430.68L126.64 326.88Z"
        fill="#AFCFF8"
      />
      <path
        d="M174.27 435.2L120.86 324.08L300.09 283.7L313.14 408.11L174.27 435.19V435.2ZM132.42 329.67L178.8 426.16L304.41 401.66L293.07 293.48L132.42 329.67Z"
        fill="black"
      />
      <path
        d="M262.02 346.15L193.22 365.67L202.15 425.69L288.39 408.87L262.02 346.15Z"
        fill="white"
      />
      <path
        d="M198.81 430.41L188.75 362.78L264.34 341.34L293.99 411.86L198.82 430.42L198.81 430.41ZM197.69 368.56L205.49 420.96L282.79 405.88L259.7 350.96L197.69 368.55V368.56Z"
        fill="black"
      />
      <path
        d="M276.119 377.63L197.142 393.368L198.705 401.214L277.683 385.476L276.119 377.63Z"
        fill="black"
      />
      <path
        d="M365.23 52.0701C356.69 45.5601 342.04 43.7301 333.19 48.5701L360.38 69.0401L349 83.9601L323.92 65.8501C321.07 75.9401 324.44 87.2001 333.29 93.9401C341 99.8201 350.91 100.78 359.28 97.3501L418.03 144.7C424.74 150.11 434.58 148.91 439.81 142.06C445.03 135.21 443.58 125.4 436.59 120.36L375.39 76.2301C376.49 67.2501 372.94 57.9501 365.23 52.0701ZM430.96 132.55C429.02 135.09 425.39 135.58 422.85 133.64C420.31 131.7 419.82 128.07 421.76 125.53C423.7 122.99 427.33 122.5 429.87 124.44C432.41 126.38 432.9 130.01 430.96 132.55Z"
        fill="black"
      />
      <path
        d="M365.23 47.31C356.69 40.8 345.46 40.32 336.61 45.16L360.38 64.28L349 79.2L323.92 61.09C321.07 71.18 324.44 82.44 333.29 89.18C341 95.06 350.91 96.02 359.28 92.59L418.03 139.94C424.74 145.35 434.58 144.15 439.81 137.3C445.03 130.45 443.58 120.64 436.59 115.6L375.39 71.47C376.49 62.49 372.94 53.19 365.23 47.31ZM430.96 130.55C429.02 133.09 425.39 133.58 422.85 131.64C420.31 129.7 419.82 126.07 421.76 123.53C423.7 120.99 427.33 120.5 429.87 122.44C432.41 124.38 432.9 128.01 430.96 130.55Z"
        fill="#AFCFF8"
      />
      <path
        d="M372.049 81.8632L368.365 86.5996L407.812 117.275L411.495 112.538L372.049 81.8632Z"
        fill="black"
      />
      <path
        d="M427.6 145.83C423.56 145.83 419.67 144.46 416.47 141.88L358.84 95.43C349.73 98.62 339.49 97.04 331.79 91.16C322.32 83.94 318.3 71.87 321.53 60.4L322.5 56.96L348.5 75.74L356.93 64.69L332.14 44.75L335.42 42.95C345.41 37.48 357.71 38.41 366.75 45.31C374.46 51.19 378.69 60.64 378.02 70.27L438.06 113.56C441.99 116.39 444.55 120.6 445.26 125.39C445.97 130.18 444.74 134.95 441.8 138.8C438.86 142.65 434.59 145.1 429.78 145.68C429.05 145.77 428.32 145.81 427.6 145.81V145.83ZM359.71 89.72L419.61 137.99C422.32 140.18 425.73 141.15 429.18 140.73C432.64 140.31 435.71 138.55 437.83 135.78C439.94 133.01 440.83 129.58 440.31 126.13C439.8 122.68 437.96 119.66 435.13 117.62L372.73 72.63L372.91 71.16C373.94 62.78 370.41 54.4 363.71 49.29C357.28 44.38 348.85 43.13 341.36 45.76L363.85 63.85L349.52 82.65L325.61 65.38C324.58 73.7 327.99 81.98 334.82 87.19C341.52 92.3 350.53 93.48 358.34 90.27L359.71 89.71V89.72ZM426.34 135.33C424.54 135.33 422.79 134.74 421.33 133.63C417.7 130.86 417 125.65 419.77 122.02C421.11 120.26 423.06 119.13 425.25 118.83C427.45 118.53 429.62 119.11 431.38 120.45C435.01 123.22 435.71 128.43 432.94 132.06C431.6 133.82 429.65 134.95 427.46 135.25C427.08 135.3 426.71 135.33 426.34 135.33ZM426.36 123.76C426.21 123.76 426.06 123.77 425.91 123.79C425.04 123.91 424.27 124.36 423.74 125.05C422.64 126.49 422.92 128.56 424.36 129.66C425.06 130.19 425.92 130.42 426.79 130.3C427.66 130.18 428.43 129.73 428.96 129.04C430.06 127.6 429.78 125.53 428.34 124.43C427.76 123.99 427.07 123.76 426.35 123.76H426.36Z"
        fill="black"
      />
      <path
        d="M421.13 58.66L425.18 57.4L423.87 51.55L419.67 52.14C419.3 51.49 418.86 50.86 418.37 50.29L420.35 46.53L415.29 43.32L412.74 46.71C412 46.51 411.26 46.38 410.51 46.32L409.25 42.27L403.4 43.58L403.99 47.78C403.34 48.15 402.71 48.59 402.14 49.08L398.38 47.1L395.17 52.16L398.56 54.71C398.36 55.45 398.23 56.19 398.17 56.94L394.12 58.2L395.43 64.05L399.63 63.46C400 64.11 400.44 64.74 400.93 65.31L398.95 69.07L404.01 72.28L406.56 68.89C407.3 69.09 408.04 69.22 408.79 69.28L410.05 73.33L415.9 72.02L415.31 67.82C415.96 67.45 416.59 67.01 417.16 66.52L420.92 68.5L424.13 63.44L420.74 60.89C420.94 60.15 421.07 59.41 421.13 58.66ZM409.65 62.75C406.92 62.75 404.71 60.54 404.71 57.81C404.71 55.08 406.92 52.87 409.65 52.87C412.38 52.87 414.59 55.08 414.59 57.81C414.59 60.54 412.38 62.75 409.65 62.75Z"
        fill="black"
      />
      <path
        d="M339.59 123.41C310.04 123.41 302.53 99.62 302.46 99.38L310.11 97.05C310.34 97.8 316.13 115.41 339.59 115.41V123.41Z"
        fill="black"
      />
    </svg>
  );
}
