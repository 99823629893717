import useApi from "@/hooks/useApi";
import React, { useCallback, useEffect, useState } from "react";
import { AutomationsContext } from "../context/AutomationsContext";
import { Automation } from "@/pages/Messaging/Bots/types";

// make this bad boy lazy???
export function AutomationsProvider(props: { children: React.ReactNode }) {
  const api = useApi();
  const [automations, setAutomations] =
    useState<Record<string, string> | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchAutomations = useCallback(async () => {
    setLoading(true);
    const json = await api.messaging.get("Automation");
    const result = (await json.json()) as Automation[];
    const dict: Record<string, string> = {};

    result.forEach((a) => {
      dict[a.id] = a.name;
    });

    setAutomations(dict);
    setLoading(false);
  }, [api.messaging]);

  useEffect(() => {
    fetchAutomations();
  }, [fetchAutomations]);

  return (
    <AutomationsContext.Provider
      value={{ automations: automations, loading: loading, fetchAutomations }}
    >
      {props.children}
    </AutomationsContext.Provider>
  );
}
