import useAutomations from "@/hooks/useAutomations";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export interface UseAutomationValue {
  automation: string;
}

interface Props {
  updateValue: (value: UseAutomationValue) => void;
  value: UseAutomationValue;
}

export default function AutomationRuleForm({ value, updateValue }: Props) {
  const { automations } = useAutomations();
  const keys = Object.keys(automations || {});

  useEffect(() => {
    if (!value.automation && keys.length > 0) {
      updateValue({ automation: (automations || {})[keys[0]] });
    }
  }, [automations, keys, updateValue, value]);

  if (!automations || !keys.length) {
    return (
      <>
        <span>{"You don't have any automations yet."}</span>
        <Link to="/bots">
          <button type="button" className="btn-link">
            Create one now.
          </button>
        </Link>
      </>
    );
  }

  return (
    <>
      <div>
        Set the conditions for when an automation should be used to handle a
        chat.
      </div>
      <div className="flex flex-col mr-auto">
        <div>
          Select your automation:
          <select
            className="flex flex-row input overflow-ellipsis whitespace-nowrap w-64 ml-2"
            value={value.automation}
            onChange={(e) => updateValue({ automation: e.currentTarget.value })}
          >
            {keys.map((k, i) => (
              <option key={i} value={automations[k]}>
                {automations[k]}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
}
