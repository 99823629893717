import useApi from "@/hooks/useApi";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CreateModal from "./CreateModal";
import EditModal from "./EditModal";
import TeamSelectDeprecated from "@/components/messaging/TeamSelect";
import LoadingSpinner from "@/components/LoadingSpinner";
import useSelectedTeam from "@/hooks/useSelectedTeam";

interface CannedResponseListItem {
  id: number;
  teamId: number | undefined;
  messageText: string;
  shortCode: string;
}

export default function CannedResponses() {
  const [responses, setResponses] = useState<CannedResponseListItem[]>([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [editId, setEditId] = useState<number | undefined>();
  const { teamId, updateTeamId } = useSelectedTeam();
  const api = useApi();

  const fetchResponses = useCallback(async () => {
    const response = await api.messaging.get(`CannedResponses/List/${teamId}`);
    setResponses(await response.json());
  }, [api.messaging, teamId]);

  useEffect(() => {
    if (!teamId) {
      return;
    }
    fetchResponses();
  }, [fetchResponses, teamId]);

  async function deleteCannedResponse(id: number) {
    if (confirm("Are you sure you want to delete this canned response?")) {
      await api.messaging.delete(`CannedResponses/${id}`);
      await fetchResponses();
    }
  }

  return (
    <>
      <div className="h-14 flex-none">
        <div className="h-full items-center w-1/2">
          <TeamSelectDeprecated
            className="input w-full p-2"
            onChange={(e) => updateTeamId(Number(e.currentTarget.value))}
            value={Number(teamId)}
          />
        </div>
      </div>
      <div className="ml-auto">
        <button
          className="btn-green m-2"
          onClick={() => setShowCreateModal(true)}
        >
          New Canned Response
        </button>
      </div>
      <table className="w-full">
        <thead>
          <tr>
            <th className={thClassName} style={{ minWidth: 140 }}>
              Short Code
            </th>
            <th className={thClassName}>Message Text</th>
            <th className={thClassName} style={{ width: 1 }}></th>
          </tr>
        </thead>
        <tbody>
          {responses.map((response) => (
            <tr key={response.id}>
              <td className={tdClassName}>
                <span className="whitespace-nowrap">
                  <span className="inline-block bg-gray-300 h-6 w-4 text-center mr-1 rounded">
                    /
                  </span>
                  {response.shortCode}
                </span>
              </td>
              <td className={`${tdClassName}`}>{response.messageText}</td>
              <td className={`${tdClassName} text-sm whitespace-nowrap`}>
                <button
                  className="underline"
                  onClick={() => setEditId(response.id)}
                >
                  Edit
                </button>
                <span className="mx-2">&bull;</span>
                <button
                  className="underline"
                  onClick={() => deleteCannedResponse(response.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showCreateModal && (
        <CreateModal
          show={showCreateModal}
          onClose={() => setShowCreateModal(false)}
          onCreated={() => {
            setShowCreateModal(false);
            fetchResponses();
          }}
        />
      )}
      {editId && (
        <EditModal
          cannedResponseId={editId}
          onClose={() => setEditId(undefined)}
          onCannedResponseUpdated={() => {
            setEditId(undefined);
            fetchResponses();
          }}
        />
      )}
    </>
  );
}

const thClassName = "border p-2 text-left";
const tdClassName = "border p-2";
