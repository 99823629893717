import React, { useRef } from "react";
import { StyleConfigModel } from "@/pages/Messaging/types";

interface Props {
  setStageLogoFile: (file: File) => void;
  update: (updates: Partial<StyleConfigModel>) => void;
  workingConfig: StyleConfigModel;
}
export default function LogoUpload(props: Props) {
  const fileInput = useRef<HTMLInputElement>(null);

  return (
    <div>
      <label className="block mb-2">Logo</label>
      <div className="mb-4">
        {props.workingConfig.logoUrl && (
          <img
            className="rounded-full h-24 w-24"
            src={props.workingConfig.logoUrl}
          />
        )}
        {!props.workingConfig.logoUrl && <div>No logo</div>}
      </div>
      <div>
        <button
          type="button"
          className="btn-green"
          onClick={() => {
            if (fileInput.current) {
              fileInput.current.value = null as any;
              fileInput.current.click();
            }
          }}
        >
          Upload File
        </button>
        <input
          className="hidden"
          ref={fileInput}
          type="file"
          accept="image/*"
          onChange={(e) => {
            const files = e.currentTarget.files!;
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
              props.update({ logoUrl: e.target?.result?.toString() });
            };
            reader.readAsDataURL(file);
            props.setStageLogoFile(file);
          }}
        />
      </div>
    </div>
  );
}
