import React from "react";
import { Link } from "react-router-dom";

export function Card({
  to,
  title,
  subtitle
}: {
  to: string;
  title: string;
  subtitle: string;
}) {
  return (
    <div className="w-80 h-36">
      <Link to={to}>
        <div className="border float-left rounded w-80 h-36 mr-4 mb-4 hover:shadow group">
          <div className="font-semibold mb-2 bg-cornflower-blue-400 group-hover:bg-cornflower-blue-500 text-slate-50 p-3 group">
            {title}
          </div>
          <div className="text-sm p-3">{subtitle}</div>
        </div>
      </Link>
    </div>
  );
}
