import { NextButton } from "@/components/Buttons";
import useRoleListItems from "@/hooks/useRoleListItems";
import { RoleListItem } from "@/providers/RoleListItemsProvider";
import FormValidator from "@velaro/velaro-shared/src/components/FormValidator";
import React from "react";
import { useNavigate } from "react-router-dom";
import { UserModel } from "../types";
import { SecondaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";

interface Props {
  setActiveStep: (step: number) => void;
  user: UserModel;
  setUser: (user: UserModel) => void;
}

export default function ProfileWizardStep(props: Props) {
  const [errors, setErrors] = React.useState<Record<string, string>>({});

  const navigate = useNavigate();
  const { defaultRoles } = useRoleListItems();

  function renderRoleCheckbox(role: RoleListItem, index: number) {
    return (
      <div key={index} className="flex mt-4">
        <input
          id={role.name}
          type="checkbox"
          className="input cursor-pointer"
          checked={!!props.user.roles.includes(role.name)}
          onChange={() => updateRoles(role.name)}
        />
        <div className="ml-4">
          <div className="font-semibold">{role.name}</div>
          <div className="text-sm">{role.description}</div>
        </div>
      </div>
    );
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    props.setUser({ ...props.user, [name]: value });
  };

  function updateRoles(roleName: string) {
    const roles = props.user.roles.slice();
    const index = roles.indexOf(roleName);
    if (index === -1) {
      roles.push(roleName);
    } else {
      roles.splice(index, 1);
    }
    props.setUser({ ...props.user, roles });
  }

  function handleNext() {
    setErrors({});

    const newErrors: Record<string, string> = {};

    if (!props.user.email) {
      newErrors["Email"] = "Email required";
    }
    if (!props.user.roles.length) {
      newErrors["Roles"] = "Select at least one role";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length !== 0) {
      return;
    }

    if (props.user.firstName && props.user.lastName) {
      props.setUser({
        ...props.user,
        displayName: `${props.user.firstName} ${props.user.lastName}`
      });
    } else {
      props.setUser({
        ...props.user,
        displayName: `${props.user.email}`
      });
    }
    props.setActiveStep(2);
  }

  return (
    <div>
      <div className="panel bg-white dark:bg-stone-300 rounded-md shadow-md p-4 mt-6 mx-8">
        <div className="font-semibold text-lg mb-2">Details</div>
        <div className="grid grid-cols-2 gap-12">
          <div>
            <label>First Name</label>
            <input
              id="firstName"
              name="firstName"
              type="text"
              className="input w-full my-2"
              autoFocus
              value={props.user.firstName}
              onChange={handleChange}
            />
            <label>Email</label>
            <FormValidator error={errors["Email"]} className="my-2">
              <input
                id="email"
                name="email"
                type="email"
                className="input w-full"
                autoFocus
                value={props.user.email}
                onChange={handleChange}
              />
            </FormValidator>
            <div className="font-semibold text-lg mb-2 mt-8">Assign Roles</div>
            <div className="text-sm">
              Users may be assigned to more than one role.
            </div>
            <FormValidator error={errors["Roles"]} className="my-2">
              <>{defaultRoles.map(renderRoleCheckbox)}</>
            </FormValidator>
          </div>
          <div>
            <label>Last Name</label>
            <input
              id="lastName"
              name="lastName"
              type="text"
              className="input w-full my-2"
              autoFocus
              value={props.user.lastName}
              onChange={handleChange}
            />
          </div>
          {/* <div>
            <label>Chat Limits</label>
            <input
              id="chatlimits"
              type="text"
              className="input w-full my-2"
              autoFocus
              //value={user.email}
              //onChange={(e) => onChange({ email: e.currentTarget.value })}
            />
            <div className="text-sm">
              Set the number of chats an agent can handle per session. We
              recommend a maximum of 4 for best performance.
            </div>
          </div> */}
        </div>
        <div className="mt-4 flex w-full justify-end">
          <div className="mr-2">
            <SecondaryBlueButton
              label="Cancel"
              onClick={() => navigate("/users")}
            />
          </div>
          <NextButton label="Next" onClick={() => handleNext()} />
        </div>
      </div>
    </div>
  );
}
