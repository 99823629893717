import React from "react";
import { IconProps } from "./types";

function IconHome(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path fill="none" d="M0 0H24V24H0z" data-name="Rectangle 422"></path>
      <path
        fill="currentColor"
        d="M20.56 10.246L12.788 2.3a1.008 1.008 0 00-1.431 0l-7.772 7.956A2.016 2.016 0 003 11.7v8.447a2.016 2.016 0 001.905 2.013h14.334a2.016 2.016 0 001.905-2.016V11.7a2.087 2.087 0 00-.585-1.452zm-10.5 9.9V14.1h4.032v6.048zm9.072 0H16.1v-7.058a1.008 1.008 0 00-1-1.008H9.048a1.008 1.008 0 00-1.008 1.008v7.056H5.016v-8.487l7.056-7.207 7.056 7.25z"
        data-name="Path 272"
        transform="translate(-.072 -.504)"
      ></path>
    </svg>
  );
}

export default IconHome;
