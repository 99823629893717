import React, { useEffect, useRef, useState } from "react";

interface Props {
  onSearch(searchText: string): void;
  placeholderText: string;
}
export default function SearchBar(props: Props) {
  const [searchText, setSearchText] = useState<string>("");

  const searchBar = useRef<HTMLInputElement>(null);
  if (searchBar.current != null) {
    searchBar.current.addEventListener("keydown", function (e) {
      if (e.code === "Enter") {
        props.onSearch(searchText);
      }
    });
  }

  return (
    <div className="relative w-96 h-10">
      <div
        onClick={() => props.onSearch(searchText)}
        className="absolute inset-y-0 left-0 flex items-center pl-3"
      >
        <i className="fa fa-search text-gray-400"></i>
      </div>
      <input
        ref={searchBar}
        type="text"
        id="search"
        className="items-center w-full h-full p-2 pl-10 text-sm text-gray-900 border focus:outline-none border-gray-300 rounded-lg bg-white focus:border-steel-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-cornflower-blue-500 dark:focus:border-cornflower-blue-500"
        placeholder={props.placeholderText}
        onChange={(e) => {
          setSearchText(e.currentTarget.value);
          props.onSearch(e.currentTarget.value);
        }}
      ></input>
    </div>
  );
}
