import { Card } from "@/components/Card";
import React from "react";

export default function Landing() {
  return (
    <Card
      to="/contact_center/custom_fields"
      title="Custom Fields"
      subtitle="Configure custom fields for Contact Center"
    />
  );
}
