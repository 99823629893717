import React from "react";

export default function IllustrationProactiveChatCommunication() {
  return (
    <svg viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M130.97 48.19L377.95 56.22C419.14 57.56 453.49 89.33 456.7 127.24L472.72 316.56C475.93 354.47 447.41 391.56 407.03 399.81L164.91 449.24C106.35 461.2 52.42 431.34 48.22 381.77L27.27 134.29C23.08 84.73 71.23 46.23 130.96 48.17L130.97 48.19Z"
        fill="#EEF4FD"
      />
      <path
        d="M431.73 280L42.22 343.28L37.68 80.08L389.31 42.17L431.73 280Z"
        fill="black"
      />
      <path
        d="M240.47 406.08L207.54 280.6L80.29 355.77L136.91 382.8L116.4 435.16L175.5 457.5L190.42 388.57L240.47 406.08Z"
        fill="black"
      />
      <path
        d="M428.73 281L53.38 329.98L52.2 79.72L386.31 43.17L428.73 281Z"
        fill="#AFCFF8"
      />
      <path
        d="M49.4 334.53L48.18 76.14L389.59 38.79L433.41 284.43L49.41 334.53H49.4ZM56.22 83.31L57.36 325.43L424.06 277.59L383.03 47.56L56.22 83.31Z"
        fill="black"
      />
      <path
        d="M386.31 43.17L52.2 79.72V140.16C129.52 132.77 298.72 117.11 397.51 105.96L386.31 43.17Z"
        fill="white"
      />
      <path
        d="M48.2 144.56V76.13L389.59 38.78L402.2 109.45L397.96 109.93C315.65 119.22 184.85 131.61 98.29 139.8C80.99 141.44 65.5 142.9 52.58 144.14L48.2 144.56ZM56.2 83.31V135.76C68.19 134.62 82.15 133.29 97.53 131.84C182.58 123.79 310.38 111.69 392.82 102.46L383.03 47.55L56.2 83.31Z"
        fill="black"
      />
      <path
        d="M360.291 148.297L142.369 175.634L143.365 183.572L361.287 156.235L360.291 148.297Z"
        fill="white"
      />
      <path
        d="M364.673 194.455L91.159 231.193L92.224 239.122L365.738 202.384L364.673 194.455Z"
        fill="white"
      />
      <path
        d="M364.662 242.096L246.625 258.266L247.71 266.192L365.748 250.022L364.662 242.096Z"
        fill="white"
      />
      <path
        d="M83.18 115.76C87.891 115.76 91.71 111.941 91.71 107.23C91.71 102.519 87.891 98.7 83.18 98.7C78.469 98.7 74.65 102.519 74.65 107.23C74.65 111.941 78.469 115.76 83.18 115.76Z"
        fill="black"
      />
      <path
        d="M116.56 112.23C121.271 112.23 125.09 108.411 125.09 103.7C125.09 98.989 121.271 95.17 116.56 95.17C111.849 95.17 108.03 98.989 108.03 103.7C108.03 108.411 111.849 112.23 116.56 112.23Z"
        fill="black"
      />
      <path
        d="M237.32 406.41L204.39 280.93L96.42 357.12L144.63 373.99L120.74 433.28L172.36 457.83L187.27 388.9L237.32 406.41Z"
        fill="#629FF0"
      />
      <path
        d="M175.2 463.61L115.62 435.28L139.35 376.38L87.76 358.33L206.8 274.34L243.1 412.67L190.22 394.17L175.2 463.61ZM125.85 431.29L169.51 452.05L184.31 383.63L231.54 400.15L201.98 287.52L105.06 355.9L149.9 371.58L125.85 431.27V431.29Z"
        fill="black"
      />
      <path
        d="M298.14 171.67L306.49 263.16L433.37 245.73L462.15 259.26L445.51 162.33L298.14 171.67Z"
        fill="black"
      />
      <path
        d="M312.43 168.58L317.14 251.28L421.04 240.07L462.32 257.06L445.69 160.13L312.43 168.58Z"
        fill="white"
      />
      <path
        d="M467.31 265.71L420.28 246.36L313.21 257.91L308.04 167.04L448.85 158.11L467.32 265.71H467.31ZM421.44 238.19L456.98 252.81L442.17 166.55L316.47 174.52L320.71 249.06L421.44 238.19Z"
        fill="black"
      />
      <path
        d="M384.42 215.04C388.645 215.04 392.07 211.615 392.07 207.39C392.07 203.165 388.645 199.74 384.42 199.74C380.195 199.74 376.77 203.165 376.77 207.39C376.77 211.615 380.195 215.04 384.42 215.04Z"
        fill="black"
      />
      <path
        d="M346.14 218.16C350.365 218.16 353.79 214.735 353.79 210.51C353.79 206.285 350.365 202.86 346.14 202.86C341.915 202.86 338.49 206.285 338.49 210.51C338.49 214.735 341.915 218.16 346.14 218.16Z"
        fill="black"
      />
      <path
        d="M421.18 210.36C425.405 210.36 428.83 206.935 428.83 202.71C428.83 198.485 425.405 195.06 421.18 195.06C416.955 195.06 413.53 198.485 413.53 202.71C413.53 206.935 416.955 210.36 421.18 210.36Z"
        fill="black"
      />
    </svg>
  );
}
