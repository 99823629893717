import React from "react";
import { IconProps } from "./types";

function IconPersonGroupAdd(props: IconProps) {
  return (
    <svg
      id="person-group-add"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}
    >
      <g id="person-group-add-2" data-name="person-group-add">
        <rect
          id="Rectangle_2066"
          data-name="Rectangle 2066"
          width="20"
          height="20"
          opacity="0"
        />
        <path
          id="Path_498"
          data-name="Path 498"
          d="M21.694,6.954H20.166V5.425a.764.764,0,0,0-1.528,0V6.954H17.108a.764.764,0,0,0,0,1.529h1.529V10.01a.764.764,0,1,0,1.528,0V8.483h1.529a.764.764,0,0,0,0-1.529"
          transform="translate(-2.458 -4.662)"
        />
        <path
          id="Path_1805"
          data-name="Path 1805"
          d="M11.992,12.416,10.6,11.271a.669.669,0,0,0-.942.092.672.672,0,0,0,.092.942l1.393,1.143a1.17,1.17,0,0,1,.428.906v4.437h1.338V14.355a2.5,2.5,0,0,0-.917-1.939"
          transform="translate(-2.368 -1.191)"
        />
        <path
          id="Path_1806"
          data-name="Path 1806"
          d="M3.251,11.364a.664.664,0,0,0-.452-.241.649.649,0,0,0-.49.149L.917,12.416A2.5,2.5,0,0,0,0,14.354v4.437H1.339V14.354a1.165,1.165,0,0,1,.427-.9l1.393-1.144a.668.668,0,0,0,.092-.942"
          transform="translate(0 -1.191)"
        />
        <path
          id="Path_1807"
          data-name="Path 1807"
          d="M5.837,1.788A3.561,3.561,0,1,0,9.4,5.349,3.566,3.566,0,0,0,5.837,1.788M8.061,5.349A2.223,2.223,0,1,1,5.837,3.127,2.225,2.225,0,0,1,8.061,5.349"
          transform="translate(-0.225 1.534)"
        />
        <path
          id="Path_1808"
          data-name="Path 1808"
          d="M21.954,15.079l-1.393-1.144a.669.669,0,0,0-.942.092.672.672,0,0,0,.092.942l1.393,1.143a1.17,1.17,0,0,1,.428.906v4.437h1.338V17.018a2.5,2.5,0,0,0-.917-1.939"
          transform="translate(-5.246 -1.454)"
        />
        <path
          id="Path_1809"
          data-name="Path 1809"
          d="M15.8,4.451a3.561,3.561,0,1,0,3.561,3.561A3.566,3.566,0,0,0,15.8,4.451m2.223,3.561A2.223,2.223,0,1,1,15.8,5.79a2.226,2.226,0,0,1,2.223,2.223"
          transform="translate(-3.049 0.87)"
        />
      </g>
    </svg>
  );
}

export default IconPersonGroupAdd;
