import * as React from "react";
import IllustrationCreateNewWorkflow1 from "@/icons/illustrations/IllustrationCreateNewWorkflow1";
import IllustrationBuildItWithAI from "@/icons/illustrations/IllustrationBuildItWithAI";
import IllustrationChat from "@/icons/illustrations/IllustrationChat";
import IllustrationChatbotLeadGen from "@/icons/illustrations/IllustrationChatbotLeadGen";
import IllustrationAfterHours from "@/icons/illustrations/IllustrationAfterHours";
import IllustrationFeedbackSurveyPostChat from "@/icons/illustrations/IllustrationFeedbackSurveyPostChat";
import IllustrationPreChatSurvey from "@/icons/illustrations/IllustrationPreChatSurvey";
import IllustrationChatbotFAQ from "@/icons/illustrations/IllustrationChatbotFAQ";
import IllustrationHelpCenterSearchArticlesKB from "@/icons/illustrations/IllustrationHelpCenterSearchArticlesKB";
import IllustrationChatPausedIdleDelay from "@/icons/illustrations/IllustrationChatPausedIdleDelay";
import IllustrationProactiveChatCommunication from "@/icons/illustrations/IllustrationProactiveChatCommunication";
import IllustrationMissedChatsUnavailable from "@/icons/illustrations/IllustrationMissedChatsUnavailable";
import IllustrationChatbotSupportService from "@/icons/illustrations/IllustrationChatbotSupportService";
import IllustrationChatTransfersReAssignments1 from "@/icons/illustrations/IllustrationChatTransfersReAssignments1";
import { useState } from "react";
import TriggerModal from "./TriggerModal";
import { useNavigate } from "react-router-dom";
import useApi from "@/hooks/useApi";
import LoadingSpinner from "@/components/LoadingSpinner";

const startBuildingCards = [
  {
    icon: <IllustrationCreateNewWorkflow1 />,
    title: "Create New",
    text: "Start with a blank canvas and build your custom workflow",
    template: "blank"
  },
  {
    icon: <IllustrationBuildItWithAI />,
    title: "Build it with AI",
    text: "Build workflows with AI-powered guidance, saving time and effort",
    template: "build-with-ai"
  }
];
const templateCards = [
  {
    icon: <IllustrationChat />,
    title: "Welcome Series",
    text: "Send a series of engaging messages to new website visitors",
    template: "welcome-series"
  },
  {
    icon: <IllustrationChatbotLeadGen />,
    title: "Lead Generation Chatbot",
    text: "Boosts leads with a chatbot that captures and engages effortlessly",
    template: "lead-gen-chatbot"
  },
  {
    icon: <IllustrationAfterHours />,
    title: "Out of Business Hours",
    text: "Automate replies for inquiries outside business hours",
    template: "after-hours"
  },
  {
    icon: <IllustrationFeedbackSurveyPostChat />,
    title: "Satisfaction Surveys",
    text: "Collect feedback after interactions to improve customer experience",
    template: "satisfaction-surveys"
  },
  {
    icon: <IllustrationPreChatSurvey />,
    title: "Pre-Chat Surveys",
    text: "Collect user information before the start of a conversation",
    template: "pre-chat-surveys"
  },
  {
    icon: <IllustrationChatbotFAQ />,
    title: "FAQ Chatbot",
    text: "Use an AI chatbot to handle frequently asked questions",
    template: "faq-chatbot"
  },
  {
    icon: <IllustrationChatTransfersReAssignments1 />,
    title: "Auto-Assignment & Handoff",
    text: "Auto-route incoming messages to the appropriate agents or teams",
    template: "auto-assignment-handoff"
  },
  {
    icon: <IllustrationHelpCenterSearchArticlesKB />,
    title: "Smart Article Guide",
    text: "Share relevant articles by detecting kewords in messages",
    template: "smart-article-guide"
  },
  {
    icon: <IllustrationChatPausedIdleDelay />,
    title: "Idle Contact",
    text: "Check in on unresponsive customers during conversations",
    template: "idle-contact"
  },
  {
    icon: <IllustrationProactiveChatCommunication />,
    title: "Proactive Engagement",
    text: "Identify high-intent visitors, engage, and drive conversations",
    template: "proactive-engagement"
  },
  {
    icon: <IllustrationMissedChatsUnavailable />,
    title: "Missed Conversations",
    text: "Reconnect with customers that are unable to connect with a live agent",
    template: "missed-conversations"
  },
  {
    icon: <IllustrationChatbotSupportService />,
    title: "Service Chatbot",
    text: "Create a service but to provide support for your visitors",
    template: "service-chatbot"
  }
];

export default function WorkflowsCreate() {
  const [showTriggerModal, setShowTriggerModal] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const api = useApi();
  function onClickCard(template: string) {
    if (template === "blank") {
      //start from scratch
      setShowTriggerModal(true);
      return;
    }
    if (template === "build-with-ai") {
      //start from ai
      return;
    }

    //starting from template
    return;
  }

  async function selectTrigger(triggerId: string) {
    setShowTriggerModal(false);
    setLoading(true);
    //persist a new workflow
    const response = await api.messaging.post("Workflows", {
      trigger: triggerId
    });
    const workflowId = await response.json();
    //navigate to workflows/id
    navigate(`/workflows/${workflowId}`);
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <CardGrouping
        header="Start Building"
        cards={startBuildingCards}
        onClick={onClickCard}
      />
      <CardGrouping
        header="Use a Template"
        cards={templateCards}
        onClick={onClickCard}
      />
      {showTriggerModal && (
        <TriggerModal
          onSelect={selectTrigger}
          onClose={() => setShowTriggerModal(false)}
        />
      )}
    </>
  );
}

function CardGrouping(props: {
  header: string;
  cards: {
    icon: React.ReactElement;
    title: string;
    text: string;
    template: string;
  }[];
  onClick: (template: string) => void;
}) {
  return (
    <div className="mb-8">
      <div className="font-semibold">{props.header}</div>
      <div className="flex flex-wrap gap-4 my-4">
        {props.cards.map((card, index) => (
          <WorkflowCard
            onClick={() => props.onClick(card.template)}
            key={index}
            {...card}
          />
        ))}
      </div>
    </div>
  );
}

function WorkflowCard(props: {
  icon: React.ReactElement;
  title: string;
  text: string;
  onClick: () => void;
}) {
  return (
    <div
      onClick={props.onClick}
      className="group w-72 h-56 p-4 flex flex-col border rounded-lg items-center justify-between cursor-pointer hover:border-cornflower-blue-500 hover:shadow-sm"
    >
      <div className="w-20 h-20 mt-2">{props.icon}</div>
      <div className="flex flex-col items-center justify-between">
        <span className="font-semibold p-2 group-hover:text-cornflower-blue-500">
          {props.title}
        </span>
        <span className="text-sm text-center text-gray-500">{props.text}</span>
      </div>
    </div>
  );
}
