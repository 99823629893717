import IconTrash1 from "@/icons/IconTrash1";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Select, { SingleValue } from "react-select";
import Option from "react-select/dist/declarations/src/components/Option";
import { NetSuiteList, NetSuiteMap } from "./NetSuiteMappingTable";

interface Option {
  value: any;
  label: string;
  key?: string;
  disabled?: boolean;
}

interface Props {
  mappedField: NetSuiteMap;
  i: number;
  updateMappedField: (i: number, field: NetSuiteMap) => void;
  metadata: Option[];
  isLoading: boolean;
  handleModal: (field: NetSuiteMap) => void;
  fetchList: (fields: NetSuiteMap[]) => void;
  listFields: NetSuiteList[];
  deleteRow: (i: number) => void;
}

export default function NetSuiteMappingTableRow(props: Props) {
  return (
    <tr key={props.i}>
      <td className="border-b-[1px]">
        <Select
          className="w-full h-max"
          options={props.metadata}
          value={props.mappedField.netSuiteField}
          onChange={(e) =>
            props.updateMappedField(props.i, {
              ...props.mappedField,
              netSuiteField: e
            })
          }
          isLoading={props.isLoading}
        />
      </td>
      <td className="border-b-[1px] ">
        <input
          className="w-full h-max focus:outline-none p-2 outline-none"
          value={props.mappedField.label}
          onChange={(e) =>
            props.updateMappedField(props.i, {
              ...props.mappedField,
              label: e.currentTarget.value
            })
          }
        ></input>
      </td>
      <td className="border-b-[1px]">
        <input
          className="w-full h-max focus:outline-none p-2 outline-none"
          value={props.mappedField.linkedTo}
          onClick={() => props.handleModal(props.mappedField)}
          onChange={(e) =>
            props.updateMappedField(props.i, {
              ...props.mappedField,
              linkedTo: e.currentTarget.value
            })
          }
        ></input>
      </td>
      <td className="border-b-[1px]">
        <input
          type="checkbox"
          className="input w-full"
          checked={props.mappedField.create}
          onChange={() =>
            props.updateMappedField(props.i, {
              ...props.mappedField,
              create: !props.mappedField.create
            })
          }
        ></input>
      </td>
      <td className="border-b-[1px]">
        <input
          type="checkbox"
          className="input w-full"
          checked={props.mappedField.usedForSearching}
          onChange={() =>
            props.updateMappedField(props.i, {
              ...props.mappedField,
              usedForSearching: !props.mappedField.usedForSearching
            })
          }
        ></input>
      </td>
      <td className="border-b-[1px]">
        <input
          type="checkbox"
          className="input w-full"
          checked={props.mappedField.showWhenSelected}
          onChange={() =>
            props.updateMappedField(props.i, {
              ...props.mappedField,
              showWhenSelected: !props.mappedField.showWhenSelected
            })
          }
        ></input>
      </td>
      <td className="border-b-[1px]">
        <input
          type="checkbox"
          className="input w-full"
          checked={props.mappedField.showInSearch}
          onChange={() =>
            props.updateMappedField(props.i, {
              ...props.mappedField,
              showInSearch: !props.mappedField.showInSearch
            })
          }
        ></input>
      </td>
      <td className="border-b-[1px] ">
        <select
          className="w-full h-max focus:outline-none p-2"
          value={props.mappedField.control}
          onChange={(e) => {
            props.updateMappedField(props.i, {
              ...props.mappedField,
              control: e.currentTarget.value
            });
            if (e.currentTarget.value === "List") {
              props.fetchList([props.mappedField]);
            }
          }}
        >
          <option></option>
          <option>Textbox</option>
          <option>Text Area</option>
          <option>Checkbox</option>
          <option>List</option>
        </select>
      </td>
      <td className="border-b-[1px] ">
        {props.mappedField?.control === "List" ? (
          <Select
            className="w-full h-max"
            options={props.listFields
              .filter(
                (item) =>
                  item.netSuiteField === props.mappedField.netSuiteField?.value
              )
              .flatMap((listField, index) => {
                return listField.listItems;
              })}
            value={props.listFields
              .filter(
                (item) =>
                  item.netSuiteField === props.mappedField.netSuiteField?.value
              )
              .map((options) =>
                options.listItems.find(
                  (option) => option.value === props.mappedField.defaultValue
                )
              )}
            onChange={(e) =>
              props.updateMappedField(props.i, {
                ...props.mappedField,
                defaultValue: e?.value
              })
            }
            isLoading={props.isLoading}
          />
        ) : (
          <input
            className="w-full h-max focus:outline-none p-2"
            value={props.mappedField.defaultValue || ""}
            onChange={(e) =>
              props.updateMappedField(props.i, {
                ...props.mappedField,
                defaultValue: e.currentTarget.value
              })
            }
          ></input>
        )}
      </td>
      <td className="border-b-[1px] text-center justify-center items-center place-self-center">
        {
          <div
            className="py-2 px-4 cursor-pointer justify-center flex"
            onClick={() => props.deleteRow(props.i)}
          >
            <IconTrash1 />
          </div>
        }
      </td>
    </tr>
  );
}
