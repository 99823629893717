import React from "react";
import { IconProps } from "./types";

function IconUnresponsiveVisitorSquare({
  className = "stroke-slate-500",
  ...props
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      className={className}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8142 4.15385C11.8403 4.15385 9.42954 6.56462 9.42954 9.53846C9.42954 12.5123 11.8403 14.9231 14.8142 14.9231C17.788 14.9231 20.1988 12.5123 20.1988 9.53846C20.1988 6.56462 17.788 4.15385 14.8142 4.15385ZM7.2757 9.53846C7.2757 5.37508 10.6508 2 14.8142 2C18.9775 2 22.3526 5.37508 22.3526 9.53846C22.3526 13.7018 18.9775 17.0769 14.8142 17.0769C10.6508 17.0769 7.2757 13.7018 7.2757 9.53846ZM11.0191 19.9487C11.0866 19.9487 11.155 19.9487 11.2244 19.9487H17.686C18.2807 19.9487 18.7629 20.4309 18.7629 21.0256C18.7629 21.6204 18.2807 22.1026 17.686 22.1026H11.2244C9.14218 22.1026 8.3398 22.1142 7.71591 22.3035C6.22444 22.7559 5.05728 23.9231 4.60485 25.4146C4.41559 26.0385 4.4039 26.8408 4.4039 28.9231C4.4039 29.5178 3.92175 30 3.32698 30C2.73221 30 2.25006 29.5178 2.25006 28.9231C2.25006 28.8537 2.25004 28.7853 2.25003 28.7177C2.24963 26.9112 2.24937 25.7598 2.54375 24.7893C3.205 22.6095 4.91083 20.9037 7.09068 20.2424C8.0611 19.948 9.21254 19.9483 11.0191 19.9487Z"
        fill="inherit"
        stroke="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5833 21.4457C23.6344 21.4457 22.0546 23.0256 22.0546 24.9744C22.0546 26.9232 23.6344 28.503 25.5833 28.503C27.5321 28.503 29.1119 26.9232 29.1119 24.9744C29.1119 23.0256 27.5321 21.4457 25.5833 21.4457ZM20.5576 24.9744C20.5576 22.1988 22.8077 19.9487 25.5833 19.9487C28.3588 19.9487 30.6089 22.1988 30.6089 24.9744C30.6089 27.75 28.3588 30 25.5833 30C22.8077 30 20.5576 27.75 20.5576 24.9744Z"
        fill="inherit"
        stroke="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.0488 22.4082C25.4622 22.4082 25.7973 22.7433 25.7973 23.1567V25.2604L27.0944 25.9089C27.4641 26.0938 27.614 26.5434 27.4291 26.9132C27.2443 27.2829 26.7947 27.4328 26.4249 27.2479L24.7141 26.3925C24.4605 26.2657 24.3003 26.0065 24.3003 25.723V23.1567C24.3003 22.7433 24.6354 22.4082 25.0488 22.4082Z"
        fill="inherit"
        stroke="none"
      />
    </svg>
  );
}

export default IconUnresponsiveVisitorSquare;
