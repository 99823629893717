import Accordion from "@/components/Accordion";
import IconHelp from "@/icons/IconHelp";
import React, { useMemo } from "react";
import { LanguageConfigModel } from "@/pages/Messaging/types";

interface Props {
  onExpanded(): void;
  workingConfig: LanguageConfigModel;
  update: (updates: Partial<LanguageConfigModel>) => void;
  persist: () => void;
  loading: boolean;
}

export default function WhatsAppForm(props: Props) {
  const saveButtonText = useMemo(() => {
    return props.loading ? "Saving..." : "Save and set live";
  }, [props.loading]);

  return (
    <Accordion.Item
      eventKey="whatsappOpen"
      onExpanded={() => {
        props.onExpanded();
      }}
      onCollapsed={() => {}}
    >
      <Accordion.Header>
        <Accordion.Title>WhatsApp Channel</Accordion.Title>
        <Accordion.SubTitle>
          Customize how your visitors will start a WhatsApp conversation
        </Accordion.SubTitle>
      </Accordion.Header>
      <Accordion.Body>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            props.persist();
          }}
        >
          <div className="mb-4">
            <label className="block mb-2" htmlFor="whatsapp-title">
              WhatsApp Title
            </label>
            <input
              className="input"
              type="text"
              id="whatsapp-title"
              value={props.workingConfig.whatsAppTitle}
              onChange={(e) => props.update({ whatsAppTitle: e.target.value })}
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="whatsapp-subtitle">
              WhatsApp Subtitle
            </label>
            <input
              className="input"
              type="text"
              id="whatsapp-subtitle"
              value={props.workingConfig.whatsAppSubTitle}
              onChange={(e) =>
                props.update({ whatsAppSubTitle: e.target.value })
              }
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="whatsapp-message">
              Response Time Message
            </label>
            <input
              className="input"
              type="text"
              id="whatsapp-message"
              value={props.workingConfig.whatsAppResponseTime}
              onChange={(e) =>
                props.update({ whatsAppResponseTime: e.target.value })
              }
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="whatsapp-header">
              Form Header
            </label>
            <input
              className="input"
              type="text"
              id="whatsapp-header"
              value={props.workingConfig.whatsAppFormHeader}
              onChange={(e) =>
                props.update({ whatsAppFormHeader: e.target.value })
              }
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="whatsapp-footer">
              Form Footer
            </label>
            <input
              className="input"
              type="text"
              id="whatsapp-footer"
              value={props.workingConfig.whatsAppFormFooter}
              onChange={(e) =>
                props.update({ whatsAppFormFooter: e.target.value })
              }
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="whatsapp-button">
              Button Text
            </label>
            <input
              className="input"
              type="text"
              id="whatsapp-button"
              value={props.workingConfig.whatsAppButton}
              onChange={(e) => props.update({ whatsAppButton: e.target.value })}
            />
          </div>
          <div>
            <button className="btn-green" disabled={props.loading}>
              {saveButtonText}
            </button>
          </div>
        </form>
      </Accordion.Body>
    </Accordion.Item>
  );
}
