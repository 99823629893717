import React from "react";
import { IconProps } from "./types";

function IconTrash1(props: IconProps) {
  return (
    <svg
      id="delete-trash"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={props.className}
    >
      <rect
        id="Rectangle_597"
        data-name="Rectangle 597"
        width="24"
        height="24"
        fill="inherit"
        stroke="none"
        opacity="0"
      />
      <g id="Group_1015" data-name="Group 1015" transform="translate(1 3)">
        <path
          id="Path_519"
          data-name="Path 519"
          d="M15.987,20.536H6.416A1.891,1.891,0,0,1,4.536,18.86L2.431,7.08a.846.846,0,0,1,1.667-.3L6.21,18.615c.019.153.1.229.205.229h9.572a.2.2,0,0,0,.2-.177L18.306,6.783a.846.846,0,0,1,1.667.3L17.858,18.913a1.875,1.875,0,0,1-1.871,1.623"
          transform="translate(-0.201 -2.478)"
          fill="inherit"
          stroke="none"
        />
        <path
          id="Path_520"
          data-name="Path 520"
          d="M11.923,17.536a.846.846,0,0,1-.846-.846V8.544a.846.846,0,1,1,1.692,0V16.69a.846.846,0,0,1-.846.846"
          transform="translate(-0.923 -2.612)"
          fill="inherit"
          stroke="none"
        />
        <path
          id="Path_521"
          data-name="Path 521"
          d="M15.118,17.536a.631.631,0,0,1-.083,0,.844.844,0,0,1-.76-.923l.788-8.145a.846.846,0,1,1,1.684.163l-.788,8.146a.846.846,0,0,1-.842.764"
          transform="translate(-1.189 -2.612)"
          fill="inherit"
          stroke="none"
        />
        <path
          id="Path_522"
          data-name="Path 522"
          d="M8.656,17.536a.846.846,0,0,1-.842-.764L7.027,8.626a.846.846,0,1,1,1.684-.163L9.5,16.608a.844.844,0,0,1-.76.923.631.631,0,0,1-.082,0"
          transform="translate(-0.585 -2.612)"
          fill="inherit"
          stroke="none"
        />
        <path
          id="Path_523"
          data-name="Path 523"
          d="M21.154,6.635a.846.846,0,0,1-.846-.846,1.949,1.949,0,0,0-1.946-1.947H3.639A1.949,1.949,0,0,0,1.692,5.789.846.846,0,1,1,0,5.789,3.643,3.643,0,0,1,3.639,2.15H18.362A3.643,3.643,0,0,1,22,5.789a.846.846,0,0,1-.846.846"
          transform="translate(0 -2.15)"
          fill="inherit"
          stroke="none"
        />
      </g>
    </svg>
  );
}

export default IconTrash1;
