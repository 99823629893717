import React, {
  useState,
  useRef,
  useCallback,
  useLayoutEffect,
  forwardRef
} from "react";
import { NodeId, NodeOption } from "../types";
import { Connector } from "./CanvasHelpers";
import { ConnectorData, DraggingData } from "../WorkflowsEditor";

interface OptionNodesProps {
  scale: number;
  nodeId: number;
  options: NodeOption[];
  color: string;
  selectedConnector?: HTMLDivElement;
  draggingData: DraggingData | null;
  renderChildOrEnd: (child: NodeId | undefined) => React.ReactNode;
  onSelectNode: (id: number) => void;
  onSelectConnector: (data: ConnectorData) => void;
  onDragOverConnector: (data: ConnectorData) => void;
  onDragLeaveConnector: () => void;
}

export function OptionNodes({
  scale,
  nodeId,
  options,
  color,
  selectedConnector,
  draggingData,
  renderChildOrEnd,
  onSelectNode,
  onSelectConnector,
  onDragOverConnector,
  onDragLeaveConnector
}: OptionNodesProps) {
  const optionRefs = useRef<HTMLDivElement[]>([]);
  const horizontalBarRef = useRef<HTMLDivElement>(null);
  const gapWidth = 40;
  const borderThickness = 4;
  const [horizontalBarWidth, setHorizontalBarWidth] = useState(0);
  const [horizontalBarOffset, setHorizontalBarOffset] = useState(0);

  const calculateHorizontalBarWidth = useCallback(() => {
    //first and last nodes are half outside the container
    let totalNodeWidth = 0;

    for (let i = 0; i < options.length; i++) {
      let width = optionRefs.current[i].getBoundingClientRect().width;

      if (i === 0 || i === options.length - 1) {
        width = width / 2;
      }

      totalNodeWidth += width;
    }
    const result =
      totalNodeWidth / scale +
      gapWidth * (options.length - 1) +
      borderThickness;

    return result;
  }, [options, scale]);

  const calculateHorizontalBarOffset = useCallback(() => {
    const barRef = horizontalBarRef.current;
    if (!barRef || optionRefs.current.length === 0) {
      return 0;
    }
    const firstOption = optionRefs.current[0].getBoundingClientRect();

    const currentOffset = Number(barRef.style.left.replace("px", "")) * scale;

    const bar = barRef.getBoundingClientRect();

    const offsetBarX = bar.x - currentOffset;
    const firstOptionMid = firstOption.x + firstOption.width / 2;
    const offsetValue = firstOptionMid - offsetBarX;
    const scaledOffsetValue = (offsetValue - 1.5 * scale) / scale;
    return scaledOffsetValue;
  }, [scale]);

  useLayoutEffect(() => {
    const width = calculateHorizontalBarWidth();
    setHorizontalBarWidth(width);
    setTimeout(() => {
      const offset = calculateHorizontalBarOffset();
      setHorizontalBarOffset(offset);
    });
  }, [calculateHorizontalBarOffset, calculateHorizontalBarWidth]);

  function renderOption(option: NodeOption, i: number) {
    const edgeNode = i === 0 || i === options.length - 1;
    return (
      <OptionNode
        ref={(ref) => {
          if (ref) {
            optionRefs.current[i] = ref;
          }
        }}
        key={i}
        edgeNode={edgeNode}
        text={option.label || ""}
        color={color}
        selectedConnector={selectedConnector}
        draggingData={draggingData}
        onSelect={() => onSelectNode(nodeId)}
        onSelectConnector={(el: HTMLDivElement) =>
          onSelectConnector({
            el,
            parentNodeId: nodeId,
            parentOptionIndex: i,
            childNodeId: option.childNodeId
          })
        }
        onDragOverConnector={(el) =>
          onDragOverConnector({
            el,
            parentNodeId: nodeId,
            parentOptionIndex: i,
            childNodeId: option.childNodeId
          })
        }
        onDragLeaveConnector={onDragLeaveConnector}
        renderChildOrEnd={() => renderChildOrEnd(option.childNodeId)}
      />
    );
  }

  return (
    <>
      <div className="relative h-16 -left-[1px] translate-x-1/2 flex-grow border-l-4 border-slate-300"></div>
      {options.length > 1 && (
        <div
          ref={horizontalBarRef}
          style={{ width: horizontalBarWidth, left: horizontalBarOffset }}
          className="w-full relative h-5 flex-grow border-t-4 border-l-4 border-r-4 border-slate-300 rounded-t-full"
        ></div>
      )}
      <div className="relative flex items-start justify-center gap-10">
        {options.map(renderOption)}
      </div>
    </>
  );
}

interface OptionNodeProps {
  edgeNode: boolean;
  text: string;
  color: string;
  selectedConnector?: HTMLDivElement;
  draggingData: DraggingData | null;
  onSelect?: () => void;
  onSelectConnector: (el: HTMLDivElement) => void;
  onDragOverConnector: (el: HTMLDivElement) => void;
  onDragLeaveConnector: () => void;
  renderChildOrEnd: () => React.ReactNode;
}

const OptionNode = forwardRef(
  (
    {
      edgeNode,
      text,
      color,
      selectedConnector,
      draggingData,
      onSelect,
      onSelectConnector,
      onDragOverConnector,
      onDragLeaveConnector,
      renderChildOrEnd
    }: OptionNodeProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    return (
      <div
        ref={ref}
        className="option flex flex-col items-center justify-between"
      >
        {edgeNode && (
          <>
            <div className="relative h-16 -left-[1px] translate-x-1/2 flex-grow border-l-4 border-slate-300"></div>
          </>
        )}
        {!edgeNode && (
          <>
            <div className="-mt-5 relative h-16 -left-[1px] translate-x-1/2 flex-grow border-l-4 border-slate-300"></div>
            <div className="relative h-5 first-letter -left-[1px] translate-x-1/2 flex-grow border-l-4 border-slate-300"></div>
          </>
        )}

        <div className="flex flex-col items-center justify-center">
          <div
            onClick={onSelect}
            className={`cursor-pointer w-40 h-11 px-4 py-3 ${color} rounded-2xl shadow justify-center items-center gap-2 inline-flex`}
          >
            <div className="justify-start items-start gap-2 flex">
              <div className="text-white text-sm">{text}</div>
            </div>
          </div>
        </div>
        <>
          <Connector
            draggingData={draggingData}
            selectedConnector={selectedConnector}
            onClick={onSelectConnector}
            onDragEnter={onDragOverConnector}
            onDragLeave={onDragLeaveConnector}
          />
          {renderChildOrEnd()}
        </>
      </div>
    );
  }
);
