import React from "react";
import AppLoading from "@/components/AppLoading";
import useAuth from "@/hooks/useAuth";

export default function Logout() {
  const auth = useAuth();

  React.useLayoutEffect(() => {
    auth.logout();
  }, [auth]);

  return <AppLoading />;
}
