import React from "react";
import { IconProps } from "./types";

function IconDownload({ className = "stroke-slate-500", ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      className={className}
      {...props}
    >
      <path
        d="M28.5 20V21.6C28.5 23.8402 28.5 24.9603 28.064 25.816C27.6805 26.5686 27.0686 27.1805 26.316 27.564C25.4603 28 24.3402 28 22.1 28H10.9C8.65979 28 7.53969 28 6.68404 27.564C5.93139 27.1805 5.31947 26.5686 4.93597 25.816C4.5 24.9603 4.5 23.8402 4.5 21.6V20M23.1667 13.3333L16.5 20M16.5 20L9.83333 13.3333M16.5 20V4"
        stroke="inherit"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconDownload;
