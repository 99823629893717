import { useState, useCallback, useEffect } from "react";

const maxRetries = 10;
const retryTimeout = 5000;

interface Props {
  asyncFunction(): any;
  setFunction?(response: any): void;
  enableRetry?: boolean;
  immediate?: boolean;
}

/*
This hook will wrap your async request, allowing the request to auto retry up to the retry limit.
It will also auto cancel and ignore the results of old requests if new ones are started before they
finish so only the latest value gets used. It also gives you value, pending, and error states to use.
if you want to set the value of the response yourself, pass in a setFunction for it to call
*/

export default function useAsync<T>({
  asyncFunction,
  setFunction,
  enableRetry,
  immediate = false
}: Props) {
  const [value, setValue] = useState<T | null>(null);
  const [error, setError] = useState(null);
  const [retryAttempts, setRetryAttempts] = useState(0);
  const [pending, setPending] = useState(false);
  const [cancelFunction, setCancelFunction] =
    useState<{ cancel: () => void } | null>(null);
  const [startRequest, setStartRequest] = useState(false);

  const execute = useCallback(() => {
    setStartRequest(true);
  }, [setStartRequest]);

  useEffect(() => {
    if (immediate) {
      setStartRequest(true);
    }
  }, [immediate]);

  useEffect(() => {
    if (!startRequest) {
      return;
    }

    if (pending && cancelFunction) {
      cancelFunction.cancel(); //always cancel any existing requests before starting a new one
    }

    const promise = asyncFunction()
      .catch((error: any) => {
        setError(error);
      })
      .finally(() => {
        setPending(false);
      });

    const cancelable = new Promise((resolve) => {
      setCancelFunction({
        cancel: () => {
          resolve("cancelled");
        }
      });
    });

    const promises = [promise, cancelable];

    if (enableRetry && retryAttempts < maxRetries) {
      const timeout = new Promise((resolve) => {
        setTimeout(resolve, retryTimeout, "timeout");
      });
      promises.push(timeout);
    }

    Promise.race(promises).then((response) => {
      if (!response) {
        return;
      }

      if (response === "timeout") {
        console.log("Request timed out. Trying again.");
        setRetryAttempts(retryAttempts + 1);
        setStartRequest(true);
        return;
      }

      if (response === "cancelled") {
        console.log("Request cancelled.");
        return;
      }

      if (setFunction) {
        setFunction(response);
      } else {
        setValue(response);
      }
      setPending(false);
      setRetryAttempts(0);
    });

    setPending(true);
    setStartRequest(false);
  }, [
    asyncFunction,
    cancelFunction,
    enableRetry,
    pending,
    retryAttempts,
    setFunction,
    startRequest,
    immediate
  ]);

  return {
    execute,
    pending,
    value,
    setValue,
    error,
    setError
  };
}
