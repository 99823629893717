import React from "react";

export default function IllustrationChatbotFAQ() {
  return (
    <svg viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M130.97 48.1899L377.95 56.2199C419.14 57.5599 453.49 89.3299 456.7 127.24L472.72 316.56C475.93 354.47 447.41 391.56 407.03 399.81L164.91 449.24C106.35 461.2 52.42 431.34 48.22 381.77L27.27 134.29C23.08 84.7299 71.23 46.2299 130.96 48.1699L130.97 48.1899Z"
        fill="#EEF4FD"
      />
      <path
        d="M435.34 201.46L161.54 211.43V371.93L323.1 387.98L394.1 460.27L413.84 369.37L447.65 370.42L435.34 201.46Z"
        fill="black"
      />
      <path
        d="M447.65 195.39L173.85 205.36V365.86L334.95 370.9L394.67 444.8L413.84 373.37L447.65 374.42V195.39Z"
        fill="#AFCFF8"
      />
      <path
        d="M396.5 453.43L332.99 374.84L169.85 369.74V201.5L451.65 191.24V378.55L416.88 377.46L396.5 453.43ZM177.85 361.99L336.91 366.96L392.84 436.18L410.79 369.27L443.64 370.3V199.54L177.84 209.22V361.99H177.85Z"
        fill="black"
      />
      <path
        d="M153.25 245.35C208.97 245.35 254.14 205.817 254.14 157.05C254.14 108.283 208.97 68.75 153.25 68.75C97.53 68.75 52.36 108.283 52.36 157.05C52.36 205.817 97.53 245.35 153.25 245.35Z"
        fill="black"
      />
      <path
        d="M161.08 242.54C216.8 242.54 261.97 203.007 261.97 154.24C261.97 105.473 216.8 65.9399 161.08 65.9399C105.36 65.9399 60.1899 105.473 60.1899 154.24C60.1899 203.007 105.36 242.54 161.08 242.54Z"
        fill="white"
      />
      <path
        d="M96.0799 211.51L99.3299 224.07C100.03 224.55 100.75 225.02 101.46 225.48L98.1699 211.49L96.0699 211.51H96.0799Z"
        fill="black"
      />
      <path
        d="M106.88 214.87L110.61 230.72C125.06 238.04 141.77 242.31 159.6 242.53L176.46 241.53C191.6 239.5 205.62 234.53 217.76 227.3L219.22 214.35L106.88 214.87Z"
        fill="#629FF0"
      />
      <path
        d="M227.85 206.6L97.17 207.21L98.18 211.5L101.47 225.49C104.4 227.37 107.45 229.12 110.61 230.72L106.88 214.87L219.22 214.35L217.76 227.3C220.67 225.57 223.46 223.71 226.14 221.73L227.85 206.61V206.6Z"
        fill="black"
      />
      <path
        d="M161.07 242.54C166.3 242.54 171.44 242.19 176.46 241.52L159.6 242.52C160.09 242.52 160.58 242.54 161.07 242.54Z"
        fill="black"
      />
      <path
        d="M163.31 203.43C141.8 203.24 121.58 196.92 106.38 185.63C92.21 175.11 83.92 161.41 82.62 146.68C82.62 146.77 82.61 146.86 82.61 146.94C82.34 178.04 116.68 203.56 159.32 203.93C164.7 203.98 169.95 203.62 175.02 202.9C171.49 203.24 167.91 203.43 164.29 203.43C163.96 203.43 163.63 203.43 163.31 203.43Z"
        fill="black"
      />
      <path
        d="M215.62 179.76C229.17 170.06 236.7 157.19 236.82 143.53C236.94 129.87 229.64 116.87 216.26 106.93C202.48 96.69 184.03 90.96 164.29 90.79C163.99 90.79 163.68 90.79 163.38 90.79C143.99 90.79 125.79 96.18 112.05 106.02C98.5 115.72 90.97 128.59 90.85 142.25C90.73 155.91 98.03 168.91 111.41 178.85C125.19 189.09 143.64 194.82 163.38 194.99C183.12 195.17 201.67 189.75 215.62 179.76ZM114.39 114.37L214.37 115.24L207.05 169.49L118.19 168.72L114.39 114.37Z"
        fill="#629FF0"
      />
      <path
        d="M236.82 143.42L237.72 117.61C233.73 111.2 228.21 105.3 221.28 100.16C206.08 88.87 185.86 82.54 164.35 82.36C142.87 82.17 122.52 88.14 107.12 99.17C91.32 110.48 82.54 125.76 82.4 142.19C82.39 143.7 82.47 145.2 82.6 146.69C83.9 161.42 92.2 175.11 106.36 185.64C121.56 196.93 141.78 203.26 163.29 203.44C163.62 203.44 163.95 203.44 164.27 203.44C167.89 203.44 171.47 203.25 175 202.91C192.1 201.25 207.95 195.63 220.52 186.63C236.28 175.34 245.06 160.11 245.24 143.72L236.81 143.42H236.82ZM90.85 142.25C90.97 128.59 98.5 115.72 112.05 106.02C125.79 96.18 143.98 90.79 163.38 90.79C163.68 90.79 163.99 90.79 164.29 90.79C184.02 90.96 202.48 96.69 216.26 106.93C229.64 116.87 236.94 129.87 236.82 143.53C236.7 157.19 229.17 170.06 215.62 179.76C201.67 189.75 183.12 195.17 163.38 194.99C143.65 194.82 125.19 189.09 111.41 178.85C98.03 168.91 90.73 155.91 90.85 142.25Z"
        fill="black"
      />
      <path
        d="M123.44 122.89L126.06 160.35L199.67 160.99L204.71 123.6L123.43 122.89H123.44ZM147.26 149.69C143.56 149.66 140.59 145.66 140.63 140.75C140.67 135.85 143.71 131.9 147.41 131.93C151.11 131.96 154.08 135.96 154.04 140.87C154 145.77 150.96 149.72 147.26 149.69ZM178.02 149.96C174.32 149.93 171.35 145.93 171.39 141.02C171.43 136.12 174.47 132.17 178.17 132.2C181.87 132.23 184.84 136.23 184.8 141.14C184.76 146.05 181.72 149.99 178.02 149.96Z"
        fill="white"
      />
      <path
        d="M214.36 115.24L114.38 114.37L118.18 168.72L207.04 169.49L214.36 115.24ZM126.06 160.35L123.44 122.89L204.72 123.6L199.68 160.99L126.07 160.35H126.06Z"
        fill="black"
      />
      <path
        d="M239.62 72.7701C239.51 72.7401 239.4 72.7001 239.29 72.6801L237.72 117.61L236.82 143.42L245.25 143.72L247.73 72.8901C246.48 73.1501 245.22 73.2801 243.94 73.2801C242.5 73.2801 241.06 73.1101 239.62 72.7801V72.7701Z"
        fill="black"
      />
      <path
        d="M252.68 49.0601C251.23 46.7201 248.95 45.0801 246.26 44.4501C245.47 44.2601 244.68 44.1801 243.9 44.1801C239.21 44.1801 234.96 47.4001 233.85 52.1501C232.55 57.6901 236 63.2601 241.55 64.5601C247.09 65.8601 252.66 62.4101 253.96 56.8601C254.59 54.1701 254.14 51.4001 252.68 49.0601Z"
        fill="#AFCFF8"
      />
      <path
        d="M248.19 36.23C243.31 35.09 238.28 35.91 234.02 38.55C229.76 41.19 226.78 45.33 225.64 50.21C224.5 55.09 225.32 60.12 227.96 64.38C230.54 68.54 234.56 71.47 239.3 72.67C239.41 72.7 239.51 72.74 239.63 72.76C241.06 73.1 242.51 73.26 243.95 73.26C245.23 73.26 246.5 73.13 247.74 72.87C249.86 72.43 251.91 71.61 253.8 70.44C258.06 67.8 261.04 63.66 262.18 58.78C263.32 53.9 262.5 48.87 259.86 44.61C257.22 40.35 253.08 37.37 248.2 36.23H248.19ZM241.55 64.55C236.01 63.25 232.55 57.68 233.85 52.14C234.96 47.39 239.22 44.17 243.9 44.17C244.68 44.17 245.47 44.26 246.26 44.44C248.95 45.07 251.22 46.71 252.68 49.05C254.14 51.39 254.59 54.16 253.96 56.85C252.66 62.39 247.09 65.85 241.55 64.55Z"
        fill="black"
      />
      <path
        d="M147.41 131.93C143.71 131.9 140.67 135.85 140.63 140.75C140.59 145.65 143.56 149.65 147.26 149.69C150.96 149.73 154 145.77 154.04 140.87C154.08 135.97 151.12 131.97 147.41 131.93Z"
        fill="black"
      />
      <path
        d="M178.17 132.2C174.47 132.17 171.43 136.12 171.39 141.02C171.35 145.92 174.32 149.92 178.02 149.96C181.72 149.99 184.76 146.04 184.8 141.14C184.84 136.24 181.87 132.24 178.17 132.2Z"
        fill="black"
      />
      <path
        d="M161.08 246.54C103.24 246.54 56.1899 205.14 56.1899 154.24C56.1899 103.34 103.24 61.9399 161.08 61.9399C218.92 61.9399 265.97 103.34 265.97 154.24C265.97 205.14 218.92 246.54 161.08 246.54ZM161.08 69.9399C107.65 69.9399 64.1899 107.76 64.1899 154.24C64.1899 200.72 107.66 238.54 161.08 238.54C214.5 238.54 257.97 200.72 257.97 154.24C257.97 107.76 214.5 69.9399 161.08 69.9399Z"
        fill="black"
      />
      <path
        d="M226.36 317.41C225.91 316.96 225.68 316.41 225.68 315.77V252.9C225.68 252.19 225.89 251.61 226.31 251.16C226.73 250.71 227.29 250.48 228 250.48H269.92C270.63 250.48 271.21 250.71 271.66 251.16C272.11 251.61 272.34 252.19 272.34 252.9V259.66C272.34 260.37 272.11 260.93 271.66 261.35C271.21 261.77 270.63 261.98 269.92 261.98H238.92V280.33H267.99C268.7 280.33 269.28 280.56 269.73 281.01C270.18 281.46 270.41 282.04 270.41 282.75V289.51C270.41 290.22 270.18 290.78 269.73 291.2C269.28 291.62 268.7 291.83 267.99 291.83H238.92V315.78C238.92 316.49 238.69 317.05 238.24 317.47C237.79 317.89 237.21 318.1 236.5 318.1H228C227.36 318.1 226.81 317.88 226.36 317.42V317.41Z"
        fill="white"
      />
      <path
        d="M274.22 317.5C273.8 317.11 273.59 316.63 273.59 316.05L273.78 315.18L297.06 252.88C297.51 251.27 298.64 250.47 300.44 250.47H310.97C312.64 250.47 313.77 251.28 314.35 252.88L337.63 315.18C337.69 315.37 337.73 315.66 337.73 316.05C337.73 316.63 337.54 317.11 337.15 317.5C336.76 317.89 336.31 318.08 335.8 318.08H327.4C326.56 318.08 325.92 317.89 325.47 317.5C325.02 317.11 324.73 316.7 324.6 316.24L320.06 304.46H291.38L286.84 316.24C286.32 317.46 285.39 318.08 284.04 318.08H275.64C275.12 318.08 274.66 317.89 274.24 317.5H274.22ZM316.67 293.36L305.66 263.52L294.75 293.36H316.67Z"
        fill="white"
      />
      <path
        d="M386.74 323.73C386.19 323.31 385.69 322.78 385.24 322.14L381.67 317.31C378.71 318.47 375.26 319.05 371.34 319.05C362.58 319.05 355.76 316.83 350.86 312.39C345.97 307.95 343.36 301.35 343.04 292.59C342.98 290.72 342.94 287.99 342.94 284.38C342.94 280.77 342.97 277.94 343.04 276.07C343.3 267.51 345.95 260.94 351.01 256.37C356.06 251.8 362.84 249.51 371.34 249.51C379.84 249.51 386.6 251.8 391.62 256.37C396.64 260.94 399.28 267.51 399.54 276.07C399.67 279.8 399.73 282.57 399.73 284.38C399.73 286.19 399.67 288.86 399.54 292.59C399.28 300.96 396.96 307.37 392.59 311.81L399.35 321.37C399.54 321.76 399.64 322.08 399.64 322.34V322.53C399.64 323.04 399.45 323.48 399.06 323.83C398.67 324.18 398.22 324.36 397.71 324.36H388.73C387.96 324.36 387.3 324.15 386.75 323.73H386.74ZM381.62 304.03C384.16 301.42 385.53 297.45 385.72 292.1C385.85 288.24 385.91 285.63 385.91 284.28C385.91 282.8 385.85 280.19 385.72 276.46C385.53 271.12 384.14 267.14 381.57 264.53C378.99 261.92 375.58 260.62 371.33 260.62C367.08 260.62 363.57 261.92 361 264.53C358.42 267.14 357.04 271.11 356.85 276.46C356.79 278.33 356.75 280.94 356.75 284.28C356.75 287.62 356.78 290.17 356.85 292.1C357.04 297.45 358.43 301.42 361 304.03C363.57 306.64 367.02 307.94 371.33 307.94C375.64 307.94 379.07 306.64 381.62 304.03Z"
        fill="white"
      />
    </svg>
  );
}
