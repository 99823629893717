import React, { useState } from "react";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";

export default function AIChatbotSettingsTrainingTabText() {
  const [title, setTitle] = useState("");
  const [trainingText, setTrainingText] = useState("");

  return (
    <>
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Enter a title"
        className="w-full p-2 border-[1px] rounded-md text-sm my-4"
      />
      <textarea
        value={trainingText}
        onChange={(e) => setTrainingText(e.target.value)}
        placeholder="Enter text here"
        className="w-full p-2 border-[1px] rounded-md text-sm"
        rows={5}
      />
      <div className="flex justify-end text-xs text-slate-500">
        Max. 10000 characters
      </div>
      <div className="flex justify-end mt-2">
        <PrimaryBlueButton
          label="Upload and Train"
          disabled={trainingText.length === 0}
          onClick={() => console.log("button clicked")}
        />
      </div>
    </>
  );
}
