import useApi from "@/hooks/useApi";
import React, { useMemo } from "react";
import Tooltip from "@velaro/velaro-shared/src/components/Tooltip";
import SearchBar from "../../../components/SearchBar";
import IconMoreHorizontalDots from "@/icons/IconMoreHorizontalDots";
import Pager from "@/components/Pager";
import EditUserPanel from "./EditUserPanel";
import useRoleListItems from "@/hooks/useRoleListItems";
import IconDeactivateUserPerson from "@/icons/IconDeactivateUserPerson";
import DeactivateUserModal from "./DeactivateUserModal";
import ActionMenu from "@/components/ActionMenu";
import { MenuItem } from "../types";
import IconEditPencil from "@/icons/IconEditPencil";
import IconContactsCrmSmall from "@/icons/IconContactsCrmSmall";
import { useNavigate } from "react-router-dom";
import useDeactivate from "@/hooks/useDeactivate";
import useSkillListItems from "@/hooks/useSkillListItems";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import IconPersonAdd from "@/icons/IconPersonAdd";
import { User } from "./types";
import useTeams from "@/hooks/useTeams";

export default function Users() {
  const [users, setUsers] = React.useState<User[]>([]);
  const [editUserId, setEditUserId] = React.useState<number | undefined>();
  const [deleteUserId, setDeleteUserId] = React.useState<number | undefined>();
  const [searchString, setSearchString] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [activeUserMenu, setActiveUserMenu] = React.useState<
    number | undefined
  >(undefined);
  const api = useApi();
  const navigate = useNavigate();
  const deactivate = useDeactivate();
  const pageSize = 10;

  const fetchUsers = React.useCallback(async () => {
    const response = await api.messaging.get("Users/List");
    setUsers(await response.json());
  }, [api.messaging]);

  async function handleDeactivate() {
    const response = await deactivate.deactivateUser(deleteUserId);

    if (response?.ok) {
      setDeleteUserId(undefined);
    } else {
      alert("Failed to delete user.");
    }

    const index = users.findIndex((x) => x.id === deleteUserId);
    const clone = [...users];
    clone.splice(index, 1);
    setUsers(clone);
  }

  const { getTeamNameById } = useTeams();
  const { getSkillNameById } = useSkillListItems();
  const { getRoleNameById } = useRoleListItems();

  React.useEffect(() => {
    fetchUsers();
  }, [currentPage, fetchUsers]);

  function getTeams(user: User) {
    if (user.teamIds?.length === 0) {
      return (
        <>
          <span className="w-4">
            <Tooltip
              text="This user is not in any teams and will not receive any new conversations."
              direction="top"
            >
              <i className="fa fa-info-circle pr-2 text-crimson-crimson-red-500" />
            </Tooltip>
          </span>
          <span className="text-slate-500">(None)</span>
        </>
      );
    }

    const teams = user.teamIds.map((x) => getTeamNameById(x));
    return teams.join(", ");
  }

  function getSkills(user: User) {
    if (user.skillIds?.length === 0) {
      return "None";
    }
    const skills = user.skillIds.map((x) => getSkillNameById(x));
    return skills.join(", ");
  }

  function getRoles(user: User) {
    if (user.roles?.length === 0 || user.roles === null) {
      return "(None)";
    }

    const roleNames = user.roles.map((x) => {
      const parsedNumber = parseInt(x);
      if (!isNaN(parsedNumber)) {
        return getRoleNameById(parsedNumber);
      } else {
        return x;
      }
    });
    return roleNames.join(", ");
  }

  const filteredUsers = useMemo(
    () =>
      users.filter((val) => {
        if (searchString === "") {
          return val;
        } else if (
          val.displayName.toLowerCase().includes(searchString.toLowerCase()) ||
          val.email.toLowerCase().includes(searchString.toLowerCase())
        ) {
          return val;
        }
      }),
    [users, searchString]
  );

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedUsers = filteredUsers.slice(startIndex, endIndex);

  const handleActionsMenuToggle = (userId: number) => {
    setActiveUserMenu(activeUserMenu === userId ? undefined : userId);
  };

  function userProfileOnClick(userId: number) {
    navigate(`/users/${userId}`);
  }

  const actionMenuItems: MenuItem[] = [
    {
      icon: <IconEditPencil className="m-2 w-8" />,
      label: "Edit User",
      onClick: (userId: number) => setEditUserId(userId)
    },
    {
      icon: <IconContactsCrmSmall className="m-2 w-8" />,
      label: "View User Profile",
      onClick: (userId: number) => userProfileOnClick(userId)
    },
    {
      icon: <IconDeactivateUserPerson className="m-2 w-8" />,
      label: "Deactivate User",
      onClick: (userId: number) => setDeleteUserId(userId)
    }
  ];

  if (editUserId) {
    return (
      <EditUserPanel
        userId={editUserId}
        onClose={() => {
          setEditUserId(undefined);
          fetchUsers();
        }}
        onUserUpdated={() => {
          setEditUserId(undefined);
          fetchUsers();
        }}
      />
    );
  }

  return (
    <div>
      <div className="flex items-center justify-between pb-4 mt-2">
        <SearchBar
          onSearch={setSearchString}
          placeholderText="Search by name or email"
        />
        <PrimaryBlueButton
          prefixIcon={<IconPersonAdd />}
          label="Add User"
          onClick={() => navigate("/user/add")}
        />
      </div>
      <div className="panel bg-white dark:bg-stone-300 rounded-md shadow-md mb-4">
        <table className="w-full">
          <thead>
            <tr>
              <th className={thClassName}></th>
              <th className={thClassName}>Display Name</th>
              <th className={thClassName}>Email</th>
              <th className={thClassName}>Roles</th>
              <th className={thClassName}>Teams</th>
              <th className={thClassName}>Skills</th>
              <th className={thClassName} style={{ width: 1 }}></th>
            </tr>
          </thead>
          <tbody>
            {paginatedUsers.map((user) => (
              <tr
                className={user.teamIds?.length ? "" : "text-slate-500"}
                key={user.id}
              >
                <td className={tdClassName}>
                  <input type="checkbox"></input>
                </td>
                <td className={tdClassName}>
                  <div className="flex items-center">
                    <div className="w-12 h-12 relative z-0 flex-grow-0 flex-shrink-0">
                      <img
                        src={user.picture}
                        alt=""
                        className="rounded-full w-12 h-12 absolute z-10"
                      />
                      {user.teamIds?.length === 0 && (
                        <div className="rounded-full w-12 h-12 relative top-0 z-20 bg-white opacity-50"></div>
                      )}
                    </div>
                    <div
                      className="p-4 cursor-pointer hover:underline"
                      onClick={() => userProfileOnClick(user.id)}
                    >
                      {user.displayName}
                    </div>
                  </div>
                </td>
                <td className={tdClassName}>{user.email}</td>
                <td className={tdClassName}>{getRoles(user)}</td>
                <td className={`${tdClassName} max-w-xs`}>{getTeams(user)}</td>
                <td className={`${tdClassName} max-w-xs`}>{getSkills(user)}</td>
                <td className={`${tdClassName} text-sm whitespace-nowrap`}>
                  <div
                    className="relative cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleActionsMenuToggle(user.id);
                    }}
                  >
                    <IconMoreHorizontalDots />
                    {activeUserMenu === user.id && (
                      <ActionMenu
                        items={actionMenuItems}
                        onClose={() => setActiveUserMenu(undefined)}
                        onClick={(index: number) => {
                          actionMenuItems[index].onClick(user.id);
                        }}
                      />
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pager
        currentPage={currentPage}
        setPage={setCurrentPage}
        pageSize={pageSize}
        totalRecords={filteredUsers.length}
      />
      <DeactivateUserModal
        show={!!deleteUserId}
        onClose={() => setDeleteUserId(undefined)}
        onDeactivate={() => handleDeactivate()}
      />
    </div>
  );
}

const thClassName = "border-b p-4 text-left";
const tdClassName = "border-b px-4 py-2 ";
