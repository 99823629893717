import React from "react";

export default function IllustrationAfterHours() {
  return (
    <svg viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M130.97 48.1899L377.95 56.2199C419.14 57.5599 453.49 89.3299 456.7 127.24L472.72 316.56C475.93 354.47 447.41 391.56 407.03 399.81L164.91 449.24C106.35 461.2 52.42 431.34 48.22 381.77L27.27 134.29C23.08 84.7299 71.23 46.2299 130.96 48.1699L130.97 48.1899Z"
        fill="#EEF4FD"
      />
      <path
        d="M416.868 92.1128L383.645 104.015L386.343 111.546L419.566 99.644L416.868 92.1128Z"
        fill="black"
      />
      <path
        d="M357.726 41.502L350.31 71.4473L358.075 73.3704L365.491 43.4251L357.726 41.502Z"
        fill="black"
      />
      <path
        d="M294.805 57.2454L287.853 61.2042L300.264 82.9982L307.216 79.0394L294.805 57.2454Z"
        fill="black"
      />
      <path
        d="M145 127.76L175.76 458.5L416.43 422.07L355.25 100.65L145 127.76Z"
        fill="black"
      />
      <path
        d="M151.65 126.37L181.56 442.09L412.26 415.92L353.15 101.65L151.65 126.37Z"
        fill="white"
      />
      <path
        d="M178.43 445.96L147.87 123.33L355.97 97.8L416.38 418.97L178.43 445.96ZM155.44 129.42L184.69 438.24L408.14 412.89L350.33 105.51L155.44 129.42Z"
        fill="black"
      />
      <path
        d="M353.15 101.65L151.65 126.37L156.95 182.33L362.77 152.81L353.15 101.65Z"
        fill="#AFCFF8"
      />
      <path
        d="M153.83 186.29L147.87 123.32L355.97 97.79L366.87 155.74L153.83 186.29ZM155.44 129.42L160.08 178.36L358.68 149.88L350.33 105.51L155.44 129.42Z"
        fill="black"
      />
      <path
        d="M401.23 357.26L176.41 387.77L181.56 442.09L412.26 415.92L401.23 357.26Z"
        fill="#AFCFF8"
      />
      <path
        d="M178.43 445.96L172.63 384.78L404.04 353.37L416.38 418.97L178.43 445.96ZM180.2 390.78L184.69 438.24L408.14 412.89L398.41 361.17L180.19 390.79L180.2 390.78Z"
        fill="black"
      />
      <path
        d="M364.69 391.54L391.19 387.47L363.48 406.45L364.69 391.54Z"
        fill="white"
      />
      <path
        d="M358.08 375.37L390.91 385.45L364.36 389.53L358.08 375.37Z"
        fill="white"
      />
      <path
        d="M300.322 132.411L206.139 143.642L206.964 150.563L301.147 139.332L300.322 132.411Z"
        fill="black"
      />
      <path
        d="M180.208 202.45L339.781 180.762C349.006 179.508 357.504 185.972 358.758 195.198C360.012 204.423 353.548 212.921 344.322 214.175L201.456 233.593C192.231 234.846 183.733 228.382 182.479 219.157L180.208 202.45Z"
        fill="#629FF0"
      />
      <path
        d="M341.039 188.507L197.756 207.981C195.972 208.224 194.723 209.866 194.965 211.65L194.966 211.66C195.209 213.444 196.852 214.694 198.636 214.452L341.918 194.977C343.702 194.735 344.952 193.092 344.71 191.308L344.708 191.298C344.466 189.514 342.823 188.264 341.039 188.507Z"
        fill="white"
      />
      <path
        d="M268.519 209.999L199.306 219.407C197.522 219.649 196.272 221.292 196.515 223.076L196.516 223.086C196.758 224.87 198.401 226.12 200.185 225.877L269.399 216.47C271.183 216.227 272.433 214.585 272.19 212.801L272.189 212.791C271.946 211.007 270.304 209.757 268.519 209.999Z"
        fill="white"
      />
      <path
        d="M296.952 206.124L283.338 207.974C281.554 208.217 280.304 209.86 280.546 211.644L280.548 211.654C280.79 213.438 282.433 214.687 284.217 214.445L297.832 212.594C299.616 212.352 300.866 210.709 300.623 208.925L300.622 208.915C300.379 207.131 298.736 205.881 296.952 206.124Z"
        fill="white"
      />
      <path
        d="M195.595 315.735L298.102 301.803C307.327 300.549 315.825 307.013 317.079 316.239C318.333 325.464 311.869 333.962 302.643 335.216L216.842 346.877C207.617 348.131 199.119 341.667 197.865 332.442L195.595 315.735Z"
        fill="#629FF0"
      />
      <path
        d="M299.368 309.536L213.151 321.255C211.367 321.497 210.117 323.14 210.36 324.924L210.361 324.934C210.604 326.718 212.246 327.968 214.031 327.725L300.248 316.007C302.032 315.764 303.282 314.122 303.039 312.338L303.038 312.328C302.795 310.544 301.152 309.294 299.368 309.536Z"
        fill="white"
      />
      <path
        d="M237.789 329.546L214.701 332.684C212.917 332.926 211.668 334.569 211.91 336.353L211.912 336.363C212.154 338.147 213.797 339.397 215.581 339.154L238.669 336.016C240.453 335.774 241.702 334.131 241.46 332.347L241.459 332.337C241.216 330.553 239.573 329.303 237.789 329.546Z"
        fill="white"
      />
      <path
        d="M255.273 327.158L252.598 327.522C250.814 327.764 249.564 329.407 249.806 331.191L249.808 331.201C250.05 332.985 251.693 334.235 253.477 333.992L256.152 333.629C257.937 333.386 259.186 331.743 258.944 329.959L258.942 329.949C258.7 328.165 257.057 326.916 255.273 327.158Z"
        fill="white"
      />
      <path
        d="M352.001 270.845L265.06 282.662C255.835 283.915 247.337 277.451 246.083 268.226C244.829 259.001 251.294 250.503 260.519 249.249L364.166 235.161L366.437 251.868C367.691 261.093 361.226 269.591 352.001 270.845Z"
        fill="#AFCFF8"
      />
      <path
        d="M262.253 263.497L349.609 251.623C351.393 251.381 352.643 249.738 352.401 247.954L352.399 247.944C352.157 246.16 350.514 244.911 348.73 245.153L261.373 257.026C259.589 257.269 258.339 258.912 258.582 260.696L258.583 260.705C258.826 262.489 260.468 263.739 262.253 263.497Z"
        fill="#629FF0"
      />
      <path
        d="M337.863 264.859L351.151 263.053C352.935 262.811 354.185 261.168 353.942 259.384L353.941 259.374C353.698 257.59 352.055 256.34 350.271 256.583L336.984 258.389C335.2 258.631 333.95 260.274 334.192 262.058L334.194 262.068C334.436 263.852 336.079 265.102 337.863 264.859Z"
        fill="#629FF0"
      />
      <path
        d="M296.419 270.492L327.86 266.219C329.644 265.976 330.894 264.333 330.651 262.549L330.65 262.539C330.407 260.755 328.764 259.506 326.98 259.748L295.539 264.022C293.755 264.264 292.506 265.907 292.748 267.691L292.75 267.701C292.992 269.485 294.635 270.735 296.419 270.492Z"
        fill="#629FF0"
      />
      <path
        d="M80.44 291.05C80.44 249.7 114.08 216.05 155.44 216.05C196.8 216.05 230.44 249.69 230.44 291.05C230.44 332.41 196.8 366.05 155.44 366.05C114.08 366.05 80.44 332.4 80.44 291.05Z"
        fill="black"
      />
      <path
        d="M155.44 351.7C190.07 351.7 218.25 323.52 218.25 288.89C218.25 254.26 190.07 226.08 155.44 226.08C120.81 226.08 92.63 254.26 92.63 288.89C92.63 323.52 120.8 351.7 155.44 351.7Z"
        fill="#AFCFF8"
      />
      <path
        d="M156.21 230.02C187.92 230.02 213.72 255.82 213.72 287.52C213.72 319.22 187.92 345.02 156.21 345.02C124.5 345.02 98.71 319.22 98.71 287.52C98.71 255.82 124.51 230.02 156.21 230.02Z"
        fill="white"
      />
      <path
        d="M206.59 269.39C205.42 268.69 203.87 269.04 203.14 270.17L200.6 274.12C196.89 253.1 177.78 237.18 155.32 237.18C129.99 237.18 109.38 257.09 109.38 281.57C109.38 282.91 110.5 283.99 111.89 283.99C113.28 283.99 114.4 282.9 114.4 281.57C114.4 259.76 132.76 242.02 155.33 242.02C176 242.02 193.18 256.79 195.89 276.23L190.37 271.94C189.85 271.53 189.19 271.35 188.53 271.42C187.86 271.49 187.27 271.81 186.85 272.31C185.99 273.35 186.16 274.88 187.23 275.71L197.21 283.47C197.21 283.47 197.22 283.48 197.23 283.49L197.44 283.63C197.55 283.7 197.67 283.75 197.75 283.78L198 283.88C198.25 283.96 198.51 284 198.76 284H198.99C199.18 283.97 199.36 283.93 199.53 283.88L200.04 283.66C200.12 283.61 200.2 283.56 200.27 283.51L200.44 283.38C200.55 283.28 200.65 283.17 200.74 283.06L207.39 272.74C208.12 271.61 207.76 270.11 206.58 269.41L206.59 269.39Z"
        fill="#629FF0"
      />
      <path
        d="M157.1 337.87C182.43 337.87 203.04 317.96 203.04 293.48C203.04 292.14 201.92 291.06 200.53 291.06C199.14 291.06 198.02 292.15 198.02 293.48C198.02 315.29 179.66 333.03 157.09 333.03C136.42 333.03 119.24 318.26 116.53 298.82L122.06 303.12C122.58 303.53 123.24 303.71 123.9 303.64C124.57 303.57 125.16 303.25 125.58 302.75C126.44 301.71 126.27 300.18 125.2 299.35L115.23 291.6L114.75 291.3L114.68 291.28C114.54 291.22 114.39 291.17 114.23 291.13L114 291.08C113.72 291.04 113.43 291.05 113.14 291.11C113.06 291.13 112.98 291.15 112.95 291.16C112.82 291.19 112.69 291.24 112.58 291.29C112.47 291.34 112.36 291.4 112.34 291.43C112.27 291.47 112.21 291.51 112.12 291.57C111.87 291.76 111.67 291.96 111.52 292.2L105.01 302.33C104.66 302.88 104.55 303.53 104.7 304.16C104.85 304.79 105.25 305.33 105.82 305.67C106.22 305.91 106.67 306.03 107.14 306.03C108.01 306.03 108.81 305.6 109.27 304.89L111.81 300.94C115.52 321.96 134.62 337.87 157.09 337.87H157.1Z"
        fill="#629FF0"
      />
      <path
        d="M146.64 263.92C143.23 261.69 138.8 261.56 134.48 263.56C129.79 265.73 126.3 269.91 125.8 273.95C125.72 274.62 125.9 275.29 126.31 275.82C126.73 276.36 127.33 276.7 128 276.78C128.67 276.86 129.34 276.68 129.88 276.26C130.41 275.84 130.75 275.25 130.83 274.57C131.12 272.27 133.55 269.58 136.61 268.16C139.3 266.91 141.95 266.91 143.86 268.16C145.77 269.41 147.07 272.4 147.34 276.17C147.69 281.13 146.11 286.33 143.58 288.54C137.48 293.88 128.95 304.34 128.59 304.78C127.97 305.53 127.85 306.59 128.26 307.47C128.68 308.35 129.58 308.92 130.55 308.92H149.91C151.31 308.92 152.45 307.78 152.45 306.38C152.45 304.98 151.31 303.84 149.91 303.84H136C138.98 300.38 143.47 295.36 146.91 292.35C150.65 289.08 152.85 282.43 152.39 275.81C152.01 270.39 149.91 266.06 146.63 263.91L146.64 263.92Z"
        fill="black"
      />
      <path
        d="M172.23 265.69C172.78 264.41 172.19 262.91 170.91 262.36C169.62 261.8 168.13 262.4 167.58 263.68C166.51 266.15 163.27 272.04 160.14 277.73C150.5 295.26 150.38 295.81 151.9 297.57C152.59 298.37 153.74 298.67 154.75 298.34C155.59 298.17 160.69 298.04 172.34 298.23V310.37C172.34 311.77 173.48 312.91 174.88 312.91C176.28 312.91 177.42 311.77 177.42 310.37V298.32C179.76 298.37 182.01 298.42 183.98 298.48C184.65 298.52 185.3 298.29 185.81 297.82C186.31 297.36 186.61 296.7 186.63 296.01C186.65 295.33 186.4 294.69 185.94 294.2C185.47 293.71 184.84 293.43 184.17 293.41C182.95 293.38 180.47 293.31 177.43 293.25V281.74C177.43 280.34 176.29 279.2 174.89 279.2C173.49 279.2 172.35 280.34 172.35 281.74V293.16C165.76 293.05 160.83 293.04 157.63 293.13C159.35 289.71 162.31 284.33 164.61 280.14C167.93 274.1 171.07 268.39 172.23 265.7V265.69Z"
        fill="black"
      />
    </svg>
  );
}
