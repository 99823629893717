import useApi from "@/hooks/useApi";
import React, { useCallback, useEffect, useState } from "react";
import LoadingSpinner from "@/components/LoadingSpinner";
import { SkillListItemsContext } from "@/context/SkillListItemsContext";

export interface SkillListItem {
  id: number;
  name: string;
  autoRoute: boolean;
  routingType: string;
  isDefault: boolean;
  autoUnassign: boolean;
  unassignWarningTime: number;
  unassignTime: number;
}

export function SkillListItemsProvider(props: { children: React.ReactNode }) {
  const api = useApi();
  const [skills, setSkills] = React.useState<SkillListItem[] | null>(null);
  const [loading, setLoading] = useState(true);

  const fetch = useCallback(async () => {
    const response = await api.messaging.get("Skills/List");
    const data = await response.json();
    setSkills(data);
    setLoading(false);
    return data;
  }, [api.messaging]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  function getSkillNameById(skillId: number) {
    return skills?.find((x) => x.id === skillId)?.name || "";
  }

  function getSkillById(skillId: number) {
    return skills?.find((x) => x.id === skillId) || null;
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <SkillListItemsContext.Provider
      value={{
        skills: skills || [],
        getSkillNameById,
        setSkills,
        getSkillById
      }}
    >
      {props.children}
    </SkillListItemsContext.Provider>
  );
}
