import React from "react";

export default function IllustrationHelpCenterSearchArticlesKB() {
  return (
    <svg viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M130.97 48.19L377.95 56.22C419.14 57.56 453.49 89.33 456.7 127.24L472.72 316.56C475.93 354.47 447.41 391.56 407.03 399.81L164.91 449.24C106.35 461.2 52.42 431.34 48.22 381.77L27.27 134.29C23.08 84.73 71.23 46.23 130.96 48.17L130.97 48.19Z"
        fill="#EEF4FD"
      />
      <path
        d="M444.46 318.44L54.95 381.72L50.41 118.52L402.04 80.61L444.46 318.44Z"
        fill="black"
      />
      <path
        d="M444.46 318.44L69.11 367.42L67.93 117.17L402.04 80.61L444.46 318.44Z"
        fill="#AFCFF8"
      />
      <path
        d="M65.13 371.97L63.91 113.58L405.32 76.23L449.14 321.87L65.14 371.97H65.13ZM71.95 120.75L73.09 362.87L439.79 315.03L398.76 85L71.95 120.75Z"
        fill="black"
      />
      <path
        d="M402.04 80.61L67.93 117.17V177.61C145.25 170.22 314.45 154.56 413.24 143.41L402.04 80.62V80.61Z"
        fill="white"
      />
      <path
        d="M63.93 182V113.57L405.32 76.22L417.93 146.89L413.69 147.37C331.39 156.66 200.59 169.05 114.04 177.24C96.74 178.88 81.24 180.34 68.32 181.58L63.94 182H63.93ZM71.93 120.75V173.2C83.93 172.06 97.89 170.73 113.28 169.28C198.33 161.23 326.12 149.13 408.55 139.9L398.76 84.99L71.93 120.75Z"
        fill="black"
      />
      <path
        d="M98.91 153.2C103.621 153.2 107.44 149.381 107.44 144.67C107.44 139.959 103.621 136.14 98.91 136.14C94.199 136.14 90.38 139.959 90.38 144.67C90.38 149.381 94.199 153.2 98.91 153.2Z"
        fill="black"
      />
      <path
        d="M132.29 149.67C137.001 149.67 140.82 145.851 140.82 141.14C140.82 136.429 137.001 132.61 132.29 132.61C127.579 132.61 123.76 136.429 123.76 141.14C123.76 145.851 127.579 149.67 132.29 149.67Z"
        fill="black"
      />
      <path
        d="M348.737 42.367L341.958 46.6135L358.137 72.445L364.916 68.1986L348.737 42.367Z"
        fill="black"
      />
      <path
        d="M447.868 88.7754L420.277 102.054L423.746 109.263L451.337 95.984L447.868 88.7754Z"
        fill="black"
      />
      <path
        d="M410.845 35.609L400.171 60.9749L407.545 64.0776L418.218 38.7117L410.845 35.609Z"
        fill="black"
      />
      <path
        d="M291.48 163.54C291.83 163.66 292.16 163.82 292.45 164.02C294.08 165.18 295.73 166.31 297.38 167.45L298.28 168.08C300.21 169.36 302.16 170.61 304.08 171.83C305.5 172.73 306.91 173.64 308.31 174.55L310.5 175.98C315.59 179.3 320.69 182.62 325.76 185.96C332.48 190.38 337.99 194.08 343.1 197.59L344.79 198.75C347.31 200.47 349.91 202.26 352.44 204.16C353.2 204.73 353.7 205.59 353.82 206.52L366.35 297.09C366.59 299.03 365.18 300.8 363.2 301.05L349.72 302.74L351.33 315.56C351.57 317.5 350.16 319.27 348.18 319.52L204.15 337.59C202.17 337.84 200.36 336.47 200.12 334.53L182.62 204.32C182.38 202.38 183.79 200.61 185.77 200.36L199.35 198.66L196.83 178.57C196.59 176.63 198 174.86 199.98 174.61L289.81 163.34C290.34 163.27 290.9 163.34 291.48 163.55V163.54Z"
        fill="black"
      />
      <path
        d="M295.5 212.03L290.02 168.3L204.52 179.02L221.81 307.74L360.4 290.36L348.76 206.71L297.07 213.19C296.29 213.29 295.58 212.77 295.49 212.02L295.5 212.03Z"
        fill="white"
      />
      <path
        d="M206.96 326.57L344.66 311.73L343.04 298.8L216.36 314.23C215.58 314.33 214.88 313.81 214.78 313.06L200.92 202.98L190.53 204.74L206.95 326.55L206.96 326.57Z"
        fill="white"
      />
      <path
        d="M301.12 208.58L341.38 203.53C340.04 202.63 338.69 201.74 337.34 200.85L335.85 199.87C333.15 198.08 330.43 196.3 327.72 194.51C319.14 188.86 310.26 183.03 301.71 177.03C300.93 176.48 300.15 175.94 299.37 175.4C298.48 174.81 297.61 174.21 296.73 173.6L301.12 208.58Z"
        fill="white"
      />
      <path
        d="M283.15 193.92L222.3 201.55C221.51 201.65 220.79 201.1 220.69 200.33C220.59 199.56 221.16 198.85 221.95 198.75L282.8 191.12C283.59 191.02 284.31 191.57 284.41 192.34C284.51 193.11 283.94 193.82 283.15 193.92Z"
        fill="#AFCFF8"
      />
      <path
        d="M222.12 203.56C220.41 203.56 218.93 202.3 218.71 200.57C218.6 199.66 218.85 198.75 219.42 198.03C219.99 197.32 220.8 196.86 221.7 196.75L282.55 189.12C284.44 188.88 286.16 190.21 286.4 192.08C286.63 193.95 285.29 195.66 283.41 195.9L222.56 203.53C222.41 203.55 222.27 203.56 222.12 203.56Z"
        fill="#AFCFF8"
      />
      <path
        d="M285.9 215.86L225.05 223.49C224.26 223.59 223.54 223.04 223.44 222.27C223.34 221.5 223.91 220.79 224.7 220.69L285.55 213.06C286.34 212.96 287.06 213.51 287.16 214.28C287.26 215.05 286.69 215.76 285.9 215.86Z"
        fill="#AFCFF8"
      />
      <path
        d="M224.87 225.51C223.16 225.51 221.68 224.25 221.46 222.52C221.23 220.65 222.57 218.94 224.45 218.7L285.3 211.07C287.19 210.83 288.91 212.16 289.15 214.03C289.38 215.9 288.04 217.61 286.16 217.85L225.31 225.48C225.16 225.5 225.02 225.51 224.88 225.51H224.87Z"
        fill="#AFCFF8"
      />
      <path
        d="M333.24 230.79C333.34 231.57 332.77 232.27 331.98 232.37L227.81 245.44C227.02 245.54 226.3 244.99 226.2 244.22C226.1 243.45 226.67 242.74 227.46 242.64L331.63 229.57C332.42 229.47 333.14 230.02 333.24 230.79Z"
        fill="#AFCFF8"
      />
      <path
        d="M227.62 247.45C225.91 247.45 224.43 246.19 224.21 244.46C224.1 243.55 224.35 242.65 224.92 241.92C225.49 241.21 226.3 240.75 227.2 240.64L331.37 227.57C333.26 227.33 334.98 228.66 335.22 230.53C335.45 232.4 334.11 234.11 332.23 234.35L228.06 247.42C227.91 247.44 227.77 247.45 227.62 247.45Z"
        fill="#AFCFF8"
      />
      <path
        d="M230.21 264.58L334.38 251.51C335.17 251.41 335.89 251.96 335.99 252.73C336.09 253.5 335.52 254.21 334.73 254.31L230.56 267.38C229.77 267.48 229.05 266.93 228.95 266.16C228.85 265.39 229.42 264.68 230.21 264.58Z"
        fill="#AFCFF8"
      />
      <path
        d="M230.37 269.4C229.62 269.4 228.89 269.16 228.28 268.7C227.54 268.14 227.07 267.33 226.96 266.42C226.85 265.51 227.1 264.61 227.67 263.88C228.23 263.17 229.04 262.72 229.95 262.6L334.12 249.53C335.03 249.42 335.92 249.66 336.65 250.21C337.39 250.77 337.86 251.58 337.97 252.49C338.08 253.4 337.83 254.3 337.26 255.03C336.69 255.74 335.88 256.2 334.98 256.31L230.81 269.38C230.66 269.4 230.52 269.41 230.37 269.41V269.4Z"
        fill="#AFCFF8"
      />
      <path
        d="M232.96 286.52L337.13 273.45C337.92 273.35 338.64 273.9 338.74 274.67C338.84 275.44 338.27 276.15 337.48 276.25L233.31 289.32C232.52 289.42 231.8 288.87 231.7 288.1C231.6 287.33 232.17 286.62 232.96 286.52Z"
        fill="#AFCFF8"
      />
      <path
        d="M233.12 291.34C232.37 291.34 231.64 291.1 231.03 290.64C230.29 290.08 229.82 289.27 229.71 288.36C229.48 286.49 230.82 284.78 232.7 284.54L336.87 271.47C337.78 271.36 338.67 271.6 339.4 272.15C340.14 272.71 340.61 273.52 340.72 274.43C340.83 275.34 340.58 276.24 340.01 276.97C339.44 277.68 338.63 278.14 337.73 278.25L233.56 291.32C233.41 291.34 233.27 291.35 233.12 291.35V291.34Z"
        fill="#AFCFF8"
      />
      <path
        d="M233.21 379.08C213.83 384.49 193.5 382.03 175.97 372.15C158.44 362.27 145.81 346.16 140.39 326.78C134.98 307.4 137.44 287.07 147.31 269.54C157.19 252.01 173.3 239.37 192.68 233.96C212.06 228.55 232.39 231.01 249.92 240.89C267.45 250.77 280.08 266.88 285.5 286.26C290.91 305.64 288.45 325.97 278.57 343.5C268.69 361.03 252.58 373.66 233.2 379.08H233.21Z"
        fill="black"
      />
      <path
        d="M233.21 382.48C213.83 387.89 193.5 385.43 175.97 375.55C158.44 365.67 145.81 349.56 140.39 330.18C134.98 310.8 137.44 290.47 147.31 272.94C157.19 255.41 173.3 242.77 192.68 237.36C212.06 231.95 232.39 234.41 249.92 244.29C267.45 254.17 280.08 270.28 285.5 289.66C290.91 309.04 288.45 329.37 278.57 346.9C268.69 364.43 252.58 377.06 233.2 382.48H233.21Z"
        fill="black"
      />
      <path
        d="M195.35 243.53C178.52 248.23 164.53 259.2 155.95 274.42C147.37 289.64 145.24 307.29 149.94 324.12C154.64 340.95 165.61 354.94 180.83 363.52C196.05 372.1 213.71 374.23 230.53 369.53C247.36 364.83 261.35 353.86 269.93 338.64C278.51 323.42 280.64 305.77 275.94 288.94C271.24 272.11 260.27 258.12 245.05 249.54C229.83 240.96 212.18 238.83 195.35 243.53Z"
        fill="white"
      />
      <path
        d="M228.39 361.85C213.62 365.98 198.12 364.1 184.75 356.57C157.16 341.03 147.36 305.93 162.91 278.35C164.25 275.96 167.28 275.12 169.66 276.46C172.05 277.8 172.89 280.83 171.55 283.21C158.69 306.04 166.79 335.07 189.62 347.93C200.68 354.16 213.5 355.71 225.72 352.3C237.95 348.89 248.11 340.92 254.34 329.86C267.2 307.03 259.1 278 236.27 265.14C233.88 263.8 233.04 260.77 234.38 258.39C235.72 256 238.75 255.16 241.13 256.5C268.72 272.04 278.52 307.14 262.97 334.72C255.44 348.09 243.16 357.72 228.38 361.84L228.39 361.85Z"
        fill="#AFCFF8"
      />
      <path
        d="M138.47 462.33C135.02 463.29 131.2 462.94 127.85 461.05C121.13 457.27 118.74 448.72 122.53 442L153.1 387.75C156.89 381.03 165.43 378.64 172.15 382.43C178.87 386.21 181.25 394.76 177.47 401.48L146.91 455.72C145.02 459.08 141.94 461.36 138.49 462.32L138.47 462.33Z"
        fill="black"
      />
      <path
        d="M164.18 387.22C163.14 387.51 162.26 388.19 161.73 389.14L131.16 443.39C130.63 444.34 130.5 445.43 130.79 446.48C131.08 447.53 131.76 448.4 132.71 448.93C134.6 450 137.19 449.28 138.25 447.38L168.81 393.14C169.34 392.19 169.47 391.1 169.18 390.05C168.89 389 168.21 388.13 167.26 387.6C166.31 387.07 165.22 386.93 164.17 387.23L164.18 387.22Z"
        fill="#629FF0"
      />
      <path
        d="M171.04 388.05C169.82 388.39 168.47 388.27 167.27 387.59C164.88 386.25 164.04 383.22 165.38 380.84L174.08 365.4C175.42 363.01 178.45 362.17 180.83 363.51C183.22 364.85 184.06 367.88 182.72 370.26L174.02 385.7C173.35 386.89 172.26 387.7 171.03 388.04L171.04 388.05Z"
        fill="black"
      />
    </svg>
  );
}
