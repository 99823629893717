import Modal from "@velaro/velaro-shared/src/components/Modal";
import useApi from "@/hooks/useApi";
import React, { useState } from "react";
import Form, { defaultCannedResponseModel, CannedResponseModel } from "./Form";
import { set } from "lodash";
import LoadingSpinner from "@/components/LoadingSpinner";
import useSelectedTeam from "@/hooks/useSelectedTeam";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";

interface Props {
  show: boolean;
  onClose(): void;
  onCreated(): void;
}

export default function CreateModal(props: Props) {
  const api = useApi();
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const { teamId } = useSelectedTeam();
  const [cannedResponse, setCannedResponse] =
    React.useState<CannedResponseModel>(defaultCannedResponseModel);

  async function handleSubmit() {
    setSending(true);
    cannedResponse.teamId = teamId;
    const response = await api.messaging.post(
      "CannedResponses",
      cannedResponse
    );

    setSuccess(response.ok);
    if (response.ok) {
      props.onCreated();
    }
    setSending(false);
  }

  return (
    <Modal show={props.show}>
      <Modal.Header
        title="New Canned Response"
        onClose={() => props.onClose()}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Modal.Body>
          <div className="p-4">
            <Form
              cannedResponse={cannedResponse}
              onChange={(updates) => {
                setCannedResponse((oldState) => ({ ...oldState, ...updates }));
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="p-4 flex flex-col">
            {success === false && (
              <div className="flex flex-row mb-4">
                There was an error saving your changes. Please see Integration
                Log for more details.
              </div>
            )}
            {sending ? (
              <div className="ml-auto flex">
                Saving Changes...
                <LoadingSpinner />
              </div>
            ) : (
              <div className="ml-auto flex">
                <PrimaryBlueButton
                  onClick={() => handleSubmit()}
                  label="Save Changes"
                />
              </div>
            )}
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
