import React from "react";
import { IconProps } from "./types";

function IconArrowCircleLeft(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      {...props}
    >
      <g id="arrow-circle-right" transform="translate(16 16) rotate(180)">
        <rect
          id="Rectangle_535"
          data-name="Rectangle 535"
          width="16"
          height="16"
          transform="translate(16 16) rotate(180)"
          fill="none"
        />
        <path
          id="Path_434"
          data-name="Path 434"
          d="M6.785,3.29H1.722L3.712.9A.549.549,0,1,0,2.868.2L.126,3.488a.652.652,0,0,0-.049.082.069.069,0,0,1-.038.071.527.527,0,0,0,0,.395.069.069,0,0,0,.038.071.652.652,0,0,0,.049.082l2.741,3.29a.549.549,0,1,0,.844-.7L1.722,4.387H6.785a.548.548,0,0,0,0-1.1Z"
          transform="translate(12 11.677) rotate(180)"
        />
        <path
          id="Ellipse_46"
          data-name="Ellipse 46"
          d="M6.667.867a5.8,5.8,0,1,0,5.8,5.8,5.807,5.807,0,0,0-5.8-5.8m0-.867A6.667,6.667,0,1,1,0,6.667,6.667,6.667,0,0,1,6.667,0Z"
          transform="translate(1.333 1.333)"
        />
      </g>
    </svg>
  );
}

export default IconArrowCircleLeft;
