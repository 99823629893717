export interface FilterState {
  searchTerm: string;
  publishStatus: "All" | "Published" | "Unpublished";
  visibility: "All" | "Public" | "Internal";
  orderBy: "CreatedAt" | "UpdatedAt";
  orderDir: "ASC" | "DESC";
  topicId: string;
  lastEditedByUserId: string;
  tags: string[];
}

export const defaultFilterState: FilterState = {
  searchTerm: "",
  publishStatus: "All",
  visibility: "All",
  orderBy: "CreatedAt",
  orderDir: "DESC",
  topicId: "",
  lastEditedByUserId: "",
  tags: []
};
