import useApi from "@/hooks/useApi";
import useSkillListItems from "@/hooks/useSkillListItems";
import React from "react";
import CreateSkillModal from "./CreateSkillModal";
import { SkillListItem } from "@/providers/SkillsProvider";
import EditSkillModal from "./EditSkillModal";

export default function Skills() {
  const [showCreateModal, setShowCreateModal] = React.useState(false);
  const [editSkillId, setEditSkillId] = React.useState<number>();
  const api = useApi();

  const thClassName = "border p-2 text-left";
  const tdClassName = "border p-2";

  const { skills, setSkills } = useSkillListItems();

  async function deleteSkill(id: number) {
    if (
      confirm(
        "Are you sure you want to delete this skill? Deleting this skill could cause users to no longer have this skill."
      )
    ) {
      await api.messaging.delete(`Skills/${id}`);
      setSkills(skills.filter((x) => x.id !== id));
    }
  }

  return (
    <>
      <div className="ml-auto">
        <button
          className="btn-green m-2"
          onClick={() => setShowCreateModal(true)}
        >
          New Skill
        </button>
      </div>
      <table className="w-full bg-white">
        <thead>
          <tr>
            <th className={thClassName}>Name</th>
            <th className={thClassName}>Id</th>
            <th className={thClassName}>Auto Routing Enabled</th>
            <th className={thClassName}>Auto Routing Type</th>
            <th className={thClassName}>Auto Unassignment Enabled</th>
            <th className={thClassName}>Auto Unassignment Timeout</th>
            <th className={thClassName} style={{ width: 1 }}></th>
          </tr>
        </thead>
        <tbody>
          {skills.map((skill) => (
            <tr key={skill.id}>
              <td className={tdClassName}>{skill.name}</td>
              <td className={tdClassName}>{skill.id}</td>
              <td className={tdClassName}>{String(skill.autoRoute)}</td>
              <td className={tdClassName}>{skill.routingType}</td>
              <td className={tdClassName}>{String(skill.autoUnassign)}</td>
              <td className={tdClassName}>{skill.unassignTime}</td>
              <td className={`${tdClassName} text-sm whitespace-nowrap`}>
                <button
                  className="underline"
                  onClick={() => setEditSkillId(skill.id)}
                >
                  Edit
                </button>
                <span className="mx-2">&bull;</span>
                <button
                  className="underline"
                  onClick={() => deleteSkill(skill.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showCreateModal && (
        <CreateSkillModal
          show={showCreateModal}
          onClose={() => setShowCreateModal(false)}
          onSkillCreated={(skill: SkillListItem) => {
            setShowCreateModal(false);
            setSkills([...skills, skill]);
          }}
        />
      )}
      {editSkillId && (
        <EditSkillModal
          skillId={editSkillId}
          onClose={() => setEditSkillId(undefined)}
          onSkillUpdated={(skill: SkillListItem) => {
            setEditSkillId(undefined);
            setSkills(skills.map((x) => (x.id === skill.id ? skill : x)));
          }}
        />
      )}
    </>
  );
}
