import React from "react";

export interface AutoResolveChatValue {
  minutes: string;
}

interface Props {
  updateValue: (value: AutoResolveChatValue) => void;
  value: AutoResolveChatValue;
}

export default function AutoResolveChatRuleForm({ value, updateValue }: Props) {
  return (
    <>
      <div>
        Choose when to automatically resolve a chat after a period of inacitivty
      </div>
      <div className="flex flex-col mr-auto">
        <div>
          Automatically resolve chat after
          <input
            className="flex input overflow-ellipsis whitespace-nowrap w-12 mx-2"
            value={value["minutes"]}
            onChange={(e) => updateValue({ minutes: e.currentTarget.value })}
          />
          minutes of activity
        </div>
      </div>
    </>
  );
}
