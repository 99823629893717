import { Card } from "@/components/Card";
import React from "react";

export default function Landing() {
  return (
    <>
      <Card
        to="/integrations/netsuite"
        title="NetSuite"
        subtitle="Configure your NetSuite integration."
      />
      <Card
        to="/integrations/formstack"
        title="Formstack"
        subtitle="Configure your Formstack integration."
      />
    </>
  );
}
