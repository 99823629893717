import { RoleListItemsContext } from "@/context/RoleListItemsContext";
import useApi from "@/hooks/useApi";
import React, { useCallback, useEffect, useState } from "react";

export interface RoleListItem {
  id: number;
  name: string;
  description: string;
}

// make this bad boy lazy???
export function RoleListItemsProvider(props: { children: React.ReactNode }) {
  const api = useApi();
  const [customRoles, setCustomRoles] = React.useState<RoleListItem[]>([]);
  const [defaultRoles, setDefaultRoles] = React.useState<RoleListItem[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchCustom = useCallback(async () => {
    const response = await api.messaging.get("Roles/List");
    const data = await response.json();
    setCustomRoles(data);
    setLoading(false);
    return data;
  }, [api.messaging]);

  const fetchDefault = useCallback(async () => {
    const response = await api.messaging.get("Roles/Default");
    const data = await response.json();
    setDefaultRoles(data);
    setLoading(false);
    return data;
  }, [api.messaging]);

  useEffect(() => {
    fetchCustom();
    fetchDefault();
  }, [fetchCustom, fetchDefault]);

  function getCustomRoleNameById(roleId: number) {
    return customRoles?.find((x) => x.id === roleId)?.name || "";
  }

  return (
    <RoleListItemsContext.Provider
      value={{
        customRoles: customRoles,
        defaultRoles: defaultRoles,
        roles: defaultRoles?.concat(customRoles),
        getRoleNameById: getCustomRoleNameById,
        loading
      }}
    >
      {props.children}
    </RoleListItemsContext.Provider>
  );
}
