import React, { useMemo } from "react";
import LoadingSpinner from "@velaro/velaro-shared/src/components/LoadingSpinner";
import useContactTags from "@/hooks/useContactTags";
import useConversationTags from "@/hooks/useConversationTags";
import MeatballMenu, {
  MeatballMenuItem
} from "@velaro/velaro-shared/src/components/MeatballMenu";
import TableBase from "@velaro/velaro-shared/src/components/Table/TableBase";
import IconTrash from "@velaro/velaro-shared/src/icons/IconTrash";

export default function Tags() {
  //make all variable named contact tags
  const {
    all: contactTags,
    loading: loadingContactTags,
    handleDelete: handleContactDelete
  } = useContactTags();
  const {
    all: conversationTags,
    loading: loadingConversationTags,
    handleDelete: handleConversationDelete
  } = useConversationTags();

  const tableData = useMemo(() => {
    const contactTagTableData = contactTags.map((tag) => {
      return {
        name: tag.name,
        color: tag.color,
        category: "contact",
        edit: <TagMeatballMenu onDelete={() => handleContactDelete(tag.id)} />
      };
    });
    const conversationTagTableData = conversationTags.map((tag) => {
      return {
        name: tag.name,
        color: tag.color,
        category: "conversation",
        edit: (
          <TagMeatballMenu onDelete={() => handleConversationDelete(tag.id)} />
        )
      };
    });
    return [...contactTagTableData, ...conversationTagTableData];
  }, [
    contactTags,
    conversationTags,
    handleContactDelete,
    handleConversationDelete
  ]);

  //Todo translate
  const columns = [
    { label: "Name", accessor: "name" },
    { label: "Color", accessor: "color" },
    { label: "Category", accessor: "category" },
    { label: "", accessor: "edit" }
  ];

  if (loadingContactTags || loadingConversationTags) return <LoadingSpinner />;

  return <TableBase columns={columns} tableData={tableData} />;
}

interface TagMeatballMenuProps {
  onDelete: () => void;
}

function TagMeatballMenu(props: TagMeatballMenuProps) {
  const menuItem: MeatballMenuItem[] = [
    { label: "Delete", icon: IconTrash, onClick: props.onDelete }
  ];
  return <MeatballMenu menuItems={menuItem} />;
}
