import React from "react";
import { IconProps } from "./types";

function IconAppsIntegrations(props: IconProps) {
  return (
    <svg
      id="apps-integrations"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect
        id="Rectangle_429"
        data-name="Rectangle 429"
        width="24"
        height="24"
        fill="none"
        opacity="0"
      />
      <g
        id="Group_1039"
        data-name="Group 1039"
        transform="translate(-104.047 -450.605)"
        fill="currentColor"
      >
        <path
          id="Path_2056"
          data-name="Path 2056"
          d="M2.744,2A.745.745,0,0,0,2,2.744V6.4a.745.745,0,0,0,.744.744H6.4A.745.745,0,0,0,7.148,6.4V2.744A.745.745,0,0,0,6.4,2H2.744m0-2H6.4A2.744,2.744,0,0,1,9.148,2.744V6.4A2.744,2.744,0,0,1,6.4,9.148H2.744A2.744,2.744,0,0,1,0,6.4V2.744A2.744,2.744,0,0,1,2.744,0Z"
          transform="translate(106 453)"
        />
        <path
          id="Path_2057"
          data-name="Path 2057"
          d="M2.744,2A.745.745,0,0,0,2,2.744V6.4a.745.745,0,0,0,.744.744H6.4A.745.745,0,0,0,7.148,6.4V2.744A.745.745,0,0,0,6.4,2H2.744m0-2H6.4A2.744,2.744,0,0,1,9.148,2.744V6.4A2.744,2.744,0,0,1,6.4,9.148H2.744A2.744,2.744,0,0,1,0,6.4V2.744A2.744,2.744,0,0,1,2.744,0Z"
          transform="translate(106 463.063)"
        />
        <path
          id="Path_2054"
          data-name="Path 2054"
          d="M2.744,2A.745.745,0,0,0,2,2.744V6.4a.745.745,0,0,0,.744.744H6.4A.745.745,0,0,0,7.148,6.4V2.744A.745.745,0,0,0,6.4,2H2.744m0-2H6.4A2.744,2.744,0,0,1,9.148,2.744V6.4A2.744,2.744,0,0,1,6.4,9.148H2.744A2.744,2.744,0,0,1,0,6.4V2.744A2.744,2.744,0,0,1,2.744,0Z"
          transform="translate(116.977 453)"
        />
        <path
          id="Path_2055"
          data-name="Path 2055"
          d="M2.744,2A.745.745,0,0,0,2,2.744V6.4a.745.745,0,0,0,.744.744H6.4A.745.745,0,0,0,7.148,6.4V2.744A.745.745,0,0,0,6.4,2H2.744m0-2H6.4A2.744,2.744,0,0,1,9.148,2.744V6.4A2.744,2.744,0,0,1,6.4,9.148H2.744A2.744,2.744,0,0,1,0,6.4V2.744A2.744,2.744,0,0,1,2.744,0Z"
          transform="translate(116.977 463.063)"
        />
      </g>
    </svg>
  );
}

export default IconAppsIntegrations;
