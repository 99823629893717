import { MenuItem } from "@/pages/Messaging/types";
import React from "react";

export interface ActionMenuItemProps {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
}

interface ActionMenuProps {
  items: MenuItem[];
  onClose: () => void;
  width?: string;
  onClick: (index: number) => void;
}

function ActionMenuItem(props: ActionMenuItemProps) {
  return (
    <ul className="divide-y divide-gray-200">
      <li>
        <button
          className="m-2 pr-2 w-[calc(100%-16px)] hover:bg-gray-100 hover:text-cornflower-blue-500 flex items-center"
          onClick={() => props.onClick()}
        >
          {props.icon}
          {props.label}
        </button>
      </li>
    </ul>
  );
}

export default function ActionMenu(props: ActionMenuProps) {
  const actionMenu = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        actionMenu.current &&
        !actionMenu.current.contains(event.target as Node)
      ) {
        props.onClose();
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [props]);

  return (
    <div
      ref={actionMenu}
      className={`${props.width} absolute right-0 mt-2 bg-white rounded-md shadow overflow-hidden z-10`}
    >
      {props.items.map((item, index) => (
        <ActionMenuItem
          key={index}
          {...item}
          onClick={() => props.onClick(index)}
        />
      ))}
    </div>
  );
}
