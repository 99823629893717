import React from "react";
import { IconProps } from "./types";

function IconNotificationsIndicator(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="currentColor"
        d="M4102 287a8 8 0 118-8 8.009 8.009 0 01-8 8zm0-5.264a1.236 1.236 0 101.206 1.229 1.147 1.147 0 00-1.206-1.229zm-1.019-8.336l.259 7.228h1.561l.292-7.228z"
        data-name="Subtraction 8"
        transform="translate(-4094 -271)"
      ></path>
    </svg>
  );
}

export default IconNotificationsIndicator;
