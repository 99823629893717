import React from "react";

export default function IllustrationChat() {
  return (
    <svg viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M130.97 48.1899L377.95 56.2199C419.14 57.5599 453.49 89.3299 456.7 127.24L472.72 316.56C475.93 354.47 447.41 391.56 407.03 399.81L164.91 449.24C106.35 461.2 52.42 431.34 48.22 381.77L27.27 134.29C23.08 84.7299 71.23 46.2299 130.96 48.1699L130.97 48.1899Z"
        fill="#EEF4FD"
      />
      <path
        d="M425.39 60.03L169.63 80.81L192.88 268.52L369.67 252.3L435.44 289.05L426.37 232.98L447.08 229.77L425.39 60.03Z"
        fill="black"
      />
      <path
        d="M435.31 53.74L187.15 73.59L198.8 262.22L374.61 233.92L428.96 277.19L422.7 227.82L457 223.47L435.31 53.74Z"
        fill="#629FF0"
      />
      <path
        d="M434.16 286.44L373.5 238.14L195.08 266.87L182.91 69.9099L438.8 49.4399L461.48 226.93L427.17 231.28L434.17 286.44H434.16ZM375.72 229.69L423.75 267.93L418.22 224.36L452.52 220.01L431.82 58.0299L191.37 77.2699L202.51 257.58L375.71 229.69H375.72Z"
        fill="black"
      />
      <path
        d="M390.49 157.21C400.746 157.21 409.06 148.896 409.06 138.64C409.06 128.384 400.746 120.07 390.49 120.07C380.234 120.07 371.92 128.384 371.92 138.64C371.92 148.896 380.234 157.21 390.49 157.21Z"
        fill="white"
      />
      <path
        d="M245.13 170.01C255.386 170.01 263.7 161.696 263.7 151.44C263.7 141.184 255.386 132.87 245.13 132.87C234.874 132.87 226.56 141.184 226.56 151.44C226.56 161.696 234.874 170.01 245.13 170.01Z"
        fill="white"
      />
      <path
        d="M317.81 163.61C328.066 163.61 336.38 155.296 336.38 145.04C336.38 134.784 328.066 126.47 317.81 126.47C307.554 126.47 299.24 134.784 299.24 145.04C299.24 155.296 307.554 163.61 317.81 163.61Z"
        fill="white"
      />
      <path
        d="M58.87 225.64L304.13 182.92L340.51 358.6L163.19 375.08L121.37 430.54L115.11 381.18L80.81 385.53L58.87 225.64Z"
        fill="black"
      />
      <path
        d="M118.64 440.81L111.64 385.65L77.38 390L54.37 222.37L307.26 178.32L345.33 362.18L165.32 378.91L118.64 440.82V440.81ZM118.58 376.71L124.11 420.28L161.07 371.26L335.69 355.03L301.01 187.52L63.36 228.92L84.24 381.06L118.58 376.7V376.71Z"
        fill="black"
      />
      <path
        d="M43 227.91L288.27 185.18L324.64 360.87L163.19 375.08L117.39 446.26L100.22 389.35L65.87 393.44L43 227.91Z"
        fill="black"
      />
      <path
        d="M58.87 225.64L304.13 182.92L340.51 358.6L163.19 375.08L121.37 430.54L115.11 381.18L80.81 385.53L58.87 225.64Z"
        fill="white"
      />
      <path
        d="M118.64 440.81L111.64 385.65L77.38 390L54.37 222.37L307.26 178.32L345.33 362.18L165.32 378.91L118.64 440.82V440.81ZM118.58 376.71L124.11 420.28L161.07 371.26L335.69 355.03L301.01 187.52L63.36 228.92L84.24 381.06L118.58 376.7V376.71Z"
        fill="black"
      />
      <path
        d="M264.323 229.146L121.836 251.434L123.072 259.338L265.559 237.05L264.323 229.146Z"
        fill="black"
      />
      <path
        d="M276.568 277.531L96.6321 302.499L97.7316 310.423L277.668 285.455L276.568 277.531Z"
        fill="black"
      />
      <path
        d="M276.577 317.483L206.785 327.093L207.876 335.018L277.668 325.408L276.577 317.483Z"
        fill="black"
      />
    </svg>
  );
}
