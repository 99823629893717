import * as React from "react";
import { FormGroup } from "../RightAsideHelpers";
import Input from "@/components/Input";
import { actionDefs, WorkflowNode } from "../../types";

interface Props {
  node: WorkflowNode;
  onUpdate: (node: Partial<WorkflowNode>) => void;
}

export default function ActionEditorBase({ node, onUpdate }: Props) {
  function renderActionEditor() {
    const Editor = actionDefs[node.actionType].editor;
    if (!Editor) {
      return null;
    }
    return <Editor node={node} onUpdate={onUpdate} />;
  }
  return (
    <>
      <FormGroup label="Node Name">
        <Input
          value={node.name || ""}
          onChange={(v) => onUpdate({ name: v })}
        />
      </FormGroup>
      {renderActionEditor()}
    </>
  );
}
