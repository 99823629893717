import React from "react";
import RemovableBaseBadge from "@velaro/velaro-shared/src/components/Badges/RemovableBaseBadge";

interface Props {
  files: File[];
  onUpdate(files: File[]): void;
}

export default function NewFileEditor(props: Props) {
  if (!props.files.length) {
    return null;
  }

  function renderFile(file: File, index: number) {
    let imageSrc: string | null = null;
    if (isFileImage(file)) {
      imageSrc = URL.createObjectURL(file);
    }

    return (
      <RemovableBaseBadge
        label={file.name}
        onClose={() => {
          const updated = [...props.files];
          updated.splice(index, 1);
          props.onUpdate(updated);
        }}
      />
      // Might need parts of this later so saving for now
      // <div
      //   title={file.name}
      //   className="border relative p-2 rounded w-36 h-40 flex flex-col gap-2 items-center justify-center"
      //   key={index}
      // >
      //   {imageSrc && (
      //     <img
      //       className="h-full min-h-0 object-scale-down flex-grow"
      //       src={imageSrc}
      //       onLoad={() => {
      //         URL.revokeObjectURL(imageSrc!);
      //       }}
      //     />
      //   )}
      //   {!imageSrc && (
      //     <i className="fa fa-file fa-8x flex-grow h-full min-h-0" />
      //   )}
      //   <div className="text-sm text-center w-full overflow-hidden whitespace-nowrap h-6">
      //     {file.name}
      //   </div>
      //   <div
      //     style={{ top: -10, right: -10 }}
      //     className="bg-white absolute border shadow rounded z-10"
      //   >
      //     <button
      //       title={`Remove ${file.name}`}
      //       className="text-crimson-red-600 w-8 h-8"
      //       onClick={() => {
      //         const updated = [...props.files];
      //         updated.splice(index, 1);
      //         props.onUpdate(updated);
      //       }}
      //     >
      //       <i className="fa fa-times"></i>
      //     </button>
      //   </div>
      // </div>
    );
  }

  return (
    <div className="flex flex-wrap cursor-default pb-4 mb-4 gap-5 pt-3">
      {props.files.map(renderFile)}
    </div>
  );
}

function isFileImage(file: File) {
  return file && file["type"].split("/")[0] === "image";
}
