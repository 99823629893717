import * as React from "react";
import { ArticleVisibility } from "../types";

export default function useArticleFormModel() {
  return React.useState({
    visibility: ArticleVisibility.Public,
    searchTags: "",
    content: "",
    description: "",
    topicId: "",
    slug: "",
    title: "",
    isPublished: false,
    url: "",
    displayPriority: 0
  });
}
