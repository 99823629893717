import useApi from "@/hooks/useApi";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import LoadingSpinner from "@velaro/velaro-shared/src/components/LoadingSpinner";
import Modal from "@velaro/velaro-shared/src/components/Modal";
import React, { useState } from "react";

interface FormstackConfig {
  displayName: string;
  token: string;
}

interface Props {
  config: FormstackConfig;
  modalType: "edit" | "add";
  handleSubmit(config: FormstackConfig): void;
  handleModalClose(): void;
}

export default function FormstackModal(props: Props) {
  const [config, setConfig] = React.useState<FormstackConfig>(props.config);
  const [displayName, setDisplayName] = React.useState(
    props.config.displayName
  );
  const [token, setToken] = React.useState(props.config.token);

  React.useEffect(() => {
    setConfig({ displayName, token });
  }, [displayName, token]);

  return (
    <div>
      <Modal show={true}>
        {props.modalType == "edit" && (
          <Modal.Header
            title="Edit Formstack Configuration"
            onClose={() => props.handleModalClose()}
          />
        )}
        {props.modalType == "add" && (
          <Modal.Header
            title="Add Formstack Configuration"
            onClose={() => props.handleModalClose()}
          />
        )}
        <form
          // className="px-4"
          onSubmit={(e) => {
            e.preventDefault();
            props.handleSubmit(props.config);
          }}
        >
          <Modal.Body>
            <div className="flex mb-4">
              <div className="w-1/3 font-semibold">Display Name</div>
              <input
                name="name"
                type="text"
                className="input w-2/3"
                autoFocus
                value={displayName}
                onChange={(e) => setDisplayName(e.currentTarget.value)}
              />
            </div>
            {/* <div className="flex mb-4">
                <div className="w-1/3 font-semibold"> NetSuite Company ID</div>
                <input
                  name="email"
                  type="text"
                  className="input w-2/3"
                  autoFocus
                  value={company}
                  onChange={(e) => setCompany(e.currentTarget.value)}
                />
              </div> */}
            <div className="flex mb-4">
              <div className="w-1/3 font-semibold">Token</div>
              <input
                name="email"
                type="text"
                className="input w-2/3"
                value={token}
                onChange={(e) => setToken(e.currentTarget.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="p-4 flex flex-col">
              <div className="ml-auto flex flex-row">
                <PrimaryBlueButton
                  onClick={() => props.handleSubmit(config)}
                  label="Save Changes"
                />
              </div>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}
