import Accordion from "@/components/Accordion";
import React, { useMemo } from "react";
import { LanguageConfigModel } from "@/pages/Messaging/types";

interface Props {
  onExpanded(): void;
  workingConfig: LanguageConfigModel;
  update: (updates: Partial<LanguageConfigModel>) => void;
  persist: () => void;
  loading: boolean;
}

export default function CalendlyForm(props: Props) {
  const saveButtonText = useMemo(() => {
    return props.loading ? "Saving..." : "Save and set live";
  }, [props.loading]);

  return (
    <Accordion.Item
      eventKey="calendlyOpen"
      onExpanded={() => {
        props.onExpanded();
      }}
      onCollapsed={() => {}}
    >
      <Accordion.Header>
        <Accordion.Title>Calendly</Accordion.Title>
        <Accordion.SubTitle>
          Enable Calendly to schedule meetings
        </Accordion.SubTitle>
      </Accordion.Header>
      <Accordion.Body>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            props.persist();
          }}
        >
          <div className="flex mb-4">
            <label>Enable Calendly</label>
            <input
              className="ml-2"
              type="checkbox"
              defaultChecked={props.workingConfig.calendlyEnabled}
              onClick={() =>
                props.update({
                  calendlyEnabled: !props.workingConfig.calendlyEnabled
                })
              }
            ></input>
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="calendly-title">
              Calendly Title
            </label>
            <input
              className="input"
              type="text"
              id="calendly-title"
              value={props.workingConfig.calendlyTitle}
              onChange={(e) => props.update({ calendlyTitle: e.target.value })}
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="calendly-subtitle">
              Calendly Subtitle
            </label>
            <input
              className="input"
              type="text"
              id="calendly-subtitle"
              value={props.workingConfig.calendlySubTitle}
              onChange={(e) =>
                props.update({ calendlySubTitle: e.target.value })
              }
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="calendly-url">
              Calendly URL
            </label>
            <input
              className="input"
              type="text"
              id="calendly-url"
              value={props.workingConfig.calendlyUrl}
              onChange={(e) => props.update({ calendlyUrl: e.target.value })}
            />
          </div>
          <div>
            <button className="btn-green" disabled={props.loading}>
              {saveButtonText}
            </button>
          </div>
        </form>
      </Accordion.Body>
    </Accordion.Item>
  );
}
