import * as React from "react";
import IconMinus from "@/icons/IconMinus";
import IconPlus from "@/icons/IconPlus";
import IconTarget from "@/icons/IconTarget";
import IconReverseLeft from "@/icons/IconReverseLeft";
import IconReverseRight from "@/icons/IconReverseRight";

export function WorkflowEditorControls({
  scale,
  zoomIn,
  zoomOut,
  resetTransform
}: {
  scale: number;
  zoomIn(step: number): void;
  zoomOut(step: number): void;
  resetTransform(): void;
}) {
  const iconClass = "text-slate-500 group-hover:text-cornflower-blue-500";

  return (
    <div className="flex flex-col items-center justify-center absolute left-10 top-10 z-50 w-7 gap-6">
      <Group>
        <Button onClick={() => zoomOut(0.1)}>
          <IconMinus className={iconClass} />
        </Button>
        <Border />
        <div className="w-10 h-10 relative flex items-center justify-center text-slate-500 text-xs cursor-default">
          {`${Math.round(scale * 100)}%`}
        </div>
        <Border />
        <Button onClick={() => zoomIn(0.1)}>
          <IconPlus className={iconClass} />
        </Button>
      </Group>
      <Group>
        <Button onClick={resetTransform}>
          <IconTarget className={iconClass} />
        </Button>
      </Group>
      <Group>
        <Button>
          <IconReverseLeft className={iconClass} />
        </Button>
        <Border />
        <Button>
          <IconReverseRight className={iconClass} />
        </Button>
      </Group>
    </div>
  );
}

function Group({ children }: { children: React.ReactNode }) {
  return (
    <div className="bg-white rounded-lg shadow-md border border-slate-200 flex-col justify-center items-center inline-flex">
      {children}
    </div>
  );
}

function Button({
  children,
  onClick
}: {
  children: React.ReactNode;
  onClick?: () => void;
}) {
  return (
    <div
      onClick={onClick}
      className="group w-10 h-10 relative flex items-center justify-center cursor-pointer hover:bg-slate-50 transition-colors duration-200 ease-in-out"
    >
      {children}
    </div>
  );
}

function Border() {
  return <div className="w-10 h-px border border-slate-200"></div>;
}
