import { useEffect, useState } from "react";

export default function useWarmup() {
  const [warmedUp, setWarmedUp] = useState(false);

  useEffect(() => {
    async function warmup() {
      await fetch(import.meta.env.VITE_ADMIN_API_URL + "/warmup", {
        method: "post"
      });

      setWarmedUp(true);
    }
    warmup();
  }, []);

  return { warmedUp };
}
