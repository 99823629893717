import Modal from "@velaro/velaro-shared/src/components/Modal";
import useApi from "@/hooks/useApi";
import React, { useState } from "react";
import TeamForm, { TeamModel, defaultTeamModel } from "./TeamForm";
import LoadingSpinner from "@/components/LoadingSpinner";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import {
  SecondaryBlueButton,
  PrimaryBlueButton
} from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import { Team } from "./types";

interface Props {
  show: boolean;
  onClose(): void;
  onTeamCreated(team: Team): void;
}

export default function CreateTeamModal(props: Props) {
  const api = useApi();
  const [team, setTeam] = React.useState<TeamModel>(defaultTeamModel);
  const [sending, setSending] = useState(false);
  const [error, setError] = useState("");
  const toastAlert = useToastAlert();

  async function handleSubmit() {
    setSending(true);
    const response = await api.messaging.post("Teams", team);
    if (response.ok) {
      const newTeam = await response.json();
      props.onTeamCreated(newTeam);
      toastAlert.displayToast(Severity.Success, "Team created successfully");
    } else {
      const error = await response.json();
      setError(error);
    }
    setSending(false);
  }

  return (
    <Modal show={props.show}>
      <Modal.Header title="New Team" onClose={() => props.onClose()} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Modal.Body>
          <div className="px-4">
            <TeamForm
              team={team}
              onChange={(updates) => {
                setTeam((oldState) => ({ ...oldState, ...updates }));
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="p-4 flex flex-col">
            {error != "" && (
              <div className="flex flex-row mb-4 text-red-500">{error}</div>
            )}
            {sending ? (
              <div className="ml-auto flex">
                Saving Changes...
                <LoadingSpinner />
              </div>
            ) : (
              <div className="ml-auto flex flex-row">
                <div className="mr-2">
                  <SecondaryBlueButton onClick={props.onClose} label="Cancel" />
                </div>
                <PrimaryBlueButton
                  onClick={() => handleSubmit()}
                  label="Save Changes"
                />
              </div>
            )}
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
