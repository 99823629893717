import React from "react";
import { IconProps } from "./types";

function IconGeneralSettings(props: IconProps) {
  return (
    <svg
      id="general-settings"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_10219"
            data-name="Rectangle 10219"
            width="24"
            height="24"
            fill="#90bcf5"
          />
        </clipPath>
      </defs>
      <g
        id="Group_9326"
        data-name="Group 9326"
        clipPath="url(#clip-path)"
        fill="currentColor"
      >
        <path
          id="Path_2342"
          data-name="Path 2342"
          d="M20.8,4.076H3.2a.88.88,0,0,0-.881.88V19.044a.88.88,0,0,0,.881.88H20.8a.88.88,0,0,0,.881-.88V4.956a.88.88,0,0,0-.881-.88m-.88,14.087H4.076V5.837H19.924Z"
          transform="translate(0 0)"
        />
        <path
          id="Path_2343"
          data-name="Path 2343"
          d="M5.837,6.717a.88.88,0,1,1-.88.88.88.88,0,0,1,.88-.88"
          transform="translate(0 0)"
        />
        <path
          id="Path_2344"
          data-name="Path 2344"
          d="M8.478,6.717a.88.88,0,1,1-.88.88.88.88,0,0,1,.88-.88"
          transform="translate(0 0)"
        />
        <path
          id="Path_2345"
          data-name="Path 2345"
          d="M11.12,6.717a.88.88,0,1,1-.88.88.88.88,0,0,1,.88-.88"
          transform="translate(0 0)"
        />
        <path
          id="Path_2346"
          data-name="Path 2346"
          d="M15.478,13.893a2.558,2.558,0,0,0,.044-.572,2.562,2.562,0,0,0-.044-.573l-.969-.22a1.8,1.8,0,0,0-.176-.44l.528-.836a3.369,3.369,0,0,0-.792-.793l-.836.528-.441-.176-.22-.968H11.428l-.22.968-.441.176-.836-.528a3.369,3.369,0,0,0-.792.793l.528.836a1.8,1.8,0,0,0-.176.44l-.969.22a2.562,2.562,0,0,0-.044.573,2.558,2.558,0,0,0,.044.572l.969.22a1.784,1.784,0,0,0,.176.44l-.528.837a3.383,3.383,0,0,0,.792.792l.836-.528.441.176.22.968h1.144l.22-.968.441-.176.836.528a3.384,3.384,0,0,0,.792-.792l-.528-.837a1.784,1.784,0,0,0,.176-.44ZM12,14.2a.881.881,0,1,1,.88-.88.88.88,0,0,1-.88.88"
          transform="translate(0)"
        />
      </g>
    </svg>
  );
}

export default IconGeneralSettings;
